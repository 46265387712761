import { Types as ClientVendorTypes } from "_types/ClientVendorTypes";

const initialState = {
  loading: false,
  data: {},
  error: "",
  reviewData: {},
  formModal: false,
  successModal: false,
  reviewModal: false,
  errMess: "",
  showKeyContactsModal: false,
  showKeyAllRegisteredLocationsModal: false,
  myReview: null,
  reviewsLoading: false,
  enquiryLoading: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ClientVendorTypes.GET_VENDOR_PROFILE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case ClientVendorTypes.GET_VENDOR_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result,
        error: "",
      };

    case ClientVendorTypes.GET_VENDOR_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case ClientVendorTypes.GET_VENDOR_PROFILE_REVIEW_SUCCESS:
      return {
        ...state,
        reviewData: action.result,
        error: "",
      };

    case ClientVendorTypes.SET_OPEN_REVIEW_FORM_MODAL_SUCCESS:
      return {
        ...state,
        loading: false,
        formModal: action?.payload,
      };
    
    case ClientVendorTypes.SET_VENDOR_PROFILE_REVIEW_REQUESTED:
      return {
        ...state,
        reviewsLoading: true,
      };
    
    case ClientVendorTypes.SET_VENDOR_PROFILE_REVIEW_SUCCESS:
      return {
        ...state,
        reviewsLoading: false,
      };

    case ClientVendorTypes.SET_VENDOR_PROFILE_REVIEW_FAILURE:
      return {
        ...state,
        reviewsLoading: false,
      };
    
    case ClientVendorTypes.SET_SUBMITTED_FORM_MODAL_SUCCESS:
      return {
        ...state,
        loading: false,
        successModal: action?.payload,
      };

    case ClientVendorTypes.SET_OPEN_REVIEW_MODAL_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewModal: action?.payload,
      };
    case ClientVendorTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errMess: action?.payload,
      };
    case ClientVendorTypes.SET_MY_REVIEW:
      return {
        ...state,
        myReview: action?.payload,
      };

    case ClientVendorTypes.SHOW_OR_HIDE_KEY_CONTACTS_MODAL:
      return { ...state, showKeyContactsModal: action?.payload };

    case ClientVendorTypes.SHOW_OR_HIDE_REGISTERED_LOCATIONS_MODAL:
      return { ...state, showKeyAllRegisteredLocationsModal: action?.payload };

    case ClientVendorTypes.POST_ENQUIRE_ABOUT_THE_VENDOR_REQUESTED:
      return {
        ...state,
        enquiryLoading: true,
      };
    
    case ClientVendorTypes.POST_ENQUIRE_ABOUT_THE_VENDOR_SUCCESS:
      return {
        ...state,
        enquiryLoading: false,
      };
    
    case ClientVendorTypes.POST_ENQUIRE_ABOUT_THE_VENDOR_FAILURE:
      return {
        ...state,
        enquiryLoading: false,
      };

    default:
      return state;
  }
};
