import React from 'react';
import { Box, Modal } from 'venwiz-ui';
import { AuthenticationButton } from '../Button/Button';

const SSOModal = (props: any) => {
    const ssoLink = process.env.REACT_APP_SSO_LINK;
    return (
        <Modal
          modalHeader={
            <Box
              display={"flex"}
              pl={"0rem"}
              pr={"0rem"}
              color={"#011759"}
              justifyContent={"center"}
              borderBottom={"2px solid #F4F9FF"}
              fontSize={"1.25rem"}
              fontWeight={600}
              position={"relative"}
            >
            {"Microsoft SSO Login"}
          </Box>
          }
          modalBody={
            <>
              <Box>
                Microsoft login has been enabled for your account. Please sign in with the button below
              </Box>
              <AuthenticationButton
                variant="outlined"
                onClick={() => window.open(ssoLink, "_self")}
              >
                Login Via Microsoft
              </AuthenticationButton>
            </>
          }
          show={true}
          handleClose={() => {props.setShowSSOModal(false)}}
          width={{ default: "100%", lg: "500px" }}
          padding={"32px"}
        />
    );
};

export default SSOModal;
