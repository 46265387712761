import styled from "styled-components";
import * as CSS from "csstype";
import { StylesProps } from "../types";
import { mediaQueries, styles } from "../utils";
import { InputHTMLAttributes } from "react";

export type IHTMLDiv = Omit<
  InputHTMLAttributes<HTMLDivElement>,
  "height" | "width" | "color"
>;
export interface BoxProps extends IHTMLDiv, StylesProps {
  cursor?: CSS.CursorProperty;
  color?: any;
}

export const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  cursor: ${(props) => props?.cursor || "default"};

  ${styles}
  ${mediaQueries}
`;
