import React from 'react'
import styled from 'styled-components'
import { AntdModal } from 'components/Modal'

interface RequestDemoProps {
  positiveAction: () => void;
  negativeAction: () => void;
}

const Div = styled.div`
display:flex;
height:calc(100vh - 310px);
justify-content:center;
align-items:center;
font-size:${props => props.theme.fontSizes.h1.fontSize};
color:${props => props.theme.colors.secondary};
font-weight:${props => props.theme.fontWeights.bold}
`
function RequestDemoModal(props: RequestDemoProps) {
  return (
    <>
      <AntdModal
        header='Request Demo'
        closable
        body={
          <Div>Coming Soon!!!</Div>
        }
        positiveAction={props.positiveAction}
        negativeAction={props.negativeAction}
      />
    </>
  )
}

export default RequestDemoModal
