import { Types as FileUploadTypes } from "_types/FileUploadTypes";

export const ActionCreators: any = {
  uploadFilesToPermanentFolder: (data: any) => ({
    types: [
      FileUploadTypes.FILE_OPERATIONS_REQUEST,
      FileUploadTypes.UPLOAD_FILES_TO_PERMANENT_FOLDER_SUCCESS,
      FileUploadTypes.FILE_OPERATIONS_FAILURE,
    ],
    promise: (client: any) => client.post("upload_files_to_permanent_folder", { data }),
  }),
};

export default ActionCreators;
