import { Types as ClientCommunicationTypes } from "_types/ClientCommunicationTypes";

const initialState = {
  hasMoreMessages: null,
  initiateChat: null,
  chatHeadData: null,
  initialChat: null,
  loading: false,
  loaded: false,
  error: false,
  errorData: null,
  chatList: null,
  chatDetails: null,
  newMessage: {},
  messages: [],
  sentMessageStatus: false,
  totalUnreadMessage: 0,
  deletedChat: false,
  deltedChatMessage: null,
  isScrollingActive: false,
  fileUploadData: null,
  sharedFiles: null,
  selectedCommunicationType: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ClientCommunicationTypes.CHAT_INITIATE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case ClientCommunicationTypes.CHAT_INITIATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        initiateChat: action.result,
      };

    case ClientCommunicationTypes.CHAT_INITIATE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };
    case ClientCommunicationTypes.CHAT_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case ClientCommunicationTypes.CHAT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        chatListData: action.result,
        totalUnreadMessage: action.result.totalUnreadMessages,
      };

    case ClientCommunicationTypes.CHAT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case ClientCommunicationTypes.CHAT_MESSAGES_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case ClientCommunicationTypes.CHAT_MESSAGES_SUCCESS:
      if (state.isScrollingActive) {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: false,
          messages: action?.result?.messages || [],
          hasMoreMessages: Boolean(action?.result?.messages),
          selectedCommunicationType: action?.result?.communicationType,
        };
      } else {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: false,
          messages: action?.result?.messages || [],
          hasMoreMessages: action?.result?.messages?.length > 4 ? true : false,
          selectedCommunicationType: action?.result?.communicationType,
        };
      }

    case ClientCommunicationTypes.CHAT_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case ClientCommunicationTypes.CHAT_OLD_MESSAGES_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case ClientCommunicationTypes.CHAT_OLD_MESSAGES_SUCCESS:
      if (action.result.messages.length) {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: false,
          messages: [...action.result.messages, ...state.messages],
          hasMoreMessages: true,
        };
      } else {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: false,
          chatDetails: action.result.message,
          hasMoreMessages: false,
        };
      }

    case ClientCommunicationTypes.CHAT_OLD_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case ClientCommunicationTypes.CHAT_SEND_MESSAGE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case ClientCommunicationTypes.CHAT_SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        sentMessageStatus: action.result,
      };

    case ClientCommunicationTypes.CHAT_SEND_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case ClientCommunicationTypes.GET_SELECTED_CHAT_ID:
      return {
        ...state,
        chatHeadData: action.payload,
      };

    case ClientCommunicationTypes.CLEAR_SELECTED_CHAT_ID:
      return {
        ...state,
        initiateChat: action.payload,
      };

    case ClientCommunicationTypes.DELETE_CHAT_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case ClientCommunicationTypes.DELETE_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        deletedChat: true,
        messages: action.result.messages,
      };

    case ClientCommunicationTypes.DELETE_CHAT_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        deletedChat: false,
        errorData: action.error.message,
      };
    //   case ClientCommunicationTypes.DELETE_ALL_CHAT_REQUESTED:
    //   return {
    //     ...state,
    //     loading: true,
    //   };

    // case ClientCommunicationTypes.DELETE_ALL_CHAT_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     loaded: true,
    //     deletedChat: true,
    //     deltedChatMessage: action.result
    //   };

    // case ClientCommunicationTypes.DELETE_ALL_CHAT_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     loaded: true,
    //     deletedChat: false,
    //     errorData: action.error.message,
    //   };

    case ClientCommunicationTypes.RESET_HAS_MORE_MESSAGES:
      return {
        ...state,
        hasMoreMessages: false,
      };

    case ClientCommunicationTypes.SET_IS_SCROLLING_ACTIVE:
      return {
        ...state,
        isScrollingActive: action.payload,
      };

    case ClientCommunicationTypes.CHAT_FILES_UPLOAD_REQUEST:
      return {
        ...state,
      };
    case ClientCommunicationTypes.CHAT_FILES_UPLOAD_SUCCESS:
      return {
        ...state,
        fileUploadData: action.result,
      };
    case ClientCommunicationTypes.CHAT_FILES_UPLOAD_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ClientCommunicationTypes.GET_CHAT_SHARED_FILES_REQUESTED:
      return {
        ...state,
      };

    case ClientCommunicationTypes.GET_CHAT_SHARED_FILES_SUCCESS:
      return {
        ...state,
        error: false,
        sharedFiles: action.result,
      };

    case ClientCommunicationTypes.GET_CHAT_SHARED_FILES_FAILURE:
      return {
        ...state,
        error: true,
        errorData: action.error.message,
      };
    case ClientCommunicationTypes.SET_BROADCAST_MESSAGES: 
    return {
      ...state,
      messages: action.payload,
    }

    default:
      return state;
  }
};
