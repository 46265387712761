import { handleLogout } from "shared/handlers/logout.handler";

const clientMiddleware = client => ({
  dispatch,
  getState,
}) => next => action => {
  if (typeof action === "function") {
    return action(dispatch, getState);
  }

  const { promise, types, ...rest } = action;

  if (!promise) {
    return next(action);
  }

  const [REQUEST, SUCCESS, FAILURE] = types;
  next({ ...rest, type: REQUEST });

  const actionPromise = promise(client);
  actionPromise
    .then(
      result => next({ ...rest, result, type: SUCCESS }),
      error => {
        if (
          error.statusCode === 403 &&
          error.message.toLowerCase().includes("token error")
        ) {
          handleLogout({ dispatch });
        }
        next({ ...rest, error, type: FAILURE });
      }
    )
    .catch(error => {
      // If needed, we can invoke a logger service later
      // eslint-disable-next-line no-console
      console.log("MIDDLEWARE ERROR:", error);
      next({ ...rest, error, type: FAILURE });
    });

  return actionPromise;
};

export default clientMiddleware;
