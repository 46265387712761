import { heapIdentifyTrigger, initializeHeapAnalyticsOnlyForNonInternalVenwizUser } from "shared/helpers/heapAnalytics.helper";
import ReactGA from "react-ga";
import { Event } from "../../analytics/UniversalGoogleAnalytics/UniversalGoogleAnalytics";
import ApiConstant from "constants/ApiConstant";
import LocalStorage from "_services/LocalStorage";
import SessionStorage from "_services/SessionStorage";

const { STORAGE_KEYS } = ApiConstant;

export const analyticsHandler = (res: any) => {
  /** For Pendo */
  if (res.userData?._id) {
    (window as any)?.pendo?.initialize({
      visitor: { id: res.userData?._id }, // Required if user is logged in
      account: { id: "kavitha@venwiz.com" }, // Required if using Pendo Feedback
    });
  }
  /** For Heap Analytics */
  initializeHeapAnalyticsOnlyForNonInternalVenwizUser(res?.userData?.email , window);
  if ((window as any)?.heap) heapIdentifyTrigger((window as any)?.heap, res?.userData?.email);
  /** For Google Analytics */
  if (res.userData?._id) {
    ReactGA.set({ userId: res.userData?._id });
    Event("Login", "Login into Client Module", "Login Page");
  }
};

export const updateLocalStorageAndSessionStorageHandler = (res: any, isRemembered: boolean) => {
  if (isRemembered) {
    LocalStorage.set(STORAGE_KEYS.userData, res);
    LocalStorage.set(STORAGE_KEYS.isRememberEnabled, 1);
  } else {
    SessionStorage.set(STORAGE_KEYS.userData, res);
    LocalStorage.set(STORAGE_KEYS.isRememberEnabled, 0);
  }
  const applicationFlags = LocalStorage.get(STORAGE_KEYS.applicationFlags);
  if (!applicationFlags) LocalStorage.set(STORAGE_KEYS.applicationFlags, { showDemoVideo: true });
  LocalStorage.set(ApiConstant.STORAGE_KEYS.isSubsequentUser, res.isSubsequentUser);
  LocalStorage.set(ApiConstant.STORAGE_KEYS.isFirstCompanyUser, res?.isFirstCompanyUser);
};

const redirectGuestUser = (linkTo: string, history: any) => {
  switch (linkTo) {
    case "vendorList":
      return history.push("/vendorList");

    case "clientVendorView":
      /** pushToClientVendorView */
      const gstn = SessionStorage.get("clientVendorViewVisitedTab");
      SessionStorage.set(ApiConstant.STORAGE_KEYS.showBackButton, false);
      history.push(`/vendor/${gstn}`);
      break;

    case "compareVendors":
      /** pushToCompareVendors */
      const compareVendorLink = SessionStorage.get("compareVendorLink");
      history.push(`${compareVendorLink}`);
      break;

    case "project":
      /** navigateToClientProjects */
      const projectPageLink = SessionStorage.get(ApiConstant.STORAGE_KEYS.prevUrl);
      SessionStorage.remove(ApiConstant.STORAGE_KEYS.prevUrl);
      history.push(`${projectPageLink}`);
      break;
    
    case "job-request":
    case "job":
      const jobRequestLink = SessionStorage.get(ApiConstant.STORAGE_KEYS.prevUrl);
      SessionStorage.remove(ApiConstant.STORAGE_KEYS.prevUrl);
      history.push(`${jobRequestLink}`);
      break;

    default:
      return history.push("/dashboard");
  }
};

export const redirectionHandler = (res: any, history: any) => {
  if (res?.isFirstTimeLogin) {
    LocalStorage.remove(STORAGE_KEYS.userEmail);
    history.push("/userInfo");
  } else {
    const linkTo = SessionStorage.get("guestRedirection");
    redirectGuestUser(linkTo, history);
  }
};

export const getErrorMessage = (err: any) => {
  try {
    return JSON.parse(err?.message)?.["error_description"]; // coming from auth0
  } catch (error) {
    return (err?.message as string)?.endsWith(".") ? (err?.message as string)?.replace(".", "") : err?.message || "";
  }
};
