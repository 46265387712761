/* eslint-disable */
import { Login, ResetPassword, Registration } from "authentication";
import ForgotPassword from "authentication/forgotPassword/ForgotPassword";
import PlantInfo from "authentication/registration/PlantInfo";
import { Spinner } from "components/Spinner";
import ApiConstant from "constants/ApiConstant";
import Main from "Main";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import LocalStorage from "_services/LocalStorage";
import SessionStorage from "_services/SessionStorage";
import { checkPrevRoute } from "shared/handlers/prevRouteCheck.handler";
import { VerifyUser } from "VerifyUser/VerifyUser";
const { STORAGE_KEYS } = ApiConstant;
import { SubmitReviewOpenPage } from "OpenPages/SubmitReviewOpenPage/SubmitReviewOpenPage";
import { OpenLogin } from "authentication/login/OpenLogin";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userData = LocalStorage.get(STORAGE_KEYS.isRememberEnabled)
    ? LocalStorage.get(STORAGE_KEYS.userData)
    : SessionStorage.get(STORAGE_KEYS.userData);
  checkPrevRoute(userData, rest);
  return (
    <Route
      {...rest}
      render={props =>
        userData && userData !== null ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathName: "/login", state: { from: props.location } }} />
        )
      }
    />
  );
};

const ContactListView = React.lazy(() => import("contactListView/ContactListView"));
const ClientVendorView = React.lazy(() => import("clientVendorView/ClientVendorView"));
const ProfileView = React.lazy(() => import("clientVendorView/ProfileView"));
const CompareVendors = React.lazy(() => import("compareVendors/Table"));
const ExploreVendors = React.lazy(() => import("dashboard/components/CategoryList/CategoryList"));

const Dashboard = React.lazy(() => import("revamp/pages/Dashboard/Dashboard"));
const UserInfo = React.lazy(() => import("profileCreation/userInfo/UserInfo"));
const PlantVisit = React.lazy(() => import("Request/PlantVisit/PlantVisit"));
const SelectVendor = React.lazy(() => import("Request/PlantVisit/SelectVendor"));
const Requests = React.lazy(() => import("Request/RequestListTabs"));
const JobRequest = React.lazy(() => import("Request/JobRequest/JobRequest"));
const ActivityLogs = React.lazy(() => import("Request/JobRequest/ActivityLogs/AllActivityLogs"));
const ComparisonReportView = React.lazy(() => import("Request/JobRequest/ComparisonReportView/ComparisonReportView"));
const PlantInfoUpdate = React.lazy(() => import("profileCreation/PlantInfo/PlantInfo"));
const Invite = React.lazy(() => import("Invite"));
const MarketingSuite = React.lazy(() => import("marketingSuite/MarketingSuite"));
const VendorListing = React.lazy(() => import("vendorList/VendorList"));
const Support = React.lazy(() => import("helpandsupport/Support"));
//ChatModule
const ChatModule = React.lazy(() => import("ChatModule"));

const GuestVendorsList = React.lazy(() => import("GuestUsers/GuestVendorsList"));
const GuestLandingPage = React.lazy(() => import("GuestUsers/SearchVendor"));
const ComparedQuotePage = React.lazy(() => import("Request/JobRequest/QuoteComparison/Pages/QuoteComparison.page"));

const Routes = (
  <Router>
    <Suspense fallback={<Spinner />}>
      <Main>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/:inviteId/login" component={Login} />
          <Route exact path="/registration" component={Registration} />
          <Route exact path="/registration?subcategoryId=:subcategoryId" component={Registration} />
          <Route exact path="/resetpassword" component={ResetPassword} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/forgotpassword/:id" component={VerifyUser} />
          <Route exact path="/" component={Login}>
            <Redirect to="/login" />
          </Route>
          <PrivateRoute exact path="/userInfo" component={UserInfo} />
          <PrivateRoute exact path="/explore" component={ExploreVendors} />

          <PrivateRoute exact path="/plantInfo" component={PlantInfoUpdate} />

          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/vendorlist" component={VendorListing} />

          <Route exact path="/search-vendors" component={GuestLandingPage} />
          <Route exact path="/search-vendors/:searchkey" component={GuestVendorsList} />

          <Route exact path="/vendor/:gstn" component={ProfileView} />
          <Route exact path="/vendor/:gstn/:profileType" component={ProfileView} />
          <Route exact path="/contactList/:gstn" component={ContactListView} />

          <Route exact path="/marketingSuite" component={MarketingSuite} />

          <PrivateRoute exact path="/click/compareVendors/:gstn" component={CompareVendors} />
          <PrivateRoute exact path="/share/compareVendors/:gstn" component={CompareVendors} />
          <Route exact path="/support" component={Support} />
          <PrivateRoute exact path="/communication" component={ChatModule} />
          <PrivateRoute exact path="/invite" component={Invite} />
          <PrivateRoute exact path="/request/plantvisit/:requestId" component={PlantVisit} />
          <PrivateRoute exact path="/request" component={SelectVendor} />
          <PrivateRoute exact path="/requests" component={Requests} />
          <PrivateRoute exact path="/job-requests" component={Requests} />
          <PrivateRoute exact path="/job-requests/:summaryFilter" component={Requests} />
          <PrivateRoute exact path="/jobs" component={Requests} />
          <PrivateRoute exact path="/jobs/:summaryFilter" component={Requests} />
          <PrivateRoute exact path="/project" component={Requests} />
          <PrivateRoute exact path="/reports" component={Requests} />
          <PrivateRoute exact path="/job-request/:id" red component={JobRequest} >
            <Redirect to="/job/:jrId/vendors" component={JobRequest} />
          </PrivateRoute>
          <PrivateRoute exact path="/job-request/:id/:resolutionId" component={JobRequest}>
            <Redirect to="/job/:jrId/approvals/:resolutionId" component={JobRequest} />
          </PrivateRoute>
          
          <PrivateRoute exact path="/activity-logs/:requestId" component={ActivityLogs} />
          {/* jobs page */}
          <PrivateRoute exact path="/job/:jrId/job-scope" component={JobRequest} />
          <PrivateRoute exact path="/job/:jrId/vendors" component={JobRequest} />
          <PrivateRoute exact path="/job/:jrId/vendors/:gstn" component={JobRequest} />q
          <PrivateRoute exact path="/job/:jrId/documents" component={JobRequest} />
          <PrivateRoute exact path="/job/:jrId/quotations" component={JobRequest} />
          <PrivateRoute exact path="/job/:jrId/approvals" component={JobRequest} />
          <PrivateRoute exact path="/job/:jrId/approvals/:resolutionId" component={JobRequest} />
          <PrivateRoute exact path="/job/:jrId/final-vendors" component={JobRequest} />
          <PrivateRoute exact path="/job/:jrId/activity-logs" component={JobRequest} />
          <PrivateRoute exact path="/job/:jrId/comparison-reports" component={JobRequest} />
          <Route exact path="/:jrId/comparison-report/:reportId" component={ComparisonReportView} />
          <Route exact path="/job/:jrId/quote-comparison/:type" component={ComparedQuotePage} />
          <Route exact  path="/submit-review" component={SubmitReviewOpenPage} />
          <Route exact path="/submit-review/:gstn/:projId/:clientEmail/:vendorEmail" component={SubmitReviewOpenPage} />
          <Route exact path="/open-login/:token" component={OpenLogin} />
          <Route exact path="" component={Login}>
            <Redirect to="/login" />
          </Route>
        </Switch>
      </Main>
    </Suspense>
  </Router>
);

export default Routes;
