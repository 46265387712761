import * as Yup from "yup";

export const validateResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Password is required")
    .min(10, "Password is too short - must have at least 10 characters")
    .matches(/(?=.*[a-z])/, "Password must have at least 1 lowercase character")
    .matches(/(?=.*[A-Z])/, "Password must have at least 1 uppercase character")
    .matches(/(?=.*\W)|(?=.*\d)/, "Password must have at least 1 number or special character "),
  confirmPassword: Yup.string()
    .required("Confirm your password")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});
