import { COLORS } from 'components/package/package.styles';
import React, { useEffect } from 'react'
import { Box, Button, Flex, IProject, Typography } from 'venwiz-ui';
import { 
  AVAILABLE_RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE,
  INITIAL_RATINGS_STATE,
  IReqForReviewRadioKeys,
  RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES,
  RATING_VALUES,
  RESPONSE_PAGE_TYPE,
} from './submitReviewOpenPage.constants';
import { useHistory, useParams } from 'react-router';
import LocalStorage from '_services/LocalStorage';
import ApiConstant from 'constants/ApiConstant';
import axios from "axios";
import ApiConfig from '_services/ApiConfig';
import { ClientVendorActions } from 'clientVendorView/actions';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { getCurrentDomainUrlOfApp } from 'shared/helpers/url.helpers';
import { SubmitReviewResponsePageContent } from './SubmitReviewResponsePageContent';


export const SubmitReviewOpenPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlParams = useParams<{ gstn?: string, projId?: string, clientEmail?: string, vendorEmail?: string }>();
  const { gstn, projId, clientEmail, vendorEmail } = urlParams;
  const localSubmitReviewUrlParams: typeof urlParams = LocalStorage.get(ApiConstant.STORAGE_KEYS.submitReviewUrlParams);
  const clearLocalValues = () => LocalStorage.remove(ApiConstant.STORAGE_KEYS.submitReviewUrlParams);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [vendorCompanyName, setVendorCompanyName] = React.useState<string>("");
  const [relevantExpObj, setRelevantExpObj] = React.useState<IProject | null>(null);
  const [reviewDescription, setReviewDescription] = React.useState<string>("");
  const [ratings, setRatings] = React.useState({ overallRating: 0, ...INITIAL_RATINGS_STATE });
  const [dataFromUrl, setDataFromUrl] = React.useState<typeof urlParams>({
    gstn: "", projId: "", clientEmail: "", vendorEmail: "",
  });
  const CURRENT_URL = window.location.href;
  const VENDOR_PROFILE_LINK = `${getCurrentDomainUrlOfApp(CURRENT_URL)}vendor/${dataFromUrl.gstn}`;

  const [showReviewForm, setShowReviewForm] = React.useState(false);
  const [showErrorPageContent, setShowErrorPageContent] = React.useState(false);
  const [showSuccessPageContent, setShowSuccessPageContent] = React.useState(false);
  const [showAlreadySubmittedPageContent, setShowAlreadySubmittedPageContent] = React.useState(false);

  useEffect(() => {
    if (gstn && projId && clientEmail && vendorEmail) {
      LocalStorage.set(
        ApiConstant.STORAGE_KEYS.submitReviewUrlParams,
        { gstn, projId, clientEmail, vendorEmail }
      );
      /** remove the url params */
      window.location.replace("/submit-review");
    } else if (
      localSubmitReviewUrlParams?.gstn
      && localSubmitReviewUrlParams?.projId
      && localSubmitReviewUrlParams?.clientEmail
      && localSubmitReviewUrlParams?.vendorEmail
    ) {
      setDataFromUrl(localSubmitReviewUrlParams);
    } else {
      notification.error({
        message: "Error", 
        onClose: () => { history.push("/login") },
        description: "Incomplete data present. Navigating to login",
      });
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (dataFromUrl?.gstn && dataFromUrl?.projId && dataFromUrl?.clientEmail && dataFromUrl?.vendorEmail) {
      /** make open-exp get call with gstn */
      (async () => {
        const resp = await axios({
          method: "GET",
          url: process.env.REACT_APP_API_URL 
            + `/${ApiConfig.endpoints.open_experience}/`
            + `${dataFromUrl.gstn}`,
        });
        if (resp.status === 200) {
          const existingExperiences: IProject[] = resp?.data?.experience || [];
          if (existingExperiences.length === 0) {
            notification.error({ 
              message: "Error", 
              description: "No Experience present for this gstn",
            });
          } else {
            const relevantExp = existingExperiences?.find((exp: IProject) => {
              return (exp?._id === dataFromUrl?.projId
                && exp?.review?.clientDetails?.email === dataFromUrl?.clientEmail
              );
            });
            if (relevantExp?.review) {
              if (relevantExp?.review?.reviewStatus === "reviewRequested") {
                /** proceed with showing the review page */
                setShowReviewForm(true);
                setRelevantExpObj(relevantExp);
                dispatch(ClientVendorActions.getVendorProfileDataWithoutToken(dataFromUrl?.gstn)).then((resp: any) => {
                  setVendorCompanyName(resp?.companyInfo?.companyName as string || "");
                });
              } else {
                /** show already submitted page */
                setShowAlreadySubmittedPageContent(true);
              }
            }
          }
        } else {
          notification.error({ 
            message: "Error", 
            description: "Unable to fetch vendor experience details",
          });
        }
      })();
    }
  }, [
    dataFromUrl?.gstn,
    dataFromUrl?.projId,
    dataFromUrl?.clientEmail,
    dataFromUrl?.vendorEmail
  ]);

  const isValidPayload = (overallRating: typeof ratings["overallRating"]) => {
    if (!overallRating) {
      notification.error({ 
        message: "Error", 
        description: "Overall Rating is mandatory!",
      });
      return false;
    }
    return true;
  };

  const prepareSubmitReviewPayload = ({ review, dataFromUrl } : {
    review: IProject["review"],
    dataFromUrl: typeof urlParams,
  }) => {
    const givenRatings: Partial<typeof ratings> = {};
    /** excluding ratings with 0 value */
    Object.keys(ratings).forEach((key) => {
      if (key === "overallRating" || ratings[key as keyof typeof ratings]) {
        givenRatings[key as keyof typeof ratings] = ratings[key as keyof typeof ratings];
      }
    });

    const payload = {
      gstn: dataFromUrl.gstn,
      experienceId: dataFromUrl.projId,
      vendorEmail: dataFromUrl.vendorEmail,
      vendorCompanyName: vendorCompanyName || "",

      clientEmail: dataFromUrl.clientEmail,
      clientName: review?.clientDetails?.name,
      companyName: review?.clientDetails?.company,

      overallReview: reviewDescription || "",
      ...givenRatings,
      ...review,
    };

    return payload;
  };

  const handleSubmitReview = () => {
    const payload = prepareSubmitReviewPayload({
      review: relevantExpObj?.review as IProject["review"],
      dataFromUrl,
    });
    if (isValidPayload(payload?.overallRating)) {
      setIsSubmitting(true);
      /** POST API Call */
      (async () => {
        try {
          const resp = await axios({
            method: "POST",
            url: process.env.REACT_APP_API_URL 
              + `/${ApiConfig.endpoints.save_review_details}`,
            data: payload,
          });
          if (resp?.data?.status === "SUCCESS") {
            setShowSuccessPageContent(true);
          } 
          setIsSubmitting(false);
        } catch (err) {
          const error = err as any;
          if (error?.response?.data?.message 
            && error?.response?.data?.message === "Review already submitted"
          ) {
            setShowAlreadySubmittedPageContent(true);
          } else {
            setShowErrorPageContent(true);
          }
          setIsSubmitting(false);
        }
      })();
    }
  };

  const renderRatingRow = ({ label, radioName }: {
    label: React.ReactNode, radioName: keyof typeof ratings
  }) => {
    return (
      <Flex
        gap={"16px"}
        width={"100%"}
        flexWrap={"wrap"}
        overflow={"hidden"}
        marginBottom={"8px"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Typography
          fontWeight={400}
          variant={"span"}
          fontSize={"12px"}
        >
          {label}
        </Typography>
        <Flex
          gap={"8px"}
          width={"max-content"}
          alignItems={"center"}
          justifySelf={"flex-end"}
          justifyContent={"space-between"}
        >
          {RATING_VALUES.map((value) => {
            return (
              <Flex 
                key={value}
                gap={"2px"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <input
                  key={value}
                  type="radio" 
                  value={value} 
                  name={radioName}
                  checked={ratings[radioName] === value}
                  onChange={() => {
                    setRatings({ ...ratings, [radioName]: value });
                  }}
                />
                <Typography 
                  fontSize={"12px"}
                >
                  {String(value)}
                </Typography>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    );
  };

  if (!dataFromUrl.gstn 
    || !dataFromUrl.projId 
    || !dataFromUrl.clientEmail 
    || !dataFromUrl.vendorEmail
  ) {
    return <></>;
  } else if (showErrorPageContent) {
    return (
      <SubmitReviewResponsePageContent 
        TYPE={RESPONSE_PAGE_TYPE.ERROR}
        vendorProfileLink={VENDOR_PROFILE_LINK} 
      />
    )
  } else if (showAlreadySubmittedPageContent) {
    return (
      <SubmitReviewResponsePageContent 
        TYPE={RESPONSE_PAGE_TYPE.ALREADY_SUBMITTED}
        vendorProfileLink={VENDOR_PROFILE_LINK} 
      />
    );
  } else if (showSuccessPageContent) {
    return (
      <SubmitReviewResponsePageContent 
        TYPE={RESPONSE_PAGE_TYPE.SUCCESS}
        vendorProfileLink={VENDOR_PROFILE_LINK} 
      />
    );
  } else if (showReviewForm) {

    return (
      <Flex
        gap={"16px"}
        height={"100vh"}
        padding={"16px 0"}
        overflow={"hidden"}
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={"center"}
        margin={{ default: "0 auto" }}
        width={{ default: "100vw", xs: "380px"}}
      >
        {/* Page Title */}
        <Flex
          gap={"5px"}
          width={"100%"}
          padding={"4px 16px"}
          backgroundColor={COLORS.alice_blue}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <Typography
            fontSize={"14px"}
            fontWeight={400}
          >
            Review for 
          </Typography>
          <Typography
            fontSize={"14px"}
            fontWeight={700}
          >
            {vendorCompanyName}
          </Typography>
        </Flex>
  
        {/* Overall Rating container */}
        <Flex
          width={"100%"}
          paddingLeft={"32px"}
          paddingRight={"32px"}
          alignItems={"center"}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
        >
          {renderRatingRow({
            radioName: "overallRating",
            label: (
              <Flex alignItems={"center"}>
                <Typography
                  variant={"span"}
                  fontSize={"12px"}
                  fontWeight={700}
                >
                  Overall Rating
                </Typography>
                <Typography
                  variant={"span"}
                  fontSize={"12px"}
                  color={COLORS.red_1}
                >
                  *
                </Typography>
              </Flex>
            ),
          })}
        </Flex>
  
        {/* Textarea container */}
        <Box
          width={"100%"}
          paddingLeft={"8px"}
          paddingRight={"8px"}
        >
          <textarea 
            placeholder={"Add review for the vendor here"}
            value={reviewDescription || ""}
            onChange={(e) => setReviewDescription(e.target.value)}
            rows={3}
            style={{
              padding: "8px",
              width: "100%",
              resize: "none",
              borderRadius: "4px",
              color: COLORS.blue_2,
              border: `1px solid ${COLORS.blue_2}`,
            }}
          />
        </Box>
  
        {/* Other Ratings container */}
        <Flex
          width={"100%"}
          overflowY={"auto"}
          maxHeight={"246px"}
          overflowX={"hidden"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box
            paddingLeft={"16px"}
            paddingRight={"16px"}
            height={"100%"}
          >
            {AVAILABLE_RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE.map(({ keyName, label }) => {
              return (
                <React.Fragment key={keyName}>
                  {renderRatingRow({
                    radioName: keyName,
                    label: (
                      <Typography
                        fontWeight={400}
                        variant={"span"}
                        fontSize={"12px"}
                      >
                        {label}
                      </Typography>
                    ),
                  })}
                </React.Fragment>
              );
            })}
          </Box>
        </Flex>
  
        {/* Submit button */}
        <Flex
          width={"100%"}
          marginTop={"8px"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Button
            colorSchema={"green"}
            variant={"primary"}
            width={"max-content"}
            disabled={!ratings?.overallRating || isSubmitting}
            onClick={() => handleSubmitReview()}
            style={{ cursor: !ratings?.overallRating ? "not-allowed" : "pointer" }}
          >
            Submit Review
          </Button>
        </Flex>
      </Flex>
    );
  } 

  return <></>;
};
