export const Types = {
  GET_VENDOR_REQUEST_REQUESTED: "vendorRequest/GET_VENDOR_REQUEST_REQUESTED",
  GET_VENDOR_REQUEST_SUCCESS: "vendorRequest/GET_VENDOR_REQUEST_SUCCESS",
  GET_VENDOR_REQUEST_FAILURE: "vendorRequest/GET_VENDOR_REQUEST_FAILURE",

  GET_JOB_REQUEST_REQUESTED: "jobRequest/GET_JOB_REQUEST_REQUESTED",
  GET_JOB_REQUEST_SUCCESS: "jobRequest/GET_JOB_REQUEST_SUCCESS",
  GET_JOB_REQUEST_FAILURE: "jobRequest/GET_JOB_REQUEST_FAILURE",

  UPLOAD_RFQ_REQUESTED: "jobRequest/UPLOAD_RFQ_REQUESTED",
  UPLOAD_RFQ_SUCCESS: "jobRequest/UPLOAD_RFQ_SUCCESS",
  UPLOAD_RFQ_FAILURE: "jobRequest/UPLOAD_RFQ_FAILURE",

  SEND_RFQ_SUCCESS: "jobRequest/SEND_RFQ_SUCCESS",
  SEND_RFQ_REQUEST: "jobRequest/SEND_RFQ_REQUEST",
  SEND_RFQ_FAILURE: "jobRequest/SEND_RFQ_FAILURE",

  RECOMMEND_VENDOR_REQUESTED: "jobRequest/RECOMMEND_VENDOR_REQUESTED",
  RECOMMEND_VENDOR_SUCCESS: "jobRequest/RECOMMEND_VENDOR_SUCCESS",
  RECOMMEND_VENDOR_FAILURE: "jobRequest/RECOMMEND_VENDOR_FAILURE",

  ADD_VENDOR_REQUESTED: "jobRequest/ADD_VENDOR_REQUESTED",
  ADD_VENDOR_SUCCESS: "jobRequest/ADD_VENDOR_SUCCESS",
  ADD_VENDOR_FAILURE: "jobRequest/ADD_VENDOR_FAILURE",

  REMOVE_VENDOR_SUCCESS: "jobRequest/REMOVE_VENDOR_SUCCESS",

  REMOVE_RFQ_DOCUMENT_REQUESTED: "jobRequest/REMOVE_RFQ_DOCUMENT_REQUESTED",
  REMOVE_RFQ_DOCUMENT_SUCCESS: "jobRequest/REMOVE_RFQ_DOCUMENT_SUCCESS",
  REMOVE_RFQ_DOCUMENT_FAILURE: "jobRequest/REMOVE_RFQ_DOCUMENT_FAILURE",

  SET_RFQ_UPLOAD_ERROR_FILE_LIST: "jobRequest/SET_RFQ_UPLOAD_ERROR_FILE_LIST",

  GET_JOB_REQUEST_RESOLUTION_LIST_REQUESTED: "jobRequest/GET_JOB_REQUEST_RESOLUTION_LIST_REQUESTED",
  GET_JOB_REQUEST_RESOLUTION_LIST_SUCCESS: "jobRequest/GET_JOB_REQUEST_RESOLUTION_LIST_SUCCESS",
  GET_JOB_REQUEST_RESOLUTION_LIST_FAILURE: "jobRequest/GET_JOB_REQUEST_RESOLUTION_LIST_FAILURE",

  GET_APPROVERS_LIST_REQUESTED: "jobRequest/GET_APPROVERS_LIST_REQUESTED",
  GET_APPROVERS_LIST_SUCCESS: "jobRequest/GET_APPROVERS_LIST_SUCCESS",
  GET_APPROVERS_LIST_FAILURE: "jobRequest/GET_APPROVERS_LIST_FAILURE",

  CREATE_RESOLUTION_REQUESTED: "jobRequest/CREATE_RESOLUTION_REQUESTED",
  CREATE_RESOLUTION_SUCCESS: "jobRequest/CREATE_RESOLUTION_SUCCESS",
  CREATE_RESOLUTION_FAILURE: "jobRequest/CREATE_RESOLUTION_FAILURE",

  UPLOAD_APPROVAL_DOCS_REQUESTED: "jobRequest/UPLOAD_APPROVAL_DOCS_REQUESTED",
  UPLOAD_APPROVAL_DOCS_SUCCESS: "jobRequest/UPLOAD_APPROVAL_DOCS_SUCCESS",
  UPLOAD_APPROVAL_DOCS_FAILURE: "jobRequest/UPLOAD_APPROVAL_DOCS_FAILURE",

  GET_JOB_REQUEST_REPORT_REQUESTED: "jobRequest/GET_JOB_REQUEST_REPORT_REQUESTED",
  GET_JOB_REQUEST_REPORT_SUCCESS: "jobRequest/GET_JOB_REQUEST_REPORT_SUCCESS",
  GET_JOB_REQUEST_REPORT_FAILURE: "jobRequest/GET_JOB_REQUEST_REPORT_FAILURE",

  SAVE_RESOLUTION_APPROVER_RESPONSE_REQUESTED: "jobRequest/SAVE_RESOLUTION_APPROVER_RESPONSE_REQUESTED",
  SAVE_RESOLUTION_APPROVER_RESPONSE_SUCCESS: "jobRequest/SAVE_RESOLUTION_APPROVER_RESPONSE_SUCCESS",
  SAVE_RESOLUTION_APPROVER_RESPONSE_FAILURE: "jobRequest/SAVE_RESOLUTION_APPROVER_RESPONSE_FAILURE",

  SET_RECOMMEND_VENDOR_UPDATE: "jobRequest/SET_RECOMMEND_VENDOR_UPDATE",
  SET_OFFSET: "jobRequest/SET_OFFSET",

  LOG_ACTIVITY_REQUEST: "jobRequest/LOG_ACTIVITY_REQUEST",
  LOG_ACTIVITY_SUCCESS: "jobRequest/LOG_ACTIVITY_SUCCESS",
  LOG_ACTIVITY_FAILURE: "jobRequest/LOG_ACTIVITY_FAILURE",

  GET_ACTIVITY_LOG_REQUEST: "jobRequest/GET_ACTIVITY_LOG_REQUEST",
  GET_ACTIVITY_LOG_SUCCESS: "jobRequest/GET_ACTIVITY_LOG_SUCCESS",
  GET_LATEST_FIVE_ACTIVITY_LOG_SUCCESS: "jobRequest/GET_LATEST_FIVE_ACTIVITY_LOG_SUCCESS",
  GET_ACTIVITY_LOG_FAILURE: "jobRequest/GET_ACTIVITY_LOG_FAILURE",
  CLEAR_ACTIVITY_LOGS: "jobRequest/CLEAR_ACTIVITY_LOGS",
  VENDOR_REQUEST_REQUESTED: "jobRequest/VENDOR_REQUEST_REQUESTED",
  VENDOR_REQUEST_FAILURE: "jobRequest/VENDOR_REQUEST_FAILURE",

  UPDATE_HAS_NEW_VENDORS_SUCCESS: "jobRequest/UPDATE_HAS_NEW_VENDORS_SUCCESS",
  UPDATE_HAS_NEW_APPROVAL_SUCCESS: "jobRequest/UPDATE_HAS_NEW_APPROVAL_SUCCESS",
  UPDATE_VENDOR_NEW_MESSAGE_COUNT_SUCCESS: "jobRequest/UPDATE_VENDOR_NEW_MESSAGE_COUNT_SUCCESS",
  UPDATE_HAS_NEW_QUOTATION_SUCCESS: "jobRequest/UPDATE_HAS_NEW_QUOTATION_SUCCESS",
  UPDATE_JOB_REQUEST_SUCCESS: "jobRequest/UPDATE_JOB_REQUEST_SUCCESS",

  GET_COMPARED_QUOTATION_SUCCESS: "jobRequest/GET_COMPARED_QUOTATION_SUCCESS",
  SUBMIT_COMPARE_QUOTATION_REQUEST_SUCCESS: "jobRequest/SUBMIT_COMPARE_QUOTATION_REQUEST_SUCCESS",
  RESUBMIT_COMPARE_QUOTATION_REQUEST_SUCCESS: "jobRequest/RESUBMIT_COMPARE_QUOTATION_REQUEST_SUCCESS",

  GET_ALL_GENERATED_REPORTS_BY_COMPANY_REQUESTED: "jobRequest/GET_ALL_GENERATED_REPORTS_BY_COMPANY_REQUESTED",
  GET_ALL_GENERATED_REPORTS_BY_COMPANY_SUCCESS: "jobRequest/GET_ALL_GENERATED_REPORTS_BY_COMPANY_SUCCESS",
  GET_ALL_GENERATED_REPORTS_BY_COMPANY_FAILURE: "jobRequest/GET_ALL_GENERATED_REPORTS_BY_COMPANY_FAILURE",

  CREATE_BROADCAST_GROUP: "jobRequest/CREATE_BROADCAST_GROUP",
  GET_BROADCAST_GROUPS_FOR_JOB: "jobRequest/GET_BROADCAST_GROUPS_FOR_JOB",
  SEND_BROADCAST_MESSAGE: "jobRequest/SEND_BROADCAST_MESSAGE",

  SEND_EMAIL_FOR_FILE_DOWNLOAD_REQUESTED: "jobRequest/SEND_EMAIL_FOR_FILE_DOWNLOAD_REQUESTED",
  SEND_EMAIL_FOR_FILE_DOWNLOAD_SUCCESS: "jobRequest/SEND_EMAIL_FOR_FILE_DOWNLOAD_SUCCESS",
  SEND_EMAIL_FOR_FILE_DOWNLOAD_FAILURE: "jobRequest/SEND_EMAIL_FOR_FILE_DOWNLOAD_FAILURE",

  GET_PROJECTS_LIST_SUCCESS: "jobRequest/GET_PROJECTS_LIST_SUCCESS",
  SET_SELECTED_PROJECT: "jobRequest/SET_SELECTED_PROJECT",
  SET_MULTI_SELECTED_PROJECT: "jobRequest/SET_MULTI_SELECTED_PROJECT",
  CLEAR_SELECTED_PROJECT: "jobRequest/CLEAR_SELECTED_PROJECT",
  GET_PROJECTS_ROLES_SUCCESS: "jobRequest/GET_PROJECTS_ROLES_SUCCESS",
  SET_SELECTED_RFQ_PERMISSIONS: "jobRequest/SET_SELECTED_RFQ_PERMISSIONS",
  SET_IS_REQUEST_OWNER: "jobRequest/SET_IS_REQUEST_OWNER",
  SET_REQUEST_TYPE: "jobRequest/SET_REQUEST_TYPE",

  RFQ_MODALS_REQUEST: "jobRequest/RFQ_MODALS_REQUEST",
  GET_FILE_HISTORY_SUCCESS: "jobRequest/GET_FILE_HISTORY_SUCCESS",
  RESTORE_DOCUMENT_VERSION_SUCCESS: "jobRequest/RESTORE_DOCUMENT_VERSION_SUCCESS",
  RFQ_MODALS_FAILURE: "jobRequest/RFQ_MODALS_FAILURE",

  EDIT_DOC_DECRIPTION_SUCCESS: "jobRequest/EDIT_DOC_DECRIPTION_SUCCESS",

  GET_JOB_RFQS_SUCCESS: "jobRequest/GET_JOB_RFQS_SUCCESS",
  TOGGLE_RFQ_FILE_STATE: "jobRequest/TOGGLE_RFQ_FILE_STATE",
  RESET_JOB_RFQS: "jobRequest/RESET_JOB_RFQS",
  EDIT_RFQ_FILE_NAME_SUCCESS: "jobRequest/EDIT_RFQ_FILE_NAME_SUCCESS",
  PUBLISH_RFQ_SUCCESS: "jobRequest/PUBLISH_RFQ_SUCCESS",

  SET_SNAP_SHOT_MODAL: "jobRequest/SET_SNAP_SHOT_MODAL",
  RESET_ERROR_AND_SUCCESS_MESSAGE: "jobRequest/RESET_ERROR_AND_SUCCESS_MESSAGE",

  GET_VENDORS_EMAIL_AND_NUM: "jobRequest/GET_VENDORS_EMAIL_AND_NUM",

  UPDATE_LAST_ACTIVITY_DATE_SUCCESS: "jobRequest/UPDATE_LAST_ACTIVITY_DATE_SUCCESS",
  
  ADD_VENDORS_TO_FINAL_SUCCESS: "jobRequest/ADD_VENDORS_TO_FINAL_SUCCESS",
  GET_FINAL_VENDORS_SUCCESS: "jobRequest/GET_FINAL_VENDORS_SUCCESS",
  RESET_FINAL_VENDORS: "jobRequest/RESET_FINAL_VENDORS",

  GET_AUCTIONS_SUCCESS: "jobRequest/GET_AUCTIONS_SUCCESS",
  
  SET_DASHBOARD_SUMMARY_FILTER: "jobRequest/SET_DASHBOARD_SUMMARY_FILTER",

  AUTO_PUBLISH_RFQ_DOCS_UPDATE: "jobRequest/AUTO_PUBLISH_RFQ_DOCS_UPDATE",

  GET_COMPARISON_REPORTS_REQUESTED: "jobRequest/GET_COMPARISON_REPORTS_REQUESTED",
  GET_COMPARISON_REPORTS_FAILED: "jobRequest/GET_COMPARISON_REPORTS_FAILED",

  GET_COMPARISON_REPORTS_SUCCESS: "jobRequest/GET_COMPARISON_REPORTS_SUCCESS",

  GET_FINANCIAL_REPORT_SUCCESS: "jobRequest/GET_FINANCIAL_REPORT_SUCCESS",

  GET_VENDORS_COMPARISON_REPORT_SUCCESS: "jobRequest/GET_VENDORS_COMPARISON_REPORT_SUCCESS",
  
  ADD_COMMENT_VENDORS_COMPARISON_REPORT_SUCCESS: "jobRequest/ADD_COMMENT_VENDORS_COMPARISON_REPORT_SUCCESS",

  RESET_COMPARISON_REPORT_DATA: "jobRequest/RESET_COMPARISON_REPORT_DATA",

  SET_IS_SUPER_USER: "jobRequest/SET_IS_SUPER_USER",

  GET_JOB_SUMMARY_REPORT: "jobRequest/GET_JOB_SUMMARY_REPORT"
};
