import React from "react";
import { IAuthenticationPage } from "authentication/interfaces/authentication.types";
import { Typography } from "components/package";
import { Flex } from "components/package/styled-system/primitives";
import { MdArrowBack } from "react-icons/md";
import { HEAP_DATA_TRACKING_ID, HEAP_TRIGGERS } from "constants/heapAnalytics.constants";

export const getAuthPageFooterVariant = (type: IAuthenticationPage) => {
  const FOOTER_VARIANTS: {
    [key in IAuthenticationPage]: {
      desc: string | React.ReactNode;
      actiontext: string | React.ReactNode;
      link: string;
      heapTrackingId?: string;
      HEAP_DATA_TRACKING_OBJ: Partial<{ [HEAP_DATA_TRACKING_ID]: string }>;
    };
  } = {
    LOGIN_PAGE: {
      desc: "Don't have an account?",
      actiontext: "Register",
      link: "/registration",
      HEAP_DATA_TRACKING_OBJ: { [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.LOGIN.LINKS.REGISTER },
    },
    REGISTER_PAGE: {
      desc: "Have an account?",
      actiontext: "Login",
      link: "/login",
      HEAP_DATA_TRACKING_OBJ: { [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.REGISTER.LINKS.LOGIN },
    },
    FORGOT_PASSWORD: {
      desc: "",
      actiontext: (
        <Flex gap={"0.5rem"} alignItems={"center"} cursor={"pointer"}>
          <MdArrowBack fontSize={"1rem"} />
          <Typography cursor={"pointer"} fontWeight={500} fontSize={"0.75rem"}>
            Back to Login
          </Typography>
        </Flex>
      ),
      link: "/login",
      HEAP_DATA_TRACKING_OBJ: { [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.FORGOT_PASSWORD_PAGE.LINKS.BACK_TO_LOGIN },
    },
    RESET_PASSWORD: { desc: "", actiontext: "", link: "", HEAP_DATA_TRACKING_OBJ: {} },
    REGISTRATION_SUCCESS_PAGE: {
      desc: "",
      actiontext: (
        <Flex gap={"0.5rem"} alignItems={"center"} cursor={"pointer"}>
          <MdArrowBack fontSize={"1rem"} />
          <Typography cursor={"pointer"} fontWeight={500} fontSize={"0.75rem"}>
            Go to Login
          </Typography>
        </Flex>
      ),
      link: "/login",
      HEAP_DATA_TRACKING_OBJ: { [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.REGISTER.LINKS.REGISTER_SUCCESS_GO_TO_LOGIN },
    },
  };

  return FOOTER_VARIANTS[type] || {};
};
