import React, { useState } from "react";
import { Button } from "components/Button";
import { Header, Base, ModalBox, Title, ErrorMessage, ButtonWrap } from "./AntdModal.style";
import { HEAP_DATA_TRACKING_ID } from "constants/heapAnalytics.constants";

export interface ModalProp {
  header?: React.ReactNode | string;
  body?: React.ReactNode;
  positiveActionText?: string;
  positiveActionButtonId?: string;
  negativeActionText?: string;
  closable?: boolean;
  width?: number | string;
  positiveAction: () => void;
  negativeAction: (value?: string, flag?: boolean) => void;
  disabled?: boolean;
  buttonDirectionColumn?: boolean;
  keepPopUpUntilDataProcessed?: boolean;
  variant?: "danger" | "primary" | "secondary" | "white" | "blue" | "red" | undefined;
  positiveButtonColor?: string;
  positiveButtonBorderColor?: string;
  negativeButtonColor?: string;
  negativeButtonBorderColor?: string;
  maskClosable?: boolean;
  usedFor?: string;
  modalBorderRadius?: string;
  buttonWidth?: string;
  showModal?: boolean;
  errorMessage?: React.ReactNode | string;
  footerHidden?: boolean;
  buttonBorderRadius?: string;
  modalPadding?: string;
  modalHeaderPadding?: string;
  titleHeadingColor?: string;
  closeMargin?: string;
  titleFontSize?: string;
  heapDataTrackingObj?: {
    positiveActionButton?: string;
    negativeActionButton?: string;
  };
}

function AntdModal(props: ModalProp) {
  const [isModalVisible, setIsModalVisible] = useState(true);

  const handleConfirm = () => {
    if (!props.keepPopUpUntilDataProcessed) {
      setIsModalVisible(false);
    }
    //callback here
    props.positiveAction();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = (e?: any) => {
    setIsModalVisible(false);

    props.negativeAction(e.currentTarget.id);
  };

  const buttonBackgroundColor = props.usedFor === "loginPopup" ? "#215EC9" : "";

  const HEAP_DATA_TRACKING_OBJ = {
    POSITIVE_ACTION_BUTTON: props?.heapDataTrackingObj?.positiveActionButton ? {
      [HEAP_DATA_TRACKING_ID] : props?.heapDataTrackingObj?.positiveActionButton
    } : {},
    NEGATIVE_ACTION_BUTTON: props?.heapDataTrackingObj?.negativeActionButton ? {
      [HEAP_DATA_TRACKING_ID] : props?.heapDataTrackingObj?.negativeActionButton
    } : {},
  };

  return (
    <Base>
      <ModalBox
        maskStyle={{ background: "#00122FCC" }}
        {...props}
        centered={true}
        title={
          props.header ? (
            <Header>
              <Title {...props}>{props.header}</Title>
              {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
            </Header>
          ) : null
        }
        footer={
          <>
            {!!(props?.positiveActionText?.length || props?.negativeActionText?.length) && (
              <ButtonWrap {...props}>
                {!!props?.positiveActionText?.length && (
                  <Button
                    id={props?.positiveActionButtonId ? props?.positiveActionButtonId : ""}
                    onClick={handleConfirm}
                    variant={props.variant ? props.variant : "tertiary"}
                    color={props.positiveButtonColor ? props.positiveButtonColor : "white"}
                    size="middle"
                    style={{
                      padding: "14px 42px",
                      fontWeight: "bold",
                      fontSize: "12px",
                      lineHeight: "18px",
                      border: `1px solid ${props.positiveButtonBorderColor}`,
                      letterSpacing: "0.1em",
                      textTransform: "uppercase",
                      backgroundColor: `${buttonBackgroundColor}`,
                      width: props.buttonWidth,
                      borderRadius: props.buttonBorderRadius
                    }}
                    disabled={props.disabled}
                    {...HEAP_DATA_TRACKING_OBJ.POSITIVE_ACTION_BUTTON}
                  >
                    {props.positiveActionText}
                  </Button>
                )}
                {!!props?.negativeActionText?.length && (
                  <Button
                    onClick={handleCancel}
                    id="onCancel"
                    variant={"white"}
                    color={props.negativeButtonColor ? props.negativeButtonColor : "secondary"}
                    size="middle"
                    style={{
                      padding: "14px 42px",
                      fontWeight: "bold",
                      fontSize: "12px",
                      lineHeight: "18px",
                      letterSpacing: "0.1em",
                      textTransform: "uppercase",
                    }}
                    border={props.negativeButtonColor ? props.negativeButtonColor : "secondary"}
                    {...HEAP_DATA_TRACKING_OBJ.NEGATIVE_ACTION_BUTTON}
                  >
                    {props.negativeActionText}
                  </Button>
                )}
              </ButtonWrap>
            )}
          </>
        }
        visible={props?.showModal || isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={props.maskClosable ?? true }
        closeIcon={<img src="/images/closeFilled.svg" alt="close button"></img>}
        closable={props.closable}
        width={props.width}
      >
        {props.body}
      </ModalBox>
    </Base>
  );
}

export default AntdModal;
