import * as CSS from "csstype";
import { getAppliedCssValues, getCSSOnlyStringValueFromCSSProperties } from "./helpers";
import { BorderProps, PartialCSSPropertiesHyphen } from "../types";

export const border = (props: BorderProps) => {
  const getCSSValue = (cssPropertiesArray: string[]) =>
    getCSSOnlyStringValueFromCSSProperties(props, cssPropertiesArray);

  const cssObject: PartialCSSPropertiesHyphen = {
    // border width
    "border-width": getCSSValue(["borderWidth"]) as CSS.BorderWidthProperty<string | 0>,
    "border-top-width": getCSSValue(["borderTopWidth"]) as CSS.BorderTopWidthProperty<string | 0>,
    "border-bottom-width": getCSSValue(["borderBottomWidth"]) as CSS.BorderBottomWidthProperty<string | 0>,
    "border-left-width": getCSSValue(["borderLeftWidth"]) as CSS.BorderLeftWidthProperty<string | 0>,
    "border-right-width": getCSSValue(["borderRightWidth"]) as CSS.BorderRightWidthProperty<string | 0>,
    // border style
    "border-style": getCSSValue(["borderStyle"]),
    "border-top-style": getCSSValue(["borderTopStyle"]) as CSS.BorderTopStyleProperty,
    "border-bottom-style": getCSSValue(["borderBottomStyle"]) as CSS.BorderBottomStyleProperty,
    "border-left-style": getCSSValue(["borderLeftStyle"]) as CSS.BorderLeftStyleProperty,
    "border-right-style": getCSSValue(["borderRightStyle"]) as CSS.BorderRightStyleProperty,
    // border color
    "border-color": getCSSValue(["borderColor"]),
    "border-top-color": getCSSValue(["borderTopColor"]),
    "border-bottom-color": getCSSValue(["borderBottomColor"]),
    "border-left-color": getCSSValue(["borderLeftColor"]),
    "border-right-color": getCSSValue(["borderRightColor"]),
    // border radius
    "border-radius": getCSSValue(["borderRadius"]) as CSS.BorderRadiusProperty<string | 0>,
    "border-top-left-radius": getCSSValue(["borderTopLeftRadius"]) as CSS.BorderTopLeftRadiusProperty<string | 0>,
    "border-top-right-radius": getCSSValue(["borderTopRightRadius"]) as CSS.BorderTopRightRadiusProperty<string | 0>,
    "border-bottom-left-radius": getCSSValue(["borderBottomLeftRadius"]) as CSS.BorderBottomLeftRadiusProperty<
      string | 0
    >,
    "border-bottom-right-radius": getCSSValue(["borderBottomRightRadius"]) as CSS.BorderBottomRightRadiusProperty<
      string | 0
    >,
    // border position
    "border-top": getCSSValue(["borderTop"]) as CSS.BorderTopProperty<string | 0>,
    "border-right": getCSSValue(["borderRight"]) as CSS.BorderRightProperty<string | 0>,
    "border-bottom": getCSSValue(["borderBottom"]) as CSS.BorderBottomProperty<string | 0>,
    "border-left": getCSSValue(["borderLeft"]) as CSS.BorderLeftProperty<string | 0>,
    // border
    border: getCSSValue(["border"]),
  };

  return getAppliedCssValues(cssObject);
};
