import { lowerCaseStringWithHyphens } from "shared/handlers/string.handler";

/** returns lower case hyphenated string without special characters(char `&` is returned) */
const getDynamicGeneratedDataTrackingId = (prefix: string, words: string) => {
  const newString = prefix + words;
  const stringWithoutSpecialCharacters = newString.replace(/[^a-zA-Z0-9\-& ]/g, "");
  return lowerCaseStringWithHyphens(stringWithoutSpecialCharacters);
};

export const HEAP_DATA_TRACKING_ID = "data-tracking-id";

/**
 * FYI: Heap Analytics Snapshot
 * text value with DOM selector is considered:
 * to identify the rendered HTML text within a event in Heap Analytics
 */
export const HEAP_TRIGGERS = {
  NAVBAR: {
    TOOLS: "navbar-tools",
    ERFX: "navbar-erfx",
    PROCUREMENT_SCHEDULER: "navbar-procurement-scheduler",
    MILESTONE_MANAGEMENT: "navbar-milestone-management",
    SCHEDULED_VISIT: "navbar-scheduled-visit",
    VENDORS: "navbar-vendors",
    MESSAGING: "navbar-messaging",
    QUOTE_COMPARISON: "quote-comparison"
  },
  DASHBOARD: {
    SHORTLISTED_TAB: {
      BUTTON: "dashboard-shortlisted-tab-button",
      SHORTLISTED_VENDOR_NAME: "dashboard-shortlisted-vendor-profile-card-name",
    },
    EXPLORE_VENDORS_BUTTONS: {
      PROJECT_SERVICES: "dashboard-project-services-card",
      MRO_SERVICES: "dashboard-mro-services-card",
      SPECIALIZED_SERVICES: "dashboard-specialized-services-card",
      SUB_CATEGORY: "dashboard-explore-sub-category",
    },
    POST_JOB: {
      STATE: "dashboard-post-job-state",
      DESCRIPTION: "dashboard-post-job-description",
      UPLOAD_DOCUMENT: "dashboard-post-job-upload-document",
      CTA: "dashboard-post-job-cta",
      VIEW: "dashboard-view-posted-job",
      ANOTHER: "dashboard-post-another-job",
    },
    SUMMARY_CARDS: {
      JOBS_CREATED: "dashboard-summary-card-jobs-created",
      VENDORS_SUGGESTED_NEW: "dashboard-summary-card-vendors-suggested-new",
      VENDORS_SUGGESTED_ALL: "dashboard-summary-card-vendors-suggested-all",
      QUOTES_RECEIVED_NEW: "dashboard-summary-card-quotes-received-new",
      QUOTES_RECEIVED_ALL: "dashboard-summary-card-quotes-received-all",
      JOBS_AWARDED: "dashboard-summary-card-jobs-awarded",
      JOBS_AWARDED_MILESTONE: "dashboard-summary-card-jobs-awarded-milestones",
    },
    SEARCH_VENDORS: {
      BY_REQ_DESCRIPTION: "dashboard-search-vendors-by-requirement-description",
      BY_REQ_EQUIPMENT: "dashboard-search-vendors-by-requirement-equipment",
      BY_REQ_SERVICE: "dashboard-search-vendors-by-requirement-servicetype",
      BY_REQ_CTA: "dashboard-search-vendors-by-requirement-cta",
      BY_COMPANY_VALUE: "dashboard-search-vendors-by-companyname-value",
      BY_COMPANY_CTA: "dashboard-search-vendors-by-companyname-cta",
    },
  },
  SEARCH: {
    MANUAL: {
      INPUT_VALUE: "search-input-value", // snapshot - input.ant-input[type="text"]
      SEARCH_ICON: "search-icon-button",
      VENDOR_PROFILE_CARD: {
        NAME: "vendor-profile-card-name",
        VIEW_PROFILE: "vendor-profile-card-view-profile", // snapshot - div[data-tracking-id="vendor-profile-card-name"]
        MESSAGE: "vendor-profile-card-message",
        SHORTLIST: {
          BUTTON_CLICKED: "vendor-profile-card-shortlist-button",
          CONFIRM: "vendor-profile-card-shortlist-modal-confirm",
          CANCEL: "vendor-profile-card-shortlist-modal-cancel",
          /** DOM selector value(for snapshot) - gives text of vendor profile card name
           * div[data-tracking-id="vendor-profile-card-name"] */
          SELECT_CHECKBOX: "vendor-profile-card-shortlist-select-checkbox",
        },
      },
      LOOKING_FOR_RELEVANT_RESULTS: "vendorlist-looking-for-relevant-results",
      SHORTLISTED_BUTTON: "vendorlist-shortlisted-button",
    },
    BY_CATEGORIES: {
      CATEGORY_NAME: "search-category", // spanshot - (function(){return event.target.innerText})()
      SUB_CATEGORY_NAME: "search-sub-category", // spanshot - (function(){return event.target.innerText})()
    },
    FILTERS: {
      /** DOM selector value(for snapshot) - input.ant-btn.sc-pIJmg.fbbYtI[type="button"] span  */
      SELECT_LOCATION: "search-select-location",
      CHECK_BOX: {
        /** DOM selector value(for Heap snapshot) for all checkbox values - input.ant-checkbox-input[type="checkbox"] */
        ALL_INDIA_LOCATIONS: "search-check-box-all-india-locations",
        SERVING_LOCATIONS: {
          STATE: "search-check-box-serving-locations-state",
          SELECT_ALL_STATES: "search-check-box-select-all-serving-states",
        },
        REGISTERED_LOCATIONS: {
          STATE: "search-check-box-registered-locations-state",
          CITY: "search-check-box-registered-locations-city",
          SELECT_ALL_CITIES: "search-check-box-select-all-registered-cities",
        },
        INDUSTRIES_SERVED: "search-check-box-industries-served",
        SCOPE_OF_WORK: "search-check-box-scope-of-work",
        ANNUAL_TURN_OVER: "search-check-box-annual-turnover",
      },
      VENDOR_LOCATIONS_TAB_HEADER: {
        SERVED_BEFORE: "search-vendor-locations-served-before-tab-header-click",
        REGISTERED_IN: "search-vendor-locations-registered-in-tab-header-click",
      },
      HEAP_TRACK: {
        REGISTERED_IN_LOCATION: {
          SELECT_SINGLE_STATE: "search-registered-location-select-single-state-heap-track",
          SELECT_ALL_CITIES: "search-registered-location-select-all-cities-heap-track",
          SELECT_SINGLE_CITY: "search-registered-location-select-single-city-heap-track",
          ARROW_BUTON_CLICKED: "search-registered-location-arrow-button-clicked-heap-track",
        },
        SERVED_BEFORE_LOCATION: {
          SELECT_ALL_STATES: "search-served-before-select-all-states-heap-track",
          SELECT_SINGLE_STATE: "search-served-before-select-single-state-heap-track",
        },
        EQUIPMENT: {
          SELECT_ALL_EQUIPMENTS: "search-equipment-select-all-states-heap-track",
          SELECT_SINGLE_EQUIPMENT: "search-equipment-select-single-state-heap-track",
        },
        SERVICES_OFFERED: {
          SELECT_ALL_SERVICES_OFFERED: "search-services-offered-select-all-states-heap-track",
          SELECT_SINGLE_SERVICES_OFFERED: "search-services-offered-select-single-state-heap-track",
        },
        FABRICATION_PROCESS: {
          SELECT_ALL_FABRICATION_PROCESS: "search-fabrication-process-select-all-states-heap-track",
          SELECT_SINGLE_FABRICATION_PROCESS: "search-fabrication-process-select-single-state-heap-track",
        },
        FABRICATION_MATERIAL: {
          SELECT_ALL_FABRICATION_MATERIALS: "search-fabrication-material-select-all-states-heap-track",
          SELECT_SINGLE_FABRICATION_MATERIAL: "search-fabrication-material-select-single-state-heap-track",
        },
        FABRICATED_PRODUCT: {
          SELECT_ALL_FABRICATED_PRODUCTS: "search-fabricated-product-select-all-states-heap-track",
          SELECT_SINGLE_FABRICATED_PRODUCT: "search-fabricated-product-select-single-state-heap-track",
        },
        SCOPE_OF_WORK: "search-select-single-scope-of-work-heap-track",
        INDUSTRIES_SERVED: "search-select-single-industry-served-heap-track",
        ANNUAL_TURN_OVER: "search-select-single-annual-turnover-heap-track",
      },
    },
    PAGINATION: {
      PREVIOUS_PAGE: "search-previous-page",
      NEXT_PAGE: "search-next-page",
      PAGE_NUMBER: "search-page-number",
      HEAP_TRACK: {
        PAGE_NUMBER_VIEWED: "search-page-number-viewed-heap-track",
      }
    },
  },
  COMPARE_VENDORS: {
    SELECT_CHECK_BOX: "compare-vendors-select", // requires snapshot for vendor name to be identified
    COMPARE_BUTTON_CLICKED: "compare-vendors-confirm-compare",
  },
  VENDOR_PROFILES: {
    HEAP_TRACK: {
      BROCHURE_LINK: "vpv-page-brochure-link-heap-track",
      WEBSITE_LINK: "vpv-page-website-link-heap-track",
      CASE_STUDY_LINK: "vpv-page-case-study-link-heap-track",
      HEAP_TRACK_MACHINE_GALLERY_BUTTON: "vpv-manufacturing-capabilities-machine-gallery-button-heap-track",
    },
    SHARE_BUTTON: "vendor-profile-page-share-button",
    BROCHURE_LINK: "vendor-profile-page-brochure-link",
    CASE_STUDY_LINK: "vendor-profile-page-case-study-link",
    VIEW_GALLERY_CTA: "vendor-profile-page-view-gallery-cta",
    WEBSITE_LINK: "vendor-profile-page-website-link",
    MESSAGE_BUTTON: "vendor-profile-page-message-cta",
    SHORTLIST_BUTTON: "vendor-profile-page-shortlist-cta",
    ADD_REVIEW_BUTTON: "vendor-profile-page-add-review-cta",
    EDIT_REVIEW_BUTTON: "vendor-profile-page-edit-review-cta",
    SUBMIT_REVIEW: "vendor-profile-page-submit-review",
    getStarRatedNumber: (value: number) => `vendor-profile-page-rated-star-${String(value)}`,

    // TODO: remove the below BADGES triggers from UI
    // as its moved in npm package
    BADGES: {
      OEM: {
        CLICK: "vendor-profile-page-oem-badge-click",
        HOVER_VIA_HEAP_TRACK: "vendor-profile-page-oem-badge-hover",
      },
      KEY_CUSTOMER: {
        CLICK: "vendor-profile-page-key-customers-badge-click",
        HOVER_VIA_HEAP_TRACK: "vendor-profile-page-key-customers-badge-hover",
      },
      TECHNICAL_CERTIFICATION: {
        CLICK: "vendor-profile-page-tech-cert-badge-click",
        HOVER_VIA_HEAP_TRACK: "vendor-profile-page-tech-cert-badge-hover",
      },
    },
  },
  COMMUNICATION: {
    MESSAGE: {
      CLICKED_INPUT: "communications-clicked-message",
      FILE_UPLOAD_CTA: "communications-file-upload-cta",
      SEND_CTA: "communications-send-cta",
      /** DOM Selector for snapshot - input.ant-input.sc-oTzgz.gYcwDC[type="text"] */
      ENTERED_VALUE: "communications-entered-message",
    },
  },
  VENDOR_REQUEST: {
    REQUEST_VENDOR_CTA: "request-vendor-cta",
    MODAL: {
      CREATE_VENDOR_REQUEST: {
        DROP_DOWN: {
          /** DOM Selector for snapshot
           * div[data-tracking-id="request-vendor-dropdown-location-state"] span.ant-select-selection-item */
          LOCATION: "request-vendor-dropdown-location-state",
          /** DOM Selector for snapshot
           * div[data-tracking-id="request-vendor-dropdown-scope-of-work"] div[class="dropdown-heading-value"] span */
          SCOPE_OF_WORK: "request-vendor-dropdown-scope-of-work",
        },
        /** DOM Selector for snapshot - textarea[class="ant-input sc-qPlga jhElJM"] */
        DESCRIPTION: "request-vendor-description",
        /** DOM Selector for snapshot - input[data-tracking-id="request-vendor-start-date"] */
        START_DATE: "request-vendor-start-date",
        /** DOM Selector for snapshot - input[data-tracking-id="request-vendor-budget"] */
        BUDGET: "request-vendor-budget",
        FILE_UPLOAD_CTA: "request-vendor-file-upload-cta",
        SUBMIT_CTA: "request-vendor-submit-cta",
        UPDATE_CTA: "request-vendor-update-cta",
      },
      PROMPT: {
        CONTINUE_SEARCH: "request-vendor-prompt-continue-search-cta",
        REQUEST_VENDORS: "request-vendor-prompt-request-vendor-cta",
      },
    },
  },
  LOGIN: {
    VIA_EMAIL: {
      EMAIL_INPUT: "login-email-page-input",
      PASSWORD_INPUT: "login-email-page-password-input",
      CTA: {
        LOGIN_SUBMIT: "login-email-page-login-cta",
        LOGIN_VIA_MOBILE: "login-email-page-loginViaPhone-cta",
      },
    },
    VIA_PHONE: {
      PHONE_INPUT: "login-phone-page-input",
      CTA: {
        LOGIN_SUBMIT: "login-phone-page-login-cta",
        LOGIN_VIA_EMAIL: "login-email-page-loginViaEmail-cta",
      },
    },
    LINKS: {
      FORGOT_PASSWORD: "login-page-forgot-password-link",
      REGISTER: "login-page-register-link",
    },
  },
  REGISTER: {
    STEP_1: {
      FULL_NAME_INPUT: "register-page-full-name-input",
      EMAIL_INPUT: "register-page-email-input",
      PHONE_INPUT: "register-page-phone-input",
      PASSWORD_INPUT: "register-page-password-input",
      NEXT_CTA: "register-page-step1-next-cta",
    },
    STEP_2: {
      COMPANY_NAME_INPUT: "register-page-company-name-input",
      PLANT_NAME_INPUT: "register-page-plant-name-input",
      RADIO_BUTTON: {
        PLANT_USER: "register-page-plant-user-radio-button",
        CORPORATE_USER: "register-page-corporate-user-radio-button",
      },
      NEXT_CTA: "register-page-step2-next-cta",
    },
    STEP_3: {
      REQUEST_ACCESS_CTA: "register-page-step3-request-access-cta",
    },
    LINKS: {
      LOGIN: "register-page-login-link",
      REGISTER_SUCCESS_GO_TO_LOGIN: "register-success-page-go-to-login-link",
    },
  },
  RESET_PASSWORD_PAGE: {
    NEW_PASSWORD_INPUT: "reset-password-page-new-password-input",
    CONFIRM_PASSWORD_INPUT: "reset-password-page-confirm-password-input",
    RESET_CTA: "reset-password-page-confirm-cta",
  },
  FORGOT_PASSWORD_PAGE: {
    EMAIL_INPUT: "forgot-password-page-email-input",
    PHONE_INPUT: "forgot-password-page-phone-input",
    SUBMIT_CTA: "forgot-password-page-submit-cta",
    LINKS: {
      BACK_TO_LOGIN: "forgot-password-page-back-to-login",
    },
  },
  JOB_REQUEST: {

    JOB_STATUS_CHANGE: "job-request-status-change",

    ADD_TO_PROJECT_CTA: "job-request-add-to-project-cta",
    EDIT_JOB_CTA: "job-request-edit-job-cta",
    EDIT_JOB_DESCRIPTION: "job-request-edit-job-description",
    EDIT_JOB_DOCUMENTS: "job-request-edit-job-documents",
    EDIT_JOB_UPDATE_CTA: "job-request-edit-job-update-cta",

    VENDORS_TAB: "job-request-vendors-tab",
    VENDORS_TAB_VIEW_FULL_PROFILE: "job-request-vendors-tab-view-full-profile",
    VENDORS_TAB_COMPARE_BUTTON : "job-request-vendors-tab-compare-button",
    VENDORS_TAB_MOVE_TO_FINAL_BUTTON : "job-request-vendors-tab-move-to-final-button",
    REMOVE_VENDORS_CTA: "job-request-remove-vendors-cta",
    CREATE_BROADCAST_CTA: "job-request-create-broadcast-cta",
    DISCOVER_VENDORS: "job-request-discover-vendors-cta",
    ADD_VENDORS: "job-request-add-vendors-cta",
    ADD_VENDOR_COMPANY_NAME: "job-request-add-vendor-company-name",
    ADD_VENDOR_EMAIL: "job-request-add-vendor-email",

    UPLOAD_NEW_RFQ_DOCS_CTA: "job-request-upload-new-rfq-docs-cta",
    PUBLISH_RFQ_CTA: "job-request-publish-rfq-cta",
    RE_PUBLISH_RFQ_CTA: "job-request-re-publish-rfq-cta",
    RFQ_DOCS_DOWNLOAD_CTA: "job-request-rfq-docs-download-cta",
    CONTINUE_TO_MODIFY_RFQ_CTA: "job-request-continue-to-modify-rfq-cta",
    UPLOAD_NEW_FILE_VERSION_CTA: "job-request-upload-new-file-version-cta",
    EDIT_FILE_NAME_CTA: "job-request-edit-file-name-cta",
    EDIT_FILE_DESCRIPTION_CTA: "job-request-edit-file-description-cta",
    REMOVE_FILE_CTA: "job-request-remove-file-cta",
    FILE_HISTORY_CTA: "job-request-file-history-cta",
    RESTORE_FILE_CTA: "job-request-restore-file-cta",
    MARK_FILE_AS_FINAL_CTA: "job-request-mark-file-as-final-cta",

    DOWNLOAD_QUOTATION: "job-request-download-quotation",
    DOWNLOAD_QUOTATION_FILE: "job-request-download-quotation-file-cta",
    DOWNLOAD_COMPARISON_SHEET: "job-request-download-comparison-sheet-cta",
    ASK_QUOTATION_COMPARISON_CTA: "job-request-ask-quotation-compare-cta",
    ASK_QUOTATION_RE_COMPARE_CTA: "job-request-ask-quotation-re-compare-cta",
    CREATE_AUCTION_CTA: "job-request-create-auction-cta",
    DOWNLOAD_FINAL_VENDOR_DOCS_CTA: "job-request-download-final-vendor-docs-cta",
  }
} as const;
