import React, { useState, useEffect } from "react";
import { Progress } from "antd";
import { ProgressProps } from "antd/lib/progress";
import styled from "styled-components";
import { Typography } from "components/package";
import { Box, Flex } from "components/package/styled-system/primitives";
import { BoxProps } from "components/package/styled-system/primitives/Box";

const ProgressAnt = styled(Progress)`
  .ant-progress-outer {
    width: 15.25rem;
    border-radius: 0.625rem;
  }
  .ant-progress-text {
    margin-left: 0.625rem;
    color: #011759;
  }
`;

export interface IProps extends ProgressProps {
  containerStyleProps?: BoxProps;
}

const PasswordStrengthMeter = (props: IProps) => {
  const [color, setColor] = useState("red");
  const [percentValue, setPercentValue] = useState(props?.percent || 0);
  const [passwordStrength, setPassStrength] = useState("Weak Password");

  useEffect(() => {
    setPercentValue(props?.percent || 0);
  }, [props?.percent]);

  useEffect(() => {
    getPasswordStrength(percentValue);
  }, [percentValue]);

  const getPasswordStrength = (percentageValue: number) => {
    switch (true) {
      case percentageValue >= 85:
        setColor("#47C882");
        setPassStrength("Strong Password");
        break;

      case percentageValue < 85 && percentageValue > 50:
        setColor("orange");
        setPassStrength("Good Password");
        break;

      case percentageValue <= 50:
      default:
        setColor("red");
        setPassStrength("Weak Password");
        break;
    }
  };

  return (
    <Flex alignItems={"center"} justifyContent={"space-between"} {...props.containerStyleProps}>
      <Typography fontWeight={600} fontSize={"0.875rem"} color={color}>
        {passwordStrength}
      </Typography>
      <Box>
        <ProgressAnt strokeColor={color} strokeWidth={4} status={"active"} {...props} />
      </Box>
    </Flex>
  );
};

export default PasswordStrengthMeter;
