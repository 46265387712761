import styled from "styled-components";

export const ForgotPassword = styled.span`
  font-weight: 600;
  font-size: 0.75rem; //12px
  line-height: 1.5rem;
  text-decoration-line: underline;
  color: #215ec9;
  width: max-content;
  cursor: pointer;
`;

export const Input = styled.input`
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 1.5px solid #215ec9;
  box-sizing: border-box;
`;
