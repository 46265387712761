import { Types as RegistrationTypes } from "_types/RegistrationTypes";

export const ActionCreators: any = {
  register: (data: any) => ({
    types: [
      RegistrationTypes.REGISTER_REQUESTED,
      RegistrationTypes.REGISTER_SUCCESS,
      RegistrationTypes.REGISTER_FAILURE,
    ],
    promise: (client: any) => client.post("register", { data }),
  }),

  generateOtpForRegistration: (data: any) => ({
    types: [RegistrationTypes.GET_OTP_REQUESTED, RegistrationTypes.GET_OTP_SUCCESS, RegistrationTypes.GET_OTP_FAILURE],
    promise: (client: any) => client.post("generate_otp_for_registration", { data }),
  }),
  setLogin: (data: any) => ({
    types: [
      RegistrationTypes.SET_REGISTRATION_REQUESTED,
      RegistrationTypes.SET_REGISTRATION_SUCCESS,
      RegistrationTypes.SET_REGISTRATION_FAILURE,
    ],
    promise: (client: any) => client.post("login", { data }),
  }),
  storeUserdata: (data: any) => ({
    type: RegistrationTypes.SET_USER_DATA,
    payload: data,
  }),
  getCompanyName: (params: any) => ({
    types: [
      RegistrationTypes.GET_COMPANY_DATA_REQUESTED,
      RegistrationTypes.GET_COMPANY_DATA_SUCCESS,
      RegistrationTypes.GET_COMPANY_DATA_FAILURE,
    ],
    promise: (client: any) =>
      client.get("get_clear_bit_company_data", {
        params,
      }),
  }),
  emailDuplication: (params: any) => ({
    types: [
      RegistrationTypes.CHECK_EMAIL_DUPLICATION,
      RegistrationTypes.CHECK_EMAIL_DUPLICATION,
      RegistrationTypes.CHECK_EMAIL_DUPLICATION,
    ],
    promise: (client: any) =>
      client.get("email_duplication", {
        params,
      }),
  }),
  phoneDuplication: (params: any) => ({
    types: [
      RegistrationTypes.CHECK_PHONE_DUPLICATION,
      RegistrationTypes.CHECK_PHONE_DUPLICATION,
      RegistrationTypes.CHECK_PHONE_DUPLICATION,
    ],
    promise: (client: any) =>
      client.get("phone_duplication", {
        params,
      }),
  }),
  retrieveRegistrationData: (data: any) => ({
    type: RegistrationTypes.RETRIEVE_DATA_ON_OTP_PAGE_REFRESH,
    payload: data,
  }),
  setInitialState: () => ({ type: RegistrationTypes.SET_INITIAL_STATE }),
};

export default ActionCreators;
