import { IVendorListingInitialState } from "vendorList/actions/reducer";

const isStringAlreadyPresentInArray = (arr: string[], name: string) => {
  return arr
    ?.map(existingName => existingName?.toLocaleLowerCase())
    ?.includes(name?.toLocaleLowerCase());
};

export const getUniqueNames = (names: string[]) => {
  let uniqueNames: string[] = [];
  if (!names) return uniqueNames;
  
  (names as string[])?.forEach(name => {
    /** iterating array of strings */
    if (name?.startsWith(" ")) {
      /** name starting with space */
      const nameWithoutSpace = name?.trimStart();
      if (nameWithoutSpace && !isStringAlreadyPresentInArray(uniqueNames, nameWithoutSpace)) {
        /** make new unique name */
        uniqueNames.push(nameWithoutSpace);
      }
    } else if (!name?.startsWith(" ") && !isStringAlreadyPresentInArray(uniqueNames, name)) {
      /** new unique name without starting with space */
      uniqueNames.push(name);
    }
  });

  return uniqueNames;
};

export const prepareRegisteredVendorLocationsAcrossIndia = ({ stateNameKeys, response }: {
  stateNameKeys: string[], 
  response: IVendorListingInitialState["registeredVendorLocationsAcrossIndia"]
}) => {
  let AVAILABLE_REGISTRED_LOCATIONS: IVendorListingInitialState["registeredVendorLocationsAcrossIndia"] = {};
  stateNameKeys?.forEach((stateName: string) => {
    if (stateName?.startsWith(" ")) {
      /** state name starting with space */
      const stateNameWithoutSpace = stateName?.trimStart();
      if (!AVAILABLE_REGISTRED_LOCATIONS[stateNameWithoutSpace]) {
        /** if the cities are not present in AVAILABLE_REGISTRED_LOCATIONS fpr the stateNameWithoutSpace - push cities */
        const citiesOfStateNameWithSpace: string[] = getUniqueNames(response?.[stateName] as string[]);
        AVAILABLE_REGISTRED_LOCATIONS[stateNameWithoutSpace] = citiesOfStateNameWithSpace;
      }
    } else {
      if (!AVAILABLE_REGISTRED_LOCATIONS[stateName]) {
        /** if the cities are not present in AVAILABLE_REGISTRED_LOCATIONS for the stateName - push cities */
        const uniqueCities: string[] = getUniqueNames(response?.[stateName] as string[]);
        AVAILABLE_REGISTRED_LOCATIONS[stateName] = uniqueCities;
      }
    }
  });
  return AVAILABLE_REGISTRED_LOCATIONS;
}