export const RATING_VALUES = [1, 2, 3, 4, 5];

export enum RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_LABELS {
  VENDOR_COMPETENCY = 'Vendor Competency',
  QUALITY_OF_EXECUTION = 'Quality of execution',
  ON_TIME_EXECUTION = 'On-time execution',
  RESPONSIVENESS = 'Responsiveness',
  WITHIN_BUDGET = 'Within budget',
  ETHICS = 'Ethics',
  SAFETY = 'Safety',
  PROCESS_AND_DOCUMENTATION = 'Process & documentation',
};

export enum RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES {
  VENDOR_COMPETENCY = 'vendorCompetency',
  QUALITY_OF_EXECUTION = 'qualityOfExecution',
  ON_TIME_EXECUTION = 'onTimeExecution',
  RESPONSIVENESS = 'responsiveness',
  WITHIN_BUDGET = 'withinBudget',
  ETHICS = 'ethics',
  SAFETY = 'safety',
  PROCESS_AND_DOCUMENTATION = 'processAndDocumentation',
};

export type IReqForReviewRadioKeys = 
  RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.VENDOR_COMPETENCY |
  RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.QUALITY_OF_EXECUTION |
  RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.ON_TIME_EXECUTION |
  RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.RESPONSIVENESS |
  RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.WITHIN_BUDGET |
  RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.ETHICS |
  RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.SAFETY |
  RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.PROCESS_AND_DOCUMENTATION;


export const AVAILABLE_RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE = [
  {
    label: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_LABELS.VENDOR_COMPETENCY,
    keyName: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.VENDOR_COMPETENCY,
  },
  {
    label: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_LABELS.QUALITY_OF_EXECUTION,
    keyName: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.QUALITY_OF_EXECUTION,
  },
  {
    label: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_LABELS.ON_TIME_EXECUTION,
    keyName: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.ON_TIME_EXECUTION,
  },
  {
    label: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_LABELS.RESPONSIVENESS,
    keyName: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.RESPONSIVENESS,
  },
  {
    label: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_LABELS.WITHIN_BUDGET,
    keyName: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.WITHIN_BUDGET,
  },
  {
    label: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_LABELS.ETHICS,
    keyName: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.ETHICS,
  },
  {
    label: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_LABELS.SAFETY,
    keyName: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.SAFETY,
  },
  {
    label: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_LABELS.PROCESS_AND_DOCUMENTATION,
    keyName: RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE_KEY_NAMES.PROCESS_AND_DOCUMENTATION,
  },
];

export const INITIAL_RATINGS_STATE = (() => {
  const ratings: Partial<Record<IReqForReviewRadioKeys, number>> = {};
  AVAILABLE_RATING_TYPES_FOR_REQUEST_FOR_REVIEW_OPEN_PAGE.forEach(({ keyName }) => {
    ratings[keyName] = 0;
  });
  return ratings;
})();

export const RESPONSE_PAGE_TYPE = {
  ERROR: "ERROR",
  ALREADY_SUBMITTED: "ALREADY_SUBMITTED",
  SUCCESS: "SUCCESS",
};

export const RESPONSE_PAGE_CONTENT = {
  [RESPONSE_PAGE_TYPE.ERROR]: {
    IMG_SRC: "https://d2kbahwkgd7n6c.cloudfront.net/1707386709278-be3cf360-81ec-472c-8478-84eced452055.png",
    TITLE: "Your review could not be posted",
    DESCRIPTION: "Seems like there was a network error, please re-submit the review.",
  },
  [RESPONSE_PAGE_TYPE.ALREADY_SUBMITTED]: {
    IMG_SRC: "https://d2kbahwkgd7n6c.cloudfront.net/1707384490060-33f83fa4-6eed-45c9-95d4-fc71d1eda7d3.png",
    TITLE: "Review Already Posted",
    DESCRIPTION: "A Review from your account has already been posted for this vendor, only one review per account is considered valid.",
  },
  [RESPONSE_PAGE_TYPE.SUCCESS]: {
    IMG_SRC: "https://d2kbahwkgd7n6c.cloudfront.net/1707384490060-33f83fa4-6eed-45c9-95d4-fc71d1eda7d3.png",
    TITLE: "Review Posted Successfully",
    DESCRIPTION: "Thank you for posting the review, your feedback is valuable to the team and will help enhance the quality of our services.",
  },
};
