import React from "react";
import styled from "styled-components";
import Button, { ButtonProps as AntButtonProps } from "../Button";

export interface ButtonProps extends AntButtonProps {
  buttonshape: "rounded" | "square";
  $iconClass: string;
  color?: string;
}

const ButtonBase = styled(Button)<ButtonProps>`
  border-radius: ${props =>
    props.buttonshape === "rounded" ? "50%" : props.theme.button.borderRadius};
  width: ${props => props.theme.button.sizes[props.size || "regular"].height};
`;

const Icon = styled.i<Partial<ButtonProps>>`
  font-size: ${props =>
    props.theme.button.sizes[props.size || "regular"].iconSize};
  &:before {
    color: ${props => props.color};
  }
`;

const IconButton = (props: ButtonProps) => {
  const { onClick, $iconClass, ...remaining } = props;
  return (
    <ButtonBase {...props}>
      <Icon className={$iconClass} {...remaining} />
    </ButtonBase>
  );
};

export default IconButton;
