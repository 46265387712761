import { Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
interface Props {
  body: any;
  children: any;
  visibility?: any;
  position?: any;
  alignConfig?: any;
  visibilityType: "onHover" | "visible";
}

const TooltipDiv = styled(Tooltip)<any>``;
const AntdTooltip = (props: Props) => {
  const [visibilityState, setVisibilityState] = useState(false);
  useEffect(() => {
    if (props?.visibilityType === "visible" && props?.visibility) {
      setVisibilityState(true);
    } else {
      setVisibilityState(false);
    }
  }, [props?.visibilityType, props?.visibility]);
  const handleMouseHover = (state: boolean) => {
    if (props?.visibilityType === "onHover") {
      setVisibilityState(state);
    }
  };
  return (
    <TooltipDiv
      onMouseEnter={() => {
        handleMouseHover(true);
      }}
      onMouseLeave={() => {
        handleMouseHover(false);
      }}
      visible={visibilityState}
      color="#ffffff"
      placement={props?.position || "bottom"}
      align={props?.alignConfig}
      overlayInnerStyle={{
        fontSize: "12px",
        color: "#011759",
        padding: "18px 20px",
        borderRadius: "12px",
        width: "max-content",
        maxWidth: "416px",
        whiteSpace: "normal",
      }}
      title={props?.body}
    >
      {props?.children}
    </TooltipDiv>
  );
};

export default AntdTooltip;
