export const TYPOGRAPHY_VARIANTS: { [keys in keyof Partial<JSX.IntrinsicElements>]: string } = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  p: "p",
  link: "link",
  ul: "ul",
  ol: "ol",
  li: "li",
  span: "span",
} as const;
