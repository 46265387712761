import { Types as PlantVisitRequestTypes } from "_types/PlantVisitRequestTypes";
import { Types as JobRequestTypes } from "_types/VendorRequestTypes";

const initialState = {
  loading: false,
  requestTypeData: null,
  createRequestData: null,
  updateRequestData: null,
  deleteRequestData: false,
  requestData: null,
  requestListData: null,
  newJobRequestMessage: null,
  newPlanVisitRequestMessage: null,
  jobRequests: null,
  totalVendors: null,
  clientRequest: null,
  customReports: null,
  uploadRfqErrorFileList: [],
  addVendorStatus: null,
  removeVendorStatus: null,
  jobRequestReport: [],
  totalReports: 0,
  offset: 1,
  activityLog: null,
  logActivityStatus: null,
  latestActivityLogs: null,
  apiResponse: null,
  comparedQuotation: null,
  broadcastGroup: null,
  broadcastGroups: null,
  projectsList: null,
  selectedProject: null,
  multiSelectedProject: [],
  rfqs: null,
  rfqModalData: null,
  haNewUpdatesForPublish: false,
  finalDocsCount: null,
  projectRoles: null,
  selectedRfqPermissions: null,
  snapShotModal: null,
  isRequestOwner: false,
  requestType: null,
  errorMsg: null,
  finalVendors: [],
  auctions: [],
  dashboardSummaryFilterValue: '',
  comparisonReports: null,
  financialReport: null,
  vendorsComparisonReport: {},
  isSuperUser: false,
  jobSummaryReport: null
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PlantVisitRequestTypes.GET_REQUEST_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        requestTypeData: action.result,
      };

    case PlantVisitRequestTypes.CREATE_PLANT_VISIT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        createRequestData: action.result,
      };

    case PlantVisitRequestTypes.DELETE_PLANT_VISIT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        deleteRequestData: action.result,
      };

    case PlantVisitRequestTypes.UPDATE_PLANT_VISIT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        updateRequestData: action.result,
      };

    case PlantVisitRequestTypes.GET_PLANT_VISIT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        requestData: action.result,
      };

    case PlantVisitRequestTypes.GET_PLANT_VISIT_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        requestListData: action.result,
      };

    case PlantVisitRequestTypes.GET_NEW_UPDATE_REQUEST_COUNT_SUCCESS:
      return {
        ...state,
        newJobRequestMessage: action.result.data.newJobRequestMessage,
        newPlanVisitRequestMessage:
          action.result.data.newPlanVisitRequestMessage,
      };

    case JobRequestTypes.GET_JOB_REQUEST_SUCCESS:
      return {
        ...state,
        jobRequests: action.result.vendorRequest,
        totalVendors: action.result.totalVendors.total,
      };

    case JobRequestTypes.SEND_RFQ_SUCCESS:
      return {
        ...state,
      };
    case JobRequestTypes.RECOMMEND_VENDOR_SUCCESS:
      return {
        ...state,
        clientRequest: action.result,
      };
      case JobRequestTypes.RECOMMEND_VENDOR_FAILURE:
        return {
          ...state,
          errorMsg: action.error.message,
        };
      case JobRequestTypes.RESET_ERROR_AND_SUCCESS_MESSAGE:
        return {
          ...state,
          successMsg: null,
          errorMsg: null,
        };
    case JobRequestTypes.SET_RFQ_UPLOAD_ERROR_FILE_LIST:
      return {
        ...state,
        uploadRfqErrorFileList: action.payload,
      };

    case JobRequestTypes.ADD_VENDOR_SUCCESS:
      return {
        ...state,
        addVendorStatus: action.result,
      };

    case JobRequestTypes.REMOVE_VENDOR_SUCCESS:
      return {
        ...state,
        removeVendorStatus: action.result,
      };

    case JobRequestTypes.GET_JOB_REQUEST_REPORT_SUCCESS:
      return {
        ...state,
        jobRequestReport: action.result?.jobRequestDetails,
        totalReports: action.result?.total?.total || action.result?.total,
      };

    case JobRequestTypes.SET_RECOMMEND_VENDOR_UPDATE:
      return {
        ...state,
        clientRequest: action.payload,
      };

    case JobRequestTypes.SET_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };
    case JobRequestTypes.UPDATE_VENDOR_NEW_MESSAGE_COUNT_SUCCESS:
      return {
        ...state,
        apiResponse: action.payload,
      };

    case JobRequestTypes.UPDATE_HAS_NEW_QUOTATION_SUCCESS:
      return {
        ...state,
        apiResponse: action.payload,
      };

    case JobRequestTypes.UPDATE_HAS_NEW_APPROVAL_SUCCESS:
      return {
        ...state,
        apiResponse: action.payload,
      };

    case JobRequestTypes.GET_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        activityLog: action.result,
      };

    case JobRequestTypes.GET_LATEST_FIVE_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        latestActivityLogs: action.result,
      };

    case JobRequestTypes.LOG_ACTIVITY_SUCCESS:
      return {
        ...state,
        logActivityStatus: action.result,
      };
    case JobRequestTypes.CLEAR_ACTIVITY_LOGS:
      return {
        ...state,
        latestActivityLogs: null,
        allActivityLog: null,
      };
    case JobRequestTypes.SUBMIT_COMPARE_QUOTATION_REQUEST_SUCCESS:
      return {
        ...state,
        clientRequest: action.result,
      };
    case JobRequestTypes.RESUBMIT_COMPARE_QUOTATION_REQUEST_SUCCESS:
      return {
        ...state,
        clientRequest: action.result,
      };
    case JobRequestTypes.GET_COMPARED_QUOTATION_SUCCESS:
      return {
        ...state,
        comparedQuotation: action.result,
      };
    case JobRequestTypes.GET_ALL_GENERATED_REPORTS_BY_COMPANY_SUCCESS:
      return {
        ...state,
        customReports: action.result,
      };
    case JobRequestTypes.CREATE_BROADCAST_GROUP:
      return {
        ...state,
        broadcastGroup: action.result,
      };
    case JobRequestTypes.GET_BROADCAST_GROUPS_FOR_JOB:
      return {
        ...state,
        broadcastGroups: action.result,
      };
    case JobRequestTypes.SEND_BROADCAST_MESSAGE:
      return {
        ...state,
        broadcastGroup: action.result,
      };
    case JobRequestTypes.GET_PROJECTS_LIST_SUCCESS:
      return {
        ...state,
        projectsList: action.result.data,
      };
    case JobRequestTypes.SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: action.payload,
      };
      case JobRequestTypes.SET_MULTI_SELECTED_PROJECT:
      return {
        ...state,
        multiSelectedProject: action.payload,
      };
    case JobRequestTypes.CLEAR_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: null,
        multiSelectedProject: [],
      };
    case JobRequestTypes.GET_JOB_RFQS_SUCCESS:
    case JobRequestTypes.RESTORE_DOCUMENT_VERSION_SUCCESS:
    case JobRequestTypes.EDIT_DOC_DECRIPTION_SUCCESS:
    case JobRequestTypes.EDIT_RFQ_FILE_NAME_SUCCESS:
    case JobRequestTypes.TOGGLE_RFQ_FILE_STATE:
    case JobRequestTypes.REMOVE_RFQ_DOCUMENT_SUCCESS:
    case JobRequestTypes.UPLOAD_RFQ_SUCCESS:
      return {
        ...state,
        rfqs: action.result?.data,
        haNewUpdatesForPublish: action.result?.haNewUpdatesForPublish,
        finalDocsCount: action.result?.finalDocs?.length,
      };
    case JobRequestTypes.RESET_JOB_RFQS:
      return {
        ...state,
        rfqs: null,
      };
    case JobRequestTypes.RFQ_MODALS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case JobRequestTypes.RFQ_MODALS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case JobRequestTypes.PUBLISH_RFQ_SUCCESS:
      return {
        ...state,
      };
    case JobRequestTypes.SET_IS_REQUEST_OWNER:
      return {
        ...state,
        isRequestOwner: action.payload,
      };
    case JobRequestTypes.SET_REQUEST_TYPE:
      return {
        ...state,
        requestType: action.payload,
    };
    case JobRequestTypes.SET_IS_SUPER_USER:
      return {
        ...state,
        isSuperUser: action.payload,
      }
    /****** UAC *******/

    case JobRequestTypes.GET_PROJECTS_ROLES_SUCCESS:
      return {
        ...state,
        projectRoles: action.result?.data,
      };
    case JobRequestTypes.SET_SELECTED_RFQ_PERMISSIONS:
      return {
        ...state,
        selectedRfqPermissions: action.payload,
      };

    /* ------------- */
    
    /**** Rfq/Quotation Snapshot  ****/
    case JobRequestTypes.SET_SNAP_SHOT_MODAL:
      return {
        ...state,
        snapShotModal: action.payload,
      };
    /* -------------------- */
    case JobRequestTypes.UPDATE_LAST_ACTIVITY_DATE_SUCCESS: 
    return {
      ...state, 
    }
    /**** Final Venors  ****/
    case JobRequestTypes.GET_FINAL_VENDORS_SUCCESS:
      return {
        ...state,
        finalVendors: action?.result?.data || [],
      };
    case JobRequestTypes.RESET_FINAL_VENDORS:
      return {
        ...state,
        finalVendors: [],
      };
    case JobRequestTypes.GET_AUCTIONS_SUCCESS: 
    return{
      ...state, 
      auctions: action?.result
    }
    case JobRequestTypes.SET_DASHBOARD_SUMMARY_FILTER: 
    return{
      ...state, 
      dashboardSummaryFilterValue: action.payload
    }
    /* -------------------- */

    /**** Comparison reports  ****/
    case JobRequestTypes.GET_COMPARISON_REPORTS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case JobRequestTypes.GET_COMPARISON_REPORTS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case JobRequestTypes.GET_COMPARISON_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        comparisonReports: action.result,
      };
    case JobRequestTypes.GET_FINANCIAL_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        financialReport: action.result,
      };
    case JobRequestTypes.GET_VENDORS_COMPARISON_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorsComparisonReport: action.result,
      };
    case JobRequestTypes.ADD_COMMENT_VENDORS_COMPARISON_REPORT_SUCCESS:
      const updatedReportData = { ...state?.vendorsComparisonReport } as any;
      updatedReportData.data.comments = action.result;
      return {
        ...state,
        loading: false,
        vendorsComparisonReport: updatedReportData
      };
    case JobRequestTypes.RESET_COMPARISON_REPORT_DATA:
      return {
        ...state,
        vendorsComparisonReport: null,
        financialReport: null,
      };
    case JobRequestTypes.GET_JOB_SUMMARY_REPORT: 
    return {
      ...state,
      jobSummaryReport: action.result,
    }
    /* -------------------- */

    default:
      return state;
  }
};
