export const Types = {
  USER_DATA_REQUESTED: "compareApi/USER_DATA_REQUESTED",
  USER_DATA_SUCCESS: "compareApi/USER_DATA_SUCCESS",
  USER_DATA_FAILURE: "compareApi/USER_DATA_FAILURE",
  STORE_GSTN: "compareApi/STORE_GSTN",
  STORE_SELECTED_DATA: "compareApi/STORE_SELECTED_DATA",
  SHAREABLE_LINK_SUCCESS: "compareApi/SHAREABLE_LINK_SUCCESS",

  


};
