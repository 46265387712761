import { Types as AuthenticationTypes } from "_types/AuthenticationTypes";

const initialState = {
  loading: false,
  email: "",
  phone: "",
  otpError: false,
  isOtpVerified: false,
  error: "",
  message: "",
  resetTimer: false,
  resetPwdToken: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case AuthenticationTypes.FORGOT_PASSWORD_REQUESTED:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case AuthenticationTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.result.message,
        error: "",
      };

    case AuthenticationTypes.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case AuthenticationTypes.VERIFY_EMAIL_OTP_REQUESTED:
      return {
        ...state,
        otpError: false,
        loading: true,
        error: "",
      };

    case AuthenticationTypes.VERIFY_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        otpError: false,
        message: action.result.message,
        isOtpVerified: true,
        error: "",
        resetPwdToken: action?.result?.resetPwdToken || null,
      };

    case AuthenticationTypes.VERIFY_EMAIL_OTP_FAILURE:
      return {
        ...state,
        error: action.error.messages,
        otpError: true,
        isOtpVerified: false,
        loading: false,
      };

    case AuthenticationTypes.VERIFY_MOBILE_OTP_REQUESTED:
      return {
        ...state,
        otpError: false,
        loading: true,
        error: "",
      };

    case AuthenticationTypes.VERIFY_MOBILE_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        otpError: false,
        message: action.result.message,
        isOtpVerified: true,
        error: "",
        resetPwdToken: action?.result?.resetPwdToken || null,
      };

    case AuthenticationTypes.VERIFY_MOBILE_OTP_FAILURE:
      return {
        ...state,
        error: action.error.messages,
        otpError: true,
        isOtpVerified: false,
        loading: false,
      };

    case AuthenticationTypes.GENERATE_MOBILE_OTP_FOR_LOGIN_REQUESTED:
      return { ...state, loading: true, error: "" };

    case AuthenticationTypes.GENERATE_MOBILE_OTP_FOR_LOGIN_SUCCESS:
      return {
        ...state,
        message: action.result.message,
        loading: false,
        error: "",
      };

    case AuthenticationTypes.GENERATE_MOBILE_OTP_FOR_LOGIN_FAILURE:
      return { ...state, error: action.error, loading: false };

    case AuthenticationTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case AuthenticationTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: action.result.message,
        resetPwdToken: null,
      };

    case AuthenticationTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case AuthenticationTypes.RESET_STATE:
      return {
        ...(state = initialState),
      };

    case AuthenticationTypes.SET_RESET_TIMER_FLAG:
      return {
        ...state,
        resetTimer: action.payload,
      };

    case AuthenticationTypes.SET_OTP_BOX_ERROR_STATE:
      return { ...state, otpError: action.payload };

    case AuthenticationTypes.PASSWORD_RESET_LINK_VERIFY_EMAIL_SUCCESS:
    case AuthenticationTypes.PASSWORD_RESET_LINK_UPDATE_USER_STATUS_SUCCESS:
      return { ...state, loading: false };

    case AuthenticationTypes.AUTHENTICATION_COMMON_FAILURE:
      return { ...state, loading: false };

    case AuthenticationTypes.AUTHENTICATION_COMMON_REQUESTED:
      return { ...state, loading: true };

    default:
      return state;
  }
};
