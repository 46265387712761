import { Types as RegistrationTypes } from "_types/RegistrationTypes";

const initialState = {
  loading: false,
  storeUserData: null,
  registerResponse: null,
  companyData: null,
  error: null,
  otpResponse: null,
  emailDuplication: null,
  phoneDuplication: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case RegistrationTypes.SET_USER_DATA:
      return {
        ...state,
        loading: false,
        storeUserData: action.payload,
      };

    case RegistrationTypes.REGISTER_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case RegistrationTypes.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        registerResponse: action.result,
      };

    case RegistrationTypes.REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        registerResponse: action.result,
      };

    case RegistrationTypes.SET_REGISTRATION_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case RegistrationTypes.SET_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result,
      };
    case RegistrationTypes.SET_REGISTRATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case RegistrationTypes.GET_COMPANY_DATA_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case RegistrationTypes.GET_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        companyData: action.result,
      };
    case RegistrationTypes.GET_COMPANY_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case RegistrationTypes.GET_OTP_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case RegistrationTypes.GET_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        otpResponse: action.result,
      };
    case RegistrationTypes.GET_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    case RegistrationTypes.CHECK_PHONE_DUPLICATION:
      return {
        ...state,
        loading: false,
        phoneDuplication: action.payload,
      };
    case RegistrationTypes.CHECK_EMAIL_DUPLICATION:
      return {
        ...state,
        loading: false,
        emailDuplication: action.payload,
      };
    case RegistrationTypes.RETRIEVE_DATA_ON_OTP_PAGE_REFRESH:
      return {
        ...state,
        ...action.payload
      }
    case RegistrationTypes.SET_INITIAL_STATE:
      return initialState;
    default:
      return state;
  }
};
