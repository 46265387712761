import { Types as LoginTypes } from "_types/LoginTypes";

const initialState = {
  loading: false as boolean,
  data: {},
  error: null,
};

export type ILoginInitialState = typeof initialState;

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LoginTypes.SET_LOGIN_REQUESTED:
    case LoginTypes.LOGIN_AFTER_VERIFYING_MOBILE_OTP_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case LoginTypes.SET_LOGIN_SUCCESS:
    case LoginTypes.LOGIN_AFTER_VERIFYING_MOBILE_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result,
      };

    case LoginTypes.SET_LOGIN_FAILURE:
    case LoginTypes.LOGIN_AFTER_VERIFYING_MOBILE_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    default:
      return state;
  }
};
