import { RegistrationActions } from "authentication/registration/actions";
import { AuthenticationContainer, Layout } from "authentication/components";
import { AuthPageHeader } from "authentication/components/AuthPageHeader/AuthPageHeader";
import ApiConstant from "constants/ApiConstant";
import { ProfileCreationActions } from "profileCreation/actions";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import LocalStorage from "_services/LocalStorage";
import SessionStorage from "_services/SessionStorage";
import { LoginViaEmail } from "./LoginViaEmail/LoginViaEmail";
import { LoginViaPhone } from "./LoginViaPhone/LoginViaPhone";
import { AuthPageFooter } from "authentication/components/AuthPageFooter/AuthPageFooter";
import { notification } from "antd";

const Login = () => {
  const dispatch = useDispatch();
  dispatch(ProfileCreationActions.reset());
  const { STORAGE_KEYS } = ApiConstant;
  const { inviteId } = useParams<{ inviteId: string }>();
  const [showLoginViaPhone, setShowLoginViaPhone] = useState(false);
  const locationState = useLocation()?.state as { errMessage?: string };

  useEffect(() => {
    if (locationState?.errMessage) {
      /** when user is coming from verify-user-email page - with unsuccessful email verification */
      notification.error({ message: "Error", description: locationState?.errMessage });
      /** resetting history state - to not show the above toast at every page refresh */
      window?.history?.replaceState({}, document.title);
    }

    dispatch(RegistrationActions.setInitialState());
    SessionStorage.remove(STORAGE_KEYS.tempPlantInfo);
    SessionStorage.remove(STORAGE_KEYS.tempRegistrationDetails);
    LocalStorage.set(STORAGE_KEYS.inviteId, inviteId?.length ? inviteId : "");
  }, []);

  return (
    <Layout>
      <AuthenticationContainer>
        <AuthPageHeader type={"LOGIN_PAGE"} />
        {showLoginViaPhone ? (
          <LoginViaPhone setShowLoginViaPhone={setShowLoginViaPhone} />
        ) : (
          <LoginViaEmail setShowLoginViaPhone={setShowLoginViaPhone} />
        )}
        <AuthPageFooter type={"LOGIN_PAGE"} />
      </AuthenticationContainer>
    </Layout>
  );
};

export default Login;
