export const INITIAL_OTP_TIMER = { seconds: 0, minutes: 5 };

export const INITIAL_OTP_STATE = {
  otpValue: "",
  isInputDisabled: false,
  seconds: INITIAL_OTP_TIMER.seconds,
  minutes: INITIAL_OTP_TIMER.minutes,
};

export const AUTH_0_OTP_VERIFICATION_ERROR_MESSAGE = "verification code";

export const AUTH_0_EXHAUSTED_ATTEMPTS_ERROR_FOR_REGISTRATION = "you've reached the maximum number of attempts";