export const Types = {
  GET_VENDOR_PROFILE_REQUESTED: "clientVendor/GET_VENDOR_PROFILE_REQUESTED",
  GET_VENDOR_PROFILE_SUCCESS: "clientVendor/GET_VENDOR_PROFILE_SUCCESS",
  GET_VENDOR_PROFILE_FAILURE: "clientVendor/GET_VENDOR_PROFILE_FAILURE",
  GET_VENDOR_PROFILE_REVIEW_REQUESTED: "clientVendor/GET_VENDOR_PROFILE_REVIEW_REQUESTED",
  GET_VENDOR_PROFILE_REVIEW_SUCCESS: "clientVendor/GET_VENDOR_PROFILE_REVIEW_SUCCESS",
  GET_VENDOR_PROFILE_REVIEW_FAILURE: "clientVendor/GET_VENDOR_PROFILE_REVIEW_FAILURE",
  SET_OPEN_REVIEW_MODAL_SUCCESS: "clientVendor/SET_OPEN_REVIEW_MODAL_REQUESTED",
  SET_OPEN_REVIEW_FORM_MODAL_SUCCESS: "clientVendor/SET_OPEN_REVIEW_FORM_MODAL_SUCCESS",
  SET_ERROR_MESSAGE: "clientVendor/SET_ERROR_MESSAGE",
  SET_MY_REVIEW: "clientVendor/SET_MY_REVIEW",
  SET_VENDOR_PROFILE_REVIEW_SUCCESS: "clientVendor/SET_VENDOR_PROFILE_REVIEW_SUCCESS",
  SET_VENDOR_PROFILE_REVIEW_FAILURE: "clientVendor/SET_VENDOR_PROFILE_REVIEW_FAILURE",
  SET_VENDOR_PROFILE_REVIEW_REQUESTED: "clientVendor/SET_VENDOR_PROFILE_REVIEW_REQUESTED",
  POST_MESSEGE_TO_NON_ONBOARDED_VENDOR_SUCCESS: "clientVendor/POST_MESSEGE_TO_NON_ONBOARDED_VENDOR_SUCCESS",
  SHOW_OR_HIDE_KEY_CONTACTS_MODAL: "clientVendor/SHOW_OR_HIDE_KEY_CONTACTS_MODAL",
  SHOW_OR_HIDE_REGISTERED_LOCATIONS_MODAL: "clientVendor/SHOW_OR_HIDE_REGISTERED_LOCATIONS_MODAL",
  SET_SUBMITTED_FORM_MODAL_SUCCESS: "clientVendor/SET_SUBMITTED_FORM_MODAL_SUCCESS",
  POST_ENQUIRE_ABOUT_THE_VENDOR_REQUESTED: "clientVendor/POST_ENQUIRE_ABOUT_THE_VENDOR_REQUESTED",
  POST_ENQUIRE_ABOUT_THE_VENDOR_SUCCESS: "clientVendor/POST_ENQUIRE_ABOUT_THE_VENDOR_SUCCESS",
  POST_ENQUIRE_ABOUT_THE_VENDOR_FAILURE: "clientVendor/POST_ENQUIRE_ABOUT_THE_VENDOR_FAILURE",
};
