import { IErrorFile, IResolutionPayload } from "Request/Interfaces/RequestInterfaces";
import { Types as PlantVisitRequestTypes } from "_types/PlantVisitRequestTypes";
import { Types as JobRequestTypes } from "_types/VendorRequestTypes";

export const ActionCreators: any = {
  requestTypes: () => ({
    types: [
      PlantVisitRequestTypes.GET_PLANT_VISIT_REQUEST,
      PlantVisitRequestTypes.GET_REQUEST_TYPE_LIST_SUCCESS,
      PlantVisitRequestTypes.GET_PLANT_VISIT_FAILURE,
    ],
    promise: (client: any) => client.get("get_request_type_list", {}),
  }),
  getRFQDocumentVersion: (queryParam: any) => ({
    types: [
      JobRequestTypes.RFQ_MODALS_REQUEST,
      JobRequestTypes.GET_FILE_HISTORY_SUCCESS,
      JobRequestTypes.RFQ_MODALS_FAILURE,
    ],
    promise: (client: any) => client.get(`get_rfq_doc_version/${queryParam}`, {}),
  }),
  restoreDocumentVersion: (data: any) => ({
    types: [
      JobRequestTypes.RFQ_MODALS_REQUEST,
      JobRequestTypes.RESTORE_DOCUMENT_VERSION_SUCCESS,
      JobRequestTypes.RFQ_MODALS_FAILURE,
    ],
    promise: (client: any) => client.patch("restore_doc_version", { data }),
  }),
  editDocumentDescription: (data: any) => ({
    types: [
      JobRequestTypes.RFQ_MODALS_REQUEST,
      JobRequestTypes.EDIT_DOC_DECRIPTION_SUCCESS,
      JobRequestTypes.RFQ_MODALS_FAILURE,
    ],
    promise: (client: any) => client.patch("edit_doc_description", { data }),
  }),
  createRequest: (data: any) => ({
    types: [
      PlantVisitRequestTypes.GET_PLANT_VISIT_REQUEST,
      PlantVisitRequestTypes.CREATE_PLANT_VISIT_REQUEST_SUCCESS,
      PlantVisitRequestTypes.GET_PLANT_VISIT_FAILURE,
    ],
    promise: (client: any) => client.post("post_request_create", { data }),
  }),
  deleteRequest: (data: any) => ({
    types: [
      PlantVisitRequestTypes.GET_PLANT_VISIT_REQUEST,
      ,
      PlantVisitRequestTypes.DELETE_PLANT_VISIT_REQUEST_SUCCESS,
      PlantVisitRequestTypes.GET_PLANT_VISIT_FAILURE,
    ],
    promise: (client: any) => client.post(`post_request_delete`, { data }),
  }),
  updateRequest: (data: any) => ({
    types: [
      PlantVisitRequestTypes.GET_PLANT_VISIT_REQUEST,
      PlantVisitRequestTypes.UPDATE_PLANT_VISIT_REQUEST_SUCCESS,
      PlantVisitRequestTypes.GET_PLANT_VISIT_FAILURE,
    ],
    promise: (client: any) => client.post("post_request_update", { data }),
  }),
  getRequest: (requestId: any) => ({
    types: [
      PlantVisitRequestTypes.GET_PLANT_VISIT_REQUEST,
      PlantVisitRequestTypes.GET_PLANT_VISIT_REQUEST_SUCCESS,
      PlantVisitRequestTypes.GET_PLANT_VISIT_FAILURE,
    ],
    promise: (client: any) => client.get(`get_request/${requestId}?sender=client`, {}),
  }),
  getRequestList: (data: any) => ({
    types: [
      PlantVisitRequestTypes.GET_PLANT_VISIT_REQUEST,
      PlantVisitRequestTypes.GET_PLANT_VISIT_REQUEST_LIST_SUCCESS,
      PlantVisitRequestTypes.GET_PLANT_VISIT_FAILURE,
    ],
    promise: (client: any) => client.get(`get_request_list/${data}&requested_by_type=client`, {}),
  }),
  getNewUpdateRequestCount: (requestId: any) => ({
    types: [
      PlantVisitRequestTypes.GET_PLANT_VISIT_REQUEST,
      PlantVisitRequestTypes.GET_NEW_UPDATE_REQUEST_COUNT_SUCCESS,
      PlantVisitRequestTypes.GET_PLANT_VISIT_FAILURE,
    ],
    promise: (client: any) => client.get(`get_new_update_request_count`, {}),
  }),

  getJobRequests: (params: any) => ({
    types: [
      JobRequestTypes.GET_JOB_REQUEST_REQUESTED,
      JobRequestTypes.GET_JOB_REQUEST_SUCCESS,
      JobRequestTypes.GET_JOB_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.get(`get_job_requests/${params}`),
  }),

  uploadRfqDocs: (data: any) => ({
    types: [
      JobRequestTypes.UPLOAD_RFQ_REQUESTED,
      JobRequestTypes.UPLOAD_RFQ_SUCCESS,
      JobRequestTypes.UPLOAD_RFQ_FAILURE,
    ],
    promise: (client: any) => client.post("add_rfq_documents", { data }),
  }),

  sendRfq: (data: any) => ({
    types: [JobRequestTypes.SEND_RFQ_REQUEST, JobRequestTypes.SEND_RFQ_SUCCESS, JobRequestTypes.SEND_RFQ_FAILURE],
    promise: (client: any) => client.post("send_rfq", { data }, "communication"),
  }),

  recommendVendor: (data: any) => ({
    types: [
      JobRequestTypes.RECOMMEND_VENDOR_REQUESTED,
      JobRequestTypes.RECOMMEND_VENDOR_SUCCESS,
      JobRequestTypes.RECOMMEND_VENDOR_FAILURE,
    ],
    promise: (client: any) => client.post("recommend_vendor", { data }),
  }),
  resetErrorAndSuccessMessage: () => ({
    type: JobRequestTypes.RESET_ERROR_AND_SUCCESS_MESSAGE
  }),
  removeRfqDocuments: (data: { requestId: string; name: string }) => ({
    types: [
      JobRequestTypes.REMOVE_RFQ_DOCUMENT_REQUESTED,
      JobRequestTypes.REMOVE_RFQ_DOCUMENT_SUCCESS,
      JobRequestTypes.REMOVE_RFQ_DOCUMENT_FAILURE,
    ],
    promise: (client: any) => client.patch("delete_job_document", { data }),
  }),

  getApproversList: (jobId: string) => ({
    types: [
      JobRequestTypes.GET_APPROVERS_LIST_REQUESTED,
      JobRequestTypes.GET_APPROVERS_LIST_SUCCESS,
      JobRequestTypes.GET_APPROVERS_LIST_FAILURE,
    ],
    promise: (client: any) => client.get(`get_approvers_list/${jobId}`),
  }),

  createResolution: (data: IResolutionPayload) => ({
    types: [
      JobRequestTypes.CREATE_RESOLUTION_REQUESTED,
      JobRequestTypes.CREATE_RESOLUTION_SUCCESS,
      JobRequestTypes.CREATE_RESOLUTION_FAILURE,
    ],
    promise: (client: any) => client.post("post_create_resolution", { data }),
  }),

  uploadApprovalDocs: (id: string, data: any) => ({
    types: [
      JobRequestTypes.UPLOAD_APPROVAL_DOCS_SUCCESS,
      JobRequestTypes.UPLOAD_APPROVAL_DOCS_REQUESTED,
      JobRequestTypes.UPLOAD_APPROVAL_DOCS_FAILURE,
    ],
    promise: (client: any) => client.post(`post_upload_approval_documents/${id}`, { data }),
  }),

  setRfqUploadErrorDocs: (payload: Array<IErrorFile>) => ({
    type: JobRequestTypes.SET_RFQ_UPLOAD_ERROR_FILE_LIST,
    payload: payload,
  }),

  addVendor: (data: any) => ({
    types: [
      JobRequestTypes.ADD_VENDOR_REQUESTED,
      JobRequestTypes.ADD_VENDOR_SUCCESS,
      JobRequestTypes.ADD_VENDOR_FAILURE,
    ],
    promise: (client: any) => client.post("add_vendor", { data }),
  }),

  removeVendor: (data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.REMOVE_VENDOR_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.patch("remove_vendor", { data }),
  }),

  getJobRequestReport: (params: any) => ({
    types: [
      JobRequestTypes.GET_JOB_REQUEST_REPORT_REQUESTED,
      JobRequestTypes.GET_JOB_REQUEST_REPORT_SUCCESS,
      JobRequestTypes.GET_JOB_REQUEST_REPORT_FAILURE,
    ],
    promise: (client: any) => client.get(`get_job_request_report/${params}`),
  }),
  saveResolutionApproverResponse: (data: any) => ({
    types: [
      JobRequestTypes.SAVE_RESOLUTION_APPROVER_RESPONSE_REQUESTED,
      JobRequestTypes.SAVE_RESOLUTION_APPROVER_RESPONSE_SUCCESS,
      JobRequestTypes.SAVE_RESOLUTION_APPROVER_RESPONSE_FAILURE,
    ],
    promise: (client: any) => client.patch("save_resolution_approver_response", { data }),
  }),

  getJobRequestResolutions: (id: string) => ({
    types: [
      JobRequestTypes.GET_JOB_REQUEST_RESOLUTION_LIST_REQUESTED,
      JobRequestTypes.GET_JOB_REQUEST_RESOLUTION_LIST_SUCCESS,
      JobRequestTypes.GET_JOB_REQUEST_RESOLUTION_LIST_FAILURE,
    ],
    promise: (client: any) => client.get(`get_job_request_resolutions/${id}`),
  }),

  setRecommendVendorUpdate: (flag: boolean) => ({
    type: JobRequestTypes.SET_RECOMMEND_VENDOR_UPDATE,
    payload: flag,
  }),

  setPageOffset: (offset: number) => ({
    type: JobRequestTypes.SET_OFFSET,
    payload: offset,
  }),

  logActivity: (data: any) => ({
    types: [
      JobRequestTypes.LOG_ACTIVITY_REQUEST,
      JobRequestTypes.LOG_ACTIVITY_SUCCESS,
      JobRequestTypes.LOG_ACTIVITY_FAILURE,
    ],
    promise: (client: any) => client.post("log_activity", { data }),
  }),

  getActivityLog: (params: any) => ({
    types: [
      JobRequestTypes.GET_ACTIVITY_LOG_REQUEST,
      JobRequestTypes.GET_ACTIVITY_LOG_SUCCESS,
      JobRequestTypes.GET_ACTIVITY_LOG_FAILURE,
    ],
    promise: (client: any) => client.get(`get_activity_log/${params}`),
  }),
  getLatestFiveActivityLog: (params: any) => ({
    types: [
      JobRequestTypes.GET_ACTIVITY_LOG_REQUEST,
      JobRequestTypes.GET_LATEST_FIVE_ACTIVITY_LOG_SUCCESS,
      JobRequestTypes.GET_ACTIVITY_LOG_FAILURE,
    ],
    promise: (client: any) => client.get(`get_latest_activity_log/${params}`),
  }),
  resetActivityLog: () => ({
    type: JobRequestTypes.CLEAR_ACTIVITY_LOGS,
  }),
  updateVendorNewMessageCount: (data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.UPDATE_VENDOR_NEW_MESSAGE_COUNT_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.patch("update_vendors_new_message_count", { data }),
  }),
  updateHasNewVendor: (data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.UPDATE_HAS_NEW_VENDORS_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.patch("update_has_new_vendor", { data }),
  }),
  updateHasNewQuotation: (data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.UPDATE_HAS_NEW_QUOTATION_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.patch("update_has_new_quotation", { data }),
  }),
  updateHasNewApproval: (data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.UPDATE_HAS_NEW_APPROVAL_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.patch("update_has_new_approval", { data }),
  }),
  updateJobRequestStatus: (data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.UPDATE_JOB_REQUEST_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.patch("update_job_status", { data }),
  }),
  submitClientRequest: (data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.SUBMIT_COMPARE_QUOTATION_REQUEST_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.post("submit_compare_quotation_request", { data }),
  }),
  resubmitCompareQuotationRequest: (data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.RESUBMIT_COMPARE_QUOTATION_REQUEST_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.put("resubmit_compare_quotation_request", { data }),
  }),
  getComparedQuotation: (id: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.GET_COMPARED_QUOTATION_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.get(`get_compared_quotation/${id}`),
  }),
  getAllGeneratedReportsByCompany: (params: any) => ({
    types: [
      JobRequestTypes.GET_ALL_GENERATED_REPORTS_BY_COMPANY_REQUESTED,
      JobRequestTypes.GET_ALL_GENERATED_REPORTS_BY_COMPANY_SUCCESS,
      JobRequestTypes.GET_ALL_GENERATED_REPORTS_BY_COMPANY_FAILURE,
    ],
    promise: (client: any) => client.get(`get_all_generated_reports_by_company/${params}`),
  }),
  createBroadcastGroup: (data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.CREATE_BROADCAST_GROUP,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.post("create_broadcast_group", { data }),
  }),
  getBroadcastGroupForAJob: (id: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.GET_BROADCAST_GROUPS_FOR_JOB,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.get(`get_broadcast_groups_for_job/${id}`),
  }),
  sendBroadcastMessage: (data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.SEND_BROADCAST_MESSAGE,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.patch("send_broadcast_message", { data }),
  }),
  sendEmailForFileDownload: (data: any) => ({
    types: [
      JobRequestTypes.SEND_EMAIL_FOR_FILE_DOWNLOAD_REQUESTED,
      JobRequestTypes.SEND_EMAIL_FOR_FILE_DOWNLOAD_SUCCESS,
      JobRequestTypes.SEND_EMAIL_FOR_FILE_DOWNLOAD_FAILURE,
    ],
    promise: (client: any) => client.post("send_email_for_file_download", { data }),
  }),
  getProjectsList: () => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.GET_PROJECTS_LIST_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.get("get_project_lists"),
  }),

  getProjectRoles: () => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.GET_PROJECTS_ROLES_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.get("get_projects_roles"),
  }),
  setSelectedProject: (data:any) => ({
    type: JobRequestTypes.SET_SELECTED_PROJECT,
    payload: data
  }),
  setMultiSelectedProject: (data:any) => ({
    type: JobRequestTypes.SET_MULTI_SELECTED_PROJECT,
    payload: data
  }),
  resetSelectedProject: () => ({
    type: JobRequestTypes.CLEAR_SELECTED_PROJECT,
  }),
  getJobRFQs: (jobRequestId: string) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.GET_JOB_RFQS_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.get(`get_rfq/${jobRequestId}`),
  }),
  resetJobRFQs: () => ({
    type: JobRequestTypes.RESET_JOB_RFQS,
  }),
  toggleRFQFileState: (data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.TOGGLE_RFQ_FILE_STATE,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.patch("toggle_rfq_file_state", { data }),
  }),
  editRfqFileName: (data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.EDIT_RFQ_FILE_NAME_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.put("edit_rfq_file_name", { data }),
  }),
  publishRFQ: (data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.PUBLISH_RFQ_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.post("publish_rfq", { data }),
  }),
  setSelectedRfqPermissions:(data:any) => ({
    type: JobRequestTypes.SET_SELECTED_RFQ_PERMISSIONS,
    payload: data
  }),
  
  setSnapShotModal : (payload: any) => ({
    type: JobRequestTypes.SET_SNAP_SHOT_MODAL,
    payload
  }),

  setIsRequestOwner:(data:any) => ({
    type: JobRequestTypes.SET_IS_REQUEST_OWNER,
    payload: data
  }),
  setRequestType:(data:any) => ({
    type: JobRequestTypes.SET_REQUEST_TYPE,
    payload: data
  }),

  getVendorEmailAndNum: (input: string) => ({
    types: JobRequestTypes.GET_VENDORS_EMAIL_AND_NUM,
    promise: (client: any) => client.get(`get_vendor_email_num/${input}`),
  }),
  
  updateLastActivityDate: (jobId: string) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED, 
      JobRequestTypes.UPDATE_LAST_ACTIVITY_DATE_SUCCESS, 
      JobRequestTypes.VENDOR_REQUEST_FAILURE
    ],
    promise: (client: any) => client.patch(`update_last_activity_date/${jobId}`),
  }),
  
  getFinalVendors:(jobId: string) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.GET_FINAL_VENDORS_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.get(`get_final_vendors/${jobId}`),
  }),

  addVendorsToFinal:(data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.ADD_VENDORS_TO_FINAL_SUCCESS,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.post("add_vendors_to_final", { data }),
  }),

  resetFinalVendors: () => ({
    type: JobRequestTypes.RESET_FINAL_VENDORS,
  }),
  getAuctions: (jobId: string) => ({
    types: [JobRequestTypes.VENDOR_REQUEST_REQUESTED, JobRequestTypes.GET_AUCTIONS_SUCCESS, JobRequestTypes.VENDOR_REQUEST_FAILURE],
    promise: (client: any) =>  client.get(`get_auctions/${jobId}/get-auctions`)
  }),
  setDashboardFilterValue: (filter: string) => ({
    type: JobRequestTypes.SET_DASHBOARD_SUMMARY_FILTER,
    payload: filter,
  }),
  autoPublishRfqDocsUpdate: (data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.AUTO_PUBLISH_RFQ_DOCS_UPDATE,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.patch("auto_publish_rfq_docs_update", { data }),
  }),
  getComparisonReports: (jrId: string) => ({
    types: [
      JobRequestTypes.GET_COMPARISON_REPORTS_REQUESTED,
      JobRequestTypes.GET_COMPARISON_REPORTS_SUCCESS,
      JobRequestTypes.GET_COMPARISON_REPORTS_FAILED,
    ],
    promise: (client: any) => client.get(`get_comparison_reports/${jrId}`),
  }),
  getFinancialReport: (reportId: string, viewAccessCode: string) => ({
    types: [
      JobRequestTypes.GET_COMPARISON_REPORTS_REQUESTED,
      JobRequestTypes.GET_FINANCIAL_REPORT_SUCCESS,
      JobRequestTypes.GET_COMPARISON_REPORTS_FAILED,
    ],
    promise: (client: any) => client.get(`get_financial_report/${reportId}/${viewAccessCode || ""}`),
  }),
  getVendorsComparisonReport: (reportId: string, viewAccessCode: string) => ({
    types: [
      JobRequestTypes.GET_COMPARISON_REPORTS_REQUESTED,
      JobRequestTypes.GET_VENDORS_COMPARISON_REPORT_SUCCESS,
      JobRequestTypes.GET_COMPARISON_REPORTS_FAILED,
    ],
    promise: (client: any) => client.get(`get_vendor_comparison_report/${reportId}/${viewAccessCode || ""}`),
  }),
  addCommentComparisonReport: (data: any) => ({
    types: [
      JobRequestTypes.GET_COMPARISON_REPORTS_REQUESTED,
      JobRequestTypes.ADD_COMMENT_VENDORS_COMPARISON_REPORT_SUCCESS,
      JobRequestTypes.GET_COMPARISON_REPORTS_FAILED,
    ],
    promise: (client: any) => client.post("add_comment_vendor_comparison_report", { data }),
  }),
  resetComparisonReportData: () => ({
    type: JobRequestTypes.RESET_COMPARISON_REPORT_DATA
  }),
  setIsSuperUser:(data:any) => ({
    type: JobRequestTypes.SET_IS_SUPER_USER,
    payload: data
  }),
  downloadSummaryReport: (data: any) => ({
    types: [
      JobRequestTypes.VENDOR_REQUEST_REQUESTED,
      JobRequestTypes.GET_JOB_SUMMARY_REPORT,
      JobRequestTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.post("get_job_summary_report", { data }),
  }),
};

export default ActionCreators;
