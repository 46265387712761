import { AuthenticationContainer, Layout } from "authentication/components";
import { AuthPageFooter } from "authentication/components/AuthPageFooter/AuthPageFooter";
import { Typography } from "components/package";
import { COLORS } from "components/package/package.styles";
import { Box, Flex } from "components/package/styled-system/primitives";
import React from "react";

export const RegistrationSuccessPage = () => {
  const LOGO_URL = {
    DESKTOP: "url('/images/logo.svg')",
    MOBILE: "url('/images/logo-venwiz-mobile.svg')",
  };

  return (
    <Layout>
      <AuthenticationContainer>
        <Box
          backgroundImage={LOGO_URL.DESKTOP}
          width={"3.875rem"} // 62px
          height={"3.125rem"} // 50px
          display={{ default: "block", xxs: "block", sm: "none" }}
        />
        <Flex
          width={"100%"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          mt={"4rem"}
        >
          <img style={{ width: "25%", height: "25%" }} src="/images/success.svg" />
          <Box
            mt={"2rem"} // 40px
          >
            <Typography
              fontSize={{
                default: "1rem", // 16px
                xxs: "1rem", // 16px
                sm: "1.125rem", // 18px
              }}
              fontWeight={600}
              color={COLORS.blue_2}
              textAlign={"center"}
            >
              Your Request for access has been successfully received.
            </Typography>
            <Typography
              mt={"1.75rem"}
              fontSize={{
                default: "1rem", // 16px
                xxs: "1rem", // 16px
                sm: "1.125rem", // 18px
              }}
              fontWeight={600}
              color={COLORS.blue_2}
              textAlign={"center"}
            >
              You will receive an email once your account is approved.
            </Typography>
          </Box>

          {/* <MessageWrapper>
            {otpResponse?.isSubsequentUser === false && (
              <GoToLoginDiv>
                <span>Received the email?</span>
                <AuthenticationButton mt={"1rem"} variant="primary" onClick={() => history.push("/login")}>
                  GO TO LOGIN
                </AuthenticationButton>
              </GoToLoginDiv>
            )}
          </MessageWrapper> */}
        </Flex>
        <AuthPageFooter type={"REGISTRATION_SUCCESS_PAGE"} />
      </AuthenticationContainer>
    </Layout>
  );
};
