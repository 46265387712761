import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { Layout, AuthenticationContainer } from "authentication/components";
import PasswordStrengthMeter from "components/PasswordStrengthMeter/PasswordStrengthMeter";
import PasswordValidator from "components/PasswordValidator/PasswordValidator";
import { getIntlString } from "_services/Locale";
import PasswordStrengthEstimator from "constants/PasswordMeterEstimator";
import { AuthenticationActions } from "authentication/actions";
import SessionStorage from "_services/SessionStorage";
import ApiConstant from "constants/ApiConstant";
import LocalStorage from "_services/LocalStorage";
import ReactGA from "react-ga";
import { Event } from "../../analytics/UniversalGoogleAnalytics/UniversalGoogleAnalytics";
import AntdToast from "components/Toast/AntdToast";
import { LoginActions } from "authentication/login/actions";
import { heapIdentifyTrigger, initializeHeapAnalyticsOnlyForNonInternalVenwizUser } from "shared/helpers/heapAnalytics.helper";
import { StyledForm } from "authentication/registration/Registration.styles";
import { Box } from "components/package/styled-system/primitives";
import { ResponsiveInput as Input, Typography } from "components/package";
import { ErrorContainer } from "authentication/components/ErrorContainer/ErrorContainer";
import { AuthPageHeader } from "authentication/components/AuthPageHeader/AuthPageHeader";
import { COLORS } from "components/package/package.styles";
import { validateResetPasswordSchema } from "./resetPassword.constants";
import { AUTHENTICATION_PAYLOAD_TYPE } from "authentication/actions/actions";
import { AuthenticationButton } from "authentication/components/Button/Button";
import { HEAP_DATA_TRACKING_ID, HEAP_TRIGGERS } from "constants/heapAnalytics.constants";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

const ResetPassword = () => {
  const intl = getIntlString();
  const dispatch = useDispatch();
  const history = useHistory();
  const authState = useSelector((state: any) => state.AuthenticationReducers);
  let email =
    authState.email ||
    LocalStorage.get(ApiConstant.STORAGE_KEYS.userEmail) ||
    SessionStorage.get(ApiConstant.STORAGE_KEYS.userEmail) ||
    SessionStorage.get(ApiConstant.STORAGE_KEYS.userData);
  const { resetPwdToken } = authState;
  const { STORAGE_KEYS } = ApiConstant;
  const initialValues = { newPassword: "", confirmPassword: "" };
  const [showPassword, setShowPassword] = useState({ newPass: false, confirmPass: false });
  const LOCAL_USER_ID_FOR_PASS_RESET_LINK = LocalStorage.get(ApiConstant.STORAGE_KEYS.userIdForPassResetLink);

  const toggleShowPassword = (type: keyof typeof showPassword) => {
    setShowPassword(prev => ({ ...prev, [type]: !prev?.[type] }));
  };

  const loginUser = (password: string) => {
    dispatch(LoginActions.setLogin({ email, password })).then((res: any) => {
      /** For Heap Analytics */
      initializeHeapAnalyticsOnlyForNonInternalVenwizUser(res?.userData?.email , window);
      if ((window as any)?.heap) heapIdentifyTrigger((window as any)?.heap, res?.userData?.email);
      LocalStorage.set(STORAGE_KEYS.userData, res);
      SessionStorage.set(STORAGE_KEYS.userData, res);
      LocalStorage.set(STORAGE_KEYS.isRememberEnabled, 0);

      const applicationFlags = LocalStorage.get(STORAGE_KEYS.applicationFlags);
      if (!applicationFlags) {
        LocalStorage.set(STORAGE_KEYS.applicationFlags, { showDemoVideo: true });
      }
      ReactGA.set({ userId: res.userData._id });
      Event("Login", "Reset Password and Login into Client Module", "Reset Password");
      LocalStorage.remove(STORAGE_KEYS.userEmail);
      AntdToast({ type: "success", message: "Success", description: "Password Reset Successfully!" });
      history.push("/dashboard");
    });
  };

  const onSubmit = (values: any, { setFieldError }: any) => {
    const { newPassword, confirmPassword } = values;
    if (typeof email === "object") {
      email = email?.userData?.email;
    }

    let RESET_PASSWORD_PAYLOAD: AUTHENTICATION_PAYLOAD_TYPE["RESET_PASSWORD"] = { email, newPassword };
    const RESET_PASS_TOKEN = resetPwdToken || SessionStorage.get(ApiConstant.STORAGE_KEYS.resetPwdToken);
    if (RESET_PASS_TOKEN) RESET_PASSWORD_PAYLOAD.resetPwdToken = RESET_PASS_TOKEN;

    dispatch(AuthenticationActions.resetPassword(RESET_PASSWORD_PAYLOAD)).then(
      (res: any) => {
        SessionStorage.remove(ApiConstant.STORAGE_KEYS.resetPwdToken);
        if (LOCAL_USER_ID_FOR_PASS_RESET_LINK) {
          dispatch(AuthenticationActions.passwordResetLinkUpdateUserStatus(LOCAL_USER_ID_FOR_PASS_RESET_LINK));
        }
        loginUser(newPassword);
      },
      (err: any) => {
        if (err?.status === 401) {
          SessionStorage.remove(ApiConstant.STORAGE_KEYS.resetPwdToken);
          AntdToast({ type: "error", message: err.messages || "Error", description: "Please try again" });
          history.push("/forgotpassword");
        } else if ((err?.messages as string)?.toLowerCase()?.includes("session expired")) {
          AntdToast({ type: "error", message: "Session Expired" });
        } else {
          setFieldError("newPassword", err.message);
        }
      },
    );
  };

  const formik = useFormik({ initialValues, onSubmit, validationSchema: validateResetPasswordSchema });

  const FIELD_ERRORS = {
    newPassword: Boolean(formik.touched.newPassword && formik.errors.newPassword),
    confirmPassword: Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword),
  };

  return (
    <Layout>
      <AuthenticationContainer>
        <AuthPageHeader type="RESET_PASSWORD" />
        <Typography mt={"1rem"} mb={"1rem"} fontSize={"0.875rem"} color={COLORS.black_2} textAlign={"left"}>
          {intl["resetPassword.title"]}
        </Typography>
        <StyledForm onSubmit={formik.handleSubmit}>
          {/* New Password */}
          <Input
            name="newPassword"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Enter New Password"
            error={FIELD_ERRORS.newPassword}
            value={formik.values.newPassword}
            type={showPassword.newPass ? "text" : "password"}
            {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.RESET_PASSWORD_PAGE.NEW_PASSWORD_INPUT }}
            fieldDescription={
              !formik.values.newPassword &&
              FIELD_ERRORS.newPassword && <ErrorContainer errorText={formik.errors.newPassword} />
            }
            pr={"2rem"}
            rightIcon={
              <Box
                right={0}
                pr={"0.5rem"}
                cursor={"pointer"}
                position={"absolute"}
                onClick={() => toggleShowPassword("newPass")}
                fontSize={{ default: "1rem", xxs: "1rem", sm: "1.2rem" }}
                top={{ default: "0.25rem", xxs: "0.25rem", sm: "0.625rem" }}
              >
                {showPassword.newPass ? (
                  <MdVisibility color={COLORS.blue_1} />
                ) : (
                  <MdVisibilityOff color={COLORS.blue_1} />
                )}
              </Box>
            }
          />
          <PasswordStrengthMeter
            showInfo={false}
            containerStyleProps={{
              flexDirection: { default: "column", xxs: "column", sm: "row" },
              alignItems: { default: "flex-start", xxs: "flex-start", sm: "center" },
              gap: { default: "0.25rem", xxs: "0.25rem", sm: "0.5rem" },
            }}
            percent={PasswordStrengthEstimator(formik.values.newPassword)}
          />
          <PasswordValidator formikData={formik} />
          {/* Confirm Password */}
          <Input
            containerProps={{ mt: "2.75rem" }}
            placeholder="Enter Confirmed Password"
            name="confirmPassword"
            type={showPassword.confirmPass ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            error={FIELD_ERRORS.confirmPassword}
            fieldDescription={
              FIELD_ERRORS.confirmPassword && <ErrorContainer errorText={formik.errors.confirmPassword} />
            }
            {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.RESET_PASSWORD_PAGE.CONFIRM_PASSWORD_INPUT }}
            // TODO: optimize code for disabled value
            disabled={FIELD_ERRORS.newPassword || formik.values.newPassword?.length === 0}
            pr={"2rem"}
            rightIcon={
              <Box
                right={0}
                pr={"0.5rem"}
                cursor={"pointer"}
                position={"absolute"}
                onClick={() => toggleShowPassword("confirmPass")}
                fontSize={{ default: "1rem", xxs: "1rem", sm: "1.2rem" }}
                top={{ default: "0.25rem", xxs: "0.25rem", sm: "0.625rem" }}
              >
                {showPassword.confirmPass ? (
                  <MdVisibility color={COLORS.blue_1} />
                ) : (
                  <MdVisibilityOff color={COLORS.blue_1} />
                )}
              </Box>
            }
          />
          <AuthenticationButton
            variant={"primary"}
            type="submit"
            {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.RESET_PASSWORD_PAGE.RESET_CTA }}
          >
            RESET
          </AuthenticationButton>
        </StyledForm>
      </AuthenticationContainer>
    </Layout>
  );
};

export default ResetPassword;
