import React from "react";
import { Box, Flex } from "components/package/styled-system/primitives";
import { getIntlString } from "_services/Locale";
import { CarouselBase, CarouselText } from "./layout.styles";

const Layout = ({ children }: any) => {
  const intl = getIntlString();
  const LOGO_URL = {
    DESKTOP: "url('/images/logo.svg')",
    MOBILE: "url('/images/logo-venwiz-mobile.svg')",
  };

  return (
    <>
      {/* AuthContainer */}
      <Flex
        height={"100vh"}
        minWidth={"100vw"}
        overflow={"auto"}
        backgroundSize={"cover"}
        backgroundImage={"url('/images/background.svg')"}
      >
        {/* Desktop ContainerBox */}
        <Box
          width={"60vw"}
          pl={"1.25rem"} // 20px
          pr={"1.25rem"} // 20px
          pt={"1.5rem"} // 24px
          display={{ default: "none", xxs: "none", sm: "block" }}
        >
          {/* Logo */}
          <Box
            backgroundImage={LOGO_URL.DESKTOP}
            width={"3.875rem"} // 62px
            height={"3.125rem"} // 50px
          />
          {/* Transition */}
          <Flex alignItems={"center"} justifyContent={"center"} mt={"150px"}>
            <CarouselBase autoplay>
              {intl.carouselData.map((item, index) => (
                <CarouselText key={index}>{item}</CarouselText>
              ))}
            </CarouselBase>
          </Flex>
        </Box>

        <Box overflow={"hidden"}>
          {/* ChildrenWrapper */}
          <Flex width={{ default: "100vw", xxs: "100vw", sm: "40vw" }}>{children}</Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Layout;
