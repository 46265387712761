import React, { useState } from "react";
import { Button } from "components/Button";
import { Base, ButtonWrap, ModalBox, PrivacyModal } from "./Terms.styles";
import { TnCsContent } from "./TnCsContent";
import { PrivacyPolicyContent } from "./PrivacyPolicyContent";
import { Typography } from "components/package";
import { COLORS } from "components/package/package.styles";

const Terms = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);

  const handleClick = () => {
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showPrivacyModal = () => {
    setPrivacyModalVisible(true);
  };

  const hidePrivacyModal = () => {
    setPrivacyModalVisible(false);
  };
  return (
    <Base>
      By continuing, you agree to Venwiz’s
      <Typography display={"inline-block"} onClick={showModal} color={COLORS.blue_1} ml={"0.25rem"} cursor={"pointer"}>
        Terms and Conditions
      </Typography>
      &nbsp;and
      <Typography
        display={"inline-block"}
        onClick={showPrivacyModal}
        color={COLORS.blue_1}
        ml={"0.25rem"}
        cursor={"pointer"}
      >
        Privacy Policy
      </Typography>
      <ModalBox
        centered
        title="Terms and Conditions"
        footer={
          <ButtonWrap>
            <Button onClick={handleClick} variant="secondary" size="small" style={{ padding: "20px 60px" }}>
              Okay
            </Button>
          </ButtonWrap>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<img src="/images/xCircle.svg" alt="close button" />}
      >
        {<TnCsContent />}
      </ModalBox>
      <PrivacyModal
        centered
        title="Privacy Policy"
        footer={
          <ButtonWrap>
            <Button onClick={hidePrivacyModal} variant="secondary" size="small" style={{ padding: "20px 60px" }}>
              Okay
            </Button>
          </ButtonWrap>
        }
        visible={privacyModalVisible}
        onOk={hidePrivacyModal}
        onCancel={hidePrivacyModal}
        closeIcon={<img src="/images/xCircle.svg" alt="close button" />}
      >
        {<PrivacyPolicyContent />}
      </PrivacyModal>
    </Base>
  );
};

export default Terms;
