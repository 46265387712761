import React, { useEffect, useRef, useState } from "react";
import { ResponsiveInput as Input, Typography } from "components/package";
import { StyledForm } from "authentication/registration/Registration.styles";
import { FormikHelpers, useFormik } from "formik";
import { FORMIK_INITIAL_VALUES, VALIDATION_SCHEMA } from "../login.constants";
import { Box, Flex } from "components/package/styled-system/primitives";
import { ErrorContainer } from "authentication/components/ErrorContainer/ErrorContainer";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OtpBox from "authentication/components/OtpBox/OtpBox";
import { AUTH_0_OTP_VERIFICATION_ERROR_MESSAGE, INITIAL_OTP_STATE, INITIAL_OTP_TIMER } from "authentication/components/OtpBox/otpBox.constants";
import {
  analyticsHandler,
  getErrorMessage,
  redirectionHandler,
  updateLocalStorageAndSessionStorageHandler,
} from "../login.handler";
import { AuthenticationActions } from "authentication/actions";
import { AUTHENTICATION_PAYLOAD_TYPE } from "authentication/actions/actions";
import { AuthenticationButton } from "authentication/components/Button/Button";
import { setOTPTimerInSession, whetherToShowResendOTPButton } from "authentication/components/OtpBox/otp.handler";
import { HEAP_DATA_TRACKING_ID, HEAP_TRIGGERS } from "constants/heapAnalytics.constants";
import { COLORS } from "components/package/package.styles";
import { LoaderActions } from "components/Spinner/actions";
import { PageSpinner } from "components/Spinner";
import { RootState } from "store/reducers";
import { LOGIN_PAYLOAD_TYPE } from "../actions/actions";
import { LoginActions } from "../actions";
import SSOModal from "authentication/components/SSOModal/SSOModal";

type InitialFormikValues = typeof FORMIK_INITIAL_VALUES.LOGIN_VIA_PHONE_NUMBER;

interface IProps {
  setShowLoginViaPhone: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoginViaPhone = (props: IProps) => {
  const IS_REMEMBERED = true;
  const buttonRef = useRef<null | HTMLDivElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [prevPhoneValue, setPrevPhoneValue] = useState("");
  const [showLoginViaPhoneOTPContainer, setShowLoginViaPhoneOTPContainer] = useState(false);
  const [initialOTPState, setInitialOTPState] = useState({
    ...INITIAL_OTP_STATE,
    seconds: Number(sessionStorage.getItem("sec")) || INITIAL_OTP_TIMER.seconds,
    minutes: Number(sessionStorage.getItem("min")) || INITIAL_OTP_TIMER.minutes,
  });
  const { otpError, error: errMessage, resetTimer, loading: isLoading } = useSelector(
    (state: RootState) => state.AuthenticationReducers,
  );
  const [showSendOTPButton, setShowSendOTPButton] = useState(true);
  const [showReSendOTPButton, setShowReSendOTPButton] = useState(false);
  const [showSSOModal, setShowSSOModal] = useState(false);

  const formik = useFormik({
    validationSchema: VALIDATION_SCHEMA.LOGIN_VIA_PHONE,
    initialValues: FORMIK_INITIAL_VALUES.LOGIN_VIA_PHONE_NUMBER,
    onSubmit: (values, actions) => handleOnSubmitLoginViaPhone(values, actions),
  });

  const FIELD_ERRORS = { phone: Boolean(formik.touched.phone && formik.errors.phone) };

  const whetherToShowSendOrResendOTPButton =
    (showSendOTPButton || showReSendOTPButton) && !FIELD_ERRORS?.phone && formik?.values?.phone;

  /** disable form Submit button after OTP container is opened & when OTP_Input_Value is less than six */
  const isFormSubmitButtonDisabled = showLoginViaPhoneOTPContainer && initialOTPState.otpValue?.length < 6;

  /** toggle Send OTP Button's visibility */
  useEffect(() => {
    if (!showSendOTPButton && formik.isValid) setShowReSendOTPButton(true);
  }, [formik?.values?.phone]);

  useEffect(() => {
    const { minutes, seconds } = initialOTPState;
    if (whetherToShowResendOTPButton({ minutes, seconds }) || (seconds === 0 && minutes === 0)) {
      /** toggle Re-Send OTP Button's visibility */
      setShowReSendOTPButton(true);
    }
  }, [initialOTPState]);

  useEffect(() => {
    if ((formik?.values?.phone !== prevPhoneValue)) {
      /** when user's input value is changed - toggle ReSend OTP button's visibility */
      setShowReSendOTPButton(true);
    }
    setPrevPhoneValue(formik?.values?.phone);
  }, [formik?.values?.phone]);

  /** Verify Mobile OTP and Login User */
  const handleOnSubmitLoginViaPhone = (
    values: InitialFormikValues,
    { setFieldError }: FormikHelpers<InitialFormikValues>,
  ) => {
    if (!showLoginViaPhoneOTPContainer && initialOTPState?.otpValue?.length !== 6) {
      /**
       * when user clicks Login Button after entering the correct phone Number -> 
       * show the below error
       */
      setFieldError("phone", "Please verify phone number!");
    }
    if (formik.isValid && initialOTPState?.otpValue?.length === 6) {
      const PAYLOAD: LOGIN_PAYLOAD_TYPE["VERIFY_MOBILE_AND_LOGIN"] = {
        mobileNo: `+91${values.phone}`,
        otp: String(initialOTPState?.otpValue),
      };
      /** verify OTP */
      dispatch(LoginActions.loginAfterVerifyingMobileOtp(PAYLOAD)).then(
        (res: any) => {
          /** successfully login user */
          const RES = { ...res, email: res?.email || "" };
          analyticsHandler(RES);
          updateLocalStorageAndSessionStorageHandler(RES, IS_REMEMBERED);
          redirectionHandler(RES, history);

          setOTPTimerInSession(INITIAL_OTP_TIMER); // reset OTP Timer
          setFieldError("phone", ""); // reset formik error field
        },
        (err: any) => {
          const ERROR_MESSAGE = getErrorMessage(err);
          if ((ERROR_MESSAGE as string)?.toLowerCase()?.includes(AUTH_0_OTP_VERIFICATION_ERROR_MESSAGE)) {
            dispatch(AuthenticationActions.setOTPBoxErrorState(true));
          } else {
            setFieldError("phone", ERROR_MESSAGE);
          }
        },
      );
    }
  };

  const resetOTPRelatedStates = () => {
    /** hide Send/Re-send OTP button - when requesting for OTP */
    setShowSendOTPButton(false);
    setShowReSendOTPButton(false);
    /** reset OTP Timer at every send/resend API call */
    setInitialOTPState(prev => ({ ...prev, ...INITIAL_OTP_TIMER, otpValue: "", isInputDisabled: false }));
    dispatch(AuthenticationActions.setOTPBoxErrorState(false));
  };

  /** Generate Mobile OTP for Login via Phone */
  const handleGenerateOTPForLoginViaPhone = () => {
    const PAYLOAD: AUTHENTICATION_PAYLOAD_TYPE["GENERATE_MOBILE_OTP_FOR_LOGIN_VIA_PHONE"] = {
      mobileNo: `+91${formik.values.phone}`,
    };
    dispatch(AuthenticationActions.generateOTPForloginViaMobile(PAYLOAD)).then(
      (res: any) => {
        // Exclude SSO users
        if (res?.data?.email?.includes('@' + process.env.REACT_APP_SSO_PRIMETAL_KEY + '.com')) {
          console.log('condition - showSSOModal', showSSOModal)
          setShowSSOModal(true);
          return;
        }
        setShowLoginViaPhoneOTPContainer(true);
        formik.setFieldError("phone", "");
        resetOTPRelatedStates();
      },
      (err: any) => {
        const ERROR_MESSSAGE = getErrorMessage(err);
        formik.setFieldError("phone", ERROR_MESSSAGE);
        setShowLoginViaPhoneOTPContainer(false);
        resetOTPRelatedStates();
      },
    );
  };

  return (
    <>
      <PageSpinner isLoading={isLoading} />
      {showSSOModal && 
        <SSOModal setShowSSOModal={setShowSSOModal} />
      }
      <StyledForm onSubmit={formik.handleSubmit}>
        {/* Phone Number */}
        <Input
          name="phone"
          type="text"
          maxLength={10}
          placeholder="Phone Number"
          onBlur={formik.handleBlur}
          error={FIELD_ERRORS.phone}
          value={formik.values.phone}
          onChange={formik.handleChange}
          {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.LOGIN.VIA_PHONE.PHONE_INPUT }}
          fieldDescription={FIELD_ERRORS.phone && <ErrorContainer errorText={formik.errors.phone} />}
          pr={"2rem"}
          rightIcon={
            whetherToShowSendOrResendOTPButton ? (
              <Box
                right={0}
                pr={"0.5rem"}
                ref={buttonRef}
                cursor={"pointer"}
                position={"absolute"}
                onClick={handleGenerateOTPForLoginViaPhone}
                top={{ default: "0.25rem", xxs: "0.25rem", sm: "0.625rem" }}
              >
                <Typography fontSize={"0.8rem"} cursor={"pointer"} fontWeight={600} color={COLORS.blue_1}>
                  <>{showSendOTPButton ? "Send OTP" : showReSendOTPButton ? "Resend OTP" : ""}</>
                </Typography>
              </Box>
            ) : (
              <></>
            )
          }
        />
        {/* login via Mobile OTP Container */}
        {showLoginViaPhoneOTPContainer && (
          <Flex
            width={"100%"}
            flexDirection={"column"}
            mt={{
              default: "2.5rem", // 40px
              xxs: "2.5rem", // 40px
              sm: "1.25rem", // 20px
            }}
          >
            <OtpBox
              title={"Enter OTP To Login"}
              renderResendOTPButton={false}
              customResendOTPButton={<></>}
              initialOTPState={initialOTPState}
              setInitialOTPState={setInitialOTPState}
            />
          </Flex>
        )}
        <Flex
          w={"100%"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          mt={
            showLoginViaPhoneOTPContainer
              ? {
                  default: "1.875rem", // 30px,
                  xxs: "1.875rem", // 30px,
                  xl: "2.5rem", // 40px,
                }
              : "0"
          }
        ></Flex>
        <Flex
          gap={"1rem"}
          flexDirection={"column"}
          mt={showLoginViaPhoneOTPContainer ? { default: "2rem", xxs: "2rem", xl: "3.8125rem" } : "0"}
        >
          <AuthenticationButton
            type="submit"
            variant="primary"
            disabled={isFormSubmitButtonDisabled}
            {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.LOGIN.VIA_PHONE.CTA.LOGIN_SUBMIT }}
          >
            {/* TODO: (optional) consume typography variant here */}
            LOGIN
          </AuthenticationButton>
          <AuthenticationButton
            variant="outlined"
            onClick={() => props?.setShowLoginViaPhone(false)}
            {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.LOGIN.VIA_PHONE.CTA.LOGIN_VIA_EMAIL }}
          >
            Login via Email
          </AuthenticationButton>
        </Flex>
      </StyledForm>
    </>
  );
};
