import { COLORS } from 'components/package/package.styles';
import { Flex } from 'components/package/styled-system/primitives';
import React from 'react'
import { Box, Button, Typography } from 'venwiz-ui';
import { RESPONSE_PAGE_CONTENT, RESPONSE_PAGE_TYPE } from './submitReviewOpenPage.constants';

interface IProps {
  vendorProfileLink: string;
  TYPE: keyof typeof RESPONSE_PAGE_CONTENT;
}

export const SubmitReviewResponsePageContent = (props: IProps) => {
  const { vendorProfileLink, TYPE } = props;
  const IS_NOT_ERROR_CONTENT = TYPE !== RESPONSE_PAGE_TYPE.ERROR;
  const PAGE_CONTENT = {
    IMG_SRC: RESPONSE_PAGE_CONTENT[TYPE].IMG_SRC,
    TITLE: RESPONSE_PAGE_CONTENT[TYPE].TITLE,
    DESCRIPTION: RESPONSE_PAGE_CONTENT[TYPE].DESCRIPTION,
  };

  return (
    <Box
      width={"100vw"}
      height={"100vh"}
      padding={"16px"}
      overflow={'hidden'}
      paddingTop={"20vh"}
    >
      <Flex
        alignItems={'center'}
        justifyContent={'center'}
        overflow={'hidden'}
        margin={"0 auto"}
      >
        <img height={"auto"} width={"150px"} src={PAGE_CONTENT.IMG_SRC} />
      </Flex>
      <Flex
        gap={"16px"}
        flexDirection={'column'}
        margin={"0 auto"}
        width={"578px"}
        maxWidth={{ default: "100%", sm: "578px" }}
        alignItems={"center"}
        justifyContent={"center"}
        borderBottom={IS_NOT_ERROR_CONTENT ? "1px solid black" : ""}
        paddingBottom={"20px"}
        marginBottom={"20px"}
      >
        <Typography
          fontSize={"16px"}
          color={COLORS.black_1}
          fontWeight={"bold"}
          textAlign={"center"}
        >
          {PAGE_CONTENT.TITLE}
        </Typography>
        <Typography
          fontSize={"12px"}
          color={COLORS.black_1}
          textAlign={"center"}
          maxWidth={"386px"}
        >
          {PAGE_CONTENT.DESCRIPTION}
        </Typography>
      </Flex>
      {IS_NOT_ERROR_CONTENT && (
        <Flex
          flexDirection={'column'}
          margin={"0 auto"}
          width={"578px"}
          maxWidth={{ default: "100%", sm: "578px" }}
          justifyContent={"flex-start"}
          marginBottom={"20px"}
        >
          <Flex gap={"2px"} alignItems={"center"}>
            <Typography
              fontSize={"12px"}
              color={COLORS.black_1}
              fontWeight={500}
            >
              What is 
            </Typography>
            <Typography
              fontSize={"12px"}
              color={COLORS.blue_1}
              fontWeight={600}
              style={{
                textDecoration: "underline",
                cursor: "pointer"
              }}
              onClick={() => window.open("https://www.venwiz.com/", "_blank")}
            >
              Venwiz
            </Typography>
            <Typography
              fontSize={"12px"}
              color={COLORS.black_1}
              fontWeight={500}
            >
              ?
            </Typography>
          </Flex>
          <Typography
            fontSize={"12px"}
            color={COLORS.black_1}
          >
            Venwiz is a digital platform enabling service procurement in the manufacturing industry. Venwiz makes CapEx &
            Industrial services procurement objective & seamless using technology.
          </Typography>
          <Flex
            gap={"12px"}
            flexWrap={"wrap"}
            marginTop={"20px"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              <Typography
                fontSize={"12px"}
                fontWeight={500}
                color={COLORS.black_1}
              >
                Where can you see the review?
              </Typography>
              <Typography
                fontSize={"12px"}
              >
                The review will be visible on the vendor profile
              </Typography>
            </Box>
            <Button
              height={"36px"}
              colorSchema={'blue'}
              variant={'primary'}
              width={"max-content"}
              style={{ padding: "4px 12px", fontSize: "12px" }}
              onClick={() => window.open(vendorProfileLink, "_blank")}
            >
              View Vendor Profile
            </Button>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
