import LocalStorage from "_services/LocalStorage";
import SessionStorage from "_services/SessionStorage";
import ApiConstant from "constants/ApiConstant";
import { CompareVendorsActions } from "compareVendors/actions";
import { VendorListingActions } from "vendorList/actions";
import { initialState } from "vendorList/actions/reducer";
import { AuthenticationActions } from "authentication/actions";

interface IHandleLogoutProps {
  dispatch: any;
}

export const handleLogout = ({ dispatch }: IHandleLogoutProps) => {
  dispatch(AuthenticationActions.logOut())
  dispatch(CompareVendorsActions.storeGstn({}));
  dispatch(
    VendorListingActions.setFilterOptions({
      ...initialState.filterOptions,
      ...initialState.pageValues,
      search: "",
    })
  );
  // clearing storage data at logout
  LocalStorage.removeAll();
  SessionStorage.removeAll();
  LocalStorage.set(ApiConstant.STORAGE_KEYS.applicationFlags, {
    showDemoVideo: false,
  });
  window.location.assign("/login");
};
