import React from "react";

export const TnCsContent = () => {
  return (
    <>
      <p>
        These terms and conditions (&ldquo;
        <strong>Terms &amp; Conditions</strong>&rdquo;) govern your access to and use of the Venwiz platform as made
        available through the websites venwiz.com, vendor.venwiz.com, tools.venwiz.com and client.venwiz.com
        (collectively the &ldquo;
        <strong>Platform</strong>&rdquo;) owned, managed and operated by Venwiz Technologies Private Limited
        (hereinafter referred as &ldquo;
        <strong>Us</strong>&rdquo; / &ldquo;<strong>We</strong>&rdquo; / &ldquo;<strong>Our</strong>&rdquo; / &ldquo;
        <strong>Venwiz</strong>&rdquo; / &ldquo;<strong>Company</strong>&rdquo;) for general browsing or availing any
        services available on the Platform. Venwiz Technologies Private Limited, is a company registered under the
        provisions of the Companies Act, 2013 and having its registered office at #175 and #176, Dollars colony,
        Bannerghatta Main Road, J.P.Nagar, 4th Phase, Bengaluru 560078, India.
        <br />
        <br />
        As a user (&ldquo;<strong>User</strong>&rdquo;/ &ldquo;
        <strong>You</strong>&rdquo; / &ldquo;<strong>Vendor</strong>&rdquo; / &ldquo;
        <strong>Client</strong>&rdquo; which shall mean and include any natural or legal person who has registered,
        agreed to use or has accessed or is visiting the Platform) You understand that Venwiz, through the Platform,
        facilitates the interaction between its Vendors and Clients. Venwiz&nbsp;is not a party to any negotiations or
        sale and supply of services or otherwise concluded between the Users of the Platform and acts only as a
        facilitator.
        <br />
        <br />
        Please read these Terms &amp; Conditions carefully before accessing or using the Platform or any part thereof.
        By accessing or using any part of the Platform, You agree to be bound by these Terms &amp; Conditions. If You do
        not agree to all the Terms &amp; Conditions, then YOU MAY NOT ACCESS THE PLATFORM OR USE ANY SERVICES
        FACILITATED BY VENWIZ THROUGH THE PLATFORM. <br />
        <br />
        These Terms &amp; Conditions form a legal and binding agreement between You and Venwiz and is made pursuant to
        the terms of the Information Technology Act, 2000. As long as You comply with these Terms &amp; Conditions, We
        grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use the Platform. By
        impliedly or expressly accepting these Terms &amp; Conditions, You also accept and agree to be bound by policies
        of Venwiz including but not limited to Privacy Policy as amended from time to time which form a part of these
        Terms &amp; Conditions by reference. <br />
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>General</strong>
        </span>
        <br />
      </p>
      <ol>
        <li>You represent and warrant that You:</li>
        <ol>
          <li>
            are at least 18 (eighteen) years old. In the event You are under the age of 18 (eighteen) years, You
            represent and warrant that You have the requisite consent from your legal guardians and are accessing or
            using the Platform under their supervision;
          </li>
          <li>are competent to form a binding contract under the Indian Contract Act, 1872;</li>
          <li>
            have not previously been suspended or your use of the Platform or any part thereof has not been restricted
            due to any breach or perceived breach by Venwiz;
          </li>
        </ol>
        <li>
          Venwiz.com is an online Platform to facilitate interaction between buyers and sellers of industrial services.
          Venwiz charges certain fee to the Vendors for connecting them to potential Clients, the details of which are
          elaborated in the &lsquo;Fees and Payments&rsquo; section of this Terms and Conditions.
        </li>
        <li>
          The Platform including all materials on it or provided to You through it are protected by copyright, trade
          mark and other intellectual property rights and laws throughout the world and are owned by, or are licensed to
          Venwiz. You are permitted to display the materials on this Platform on a computer screen and/or mobile screen
          and, save for restricted access documents, to download and print a hard copy for your personal use, provided
          you do not alter or remove any of the content which may include text, graphics, images, logos, button icons,
          software code, design, and the collection, arrangement and assembly of content provided by Venwiz (&ldquo;
          <strong>Content</strong>&rdquo;) without our express permission to do so and that you do not change or delete
          any copyright, trade mark or other proprietary notices
        </li>
        <li>You agree not to:</li>
        <ol>
          <li>
            copy, reproduce, store (in any medium or format), distribute, transmit, modify, create derivate works from
            all or any part of the Content or Platform or the materials or software on it, or provided to you through it
            without our prior written consent (which may be given or withheld in Venwiz&rsquo;s absolute discretion);
          </li>
          <li>use the Platform or any of the Content on it, or provided to you through it, for:</li>
          <ol>
            <li>any unlawful purpose or in contravention of applicable law;</li>
            <li>commercial exploitation without Our prior written consent; and</li>
            <li>
              any purpose or in any manner that may give a false or misleading impression of us, our staff or our
              services.
            </li>
          </ol>
          <li>
            establish a link to the Content or the Platform from any other website, intranet or extranet site without
            Our prior written consent;
          </li>
          <li>
            decompile, disassemble or reverse engineer (or attempt to do any of them) any of the Content provided on or
            through the Platform;
          </li>
          <li>do anything that may interfere with or disrupt the Platform;</li>
          <li>encourage or permit others to do any of the above;</li>
          <li>
            publicly criticise, disparage, call into disrepute, or otherwise defame or slander the Platform or Venwiz
            and any of its officers, directors, members or employees.
          </li>
        </ol>
        <li>
          In addition to what is already provided in the Terms &amp; Conditions, You and all other Users of the Platform
          represent and warrant that You shall not post, display, upload, modify, publish, transmit, update, or share
          any information that:&nbsp;
        </li>
        <ol>
          <li>infringes the intellectual property rights of any other person or entity;</li>
          <li>
            is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous,
            hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or
            gambling, or otherwise unlawful in any manner whatever;
          </li>
          <li>is posted without permission of the owner of the information;</li>
          <li>violates any law for the time being in force;</li>
          <li>impersonates another person;</li>
          <li>harms minors in any way;</li>
          <li>is invasive of another person's privacy;</li>
          <li>
            deceives or misleads the addressee about the origin of such messages or communicates any information that is
            grossly offensive and menacing in nature;
          </li>
          <li>
            contains software viruses or any other computer code, files or programs designed to interrupt, destroy or
            limit the functionality of any computer resource;
          </li>
          <li>
            threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign
            states, or public order or causes incitement to the commission of any cognizable offence or prevents
            investigation of any offence or is insulting any other nation.
          </li>
          <li>spreads false and inaccurate information;</li>
          <li>violates or attempts to violate the integrity or security of the Platform or Content;</li>
          <li>disrupts the services or in any way attempts to solicit users towards the services of a competitor;</li>
          <li>may interfere with the proper operation of this Platform or Venwiz&rsquo;s systems in any manner;</li>
        </ol>
        <li>
          In the event that You do not comply with the above restrictions or Venwiz has reasons to believe that You have
          indulged in any of the activities specified above, Venwiz retains the right to immediately suspend or
          terminate Your account and access to the Platform, and take legal action against You, including without
          limitation, initiation of proceedings for claims against You for any losses and costs (including legal costs)
          Venwiz may suffer as a result of Your actions. You shall further solely be liable for any claim brought
          against You by any other User affected by Your actions.
        </li>
      </ol>
      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Acceptance of Terms &amp; Conditions</strong>
        </span>
        <br />
      </p>
      <ol>
        <li>
          Venwiz reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms
          &amp; Conditions, at any time without any prior written notice to the User. It is the User&rsquo;s
          responsibility to review these Terms &amp; Conditions periodically for any updates/ changes. User&rsquo;s
          continued use of the Platform following the modification of these Terms &amp; Conditions will imply the
          acceptance by the User of the revisions.
        </li>
        <li>
          All information on the Platform&nbsp;is provided &ldquo;as is&rdquo; with no guarantee of completeness,
          accuracy, timeliness or of the results obtained from the use of the information, and without warranty of any
          kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness
          for a particular purpose. Nothing contained in this agreement shall to any extent substitute for the
          independent investigations and the sound technical and business judgement of the User. In no event shall
          Venwiz be liable for any direct, indirect, incidental, punitive, or consequential damages of any kind
          whatsoever with respect to the Platform's and Venwiz&rsquo;s services. User hereby acknowledges that any
          reliance upon the Platform's information shall be at its sole risk and further understands and acknowledges
          that the information has been compiled from publicly aired and published sources or based on the information
          provided by the users of the Platform themselves. Venwiz reserves the right, in its sole discretion and
          without any obligation, to make improvements to, or correct any error or omissions in any portion of the
          information that is obtained from publicly available sources. Venwiz respects the rights of such entities and
          cannot be deemed to be infringing on the respective copyrights or businesses of such entities
        </li>
        <li>
          User agrees and consents to be contacted by Venwiz through phone calls, SMS notifications, WhatsApp messages
          or any other means of communication, in respect of the services provided by Venwiz even if contact number(s)
          provided to Venwiz are on Do Not call Registry.
        </li>
      </ol>
      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Vendor Account</strong>
        </span>
      </p>
      <ol>
        <li>
          As a Vendor, You can access vendor.venwiz.com and the related services by creating an account on
          vendor.venwiz.com. To create a Vendor account (“Vendor Account”) on the Platform, You will be required to
          follow the registration procedure prescribed by Venwiz and would be required to furnish certain information
          and details, including Your name, email id, contact number, company/business information, and any other
          information deemed necessary by Venwiz.
        </li>
        <li>
          To further ensure that You are able to utilize the Platform to its fullest extent as a “Vendor”, Venwiz may
          request additional information in relation to the Vendor’s business, services, capabilities, experiences, key
          personnel, team structure, company details etc. The documents mentioned herein are only illustrative, and any
          other information as may be required for creation of the Vendor Account as may be requested by Venwiz. All
          information shared by You at the time of creating an account shall be protected under the Privacy Policy.
        </li>
        <li>
          Venwiz will create the digital profile of the Vendor as part of the Vendor Account based on the information
          provided by You, public information available on Vendor's website, information obtained from Venwiz’s third
          party service providers and regulatory information collected from government databases. By sharing the input
          information and creating a profile at vendor.venwiz.com, it is deemed that You have given the consent to
          Venwiz to create Your profile and publish them for the view of the Clients as part of the Vendor Account.
          However, this information will not be available for view by any general public who are not registered as a
          Client at client.venwiz.com or the internal team at Venwiz. On confirmation of Your Vendor Account by Venwiz,
          the same will be made available for viewing by Clients on the Platform. You shall ensure that you have read
          the contents of your digital profile and may request modifications to the digital profile or the Vendor
          Account by writing to Venwiz at contact@venwiz.com. You hereby authorize Venwiz to obtain information
          pertaining to You or Your business directly from Your website or any other portal by employing crawlers or
          scrapers to obtain such information from the portals designated by You.
        </li>
        <li>
          You further authorise the Company to share any information provided by you with third party service providers,
          solely for the purpose of authentication of the information provided at the time of registration of the Vendor
          Account and to obtain further information from sources available to Our third party service providers. You
          shall solely be responsible for reviewing all information made available on Your Vendor Account from time to
          time, as Venwiz shall use the information received from such third party service providers on an “as is” basis
          and does not provide any representation or warranty that the said information is true and correct. The Vendor
          understands that Venwiz is only adding value in formatting, content preparation etc. Venwiz will have no
          liability about the correctness of the information received by it.
        </li>
        <li>
          You also authorise the Company to call or message you on the contact number provided for the purpose of
          authentication of your Vendor Account.
        </li>
        <li>
          Venwiz will provide its terms and conditions and pricing policy to the Vendor and the Vendor shall be required
          to provide their opt-ins or agreements, pursuant to which the commission and fees shall be payable by the
          Vendor. The Vendor shall have the option to select their subscription model basis which the payment of fees
          and commissions shall be made.
        </li>
        <li>
          The Vendor shall not be allowed to interact with Clients or make their profile available on the Platform
          unless the Vendor has complied with the provisions of Paragraph 6 mentioned above.
        </li>
        <li>
          You understand and agree that You are responsible for maintaining the confidentiality and security of Your
          Vendor Account, password, activities that occur in or through Your account. You agree to accept responsibility
          for restricting and preventing unauthorized access to Your account. You agree to accept responsibility for all
          activities that occur under Your account or password. You should take all necessary steps to ensure that the
          password is kept confidential and secure and should inform Us immediately if You have any reason to believe
          that Your password has become known to anyone else, or if the password is being, or is likely to be, used in
          an unauthorized manner.
        </li>
        <li>
          You accept and agree that Your Vendor Account shall not be transferred to any other third party. You will be
          responsible for the activities associated with Your Vendor Account in case of such transfer.
        </li>
        <li>
          The Platform uses temporary cookies to store certain data (that is not sensitive personal data or information)
          that is used by Us for maintenance of the Platform and its features as well as research and development, and
          for administration of Your account. While serving advertisements or optimizing Services, We may allow
          authorized third parties to place or recognize a unique cookie on the Your browser. We do not store personally
          identifiable information in the cookies.
        </li>
        <li>
          You represent and warrant that all the details provided by You are true, correct, accurate, complete, and up
          to date. In the event of any change in the information provided, You agree to update the details on the Vendor
          Account immediately without any delay. You further represent and warrant that at the time of making a new
          Vendor Account, You shall inform Venwiz of any existing Account(s) on the Platform.
        </li>
      </ol>

      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Client Account</strong>
        </span>
      </p>
      <ol>
        <li>
          You can register yourself as a Client and access client.venwiz.com and avail the Services of Venwiz by
          creating an account on client.venwiz.com. To create an account (“Client Account”) (Client Account and Vendor
          Account shall collectively be referred to as “Account”) on the Platform, You will be required to follow the
          registration procedure prescribed by Venwiz and would be required to furnish certain information and details,
          including your name, email id, contact number, company/business information, and any other information deemed
          necessary by Venwiz.
        </li>
        <li>
          You may register on the Platform as a “Client”, You may be required to furnish certain additional documents
          such as GST registration, purchase order formats, MSME registration, ISO certification, factory license,
          brochures, factory plan, manufacturing capacity, key products, key personnel, team structure, company details
          etc. based on the nature of the Account. The documents mentioned herein are only illustrative, and any other
          information as may be required for creation of the Client Account as may be requested by Venwiz. All
          information shared by You at the time of creating an account shall be protected under the Privacy Policy.
        </li>
        <li>
          You hereby authorize Venwiz to obtain information pertaining to You or Your business directly from Your
          website or any other portal by employing crawlers or scrapers to obtain such information from the portals
          designated by You. You agree and consent to Venwiz utilizing public information available on Your website,
          information obtained from Venwiz’s third party service providers and regulatory information collected from
          government data bases for creating Your Client Account. By sharing the input information and creating a
          profile at client.venwiz.com, it is deemed that the Client has given the consent to Venwiz to create their
          profiles and publish them for the view of the Vendors. However, this information will not be available for
          view by any general public who are not registered as a vendor at vendor.venwiz.com or the internal team at
          Venwiz. You shall ensure that you have read the contents of your digital profile and may request modifications
          to the digital profile or the Client Account by writing to Venwiz at contact@venwiz.com.
        </li>
        <li>
          You further authorise the Company to share any information provided by You with third party service providers,
          solely for the purpose of authentication of the information provided at the time of registration of the Client
          Account and to obtain further information from sources available to our third party service providers. You
          shall solely be responsible for reviewing all information made available on Your Client Account from time to
          time, as Venwiz shall use the information received from such third party service providers on an “as is” basis
          and does not provide any representation or warranty that the said information is true and correct. The Client
          understands that Venwiz is only adding value in formatting, content preparation etc. Venwiz will have no
          liability about the correctness of the information received by it.
        </li>
        <li>
          You further authorise the Company to call or message you on the contact number provided for the purpose of
          authentication of your Client Account.
        </li>
        <li>
          You understand and agree that You are responsible for maintaining the confidentiality and security of Your
          Client Account, password, activities that occur in or through Your Client Account. You agree to accept
          responsibility for restricting and preventing unauthorized access to Your Client Account. You agree to accept
          responsibility for all activities that occur under Your Client Account or password. You should take all
          necessary steps to ensure that the password is kept confidential and secure and should inform Us immediately
          if You have any reason to believe that Your password has become known to anyone else, or if the password is
          being, or is likely to be, used in an unauthorized manner.
        </li>
        <li>
          You accept and agree that Your Client Account shall not be transferred to any other third party. You will be
          responsible for the activities associated with Your Client Account in case of such transfer.
        </li>
        <li>
          The Platform uses temporary cookies to store certain data (that is not sensitive personal data or information)
          that is used by Us for maintenance of the Platform and its features as well as research and development, and
          for administration of Your account. While serving advertisements or optimizing Services, We may allow
          authorized third parties to place or recognize a unique cookie on the Your browser. We do not store personally
          identifiable information in the cookies.
        </li>
        <li>
          You represent and warrant that all the details provided by You are true, correct, accurate, complete, and up
          to date. In the event of any change in the information provided, You agree to update the details on the Client
          Account immediately without any delay. You further represent and warrant that at the time of making a new
          Client Account, You shall inform Venwiz of any existing Account(s) on the Platform.
        </li>
      </ol>

      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Fees and Payments for Vendor</strong>
        </span>
        <br />
      </p>
      <ol>
        <li>
          Venwiz shall charge You a fees based on the Services provided to You or the subscriptions availed by You. All
          fees are exclusive of applicable taxes.
        </li>
        <li>
          <p>The Vendor shall be charged the following fees:</p>
          <p style={{ fontWeight: 500 }}>a. Lead Fees</p>
          <p>
            Lead fees are charged to Vendors for any potential Client(s) who connect with them through the Platform. The
            lead fee becomes payable by the Vendors, irrespective of whether they are able to convert the interest shown
            by the Client into a purchase order or contract. If after payment of the lead fee, the Client does not avail
            the service of the Vendor, Venwiz refunds the amount to the Vendors in the form of credit for the next lead
            or at its sole discretion as a refund.
          </p>
          <p>
            Where the Vendor has opted for availing Services from Venwiz under a subscription model, in the event of
            cancellation by a Client, the subscription time is adjusted to deliver the promised services covered under
            the specific options chosen by the Vendor.
          </p>
          <p style={{ fontWeight: 500 }}>b. Commission Fee</p>
          <p>
            Whenever a Vendor receives a purchase order, work order, letter of intent or enters into any other
            arrangement with a Client for provision of the Vendor’s services to such Client, Venwiz shall charge the
            Vendor a % of the value of the contract or services to be provided by the Vendor as commission. The % of the
            commission shall be determined on the basis of the subscription model that is availed by the Vendor at the
            time of setting up their Vendor Account.
          </p>
        </li>
        <li>
          You agree that You or the Clients are solely responsible for payment of all taxes, legal compliances,
          statutory registrations and reporting in case of any payment made to You.
        </li>
        <li>
          In order to process the above mentioned fees, commissions and payments, we might require details of Your bank
          account, credit card number etc. Please check Our Privacy Policy to understand the purpose of collection and
          manner of use of Your information by Venwiz. We provide the following payment options on the Platform:
          <ol type="a">
            <li>
              Wallets such as Paytm, Mobikwik, Oxigen, AirtelMoney, Freecharge, JIO Money, PhonePe, MPESA UPI – BHIM and
              GooglePay.
            </li>
            <li>All debit cards.</li>
            <li>All credit cards</li>
            <li>Net banking</li>
          </ol>
        </li>
      </ol>

      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Fees and Payments for Client</strong>
        </span>
        <br />
      </p>
      <ol>
        <li>
          Venwiz may charge You a fee based on the account plan selected by You. The fees for the account plan are
          billed in advance and is non-refundable. There will be no refunds or credits for partial months of service, or
          refunds made should You not use the Platform during a period of time when your Account is active. No
          exceptions will be made in this regard. All fees are exclusive of applicable taxes.
        </li>
        <li>
          You shall also make payments to the Vendors for availing the services offered by the Vendor. You agree to make
          all payments directly to the respective Vendors. The mode of payment may be chosen by the Vendor. You agree
          and acknowledge that You shall not hold Venwiz responsible for any loss or damage caused to You during the
          process, due to any acts or omission on the part of Vendor for any actions/ omissions which are beyond the
          control of Venwiz.
        </li>
        <li>
          You agree that You or the Vendors are solely responsible for payment of all taxes, legal compliances,
          statutory registrations and reporting in case of any payment made to You.
        </li>
        <li>
          In order to process the above mentioned payments, we might require details of Your bank account, credit card
          number etc. Please check Our Privacy Policy to understand the purpose of collection and manner of use of Your
          information by Venwiz. We provide the following payment options on the Platform:
          <ol type="a">
            <li>
              Wallets such as Paytm, Mobikwik, Oxigen, AirtelMoney, Freecharge, JIO Money, PhonePe, MPESA UPI – BHIM and
              GooglePay.
            </li>
            <li>All debit cards.</li>
            <li>All credit cards</li>
            <li>Net banking</li>
          </ol>
        </li>
      </ol>

      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Vendor Services</strong>
        </span>
        <br />
      </p>
      <ol>
        <li>
          Venwiz allows You to provide the services to the Clients, who are registered on the Platform. Venwiz merely
          acts as a facilitator of the Platform and exercises no control over such third-party content.
        </li>
        <li>
          The information displayed by the Clients on the Platform including without limitation, text, copy, audio,
          video, photographs, illustrations, graphics and other visuals, is for general information purposes only and
          does not constitute either an advertisement/ promotion of any services on the Platform.
        </li>
        <li>
          You acknowledge and understand that Venwiz provides no warranty or representation with respect to the
          authenticity/ veracity of the content and information provided by Clients on the Platform nor does Venwiz
          guarantee that any payments shall be made by the Clients to the Vendors. You must conduct Your own due
          diligence with regard to the Clients before providing services to Clients. Further, it is hereby clarified You
          are responsible for procuring the appropriate licenses for sale or provision of such services under all
          applicable law. You agree and acknowledge that You shall not hold Venwiz responsible or liable for any damages
          arising out of such deficiency in the services provided by You.
        </li>
        <li>
          You understand and agree that all commercial / contractual terms, with respect to the sale/ purchase/ delivery
          and consumption of the services provided to the Clients are offered by and agreed to between You and the
          Clients and the contract for purchase of any of goods or services shall strictly be a bipartite contract
          between the Client and You.
        </li>
        <li>
          All commercial/contractual terms including without limitation price, shipping costs, payment methods, payment
          terms, date, period and mode of delivery, warranties related to products and services shall be directly agreed
          between You and the Client.
        </li>
        <li>
          You shall provide a copy of the contractual terms to Venwiz for the purpose of charging the fees and
          commissions for use of the Platform.
        </li>
        <li>
          Venwiz is not responsible for any non-performance or breach of any contract entered into between You and a
          Client. We cannot and do not guarantee that the concerned Client will perform any transaction concluded on the
          Platform.
        </li>
        <li>
          Venwiz is not responsible for any non-performance or breach of any contract entered into between You and a
          Vendor. We cannot and do not guarantee that the concerned Vendor will perform any transaction concluded on the
          Platform.
        </li>
        <li>
          Venwiz shall not and is not required to mediate or resolve any dispute or disagreement between You and the
          Clients. In particular, Venwiz does not implicitly or explicitly support or endorse the sale or purchase of
          any items or services on the Platform. However, Venwiz, may, at its sole discretion provide reasonable support
          to the Users of the Platform in case of any grievance. Any grievances in relation to the Platform or the
          services shall be dealt with in line with the Grievance Redressal section of these Terms & Conditions.
        </li>
        <li>
          Vendors can freely share their Venwiz profile with their other clients. How their other clients use that
          information is not Venwiz’s responsibility.
        </li>
      </ol>

      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Client Services</strong>
        </span>
        <br />
      </p>
      <ol>
        <li>
          Venwiz allows You to avail the services of Vendors, who are registered on the Platform. Venwiz merely acts as
          a facilitator of the Platform and exercises no control over such third-party content nor warrants the
          authenticity and genuineness of the services made available by the Vendor through the Platform. Whenever a
          Client posts a requirement on the Platform, Venwiz matches the requirement with the relevant Vendors and the
          Vendors are referred to the client.
        </li>
        <li>
          Clients on the Platform can also search for specific Vendors and contact them directly for any job requirement
          the Client may have.
        </li>
        <li>
          The information displayed by the Vendors on the Platform including without limitation, text, copy, audio,
          video, photographs, illustrations, graphics and other visuals, is for general information purposes only and
          does not constitute either an advertisement/ promotion of any services on the Platform.
        </li>
        <li>
          You acknowledge and understand that Venwiz provides no warranty or representation with respect to the
          authenticity/ veracity of the content and information provided by Vendors on the Platform nor does Venwiz
          endorse the quality of services offered by the Vendors. You agree and acknowledge that Venwiz has not made any
          warranties as to the use and effect of any of the goods or services offered by such Vendors. Further, it is
          hereby clarified that the Vendors who are offering the goods and services to You are responsible for procuring
          the appropriate licenses for sale or provision of such services under all applicable law. You agree and
          acknowledge that You shall not hold Venwiz responsible or liable for any damages arising out reliance on
          Vendor generated content by You.
        </li>
        <li>
          You understand and agree that all commercial / contractual terms, with respect to the sale/ purchase/ delivery
          and consumption of the services of the Vendors are offered by and agreed to between You and the Vendors and
          the contract for purchase of any of goods or services shall strictly be a bipartite contract between the
          Vendor and You.
        </li>
        <li>
          All commercial/contractual terms including without limitation price, shipping costs, payment methods, payment
          terms, date, period and mode of delivery, warranties related to products and services shall be directly agreed
          between You and the Vendor.
        </li>
        <li>
          We do not make any representation or warranty as to specifics (such as quality, value, saleability, etc) of
          the products or services proposed to be sold or offered to be sold by the Vendors or purchased on the
          Platform.
        </li>
        <li>
          Venwiz is not responsible for any non-performance or breach of any contract entered into between You and a
          Vendor. We cannot and do not guarantee that the concerned Vendor will perform any transaction concluded on the
          Platform.
        </li>
        <li>
          Venwiz shall not and is not required to mediate or resolve any dispute or disagreement between You and the
          Vendors. In particular, Venwiz does not implicitly or explicitly support or endorse the sale or purchase of
          any items or services on the Platform. However, Venwiz, may, at its sole discretion provide reasonable support
          to the Users of the Platform in case of any grievance. Any grievances in relation to the Platform or the
          services shall be dealt with in line with the Grievance Redressal section of these Terms & Conditions.
        </li>
      </ol>

      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Venwiz Services</strong>
        </span>
        <br />
      </p>
      <p>
        As part of the Services provided through the Platform, Venwiz may collaborate with Vendors for preparing case
        studies for use by the Vendors. Vendors are free to share the final outcome for their own benefit without
        liability to Venwiz.
      </p>
      <p>
        At venwiz.com, Venwiz publishes content, blogs, industry news etc. which Users are free to access for their own
        use.
      </p>
      <p>
        <br />
        <strong>
          <span style={{ textDecoration: "underline" }}>User Content and User Information</span>
        </strong>
        <br />
      </p>
      <ol>
        <li>
          You hereby grant Us the worldwide, perpetual and transferable rights to use any content that You upload or
          post on the Platform (“User Content”). We shall be entitled to, consistent with Our Privacy Policy as adopted
          in accordance with applicable law, use the User Content or any of its elements for any type of use forever,
          including but not limited to promotional and advertising purposes and in any media whether now known or
          hereafter devised, including the creation of derivative works that may include any content You provide and are
          not entitled to any payment or other compensation for such use. You also grant us the right to sub-license
          these rights, and the right to bring an action for infringement of these rights. We reserve the right to edit
          or remove any material submitted to this Platform, or stored on our servers, or hosted or published upon this
          Platform. Certain User Content may also be visible to other users of the Platform.
        </li>
        <li>
          If You are posting any form of User Content on the Platform, You must verify the truthfulness and authenticity
          of the particulars of the content, including the time, place, and nature. Before allowing such content to be
          posted on the Platform, We have the right and authority to verify the accuracy of such particulars related to
          the content with the respective sources provided by the user posting such content, if We feel that there is a
          need to check such accuracy. We have the right to upload content on behalf of third parties, subsequent to
          collecting such information and verifying it, if we deem it necessary. Notwithstanding this, we can in no way
          be held liable for any false or misleading information.
        </li>
        <li>
          In case any content is considered to be unlawful or against the law within any jurisdiction in which our
          Platform can be seen and accessed, it shall be removed forthwith by us on being intimated by the authorities
          of the respective jurisdiction that such content is deemed unlawful. We cannot be held liable or questioned
          for the same.
        </li>
        <li>
          We reserve the right to maintain, delete or destroy all communications and materials posted or uploaded on the
          Platform according to its internal record retention and/or destruction policies. You might be contacted via
          the contact details provided by You to review the information provided by You. Please make sure Your contact
          information is updated at all times.
        </li>
        <li>
          For additional information regarding use of information about You, please refer to the Privacy Policy. The
          terms &ldquo;personal information&rdquo; and &ldquo;sensitive personal data or information&rdquo; as defined
          under applicable laws are provided under the Privacy Policy. The Privacy policy also contains details of the
          type of information collected from You, including sensitive personal data or information, the purpose, means
          and modes of usage of such information, and how We use and disclose such information.
        </li>
        <li>
          You accept that the Privacy Policy forms an integral part of the Terms &amp; Conditions and you shall refer to
          the Privacy Policy to understand the manner in which Your information is used and processed.
        </li>
        <li>
          We shall not be responsible in any manner for the authenticity of the personal information or sensitive
          personal data or information supplied by You.
        </li>
        <li>
          If You provide any information that is untrue, inaccurate, not current or incomplete (or becomes untrue,
          inaccurate, not current or incomplete), or We have reasonable grounds to suspect that such information is
          untrue, inaccurate, not current or incomplete, We shall have the right to suspend or terminate such Account at
          Our sole discretion.
        </li>
        <li>
          Any information or User Content publicly posted or privately transmitted through the Platform is the sole
          responsibility of the User from whom such Content originated, and You access all such information and User
          Content at Your own risk, and We are not liable for any errors or omissions in that information or User
          Content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty
          to take any action regarding how You may interpret and use the User Content or what actions You may take as a
          result of having been exposed to the User Content, and You hereby release Us from all liability for You have
          acquired or not acquired the User Content through the Platform.
        </li>
        <li>
          You are responsible for all User Content You contribute, in any manner, to the Platform, and You represent and
          warrant that You have all rights necessary to do so, in the manner in which You contribute it.&nbsp;{" "}
        </li>
      </ol>
      <p>
        <p>
          <br />
          <span style={{ textDecoration: "underline" }}>
            <strong>Monitoring of the Platform and User Accounts</strong>
          </span>
          <br />
        </p>
        <p>
          Venwiz always has the right and liberty to monitor the content of the Platform or any part thereof which shall
          include information provided in Your Account. The monitoring of the Platform is important to determine the
          veracity of the information provided by You and to ensure that every User remains compliant with the Terms
          &amp; Conditions provided herein. Subject to the Terms &amp; Conditions mentioned herein, Venwiz shall also
          have the liberty to remove any objectionable content which is in contravention of the Terms &amp; Conditions
          or any applicable laws in force in India or share such information with any governmental authority as per
          procedures laid down by the law for the time being in force in India.
        </p>
        <p>
          <br />
          <strong>
            <span style={{ textDecoration: "underline" }}>Suspension of Access</span>
          </strong>
          <br />
        </p>
        Venwiz may stop providing one or more services through the Platform and may terminate access to or use of the
        Platform at any time without giving notice of termination to You. Unless Venwiz informs the User otherwise, upon
        any termination, (a) the rights and licenses granted to You in these Terms &amp; Conditions will end; and (b)
        Your Account shall be suspended; and (c) User must stop using the Platform or any part thereof forthwith. Venwiz
        reserves the right to suspend or cease providing the Platform and shall have no liability or responsibility to
        the User in any manner whatsoever, except as prescribed under applicable law.
        <br />
        <p>
          <br />
          <strong>
            <span style={{ textDecoration: "underline" }}>Third Party Materials and Services</span>
          </strong>
          <br />
        </p>
        The Platform may provide You links to services of the Vendors or other third-party services (&ldquo;
        <strong>Third Party Services</strong>
        &rdquo;) and may display, include, or make available content, data, information, applications or materials from
        third parties (&ldquo;
        <strong>Third Party Materials</strong>&rdquo;). You acknowledge and agree that Venwiz is not responsible for
        examining or evaluating the content, accuracy, completeness, timeliness, validity, copyright compliance,
        legality, decency, quality or any other aspect of such Third-Party Materials or Third-Party Services. VENWIZ
        DOES NOT WARRANT OR ENDORSE AND DOES NOT ASSUME AND WILL NOT HAVE ANY LIABILITY OR RESPONSIBILITY TO YOU OR ANY
        OTHER PERSON FOR ANY THIRD-PARTY SERVICES, THIRD PARTY MATERIALS OR WEBSITES, OR FOR ANY OTHER MATERIALS,
        PRODUCTS, OR SERVICES OF THIRD PARTIES.
        <br />
        <br />
        <strong>
          <span style={{ textDecoration: "underline" }}>Limitation of Liability and Disclaimer of Warranties</span>
        </strong>
        <br />
      </p>
      <ol>
        <li>
          Notwithstanding anything to the contrary contained herein, neither Venwiz nor its affiliated companies,
          subsidiaries, officers, directors, employees or any related party shall have any liability to You or to any
          third party for any indirect, incidental, special or consequential damages or any loss of revenue or profits
          arising under or relating to these Terms &amp; Conditions.
        </li>
        <li>
          To the maximum extent permitted by law, You waive, release, discharge and hold harmless Venwiz, its affiliated
          and subsidiary companies, and each of their directors, officers, employees, and agents, from any and all
          claims, losses, damages, liabilities, expenses and causes of action arising out of Your access to or use of
          the Platform or Services.
        </li>
        <li>
          The information provided on the Platform is based on the information that is provided to Venwiz by its Users
          or otherwise procured by Venwiz through other sources. In case the User does not disclose any information to
          Venwiz, and the same is not found out at the first instance by Venwiz, Venwiz shall not be responsible for any
          claims or liabilities.
        </li>
        <li>
          We do not guarantee our site will be secure or free from bugs or viruses and accept no liability for loss or
          damage in respect of the same.
        </li>
        <li>
          Venwiz shall not be liable to any User or third parties for any purchase order, work orders, arrangements or
          agreements entered into between the Users.
        </li>
        <li>
          YOUR USE OF THE PLATFORM AND ALL INFORMATION, PRODUCTS AND OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES)
          INCLUDED IN OR ACCESSIBLE FROM THE PLATFORM IS AT YOUR SOLE RISK. THE PLATFORM IS PROVIDED ON AN "AS IS" AND
          "AS AVAILABLE" BASIS.
        </li>
      </ol>
      <p>
        <p>
          <br />
          <span style={{ textDecoration: "underline" }}>
            <strong>Indemnity</strong>
          </span>
          <br />
        </p>
        You agree to indemnify, save, and hold Venwiz, its affiliates, contractors, employees, officers, directors,
        agents and its third party associates, licensors, and partners harmless from any and all claims, demands,
        losses, damages, and liabilities, costs and expenses, including without limitation legal fees and expenses,
        arising out of or related to Your use or misuse of the Platform or Services, any violation by You of these Terms
        &amp; Conditions, or any breach of the representations, warranties, and covenants made by You herein or Your
        infringement of any intellectual property or other right of any person or entity, or as a result of any
        threatening, libellous, obscene, harassing or offensive material posted/ transmitted by You on the Platform or
        due to any third party claim in relation to the services provided by You to another User of the Platform. Venwiz
        reserves the right, at Your expense, to assume the exclusive defence and control of any matter for which You are
        required to indemnify Venwiz, including rights to settle, and You agree to cooperate with Venwiz&rsquo;s defence
        and settlement of these claims. THIS PARAGRAPH SHALL SURVIVE TERMINATION OF THIS TERMS &amp; CONDITIONS.
        <br />
        <p>
          <br />
          <span style={{ textDecoration: "underline" }}>
            <strong>Privacy</strong>
          </span>
          <br />
        </p>
        Venwiz&rsquo;s use of Your personal information is governed in accordance with the Privacy Policy of Venwiz.
        <br />
        <p>
          <br />
          <span style={{ textDecoration: "underline" }}>
            <strong>Applicable Laws</strong>
          </span>
          <br />
        </p>
        Your access to and use of the Platform and Terms &amp; Conditions is subject to the laws of India. In case of
        any disputes arising out of the access to or use of the Platform or any part thereof, Courts of Bengaluru, India
        will have exclusive jurisdiction.
        <br />
        <p>
          <br />
          <span style={{ textDecoration: "underline" }}>
            <strong>Third Party Rights</strong>
          </span>
          <br />
        </p>
        Nothing in these Terms &amp; Conditions is intended to nor shall it confer a benefit on any third party who is
        not a party to these Terms &amp; Conditions and such third party has no rights to enforce them.
        <br />
        <p>
          <br />
          <span style={{ textDecoration: "underline" }}>
            <strong>Waiver</strong>
          </span>
          <br />
        </p>
        No delay or decision not to enforce rights under these Terms &amp; Conditions will constitute a waiver of the
        right to do so and will not affect rights in relation to any subsequent breach.
        <br />
        <p>
          <br />
          <span style={{ textDecoration: "underline" }}>
            <strong>Intellectual Property Rights</strong>
          </span>
          <br />
        </p>
        All the intellectual property used on the Platform except those which have been identified as the intellectual
        properties of a User shall remain the exclusive property of Venwiz. You agree not to circumvent, disable or
        otherwise interfere with security related features of the Platform or features that prevent or restrict use or
        copying of any materials or enforce limitations on use of the Platform or the materials therein. The materials
        on the Platform or otherwise may not be modified, copied, reproduced, distributed, republished, downloaded,
        displayed, sold, compiled, posted or transmitted in any form or by any means, including but not limited to,
        electronic, mechanical, photocopying, recording or other means. <br />
        Compliance with Applicable Law
        <br />
        You also represent and warrant that any transaction between You and a User on the Platform shall be conducted in
        compliance with all applicable laws. <br />
        Termination
        <br />
        You may terminate this agreement by ceasing to use the Platform. Venwiz reserves the right to terminate your
        Account or access to the Platform at its sole discretion if You are found to be in breach of these Terms &amp;
        Conditions or any applicable laws. In all other cases Venwiz shall provide you with prior written notice of one
        (1) day for termination of your Account. Termination by Venwiz shall not entitle You to refund of fees paid to
        Venwiz, and Venwiz at its sole discretion may determine whether any refund is to be provided to You in case of
        termination. <br />
        Survival
        <br />
        Even after termination Indemnity, Intellectual Property, Applicable Laws and any other clause that naturally
        survives the termination of this Terms &amp; Conditions shall survive. <br />
        <p>
          <br />
          <span style={{ textDecoration: "underline" }}>
            <strong>Severability</strong>
          </span>
          <br />
        </p>
        If any provision of these Terms &amp; Conditions is deemed invalid, unlawful, void or for any other reason
        unenforceable, then that provision shall be deemed severable from these Terms &amp; Conditions and shall not
        affect the validity and enforceability of any of the remaining provisions. <br />
        <p>
          <br />
          <span style={{ textDecoration: "underline" }}>
            <strong>Grievances</strong>
          </span>
          <br />
        </p>
      </p>
      <ol>
        <li>
          Venwiz believes in ensuring that all the Users of the Platform are able to access and utilize the Platform to
          their satisfaction. Therefore, we believe in addressing any grievances or concerns that the Users have with
          regard to their experience on the Platform. Where any grievance is addressed to customer service, the User
          shall receive an acknowledgement of the grievance or complaint within 24 hours of receipt.&nbsp;
        </li>
        <li>
          You shall also receive a numerical ID for tracking your grievances with the customer care. We assure you that
          Venwiz will make all reasonable endeavours to address the grievance made by You at the latest within 15 from
          the date of receipt of the grievance.
        </li>
        <li>
          A grievance will be treated as closed if You communicate Your acceptance of the response received from the
          customer care team or where You fail to respond to the response received from the customer care team within 15
          days.
        </li>
        <li>
          If You are not satisfied with the response received from the customer care team, You can escalate the matter
          to the Grievance Officer appointed on the Platform. The Grievance Officer will respond with an acknowledgement
          24 hours of receipt of your email. The details of the Grievance Officer are as follows:
        </li>
        <ul>
          <li>Name: Philip Desmond</li>
          <li>Contact Details: care@venwiz.com</li>
          <li>Designation: Senior Manager</li>
        </ul>
        <li>
          If You are not satisfied with the response received from the Grievance Officer, You can escalate the matter to
          the Nodal Officer provided below:
        </li>
        <ul>
          <li>Name: Sandesh Paturi</li>
          <li>Contact Details: sandesh@venwiz.com</li>
          <li>Designation: Co-founder</li>
        </ul>
      </ol>
    </>
  );
};
