import * as CSS from "csstype";
import { getAppliedCssValues, getCSSOnlyStringValueFromCSSProperties } from "./helpers";
import { TypographyProps, PartialCSSPropertiesHyphen } from "../types";

export const typography = (props: TypographyProps) => {
  const getCSSValue = (cssPropertiesArray: string[]) =>
    getCSSOnlyStringValueFromCSSProperties(props, cssPropertiesArray);

  const cssObject: PartialCSSPropertiesHyphen = {
    "font-size": getCSSValue(["fontSize"]) as CSS.FontSizeProperty<string | 0>,
    "font-family": getCSSValue(["fontFamily"]),
    "font-weight": getCSSValue(["fontWeight"]) as CSS.FontWeightProperty,
    "line-height": getCSSValue(["lineHeight"]) as CSS.LineHeightProperty<string | 0>,
    "text-align": getCSSValue(["textAlign"]) as CSS.TextAlignProperty,
    "font-style": getCSSValue(["fontStyle"]),
    "letter-spacing": getCSSValue(["letterSpacing"]) as CSS.LetterSpacingProperty<string | 0>,
  };

  return getAppliedCssValues(cssObject);
};
