import { Types as ProfileCreationTypes } from "_types/ProfileCreationTypes";

const initialState = {
  loading: false,
  userInfo: {},
  error: null,
  updateResponse: null,
  userInfoData: null,
  plantInfo: null,
  makeUserApiCall: true, //true --for the 1st load and when data updated
  makePlantApiCall: true, //true --for the 1st load and when data updated
  companyLogo: {},
  profileCompletion: {},
  shareVendorLoading: false,
  shareVendorSuccess: false,
  shareVendorError: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ProfileCreationTypes.SET_USER_INFO_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case ProfileCreationTypes.SET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        updateResponse: action.result,
        makeUserApiCall: true,
        companyLogo: action.result?.companyLogo,
      };

    case ProfileCreationTypes.SET_USER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case ProfileCreationTypes.GET_USER_INFO_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case ProfileCreationTypes.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfoData: action.result,
        makeUserApiCall: false,
        companyLogo: action.result?.companyLogo,
      };

    case ProfileCreationTypes.GET_USER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    case ProfileCreationTypes.SET_PLANT_INFO_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case ProfileCreationTypes.SET_PLANT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        updateResponse: action.result,
        makePlantApiCall: true,
      };

    case ProfileCreationTypes.SET_PLANT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case ProfileCreationTypes.GET_PLANT_INFO_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case ProfileCreationTypes.GET_PLANT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        plantInfo: action.result,
        makePlantApiCall: false,
        companyLogo: action.result?.companyLogo,
      };
    case ProfileCreationTypes.GET_PLANT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case ProfileCreationTypes.SET_PLANT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case ProfileCreationTypes.GET_USER_PLANT_INFO_COMPLETION_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case ProfileCreationTypes.GET_USER_PLANT_INFO_COMPLETION_SUCCESS:
      return {
        ...state,
        loading: false,
        profileCompletion: action.result,
      };
    case ProfileCreationTypes.GET_USER_PLANT_INFO_COMPLETION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case ProfileCreationTypes.UPDATE_LOGO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ProfileCreationTypes.UPDATE_LOGO_SUCCESS:
      return {
        ...state,
        loading: false,
        updateResponse: action.result,
      };
    case ProfileCreationTypes.UPDATE_LOGO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };

    case ProfileCreationTypes.RESET:
      return {
        ...state,
        userInfoData: null,
        plantInfo: null,
        makeUserApiCall: true,
        makePlantApiCall: true,
        companyLogo: {},
        profileCompletion: {},
      };

    case ProfileCreationTypes.SET_VENDOR_SHARE_REQUESTED:
      return {
        ...state,
        shareVendorLoading: true,
        shareVendorError: false,
        shareVendorSuccess: false,
      };

    case ProfileCreationTypes.SET_VENDOR_SHARE_SUCCESS:
      return {
        ...state,
        shareVendorLoading: false,
        shareVendorError: false,
        shareVendorSuccess: true,
      };

    case ProfileCreationTypes.SET_VENDOR_SHARE_FAILURE:
      return {
        ...state,
        shareVendorLoading: false,
        shareVendorError: true,
        shareVendorSuccess: false,
      };

    default:
      return state;
  }
};
