import React, { InputHTMLAttributes, useState } from "react";
import { BoxProps } from "../styled-system/primitives/Box";
import { Box, Grid, Input } from "../styled-system/primitives";
import { DEFAULT_STYLES, ButtonWraper } from "./accordion.styles";
import { Button } from "../Button";
import { StylesProps } from "../styled-system/types";

export type IHTMLInputElementType = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "height" | "width" | "color"
>;
export type elementType = string | React.ReactNode;

interface SetActiveIndex {
  (index: Number): void;
}
export interface IAccordionSectionProps
  extends IHTMLInputElementType,
    StylesProps {
  isExpand?: boolean;
  accordionOpenIcon?: elementType;
  accordionCloseIcon?: elementType;
  accordionTitle: elementType;
  accordionHeader?: elementType;
  addittionalButton?: elementType;
  accordionBody: elementType;
  containerProps?: BoxProps;
  accIconContainerProps?: BoxProps;
  accButtonContainerProps?: StylesProps;
  onAccordionClick: (x?: any) => void;
}

export interface IAccordionProps extends IHTMLInputElementType, StylesProps {
  allowMultipleOpen: boolean;
}

export const AccordionSection = (props: IAccordionSectionProps) => {
  const {
    // custom props
    isExpand,
    accordionOpenIcon,
    accordionCloseIcon,
    accordionTitle,
    accordionHeader,
    addittionalButton,
    accordionBody,
    // style props
    containerProps,
    accButtonContainerProps,
    accIconContainerProps,
    ...remainingProps
  } = props;

  return (
    <>
      {/* parent container */}
      <Box borderBottom={"2px solid #F4F9FF"} {...containerProps}>
        <Box>
          {props?.accordionHeader || (
            <ButtonWraper
              {...DEFAULT_STYLES.accordionHeader}
              {...accButtonContainerProps}
              onClick={() => {
                props?.onAccordionClick();
              }}
            >
              {props?.accordionTitle}
              {props?.addittionalButton}

              <Box
                {...DEFAULT_STYLES?.accordionIcon}
                {...accIconContainerProps}
              >
                {isExpand
                  ? props?.accordionOpenIcon || "-"
                  : props?.accordionCloseIcon || "+"}
              </Box>
            </ButtonWraper>
          )}
        </Box>

        {isExpand && <Box>{props?.accordionBody}</Box>}
      </Box>
    </>
  );
};

// export const Accordion = () => {};
