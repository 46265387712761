import { Types as NavigationTypes } from "_types/NavigationTypes";

export const ActionCreators: any = {
  getModuleLink: (data: any) => ({
    types: [
      NavigationTypes.GET_BUBBLE_LINK_REQUEST,
      NavigationTypes.GET_BUBBLE_LINK_SUCCESS,
      NavigationTypes.GET_BUBBLE_LINK_FAILURE,
    ],
    promise: (client: any) => client.post("get_bubble_link", {data}),
  }),
};
export default ActionCreators;
