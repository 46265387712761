import { Types as ResetPasswordTypes } from "_types/ResetPasswordTypes"

const initialState = {
  loading: false,
  data: {},
  error: "",
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ResetPasswordTypes.SET_RESET_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case ResetPasswordTypes.SET_RESET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result,
        error: "",
      };

    case ResetPasswordTypes.SET_RESET_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};