import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  analyticsHandler,
  getErrorMessage,
  redirectionHandler,
  updateLocalStorageAndSessionStorageHandler,
} from "./login.handler";
import { LoginActions } from "./actions";
import { jwtDecode } from 'jwt-decode';

export const OpenLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlParams = useParams<{ token?: string }>();

  // Example JWT token - replace this with the actual token received from your backend
  const token: any = urlParams.token;

  const decodedToken: {email: string, key: string} = jwtDecode(token);
  // console.log("decodedToken", decodedToken);

  // Accessing the username
  // console.log('Username:', decodedToken.email);
  const email = decodedToken.email;
  const password = decodedToken.key;

  useEffect(() => {
    dispatch(LoginActions.setLogin({ email, password })).then(
      (res: any) => {
        if(res.access_token) {
          analyticsHandler(res);
          updateLocalStorageAndSessionStorageHandler(res, true);
          redirectionHandler(res, history);
        }
      },
      (err: any) => {
        const errString: string = getErrorMessage(err);
        console.log("Error in SSO Login", errString);
      },
    );
  }, []);
 
  return (<></>);
};
