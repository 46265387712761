import { IMessegePayloadForNonOnboardedVendor, IPostEnquireVendor } from "clientVendorView/Interfaces/interface";
import { Types as ClientVendorTypes } from "_types/ClientVendorTypes";

export const ActionCreators: any = {
  getVendorProfileData: (gstn: any) => ({
    types: [
      ClientVendorTypes.GET_VENDOR_PROFILE_REQUESTED,
      ClientVendorTypes.GET_VENDOR_PROFILE_SUCCESS,
      ClientVendorTypes.GET_VENDOR_PROFILE_FAILURE,
    ],
    promise: (client: any) => client.get(`vendor_profile_data/${gstn}`, {}),
  }),
  getVendorProfileDataWithoutToken: (gstn: any) => ({
    types: [
      ClientVendorTypes.GET_VENDOR_PROFILE_REQUESTED,
      ClientVendorTypes.GET_VENDOR_PROFILE_SUCCESS,
      ClientVendorTypes.GET_VENDOR_PROFILE_FAILURE,
    ],
    promise: (client: any) => client.get(`vendor_profile_data_without_token/${gstn}`, {}),
  }),
  getVendorProfileReviewData: (gstn: any) => ({
    types: [
      ClientVendorTypes.SET_VENDOR_PROFILE_REVIEW_REQUESTED,
      ClientVendorTypes.GET_VENDOR_PROFILE_REVIEW_SUCCESS,
      ClientVendorTypes.SET_VENDOR_PROFILE_REVIEW_FAILURE,
    ],
    promise: (client: any) => client.get(`get_all_review/${gstn}`, {}),
  }),
  setVendorProfileReview: (apiType: any, data: any) => ({
    types: [
      ClientVendorTypes.SET_VENDOR_PROFILE_REVIEW_REQUESTED,
      ClientVendorTypes.SET_VENDOR_PROFILE_REVIEW_SUCCESS,
      ClientVendorTypes.SET_VENDOR_PROFILE_REVIEW_FAILURE,
    ],
    promise: (client: any) =>
      apiType === "create" ? client.post(`create_review`, { data }) : client.patch(`update_review`, { data }),
  }),
  setOpenReviewFormModal: (data: any) => ({
    type: ClientVendorTypes.SET_OPEN_REVIEW_FORM_MODAL_SUCCESS,
    payload: data?.status,
  }),
  setOpenSubmittedSuccessModal: (data: any) => ({
    type: ClientVendorTypes.SET_SUBMITTED_FORM_MODAL_SUCCESS,
    payload: data?.status,
  }),
  setOpenReviewModal: (data: any) => ({
    type: ClientVendorTypes.SET_OPEN_REVIEW_MODAL_SUCCESS,
    payload: data?.status,
  }),
  setReviewErrorMess: (data: any) => ({
    type: ClientVendorTypes.SET_ERROR_MESSAGE,
    payload: data?.message,
  }),
  updateMyReview: (data: any) => ({
    type: ClientVendorTypes.SET_MY_REVIEW,
    payload: data,
  }),
  postMsgToNonOnboardedVendor: (data: IMessegePayloadForNonOnboardedVendor) => ({
    types: ClientVendorTypes.POST_MESSEGE_TO_NON_ONBOARDED_VENDOR_SUCCESS,
    promise: (client: any) => client.post("contact_notification", { data }),
  }),
  toggleKeyContactsModal: (data: boolean) => ({
    type: ClientVendorTypes.SHOW_OR_HIDE_KEY_CONTACTS_MODAL,
    payload: data,
  }),
  toggleRegisteredLocationsModal: (data: boolean) => ({
    type: ClientVendorTypes.SHOW_OR_HIDE_REGISTERED_LOCATIONS_MODAL,
    payload: data,
  }),
  postEnquireAboutTheVendor: (data: IPostEnquireVendor) => ({
    types: [
      ClientVendorTypes.POST_ENQUIRE_ABOUT_THE_VENDOR_REQUESTED,
      ClientVendorTypes.POST_ENQUIRE_ABOUT_THE_VENDOR_SUCCESS,
      ClientVendorTypes.POST_ENQUIRE_ABOUT_THE_VENDOR_FAILURE,
    ],
    promise: (client: any) => client.post("enquire_vendor", { data }),
  }),
};

export default ActionCreators;
