import * as Yup from "yup";

export const REGEX = {
  EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE_NUMBER: /^[6-9]\d{9}$/,
  PHONE_OR_EMAIL: /^((?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/,
};

export const FORMIK_INITIAL_VALUES = {
  LOGIN_VIA_EMAIL: { email: "", password: "" },
  LOGIN_VIA_PHONE_NUMBER: { phone: "" },
};

export const VALIDATION_SCHEMA = {
  LOGIN_VIA_EMAIL: Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid email address")
      .matches(REGEX.EMAIL, "Please enter valid email address")
      .max(256, "Max 256 characters allowed")
      .required("Please enter your email address"),
    password: Yup.string().required("Please enter your password"),
  }),
  LOGIN_VIA_PHONE: Yup.object().shape({
    phone: Yup.string()
      .matches(REGEX.PHONE_NUMBER, "Please enter 10 digit mobile number")
      .required("Please enter phone number"),
  }),
};
