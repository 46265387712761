import { IJobShortlistBanner } from "vendorList/components/Banner/JobShortlistBanner/interface";
import { Types as VendorListingTypes } from "_types/VendorListingTypes";
import { IVendorListingInitialState } from "./reducer";

const isGuestRoute = window.location.pathname.includes("/search-vendors");
const apiEndPoint = isGuestRoute ? "guest_vendor_listing" : "vendor_listing";

export const ActionCreators: any = {
  getVendorData: (params: any) => {
    return {
      types: [
        VendorListingTypes.GET_VENDORS_REQUESTED,
        VendorListingTypes.GET_VENDORS_SUCCESS,
        VendorListingTypes.GET_VENDORS_FAILURE,
      ],
      promise: (client: any) => client.get(apiEndPoint, { params }),
    };
  },


  getVendorDataByName: (params: any) => {
    return {
      types: [
        VendorListingTypes.GET_VENDORS_REQUESTED,
        VendorListingTypes.GET_VENDORS_SUCCESS,
        VendorListingTypes.GET_VENDORS_FAILURE,
      ],
      promise: (client: any) => client.get('vendor_listing_by_name', { params }),
    };
  },

  /** get/fetch registered vendor locations across India  */
  getRegisteredVendorLocationsAcrossIndia: () => ({
    types: [
      VendorListingTypes.GET_REGISTERED_VENDOR_LOCATIONS_ACROSS_INDIA_REQUESTED,
      VendorListingTypes.GET_REGISTERED_VENDOR_LOCATIONS_ACROSS_INDIA_SUCCESS,
      VendorListingTypes.GET_REGISTERED_VENDOR_LOCATIONS_ACROSS_INDIA_FAILURE,
    ],
    promise: (client: any) => client.get("get_registered_vendor_locations_across_india"),
  }),

  /** set available filter options for Registered In Filter-Tab */
  setAvailableRegisteredLocationsOptions: (
    availableRegisteredLocationsObject: IVendorListingInitialState["availableRegisteredLocations"],
  ) => ({
    type: VendorListingTypes.SET_AVAILABLE_REGISTERED_LOCATIONS_OPTIONS,
    payload: availableRegisteredLocationsObject,
  }),

  getVendorShortlistGroups: () => ({
    types: [
      VendorListingTypes.GET_VENDORS_SHORTLIST_REQUESTED,
      VendorListingTypes.GET_VENDORS_SHORTLIST_SUCCESS,
      VendorListingTypes.GET_VENDORS_SHORTLIST_FAILURE,
    ],
    promise: (client: any) => client.get("get_vendor_shortlist_group"),
  }),

  setVendorShortlistGroup: (data: any) => ({
    types: [
      VendorListingTypes.SET_VENDORS_SHORTLIST_REQUESTED,
      VendorListingTypes.SET_VENDORS_SHORTLIST_SUCCESS,
      VendorListingTypes.SET_VENDORS_SHORTLIST_FAILURE,
    ],
    promise: (client: any) => client.post("set_vendor_shortlist_group", { data }),
  }),

  getShortlistDetails: () => ({
    types: [
      VendorListingTypes.GET_SHORTLIST_DETAILS_REQUESTED,
      VendorListingTypes.GET_SHORTLIST_DETAILS_SUCCESS,
      VendorListingTypes.GET_SHORTLIST_DETAILS_FAILURE,
    ],
    promise: (client: any) => client.get("get_vendor_shortlist"),
  }),

  setShortlistGroupName: (data: any) => ({
    types: [
      VendorListingTypes.SET_SHORTLIST_GROUP_NAME_REQUESTED,
      VendorListingTypes.SET_SHORTLIST_GROUP_NAME_SUCCESS,
      VendorListingTypes.SET_SHORTLIST_GROUP_NAME_FAILURE,
    ],
    promise: (client: any) => client.post("set_rename_vendor_shortlist", { data }),
  }),

  deleteShortlistGroup: (data: any) => ({
    types: [
      VendorListingTypes.DELETE_SHORTLIST_GROUP_REQUESTED,
      VendorListingTypes.DELETE_SHORTLIST_GROUP_SUCCESS,
      VendorListingTypes.DELETE_SHORTLIST_GROUP_FAILURE,
    ],
    promise: (client: any) => client.post("set_delete_vendor_shortlist", { data }),
  }),

  deleteShortlistGroupVendor: (data: any) => ({
    types: [
      VendorListingTypes.DELETE_SHORTLIST_GROUP_VENDOR_REQUEST,
      VendorListingTypes.DELETE_SHORTLIST_GROUP_VENDOR_SUCCESS,
      VendorListingTypes.DELETE_SHORTLIST_GROUP_VENDOR_FAILED,
    ],
    promise: (client: any) => client.post("delete_shortlist_group_vendor", { data }),
  }),

  setFilterOptions: (filterOptions: object) => ({
    type: VendorListingTypes.SET_FILTER_OPTIONS,
    payload: filterOptions,
  }),

  setSearchByName: (search: any) => ({
    type: VendorListingTypes.SET_SEARCH_BY_NAME,
    payload: search,
  }),

  setFileUpload: (data: any) => ({
    types: [
      VendorListingTypes.SET_FILE_REQUESTED,
      VendorListingTypes.SET_FILE_SUCCESS,
      VendorListingTypes.SET_FILE__FAILURE,
    ],
    promise: (client: any) =>
      client.post("set_file_upload", {
        files: [{ key: "file", value: data }],
      }),
  }),

  postVendorRequest: (data: any) => ({
    types: [
      VendorListingTypes.POST_VENDOR_REQUEST_REQUESTED,
      VendorListingTypes.POST_VENDOR_REQUEST_SUCCESS,
      VendorListingTypes.POST_VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) =>
      client.post("post_vendor_request", {
        data,
      }),
  }),
  updateVendorRequest: (data: any) => ({
    types: [
      VendorListingTypes.POST_VENDOR_REQUEST_REQUESTED,
      VendorListingTypes.POST_VENDOR_REQUEST_SUCCESS,
      VendorListingTypes.POST_VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) =>
      client.put("update_vendor_request", {
        data,
      }),
  }),
  getVendorRequest: () => ({
    types: [
      VendorListingTypes.GET_VENDOR_REQUEST_REQUESTED,
      VendorListingTypes.GET_VENDOR_REQUEST_SUCCESS,
      VendorListingTypes.GET_VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.get("get_vendor_requests"),
  }),
  resetVendorList: () => ({
    type: VendorListingTypes.RESET_VENDOR_LIST,
  }),
  vendorRequestSuggestions: (params: any) => ({
    types: [
      VendorListingTypes.GET_VENDOR_REQUEST_SUGGESTIONS_REQUESTED,
      VendorListingTypes.GET_VENDOR_REQUEST_SUGGESTIONS_SUCCESS,
      VendorListingTypes.GET_VENDOR_REQUEST_SUGGESTIONS_FAILURE,
    ],
    promise: (client: any) => client.get(`get_vendor_request/${params}`),
  }),
  resetSearch: () => ({
    type: VendorListingTypes.RESET_SEARCH_RESET,
  }),

  resetSearchByName: () => ({
    type: VendorListingTypes.RESET_SEARCH_BY_NAME,
  }),
  setSelectedRequest: (payload: any) => ({
    type: VendorListingTypes.SET_SELECTED_REQUEST,
    payload: payload,
  }),
  setFeedbackPopupState: (payload: any) => ({
    type: VendorListingTypes.SHOW_SEARCH_FEEDBACK_POP_UP,
    payload: payload,
  }),
  requestVRAdditionToAProject: (data: any) => ({
    types: [
      VendorListingTypes.VENDOR_REQUEST_REQUESTED,
      VendorListingTypes.REQUEST_VR_ADDITION_TO_A_PROJECT_SUCCESS,
      VendorListingTypes.VENDOR_REQUEST_FAILURE,
    ],
    promise: (client: any) => client.patch("request_vr_addition_to_a_project", { data }),
  }),
  resetErrorAndSuccessMessage: () => ({
    type: VendorListingTypes.RESET_ERROR_AND_SUCCESS_MESSAGE,
  }),
  resetVendorRequest: () => ({
    type: VendorListingTypes.RESET_VENDOR_REQUEST,
  }),

  getClientJobsToShortlistVendor: (gstn: string, jobId?: string) => ({
    types: [
      VendorListingTypes.GET_JOBS_TO_SHORTLIST_VENDOR_REQUESTED,
      VendorListingTypes.GET_JOBS_TO_SHORTLIST_VENDOR_SUCCESS,
      VendorListingTypes.GET_JOBS_TO_SHORTLIST_VENDOR_FAILURE,
    ],
    promise: (client: any) => client.get(`get_client_jobs/${gstn}?jobId=${jobId}`),
  }),
  addVendorToShortlistJobs: (data: { requestIds: string[]; vendor: object }) => ({
    types: [
      VendorListingTypes.ADD_VENDOR_TO_SHORTLISTED_JOBS_REQUESTED,
      VendorListingTypes.ADD_VENDOR_TO_SHORTLISTED_JOBS_SUCCESS,
      VendorListingTypes.ADD_VENDOR_TO_SHORTLISTED_JOBS_FAILURE,
    ],
    promise: (client: any) => client.patch("add_vendor_to_shortlisted_jobs", { data }),
  }),
  approvePQR:(jobId: string)=>({
    types: [
      VendorListingTypes.APPROVE_PQR_REQUESTED,
      VendorListingTypes.APPROVE_PQR_SUCCESS,
      VendorListingTypes.APPROVE_PQR_FAILURE,
    ],
    promise: (client: any) => client.get(`approve_pqr/${jobId}`),
  }),
  updatePQR:(data: any)=>({
    types: [
      VendorListingTypes.APPROVE_PQR_REQUESTED,
      VendorListingTypes.APPROVE_PQR_SUCCESS,
      VendorListingTypes.APPROVE_PQR_FAILURE,
    ],
    promise: (client: any) => client.patch(`update_pqr`, {data}),
  }),

  setVendorToJobsShortlist: (data: any) => ({
    type: VendorListingTypes.SET_VENDOR_TO_JOBS_SHORTLIST,
    payload: data,
  }),
  resetVendorToJobsShortlist: () => ({
    type: VendorListingTypes.RESET_VENDOR_TO_JOBS_SHORTLIST,
  }),
  setJobShortlistBanner: (data: IJobShortlistBanner) => ({
    type: VendorListingTypes.SET_JOB_SHORTLIST_BANNER,
    payload: data,
  }),
  resetJobShortlistBanner: () => ({
    type: VendorListingTypes.SET_JOB_SHORTLIST_BANNER,
  }),
  setCurrJobToJobsShortlist: (job: { id: string; jRId: string }) => ({
    type: VendorListingTypes.SET_CURR_JOB_TO_JOBS_SHORTLIST,
    payload: job,
  }),
  setHowToUseJobsShortlist: (show: boolean) => ({
    type: VendorListingTypes.SET_HOW_TO_USE_JOBS_SHORTLIST,
    payload: show,
  }),
};

export default ActionCreators;
