import * as CSS from "csstype";
import {
  getAppliedCssValues,
  getCSSOnlyStringValueFromCSSProperties,
} from "./helpers";
import { ShadowProps, PartialCSSPropertiesHyphen } from "../types";

export const shadow = (props: ShadowProps) => {
  const getCSSValue = (cssPropertiesArray: string[]) =>
    getCSSOnlyStringValueFromCSSProperties(props, cssPropertiesArray);

  const cssObject: PartialCSSPropertiesHyphen = {
    "box-shadow": getCSSValue(["boxShadow"]) as CSS.BoxShadowProperty,
    "text-shadow": getCSSValue(["textShadow"]) as CSS.TextShadowProperty,
  };

  return getAppliedCssValues(cssObject);
};
