import React from "react";
import { COLORS } from "components/package/package.styles";
import { Box, Flex } from "components/package/styled-system/primitives";
import { BoxProps } from "components/package/styled-system/primitives/Box";
import { Typography } from "components/package";
import { IAuthenticationPage } from "authentication/interfaces/authentication.types";

interface IProps {
  type: IAuthenticationPage;
  stepCounter?: string | number;
}

export const AuthPageHeader = (props: IProps) => {
  let TITLE = "";
  const MOBILE_LOGO_URL = "url('/images/logo.svg')";
  let marginBottom: BoxProps["marginBottom"] = {};

  switch (props?.type) {
    case "REGISTER_PAGE":
      TITLE = "Register";
      marginBottom = {
        default: "0.75rem", // 12px
        xxs: "0.75rem", // 12px
        sm: "0.875rem", // 14px
      };

      break;

    case "LOGIN_PAGE":
      TITLE = "Login";
      marginBottom = {
        default: "1.5rem", // 24px
        xxs: "1.5rem", // 24px
        sm: "2rem", // 32px
      };
      break;

    case "FORGOT_PASSWORD":
      TITLE = "Forgot Password";
      marginBottom = {
        default: "1.5rem", // 24px
        xxs: "1.5rem", // 24px
        sm: "2rem", // 32px
      };
      break;

    case "RESET_PASSWORD":
      TITLE = "Reset Password";
      marginBottom = {
        default: "1.5rem", // 24px
        xxs: "1.5rem", // 24px
        sm: "2rem", // 32px
      };
      break;

    default:
      break;
  }

  return (
    <Box width={"100%"} mb={marginBottom}>
      {/* Mobile Logo */}
      <Box
        backgroundImage={MOBILE_LOGO_URL}
        width={"3.875rem"} // 62px
        height={"3.125rem"} // 50px
        display={{ default: "block", xxs: "block", sm: "none" }}
        mb={"1.2rem"} // 28px
      />
      <Typography
        color={{ default: COLORS.blue_1, xxs: COLORS.blue_1, sm: COLORS.blue_1 }}
        fontSize={"1.5rem"} // 24px
        fontWeight={{ default: 600, xxs: 600, sm: 700 }}
      >
        {TITLE}
      </Typography>
      {props?.type === "REGISTER_PAGE" && props?.stepCounter && (
        <Flex
          width={"100%"}
          fontWeight={600}
          color={COLORS.green_1}
          flexDirection={"column"}
          fontSize={{ default: "0.625rem", xxs: "0.625rem", sm: "0.75rem" }}
        >
          Step {props?.stepCounter}/3
        </Flex>
      )}
    </Box>
  );
};
