export const Types = {
  SET_REGISTRATION_REQUESTED: "registration/SET_REGISTRATION_REQUESTED",
  SET_REGISTRATION_SUCCESS: "registration/SET_REGISTRATION_SUCCESS",
  SET_REGISTRATION_FAILURE: "registration/SET_REGISTRATION_FAILURE",

  GET_COMPANY_DATA_REQUESTED: "registration/GET_COMPANY_DATA_REQUESTED",
  GET_COMPANY_DATA_SUCCESS: "registration/GET_COMPANY_DATA_SUCCESS",
  GET_COMPANY_DATA_FAILURE: "registration/GET_COMPANY_DATA_FAILURE",

  REGISTER_REQUESTED: "registration/REGISTER_REQUESTED",
  REGISTER_SUCCESS: "registration/REGISTER_SUCCESS",
  REGISTER_FAILURE: "registration/REGISTER_FAILURE",

  GET_OTP_REQUESTED: "registration/GET_OTP_REQUESTED",
  GET_OTP_SUCCESS: "registration/GET_OTP_SUCCESS",
  GET_OTP_FAILURE: "registration/GET_OTP_FAILURE",

  SET_USER_DATA: "registration/SET_USER_DATA",
  CHECK_EMAIL_DUPLICATION: "registration/CHECK_EMAIL_DUPLICATION",

  CHECK_PHONE_DUPLICATION: "registration/CHECK_PHONE_DUPLICATION",

  RETRIEVE_DATA_ON_OTP_PAGE_REFRESH: "registration/RETRIEVE_DATA_ON_OTP_PAGE_REFRESH",

  SET_INITIAL_STATE: "SET_INITIAL_STATE"

};
