import React from "react";
import * as CSS from "csstype";
import styled from "styled-components";
import { TYPOGRAPHY_VARIANTS } from "./typography.constants";
import { Box } from "../styled-system/primitives";
import { BoxProps } from "../styled-system/primitives/Box";

const StyledBox = styled(Box)``;

export interface IProps extends BoxProps {
  variant?: keyof typeof TYPOGRAPHY_VARIANTS;
  as?: any;
  children?: string | React.ReactNode;
}

export const Typography = (props: IProps) => {
  const { variant, children, ...remainingProps } = props;

  const defaultStyles: IProps = {
    m: 0,
    p: 0,
    as: TYPOGRAPHY_VARIANTS[variant || "p"],
  };

  return (
    <StyledBox {...defaultStyles} {...remainingProps}>
      {props?.children}
    </StyledBox>
  );
};
