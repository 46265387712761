import { message, notification } from "antd";
import { ToastProps } from "./interfaces/interfaces";

const AntdToast = ({ type, message, description, ...remainingArgs }: ToastProps) => {
  notification[type]({
    message: message,
    description,
    ...remainingArgs,
  });
};

export default AntdToast;
