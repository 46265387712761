import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Theme from "styles/Theme";
import store from "./store/store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ToastProvider } from "venwiz-ui";


ReactDOM.render(
  <StrictMode>
    <ToastProvider>
      <Provider store={store}>
        <Theme>
          <App />
        </Theme>
      </Provider>
    </ToastProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
