import React from "react";
import { ThemeProvider } from "styled-components";
import { buttonTheme } from "components/Button/ButtonStyles";
import { inputTheme } from "components/TextBox/InputStyles";
import { SCREENS } from "constants/mediaQueries.constants";

export const colors = {
  primary: "#011759",
  secondary: "#215EC9",
  tertiary: "#47C882",
  black: "#161616",
  blackLight: "#1C252B",
  blue: "#112B7A",
  blue_1: "#205EC9",
  green: "#47C882",
  red: "#BE000B",
  orange: "#FFAC39",
  white: "#FFFFFF",
  whiteFifty: "rgba(255, 255, 255, 0.5)",
  grayLight: "#B5C3C9",
  gray: "#98A6AD",
  grayDark: "#4A5A65",
  orangeDark: "#F78E50",
  grey: "#4D5D8B",
  darkBlue: "#011759",
} as const;

export type Colors = typeof colors;

export const globalStyles = {
  fontFamily: "Poppins",
  baseFontSize: "16px",
  bodyBG: "#FFFFFF",
  baseFontColor: "#11151A",
};

const fontSizes = {
  hero: {
    fontSize: "3.2rem",
    lineHeight: "4.7rem",
  },
  h1: {
    fontSize: "1.25rem",
    lineHeight: "2.9rem",
  },
  h2: {
    fontSize: "1.12rem",
    lineHeight: "2.7rem",
  },
  h3: {
    fontSize: "1rem",
    lineHeight: "2.4rem",
  },
  body: {
    fontSize: "0.87rem",
    lineHeight: "1.9rem",
  },
  body2: {
    fontSize: "0.75rem",
    lineHeight: "1.8rem",
  },
  caption: {
    fontSize: "0.75rem",
    lineHeight: "1.12rem",
  },
};

const fontWeights = {
  light: "300",
  regular: "400",
  medium: "500",
  bold: "600",
};

const breakpoints = SCREENS;

export const theme = {
  colors,
  button: { ...buttonTheme(colors) },
  input: { ...inputTheme(colors) },
  fontSizes,
  fontWeights,
  breakpoints,
} as const;

const Theme = ({ children }: any) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export default Theme;
