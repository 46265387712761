export const getCurrentDomainUrlOfApp = (url: string) => {
  switch(true) {
    case (url.includes("localhost")): 
      return "http://localhost:3000/";
    case (url.includes("-dev")): 
      return "https://client-dev.venwiz.com/";
    case (url.includes("-qa")): 
      return "https://client-qa.venwiz.com/";
    case (url.includes("-stg")): 
      return "https://client-stg.venwiz.com/";
    default: 
      return "https://client.venwiz.com/";
  };
};
