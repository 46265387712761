export const Types = {
  GET_PLANT_VISIT_REQUEST: "plantVisitRequest/GET_PLANT_VISIT_REQUEST",
  GET_PLANT_VISIT_FAILURE: "plantVisitRequest/GET_PLANT_VISIT_FAILURE",
  GET_REQUEST_TYPE_LIST_SUCCESS:
    "plantVisitRequest/GET_REQUEST_TYPE_LIST_SUCCESS",

  CREATE_PLANT_VISIT_REQUEST_SUCCESS:
    "plantVisitRequest/CREATE_PLANT_VISIT_REQUEST_SUCCESS",
  DELETE_PLANT_VISIT_REQUEST_SUCCESS:
    "plantVisitRequest/DELETE_PLANT_VISIT_REQUEST_SUCCESS",
  UPDATE_PLANT_VISIT_REQUEST_SUCCESS:
    "plantVisitRequest/UPDATE_PLANT_VISIT_REQUEST_SUCCESS",
  GET_PLANT_VISIT_REQUEST_SUCCESS:
    "plantVisitRequest/GET_PLANT_VISIT_REQUEST_SUCCESS",
  GET_PLANT_VISIT_REQUEST_LIST_SUCCESS:
    "plantVisitRequest/GET_PLANT_VISIT_REQUEST_LIST_SUCCESS",
  GET_NEW_UPDATE_REQUEST_COUNT_SUCCESS: "plantVisitRequest/GET_NEW_UPDATE_REQUEST_COUNT_SUCCESS",

};
