import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { domainNames } from "../../constants/domains";
import { message } from "antd";
import { Layout, AuthenticationContainer } from "authentication/components";
import SessionStorage from "_services/SessionStorage";
import LocalStorage from "_services/LocalStorage";
import ApiConstant from "constants/ApiConstant";
import PlantInfo from "./PlantInfo";
import { RegistrationActions } from "./actions";
import { PasswordInstruction, StyledForm } from "./Registration.styles";
import { ResponsiveInput as Input } from "components/package";
import { Box } from "components/package/styled-system/primitives";
import { AuthPageHeader } from "authentication/components/AuthPageHeader/AuthPageHeader";
import { ErrorContainer } from "authentication/components/ErrorContainer/ErrorContainer";
import { Typography } from "components/package";
import { COLORS } from "components/package/package.styles";
import { AuthenticationButton } from "authentication/components/Button/Button";
import { HEAP_DATA_TRACKING_ID, HEAP_TRIGGERS } from "constants/heapAnalytics.constants";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { AuthPageFooter } from "authentication/components/AuthPageFooter/AuthPageFooter";
import { RegistrationSuccessPage } from "./RegistrationSuccessPage";
import SSOModal from "authentication/components/SSOModal/SSOModal";

const Registration = (props: any) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const lowerCase = /[a-z]/;
  const upperCase = /[A-Z]/;
  const specialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  const numbers = /[0-9]/;
  const nameValidations = /^[^-\s][_A-z]*((-|\s)*[_A-z])*$/;
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneRegExp = /^[6-9]\d{9}$/;
  const password = /^(?=.*?[A-Z])(?=.*?[a-z])((?=.*?[0-9])|(?=.*?[#?!@$%^&*-])).{10,}$/;
  const { STORAGE_KEYS } = ApiConstant;
  const [stepCounter, setStepCounter] = useState(1);
  const [errorMessage, setErrorMessage] = useState("Please enter password");
  const [showPassword, setShowPassword] = useState(false);
  const [fullNameErrorMessage, setFullNameErrorMessage] = useState("Please enter a valid full name");
  const [loadCompanyContainer, setLoadCompanyContainer] = useState(false);
  const userData = useSelector((state: any) => state.RegistrationReducers);
  const [showSuccMess, setShowSuccMess] = useState(false);
  const [showSSOModal, setShowSSOModal] = useState(false);
  const { otpResponse } = userData;
  let errorArr: any = [];
  const initialValues = {
    email: "",
    password: "",
    name: "",
    phoneNo: "",
  };

  const getSubCategoryIdFromVenwizLandingPage = (urlString: string) => {
    const urlParams = new URLSearchParams(urlString);
    const subCategoryId = urlParams.get(ApiConstant.STORAGE_KEYS.subcategoryId);
    if (subCategoryId) {
      // for vendorListing Search via sub-category
      LocalStorage.set(ApiConstant.STORAGE_KEYS.subCategoryIdFromVenwizLandingPage, subCategoryId);
    }
  };

  const onSubmit = (values: any, { setFieldError }: any) => {
    const { password, phoneNo, name } = values;
    const email = values["email"].toLowerCase();
    // Exclude SSO users
    if (email.includes('@' + process.env.REACT_APP_SSO_PRIMETAL_KEY + '.com')) {
      console.log('condition - showSSOModal', showSSOModal)
      setShowSSOModal(true);
      return;
    }
    const emailAddress = email.split("@");
    const domainName = emailAddress[1].split(".");
    const names = domainNames;

    const onHandleNext = () => {
      const phoneNumber = phoneNo.length ? `+91${phoneNo}` : "";
      dispatch(
        RegistrationActions.storeUserdata({
          email: email,
          password: password,
          phoneNumber: phoneNumber,
          fullName: name.trimStart(),
        }),
      );
      dispatch(RegistrationActions.getCompanyName(`email=https://${emailAddress[1]}`))
        .then(() => {
          setLoadCompanyContainer(true);
          setStepCounter(2);
        })
        .catch((err: any) => {
          message.error(err.error, 2);
        });
    };

    function checkDuplication() {
      if (phoneNo?.length) {
        dispatch(RegistrationActions.emailDuplication(`email=${email}`))
          .then(() => {
            dispatch(RegistrationActions.phoneDuplication(`phone=+91${phoneNo}`))
              .then(() => {
                onHandleNext();
              })
              .catch((err: any) => {
                setFieldError("phoneNo", err.message);

                return false;
              });
          })
          .catch((err: any) => {
            setFieldError("email", err.message);
            return false;
          });
      } else {
        dispatch(RegistrationActions.emailDuplication(`email=${email}`))
          .then(() => {
            onHandleNext();
          })
          .catch((err: any) => {
            setFieldError("email", err.message);
            return false;
          });
      }
      return false;
    }

    if (names.includes(domainName[0])) {
      setFieldError("email", "Please enter your company email address");
    } else {
      checkDuplication();
    }
  };

  const validate = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid company email address")
      .matches(emailRegex, "Please enter valid company email address")
      .max(256, "Max 256 characters allowed")
      .required("Please enter valid company email address"),
    name: Yup.string()
      .matches(nameValidations, fullNameErrorMessage)
      .required("Please enter full name"),
    phoneNo: Yup.string().matches(phoneRegExp, "Please enter 10 digit mobile number"),
    password: Yup.string()
      .matches(password, errorMessage)
      .required("Please enter password"),
  });

  const formik = useFormik({ initialValues, onSubmit, validationSchema: validate });

  const setAlertMessage = (arr: any) => {
    if (arr.length === 1) {
      setErrorMessage(`Include atleast one ${arr[0]} `);
    } else if (arr.length === 2) {
      setErrorMessage(`Include atleast one ${arr[1]} and  ${arr[0]} `);
    }
  };

  const validateData = () => {
    if (formik.values.password.length < 10) {
      if (formik.values.password.length === 0) {
        setErrorMessage(`Please enter password`);
      } else {
        setErrorMessage(`Include atleast 10 characters`);
      }

      // formik.errors.password = `Password should be 8 characters long`;
    } else {
      if (!(specialChar.test(formik.values.password) || numbers.test(formik.values.password))) {
        errorArr.push("number or special character");
      }
      if (!lowerCase.test(formik.values.password)) {
        errorArr.push("lowercase character");
      }
      if (!upperCase.test(formik.values.password)) {
        errorArr.push("uppercase character");
      }

      setAlertMessage(errorArr);
    }
  };

  useEffect(() => {
    getSubCategoryIdFromVenwizLandingPage(search);
  }, []);

  useEffect(() => {
    errorArr = [];
    validateData();
  }, [formik.errors.password, formik.values.password]);

  const setNameError = (message: string) => {
    setFullNameErrorMessage(message);
  };
  useEffect(() => {
    if (/\d/.test(formik.values.name)) {
      setNameError("Enter only letters");
    }
  }, [formik.errors.name, formik.values.name]);

  useEffect(() => {
    if (window.location.pathname.match("/registration")) {
      const tempRegistrationDetails = SessionStorage.get(STORAGE_KEYS.tempRegistrationDetails);
      if (tempRegistrationDetails) {
        if (tempRegistrationDetails?.registerResponse?.status) {
          dispatch(RegistrationActions.retrieveRegistrationData(tempRegistrationDetails));
          setShowSuccMess(true);
        } else {
          setLoadCompanyContainer(true);
          setStepCounter(3);
        }
      }
    }
  }, []);

  if (showSuccMess) {
    return <RegistrationSuccessPage />;
  }

  if (loadCompanyContainer) {
    return (
      <Layout>
        <AuthenticationContainer>
          <AuthPageHeader type={"REGISTER_PAGE"} stepCounter={stepCounter} />
          {/* {stepCounter !== 3 && <Footer borderLine={false} footerType={FooterType.registrationFooter} />} */}
          <PlantInfo
            onCall={(value: string) => {
              if (value === "back") {
                setLoadCompanyContainer(false);
                setStepCounter(1);
              } else if (value === "success") {
                setShowSuccMess(true);
              } else {
                setStepCounter(3);
              }
            }}
          />
        </AuthenticationContainer>
      </Layout>
    );
  }

  const FIELD_ERRORS = {
    name: Boolean(formik.touched.name && formik.errors.name),
    email: Boolean(formik.touched.email && formik.errors.email),
    phoneNo: Boolean(formik.touched.phoneNo && formik.errors.phoneNo),
    password: Boolean(formik.touched.password && formik.errors.password),
  };

  return (
    <Layout>
      {showSSOModal && 
        <SSOModal setShowSSOModal={setShowSSOModal} />
      }
      <AuthenticationContainer>
        <AuthPageHeader type={"REGISTER_PAGE"} stepCounter={stepCounter} />
        {/* <Footer borderLine={false} footerType={FooterType.registrationFooter} /> */}
        <StyledForm onSubmit={formik.handleSubmit}>
          {/* Full Name */}
          <Input
            placeholder="Full Name"
            name="name"
            type="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={FIELD_ERRORS.name}
            fieldDescription={FIELD_ERRORS.name && <ErrorContainer errorText={formik.errors.name} />}
            {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.REGISTER.STEP_1.FULL_NAME_INPUT }}
          />
          {/* Email */}
          <Input
            placeholder="Company Email Address"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={FIELD_ERRORS.email}
            fieldDescription={FIELD_ERRORS.email && <ErrorContainer errorText={formik.errors.email} />}
            {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.REGISTER.STEP_1.EMAIL_INPUT }}
          />
          {/* Phone Number */}
          <Input
            placeholder="Enter 10 Digit Mobile Number(Optional)"
            name="phoneNo"
            type="phoneNo"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNo}
            error={FIELD_ERRORS.phoneNo}
            fieldDescription={FIELD_ERRORS.phoneNo && <ErrorContainer errorText={formik.errors.phoneNo} />}
            {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.REGISTER.STEP_1.PHONE_INPUT }}
          />
          {/* Password */}
          <Input
            placeholder="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={FIELD_ERRORS.password}
            fieldDescription={FIELD_ERRORS.password && <ErrorContainer errorText={formik.errors.password} />}
            {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.REGISTER.STEP_1.PASSWORD_INPUT }}
            pr={"2rem"}
            rightIcon={
              <Box
                right={0}
                pr={"0.5rem"}
                cursor={"pointer"}
                position={"absolute"}
                onClick={() => setShowPassword(prev => !prev)}
                fontSize={{ default: "1rem", xxs: "1rem", sm: "1.2rem" }}
                top={{ default: "0.25rem", xxs: "0.25rem", sm: "0.625rem" }}
              >
                {showPassword ? <MdVisibility color={COLORS.blue_1} /> : <MdVisibilityOff color={COLORS.blue_1} />}
              </Box>
            }
          />
          <PasswordInstruction>
            <img src="/images/vendorListing/info.svg" />
            <Typography
              ml={"0.5rem"}
              variant={"p"}
              fontSize={"0.75rem"} // 12px
              color={COLORS.blue_1}
            >
              Use 10 or more characters with a mix of upper and lower case letters and atleast 1 number/special
              character
            </Typography>
          </PasswordInstruction>
          <AuthenticationButton
            mt={"1rem"}
            variant="primary"
            onClick={() => onSubmit}
            type="submit"
            id="vzc-registration-next"
            {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.REGISTER.STEP_1.NEXT_CTA }}
          >
            Next
          </AuthenticationButton>
        </StyledForm>
        <AuthPageFooter type={"REGISTER_PAGE"} />
      </AuthenticationContainer>
    </Layout>
  );
};

export default Registration;
