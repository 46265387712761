import React, { useEffect, useState } from "react";
import { Layout, AuthenticationContainer } from "authentication/components";
import { FormikErrors, FormikHelpers, useFormik } from "formik";
import { useHistory } from "react-router";
import { AuthenticationActions } from "authentication/actions";
import { useDispatch, useSelector } from "react-redux";
import ApiConstant from "constants/ApiConstant";
import AntdToast from "components/Toast/AntdToast";
import LocalStorage from "_services/LocalStorage";
import SessionStorage from "_services/SessionStorage";
import { RegistrationActions } from "authentication/registration/actions";
import { StyledForm } from "authentication/registration/Registration.styles";
import { AuthPageHeader } from "authentication/components/AuthPageHeader/AuthPageHeader";
import { ResponsiveInput as Input, Typography } from "components/package";
import { ErrorContainer } from "authentication/components/ErrorContainer/ErrorContainer";
import { Box, Flex } from "components/package/styled-system/primitives";
import OtpBox from "authentication/components/OtpBox/OtpBox";
import { setOTPTimerInSession, whetherToShowResendOTPButton } from "authentication/components/OtpBox/otp.handler";
import { INITIAL_OTP_TIMER, INITIAL_OTP_STATE, AUTH_0_OTP_VERIFICATION_ERROR_MESSAGE } from "authentication/components/OtpBox/otpBox.constants";
import { validate } from "./forgotPassword.constants";
import { AUTHENTICATION_PAYLOAD_TYPE } from "authentication/actions/actions";
import { REGEX } from "authentication/login/login.constants";
import { AuthenticationButton } from "authentication/components/Button/Button";
import { HEAP_DATA_TRACKING_ID, HEAP_TRIGGERS } from "constants/heapAnalytics.constants";
import { COLORS } from "components/package/package.styles";
import { AuthPageFooter } from "authentication/components/AuthPageFooter/AuthPageFooter";
import { getErrorMessage } from "authentication/login/login.handler";
import { RootState } from "store/reducers";
import { PageSpinner } from "components/Spinner";
import { Modal } from "venwiz-ui";
import SSOModal from "authentication/components/SSOModal/SSOModal";

const ForgotPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { STORAGE_KEYS } = ApiConstant;
  const initialValues = { emailOrPhone: "" };
  const [prevEmailOrPhoneValue, setPrevEmailOrPhoneValue] = useState("");
  const [initialOTPState, setInitialOTPState] = useState({ ...INITIAL_OTP_STATE, seconds: 5, minutes: 3});
  const authData = useSelector((state: RootState) => state.AuthenticationReducers);
  const [showForgotPasswordOTPContainer, setShowForgotPasswordOTPContainer] = useState(false);
  const { otpError, error: errMessage, resetTimer, loading: isLoading } = useSelector(
    (state: RootState) => state.AuthenticationReducers,
  );
  const [showReSendOTPButton, setShowReSendOTPButton] = useState(false);

  const [showSSOModal, setShowSSOModal] = useState(false);

  const isUserValueEmail = (val: string) => REGEX.EMAIL.test(val);

  const resetOTPTimerInSession = () => setOTPTimerInSession(INITIAL_OTP_TIMER);

  const mobileNumberCheck = (value: string) => (value as string)?.startsWith("+91") ? value : `+91${value}`;

  const handleRequestOTPForForgotPassword = (TYPE: "EMAIL" | "PHONE", value: string) => {
    let DATA: { entity?: "mobile" | "email"; value?: string } = {};
    let STORAKE_KEY_NAME = "";

    if (value) {
      if (TYPE === "EMAIL") {
        DATA.entity = "email";
        DATA.value = value;
        authData.email = value;
        STORAKE_KEY_NAME = STORAGE_KEYS.userEmail;
      } else if (TYPE === "PHONE") {
        DATA.entity = "mobile";
        DATA.value = mobileNumberCheck(value);
        authData.phone = DATA.value;
        STORAKE_KEY_NAME = STORAGE_KEYS.userPhone;
      }

      LocalStorage.set(STORAKE_KEY_NAME, DATA.value);
      const data = DATA;

      const resetOTPRelatedStates = () => {
        /** hide Send/Re-send OTP button - when requesting for OTP */
        setShowReSendOTPButton(false);
        dispatch(AuthenticationActions.setOTPBoxErrorState(false));
        /** reset OTP Timer at every send/resend API call */
        setInitialOTPState(prev => ({ ...prev, ...INITIAL_OTP_TIMER, otpValue: "", isInputDisabled: false }));
      };

      dispatch(AuthenticationActions.forgotPassword(data)).then(
        (res: any) => {
          dispatch(AuthenticationActions.setResetTimerFlag(true));
          formik.setFieldError("emailOrPhone", ""); // reset formik error
          AntdToast({ type: "success", message: "Success", description: res.messages });
          setShowForgotPasswordOTPContainer(true);
          resetOTPRelatedStates();
        },
        (err: any) => {
          const ERROR_MESSAGE = getErrorMessage(err) || err?.messages;
          formik.setFieldError("emailOrPhone", ERROR_MESSAGE);
          setShowForgotPasswordOTPContainer(false);
          resetOTPRelatedStates();
        },
      );
    }
  };

  /** Generate forgot password OTP for email & phone */
  const handleGenerateOTPForForgotPassword = (values: typeof initialValues) => {
    console.log('handleGenerateOTPForForgotPassword - values', values)
    const { emailOrPhone } = values;
    if (emailOrPhone) {
      const isEmail = isUserValueEmail(emailOrPhone);
      const TYPE = isEmail ? "EMAIL" : "PHONE";
      switch (TYPE) {
        case "EMAIL":
          // Exclude SSO users
          if (emailOrPhone.includes('@' + process.env.REACT_APP_SSO_PRIMETAL_KEY + '.com')) {
            console.log('condition - showSSOModal', showSSOModal)
            setShowSSOModal(true);
            break;
          }
          handleRequestOTPForForgotPassword("EMAIL", emailOrPhone);
          break;

        case "PHONE":
          handleRequestOTPForForgotPassword("PHONE", emailOrPhone);
          break;

        default:
          break;
      }
    }
  };

  /** Verify forgot password OTP form email & phone */
  const handleVerifyOTP = (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
    const { emailOrPhone } = values;
    if (emailOrPhone && initialOTPState?.otpValue) {
      const isEmail = isUserValueEmail(emailOrPhone);
      const TYPE = isEmail ? "EMAIL" : "PHONE";
      switch (TYPE) {
        case "EMAIL":
          const VERIFY_EMAIL_OTP_PAYLOAD: AUTHENTICATION_PAYLOAD_TYPE["VERIFY_EMAIL_OTP"] = {
            email: emailOrPhone,
            otp: String(initialOTPState?.otpValue),
            forResetPwd: true,
          };
          dispatch(AuthenticationActions.verifyEmailOtp(VERIFY_EMAIL_OTP_PAYLOAD)).then(
            (res: any) => {
              SessionStorage.set(ApiConstant.STORAGE_KEYS.resetPwdToken, res?.resetPwdToken);
              SessionStorage.set(ApiConstant.STORAGE_KEYS.userEmail, res?.email);
              // reset OTP Timer after successful OTP verification
              resetOTPTimerInSession();
              history.push("/resetpassword");
            },
            (err: any) => {
              const ERROR_MESSAGE = Array.isArray(err?.message) ? err?.message[0] : getErrorMessage(err) || "";
              if ((ERROR_MESSAGE as string)?.toLowerCase()?.includes(AUTH_0_OTP_VERIFICATION_ERROR_MESSAGE)) {
                dispatch(AuthenticationActions.setOTPBoxErrorState(true));
              } else {
                formik.setFieldError("emailOrPhone", ERROR_MESSAGE);
              }
            },
          );
          break;

        case "PHONE":
          const VERIFY_MOBILE_OTP_PAYLOAD: AUTHENTICATION_PAYLOAD_TYPE["VERIFY_MOBILE_OTP"] = {
            mobileNo: mobileNumberCheck(emailOrPhone),
            otp: String(initialOTPState?.otpValue),
            forResetPwd: true,
          };
          dispatch(AuthenticationActions.verifyMobileOtp(VERIFY_MOBILE_OTP_PAYLOAD)).then(
            (res: any) => {
              SessionStorage.set(ApiConstant.STORAGE_KEYS.resetPwdToken, res?.resetPwdToken);
              SessionStorage.set(ApiConstant.STORAGE_KEYS.userEmail, res?.email);
              // reset OTP Timer after successful OTP verification
              resetOTPTimerInSession();
              history.push("/resetpassword");
            },
            (err: any) => {
              const ERROR_MESSAGE = Array.isArray(err?.message) ? err?.message[0] : getErrorMessage(err) || "";
              if ((ERROR_MESSAGE as string)?.toLowerCase()?.includes(AUTH_0_OTP_VERIFICATION_ERROR_MESSAGE)) {
                dispatch(AuthenticationActions.setOTPBoxErrorState(true));
              } else {
                formik.setFieldError("emailOrPhone", ERROR_MESSAGE);
              }
            },
          );
          break;

        default:
          break;
      }
    }
  };

  const formik = useFormik({ initialValues, onSubmit: handleVerifyOTP, validationSchema: validate });

  const EMAILOR_PHONE_FIELD_ERROR = Boolean(formik.touched.emailOrPhone && formik.errors.emailOrPhone);

  /** disable form Submit button after OTP container is opened & when OTP_Input_Value is less than six */
  const isFormSubmitButtonDisabled = showForgotPasswordOTPContainer && initialOTPState.otpValue?.length < 6;

  useEffect(() => {
    if (showForgotPasswordOTPContainer && (formik?.values?.emailOrPhone !== prevEmailOrPhoneValue)) {
      /** when user's input value is changed - toggle ReSend OTP button's visibility */
      setShowReSendOTPButton(true);
    }
    setPrevEmailOrPhoneValue(formik?.values?.emailOrPhone);
  }, [formik?.values?.emailOrPhone]);

  useEffect(() => {
    const { minutes, seconds } = initialOTPState;
    if (whetherToShowResendOTPButton({ minutes, seconds }) || (seconds === 0 && minutes === 0)) {
      /** toggle Re-Send OTP Button's visibility */
      setShowReSendOTPButton(true);
    }
  }, [initialOTPState]);

  useEffect(() => {
    dispatch(RegistrationActions.setInitialState());
    SessionStorage.remove(STORAGE_KEYS.tempPlantInfo);
    SessionStorage.remove(STORAGE_KEYS.tempRegistrationDetails);
  }, []);

  const handleOnClick = () => {
    /** when OTP container is not OPEN - don't call generate OTP function  */
    !showForgotPasswordOTPContainer && formik.isValid
      ? handleGenerateOTPForForgotPassword(formik?.values)
      : (()  => {})();
  };

  const BUTTON_TYPE = (() => {
    /**
     * when html-button-type is "submit" for a formik form,
     * formik's onSubmit is triggered when button is clicked
     */
    if (showForgotPasswordOTPContainer || !formik?.values?.emailOrPhone) return "submit";
    return "button";
  })()

  return (
    <Layout>
      {isLoading && <PageSpinner isLoading={isLoading} />}
      {showSSOModal && 
        <SSOModal setShowSSOModal={setShowSSOModal} />
      }
      <AuthenticationContainer>
        <AuthPageHeader type={"FORGOT_PASSWORD"} />
        <StyledForm onSubmit={formik.handleSubmit}>
          {/* Email or Phone */}
          <Input
            placeholder="Email or Phone"
            name="emailOrPhone"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.emailOrPhone}
            error={EMAILOR_PHONE_FIELD_ERROR}
            fieldDescription={EMAILOR_PHONE_FIELD_ERROR && <ErrorContainer errorText={formik.errors.emailOrPhone} />}
            {...{
              [HEAP_DATA_TRACKING_ID]: formik?.values?.emailOrPhone
                ? isUserValueEmail(formik?.values?.emailOrPhone)
                  ? HEAP_TRIGGERS.FORGOT_PASSWORD_PAGE.EMAIL_INPUT
                  : HEAP_TRIGGERS.FORGOT_PASSWORD_PAGE.PHONE_INPUT
                : "",
            }}
            pr={"2rem"}
            rightIcon={
              showReSendOTPButton ? (
                <Box
                  right={0}
                  pr={"0.5rem"}
                  cursor={"pointer"}
                  position={"absolute"}
                  top={{ default: "0.25rem", xxs: "0.25rem", sm: "0.625rem" }}
                  onClick={() => handleGenerateOTPForForgotPassword(formik.values)}
                >
                  <Typography fontSize={"0.8rem"} cursor={"pointer"} fontWeight={600} color={COLORS.blue_1}>
                    <>{showReSendOTPButton ? "Resend OTP" : ""}</>
                  </Typography>
                </Box>
              ) : null
            }
          />
          {showForgotPasswordOTPContainer && (
            <Flex
              width={"100%"}
              flexDirection={"column"}
              mt={
                showForgotPasswordOTPContainer
                  ? {
                      default: "2.5rem", // 40px
                      xxs: "2.5rem", // 40px
                      sm: "1.25rem", // 20px
                    }
                  : "0px"
              }
            >
              <OtpBox
                renderResendOTPButton={false}
                customResendOTPButton={<></>}
                initialOTPState={initialOTPState}
                title={"Enter OTP To Reset Password"}
                setInitialOTPState={setInitialOTPState}
              />
            </Flex>
          )}
          <Flex
            gap={"1rem"}
            flexDirection={"column"}
            mt={showForgotPasswordOTPContainer ? { default: "2rem", xxs: "2rem", xl: "3.8125rem" } : "0px"}
          >
            <AuthenticationButton
              type={BUTTON_TYPE}
              variant="primary"
              onClick={handleOnClick}
              disabled={isFormSubmitButtonDisabled}
              {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.FORGOT_PASSWORD_PAGE.SUBMIT_CTA }}
            >
              {showForgotPasswordOTPContainer ? "Verify OTP" : "Send OTP"}
            </AuthenticationButton>
          </Flex>
        </StyledForm>
        {!showForgotPasswordOTPContainer && <AuthPageFooter type={"FORGOT_PASSWORD"} />}
      </AuthenticationContainer>
    </Layout>
  );
};

export default ForgotPassword;
