import { combineReducers } from "redux";
import { DashboardReducers } from "dashboard/actions";
import { VendorListingReducers } from "vendorList/actions";
import { ClientVendorReducers } from "clientVendorView/actions";
import { LoginReducers } from "authentication/login/actions";
import { ResetPasswordReducers } from "authentication/resetPassword/actions";
import { ClientCommunicationReducers } from "ChatModule/actions";
import { LoaderReducers } from "../components/Spinner/actions";
import { AuthenticationReducers } from "authentication/actions";
import { RegistrationReducers } from "authentication/registration/actions";
import { ProfileCreationReducers } from "profileCreation/actions";
import { InviteReducers } from "Invite/actions";
import { CompareVendorsReducers } from "compareVendors/actions";
import { RequestReducers } from "Request/actions";
import {NavigationReducers} from "common/Navigation/actions"
import { FileReducers } from "shared/redux/FileUpload/actions";
import DashboardReducer from "revamp/redux/reducers/Dashboard.reducer";

const appReducer = combineReducers({
  LoginReducers,
  ResetPasswordReducers,
  DashboardReducers,
  vendorListing: VendorListingReducers,
  ClientVendorReducers,
  ClientCommunicationReducers,
  AuthenticationReducers,
  InviteReducers,
  LoaderReducers,
  RegistrationReducers,
  ProfileCreationReducers,
  CompareVendorsReducers,
  RequestReducers: RequestReducers,
  NavigationReducers,
  FileReducers,
  DashboardReducer
});

const rootReducer = (state: any, action: any) => appReducer(state, action);

export type RootState = ReturnType<typeof rootReducer> 

export default rootReducer;
