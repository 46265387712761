export const domainNames = [
  "gmail",
  "yahoo",
  "hotmail",
  "aol",
  "msn",
  "wanadoo",
  "orange",
  "comcast",
  "live",
  "rediffmail",
  "free",
  "gmx",
  "web",
  "yandex",
  "ymail",
  "libero",
  "outlook",
  "uol",
  "bol",
  "mail",
  "cox",
  "sbcglobal",
  "sfr",
  "verizon",
  "googlemail",
  "yahoo.es",
  "ig",
  "bigpond",
  "terra",
  "neuf",
  "alice",
  "rocketmail",
  "att",
  "laposte",
  "facebook",
  "bellsouth",
  "charter",
  "rambler",
  "tiscali",
  "shaw",
  "sky",
  "earthlink",
  "optonline",
  "freenet",
  "t-online",
  "aliceadsl",
  "virgilio",
  "home",
  "qq",
  "telenet.be",
  "me",
  "voila",
  "planet",
  "tin",
  "ntlworld",
  "arcor",
  "frontiernet",
  "hetnet",
  "zonnet",
  "club-internet",
  "juno",
  "optusnet",
  "blueyonder",
  "bluewin",
  "skynet",
  "sympatico",
  "windstream",
  "mac",
  "centurytel",
  "chello",
  "aim",
];
