import React from "react";
import { Typography } from "components/package";
import { COLORS } from "components/package/package.styles";
import { Flex } from "components/package/styled-system/primitives";
import { BoxProps } from "components/package/styled-system/primitives/Box";
import { IAuthenticationPage } from "authentication/interfaces/authentication.types";
import { getAuthPageFooterVariant } from "./authPageFooter.handler";
import { useHistory } from "react-router-dom";

export interface IAuthPageFooterProps extends BoxProps {
  type: IAuthenticationPage;
}

export const AuthPageFooter = (props: IAuthPageFooterProps) => {
  const { type, ...remainingProps } = props;
  const history = useHistory();

  const { desc, actiontext, link, HEAP_DATA_TRACKING_OBJ } = getAuthPageFooterVariant(type);

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      w={"100%"}
      mt={{
        default: "1.5rem", // 24px
        xxs: "1.5rem", // 24px
        sm: "2rem", // 32px
      }}
      {...remainingProps}
    >
      {desc ? (
        <Typography
          color={"black"}
          fontSize={"0.75rem"} // 12px
          fontWeight={400}
        >
          {desc}
        </Typography>
      ) : (
        <></>
      )}
      {actiontext ? (
        <Typography
          variant={"span"}
          display={"inline-block"}
          color={COLORS.blue_1}
          fontSize={"0.75rem"} // 12px
          fontWeight={700}
          ml={"0.25rem"} // 4px
          cursor={"pointer"}
          onClick={() => {
            if (link) history.push(link);
          }}
          {...HEAP_DATA_TRACKING_OBJ}
        >
          {actiontext}
        </Typography>
      ) : (
        <></>
      )}
    </Flex>
  );
};
