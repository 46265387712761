import { Types as AuthenticationTypes } from "_types/AuthenticationTypes";

export type AUTHENTICATION_PAYLOAD_TYPE = {
  VERIFY_EMAIL_OTP: { email: string; otp: string; forResetPwd?: boolean };
  VERIFY_MOBILE_OTP: { mobileNo: string; otp: string; forResetPwd?: boolean };
  RESET_PASSWORD: { email: string; newPassword: string; resetPwdToken?: string };
  UPDATE_PASSWORD: { email: string; newPassword: string; confirmPassword: string; oldPassword: string };
  GENERATE_MOBILE_OTP_FOR_LOGIN_VIA_PHONE: { mobileNo: string };
};

export const ActionCreators: any = {
  forgotPassword: (data: any) => ({
    types: [
      AuthenticationTypes.FORGOT_PASSWORD_REQUESTED,
      AuthenticationTypes.FORGOT_PASSWORD_SUCCESS,
      AuthenticationTypes.FORGOT_PASSWORD_FAILURE,
    ],
    promise: (client: any) => client.post("forgot_password", { data }),
  }),

  /** consumeed for all used cases at - Email OTP verification  */
  verifyEmailOtp: (data: AUTHENTICATION_PAYLOAD_TYPE["VERIFY_EMAIL_OTP"]) => ({
    types: [
      AuthenticationTypes.VERIFY_EMAIL_OTP_REQUESTED,
      AuthenticationTypes.VERIFY_EMAIL_OTP_SUCCESS,
      AuthenticationTypes.VERIFY_EMAIL_OTP_FAILURE,
    ],
    promise: (client: any) => client.post("verify_email_otp", { data }),
  }),

  /** consumed for all used cases at - Mobile OTP verification - except Login Via Phone */
  verifyMobileOtp: (data: AUTHENTICATION_PAYLOAD_TYPE["VERIFY_MOBILE_OTP"]) => ({
    types: [
      AuthenticationTypes.VERIFY_MOBILE_OTP_REQUESTED,
      AuthenticationTypes.VERIFY_MOBILE_OTP_SUCCESS,
      AuthenticationTypes.VERIFY_MOBILE_OTP_FAILURE,
    ],
    promise: (client: any) => client.post("verify_mobile_otp", { data }),
  }),

  resetPassword: (data: AUTHENTICATION_PAYLOAD_TYPE["RESET_PASSWORD"]) => ({
    types: [
      AuthenticationTypes.RESET_PASSWORD_REQUEST,
      AuthenticationTypes.RESET_PASSWORD_SUCCESS,
      AuthenticationTypes.RESET_PASSWORD_FAILURE,
    ],
    promise: (client: any) => client.post("reset_password", { data }),
  }),

  resetState: () => ({
    type: AuthenticationTypes.RESET_STATE,
  }),

  setResetTimerFlag: (data: any) => ({
    type: AuthenticationTypes.SET_RESET_TIMER_FLAG,
    payload: data,
  }),

  setOTPBoxErrorState: (data: boolean) => ({
    type: AuthenticationTypes.SET_OTP_BOX_ERROR_STATE,
    payload: data,
  }),

  generateOTPForloginViaMobile: (data: AUTHENTICATION_PAYLOAD_TYPE["GENERATE_MOBILE_OTP_FOR_LOGIN_VIA_PHONE"]) => ({
    types: [
      AuthenticationTypes.GENERATE_MOBILE_OTP_FOR_LOGIN_REQUESTED,
      AuthenticationTypes.GENERATE_MOBILE_OTP_FOR_LOGIN_SUCCESS,
      AuthenticationTypes.GENERATE_MOBILE_OTP_FOR_LOGIN_FAILURE,
    ],
    promise: (client: any) => client.post("generate_otp_for_login_via_phone", { data }),
  }),

  logOut: () => ({
    types: [
      AuthenticationTypes.AUTHENTICATION_COMMON_REQUESTED,
      AuthenticationTypes.LOG_OUT_SUCCESS,
      AuthenticationTypes.AUTHENTICATION_COMMON_FAILURE,
    ],
    promise: (client: any) => client.post("logout", {}),
  }),

  /** Verifying whether a user with pass-reset-link is verified customer or not */
  passwordResetLinkVerifyEmail: (id: string) => ({
    types: [
      AuthenticationTypes.AUTHENTICATION_COMMON_REQUESTED,
      AuthenticationTypes.PASSWORD_RESET_LINK_VERIFY_EMAIL_SUCCESS,
      AuthenticationTypes.AUTHENTICATION_COMMON_FAILURE,
    ],
    promise: (client: any) => client.get(`password_reset_link_verify_email/${id}`),
  }),

  /** Updating backend that the user has successfully reset password */
  passwordResetLinkUpdateUserStatus: (id: string) => ({
    types: [
      AuthenticationTypes.AUTHENTICATION_COMMON_REQUESTED,
      AuthenticationTypes.PASSWORD_RESET_LINK_UPDATE_USER_STATUS_SUCCESS,
      AuthenticationTypes.AUTHENTICATION_COMMON_FAILURE,
    ],
    promise: (client: any) => client.put(`password_reset_link_update_user_status/${id}`),
  }),
};

export default ActionCreators;
