import ApiConstant from "constants/ApiConstant";
import SessionStorage from "_services/SessionStorage";

export const checkPrevRoute = (userData: any, history: any) => {
  const { STORAGE_KEYS } = ApiConstant;
  if (userData === null) {
    switch (true) {
      case history.location.pathname.includes("compareVendors"):
        {
          SessionStorage.set(STORAGE_KEYS.guestRedirection, "compareVendors");
          SessionStorage.set(
            STORAGE_KEYS.compareVendorLink,
            history.location.pathname
          );
        }
        break;
      case history.location.pathname.includes("project"):
        {
          SessionStorage.set(STORAGE_KEYS.guestRedirection, "project");
          SessionStorage.set(STORAGE_KEYS.prevUrl,`${history.location.pathname}${history.location.search}`);
        }
        break;
      case history.location.pathname.includes("job-scope"):
        {
          SessionStorage.set(STORAGE_KEYS.guestRedirection, "job");
          SessionStorage.set(STORAGE_KEYS.prevUrl,`${history.location.pathname}${history.location.search}`);
        }
      break;
      case history.location.pathname.includes("job-request") ||
        history.location.pathname.includes("job") ||
        history.location.pathname.includes("comparison-report") && 
        !history.location.pathname.includes("job-scope"):
        {
          SessionStorage.set(STORAGE_KEYS.guestRedirection, "job");
          SessionStorage.set(STORAGE_KEYS.prevUrl, history.location.pathname);
        }
        break;
      default:
        break;
    }
  }
};
