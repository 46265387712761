export const Types = {
  GET_VENDORS_REQUESTED: "dashboard/GET_VENDORS_REQUESTED",
  GET_VENDORS_SUCCESS: "dashboard/GET_VENDORS_SUCCESS",
  GET_VENDORS_FAILURE: "dashboard/GET_VENDORS_FAILURE",

  GET_SHORTLIST_REQUESTED: "dashboard/GET_SHORTLIST_REQUESTED",
  GET_SHORTLIST_SUCCESS: "dashboard/GET_SHORTLIST_SUCCESS",
  GET_SHORTLIST_FAILURE: "dashboard/GET_SHORTLIST_FAILURE",

  SET_FILTER_OPTIONS: "vendorListing/SET_FILTER_OPTIONS",
  SET_SEARCH_BY_NAME: "vendorListing/SET_SEARCH_BY_NAME",

  GET_VENDORS_SHORTLIST_REQUESTED: "vendorListing/GET_VENDORS_SHORTLIST_REQUESTED",
  GET_VENDORS_SHORTLIST_SUCCESS: "vendorListing/ GET_VENDORS__SHORTLIST_SUCCESS",
  GET_VENDORS_SHORTLIST_FAILURE: "vendorListing/ GET_VENDORS__SHORTLIST_FAILURE",

  GET_REGISTERED_VENDOR_LOCATIONS_ACROSS_INDIA_REQUESTED:
    "vendorListing/GET_REGISTERED_VENDOR_LOCATIONS_ACROSS_INDIA_REQUESTED",
  GET_REGISTERED_VENDOR_LOCATIONS_ACROSS_INDIA_SUCCESS:
    "vendorListing/GET_REGISTERED_VENDOR_LOCATIONS_ACROSS_INDIA_SUCCESS",
  GET_REGISTERED_VENDOR_LOCATIONS_ACROSS_INDIA_FAILURE:
    "vendorListing/GET_REGISTERED_VENDOR_LOCATIONS_ACROSS_INDIA_FAILURE",

  SET_AVAILABLE_REGISTERED_LOCATIONS_OPTIONS: "vendorListing/SET_AVAILABLE_REGISTERED_LOCATIONS_OPTIONS",

  SET_VENDORS_SHORTLIST_REQUESTED: "vendorListing/SET_VENDORS_SHORTLIST_REQUESTED",
  SET_VENDORS_SHORTLIST_SUCCESS: "vendorListing/ SET_VENDORS_SHORTLIST_SUCCESS",
  SET_VENDORS_SHORTLIST_FAILURE: "vendorListing/SET_VENDORS_SHORTLIST_FAILURE",

  GET_SHORTLIST_DETAILS_REQUESTED: "vendorListing/GET_SHORTLIST_DETAILS_REQUESTED",
  GET_SHORTLIST_DETAILS_SUCCESS: "vendorListing/GET_SHORTLIST_DETAILS_SUCCESS",
  GET_SHORTLIST_DETAILS_FAILURE: "vendorListing/GET_SHORTLIST_DETAILS_FAILURE",

  SET_SHORTLIST_GROUP_NAME_REQUESTED: "vendorListing/SET_SHORTLIST_GROUP_NAME_REQUESTED",
  SET_SHORTLIST_GROUP_NAME_SUCCESS: "vendorListing/ SET_SHORTLIST_GROUP_NAME_SUCCESS",
  SET_SHORTLIST_GROUP_NAME_FAILURE: "vendorListing/SET_SHORTLISTGROUP_NAME_FAILURE",

  DELETE_SHORTLIST_GROUP_REQUESTED: "vendorListing/DELETE_SHORTLIST_GROUP_REQUESTED",
  DELETE_SHORTLIST_GROUP_SUCCESS: "vendorListing/ DELETE_SHORTLIST_GROUP_SUCCESS",
  DELETE_SHORTLIST_GROUP_FAILURE: "vendorListing/DELETE_SHORTLIST_GROUP_FAILURE",

  DELETE_SHORTLIST_GROUP_VENDOR_REQUEST: "vendorListing/DELETE_SHORTLIST_GROUP_VENDOR_REQUEST",
  DELETE_SHORTLIST_GROUP_VENDOR_SUCCESS: "vendorListing/DELETE_SHORTLIST_GROUP_VENDOR_SUCCESS",
  DELETE_SHORTLIST_GROUP_VENDOR_FAILED: "vendorListing/DELETE_SHORTLIST_GROUP_VENDOR_FAILURE",

  SET_FILE_REQUESTED: "vendorListing/SET_FILE_REQUESTED",
  SET_FILE_SUCCESS: "vendorListing/SET_FILE_SUCCESS",
  SET_FILE__FAILURE: "vendorListing/SET_FILE_FAILURE",

  POST_VENDOR_REQUEST_REQUESTED: "vendorListing/POST_VENDOR_REQUEST_REQUESTED",
  POST_VENDOR_REQUEST_SUCCESS: "vendorListing/POST_VENDOR_REQUEST_SUCCESS",
  POST_VENDOR_REQUEST_FAILURE: "vendorListing/POST_VENDOR_REQUEST_FAILURE",

  GET_VENDOR_REQUEST_REQUESTED: "vendorListing/GET_VENDOR_REQUEST_REQUESTED",
  GET_VENDOR_REQUEST_SUCCESS: "vendorListing/GET_VENDOR_REQUEST_SUCCESS",
  GET_VENDOR_REQUEST_FAILURE: "vendorListing/GET_VENDOR_REQUEST_FAILURE",

  GET_VENDOR_REQUEST_SUGGESTIONS_REQUESTED: "vendorRequest/GET_VENDOR_REQUEST_SUGGESTIONS_REQUESTED",
  GET_VENDOR_REQUEST_SUGGESTIONS_SUCCESS: "vendorRequest/GET_VENDOR_REQUEST_SUGGESTIONS_SUCCESS",
  GET_VENDOR_REQUEST_SUGGESTIONS_FAILURE: "vendorRequest/GET_VENDOR_REQUEST_SUGGESTIONS_FAILURE",

  RESET_VENDOR_LIST: "vendorListing/RESET_VENDOR_LIST", 
  RESET_SEARCH_BY_NAME: "vendorListing/RESET_SEARCH_BY_NAME",
  RESET_SEARCH_RESET: "vendorListing/RESET_SEARCH_RESET",
  SET_SELECTED_REQUEST: "vendorRequest/SET_SELECTED_REQUEST",
  SHOW_SEARCH_FEEDBACK_POP_UP: "vendorRequest/SHOW_SEARCH_FEEDBACK_POP_UP",

  REQUEST_VR_ADDITION_TO_A_PROJECT_SUCCESS: "vendorRequest/REQUEST_VR_ADDITION_TO_A_PROJECT_SUCCESS",
  VENDOR_REQUEST_REQUESTED: "vendorRequest/VENDOR_REQUEST_REQUESTED",
  VENDOR_REQUEST_FAILURE: "vendorRequest/VENDOR_REQUEST_FAILURE",

  RESET_ERROR_AND_SUCCESS_MESSAGE: "vendorRequest/RESET_ERROR_AND_SUCCESS_MESSAGE",

  RESET_VENDOR_REQUEST: "vendorRequest/RESET_VENDOR_REQUEST",

  GET_JOBS_TO_SHORTLIST_VENDOR_REQUESTED: "vendorRequest/GET_JOBS_TO_SHORTLIST_VENDOR_REQUESTED",
  GET_JOBS_TO_SHORTLIST_VENDOR_SUCCESS: "vendorRequest/GET_JOBS_TO_SHORTLIST_VENDOR_SUCCESS",
  GET_JOBS_TO_SHORTLIST_VENDOR_FAILURE: "vendorRequest/GET_JOBS_TO_SHORTLIST_VENDOR_FAILURE",
  ADD_VENDOR_TO_SHORTLISTED_JOBS_REQUESTED: "vendorRequest/ADD_VENDOR_TO_SHORTLISTED_JOBS_REQUESTED",
  ADD_VENDOR_TO_SHORTLISTED_JOBS_SUCCESS: "vendorRequest/ADD_VENDOR_TO_SHORTLISTED_JOBS_SUCCESS",
  ADD_VENDOR_TO_SHORTLISTED_JOBS_FAILURE: "vendorRequest/ADD_VENDOR_TO_SHORTLISTED_JOBS_FAILURE",
  SET_VENDOR_TO_JOBS_SHORTLIST: "vendorRequest/SET_VENDOR_TO_JOBS_SHORTLIST",
  RESET_VENDOR_TO_JOBS_SHORTLIST: "vendorRequest/RESET_VENDOR_TO_JOBS_SHORTLIST",
  SET_JOB_SHORTLIST_BANNER: "vendorRequest/SET_JOB_SHORTLIST_BANNER",
  SET_CURR_JOB_TO_JOBS_SHORTLIST: "vendorRequest/SET_CURR_JOB_TO_JOBS_SHORTLIST",
  SET_HOW_TO_USE_JOBS_SHORTLIST: "vendorRequest/SET_HOW_TO_USE_JOBS_SHORTLIST",
  APPROVE_PQR_REQUESTED: "vendorRequest/APPROVE_PQR_REQUESTED",
  APPROVE_PQR_SUCCESS: "vendorRequest/APPROVE_PQR_SUCCESS",
  APPROVE_PQR_FAILURE: "vendorRequest/APPROVE_PQR_FAILURE"

};
