import React from "react";
import { createGlobalStyle } from "styled-components";
import { globalStyles } from "styles/Theme";
import routes from "./routes/Routes.jsx";
import "./styles/scss/global.css";

import ReactGA from "react-ga";
// import { createBrowserHistory } from "history";

export const GlobalStyle = createGlobalStyle`.
  html {
    font-size:${(props) => props.theme.baseFontSize};
  }
  body {
    font-family:${(props) => props.theme.fontFamily};
    background-color:${(props) => props.theme.bodyBG};
    color: ${(props) => props.theme.baseFontColor};

    & .ant-popover-inner {
      border-radius: 0px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  }
  ::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    width: 8px;
    height: 30%;
    background: #d5d9ec;
    border-radius: 10px; /* color of the scroll thumb */
    /* roundness of the scroll thumb */
    /* creates padding around scroll thumb */
    padding-bottom:60px;
  }
`;

// const history = createBrowserHistory();
const url = window.location.href;
const script = document.createElement("script");
let userId;
if (localStorage.userData) {
  const localStorageUserData = JSON.parse(localStorage.userData);

  if (localStorageUserData?.userData?._id) {
    userId = localStorageUserData.userData?._id;
  } else {
    if (sessionStorage?.userData) {
      const sessionStorageUserData = JSON.parse(sessionStorage.userData);
      userId = sessionStorageUserData?.userData?._id;
    }
  }
}

//if staging url
if (url.match("client")) {
  //Universal Google Analytics
  ReactGA.initialize("UA-196207863-1");
  //GA4
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-3M2SY66WTW";
} else {
  // dev
  //Universal Google Analytics
  ReactGA.initialize("UA-196207863-2");
  //GA4
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-6GDHPBLRMB";
}
script.async = true;
document.body.appendChild(script);
if (userId) {
  ReactGA.set("userId", userId);
  window.ga("set", "userId", userId);
}

// Initialize google analytics page view tracking
// history.listen(location => {
//   ReactGA.set({ page: location.pathname }); // Update the user's current page
//   ReactGA.pageview(location.pathname); // Record a pageview for the given page
// });
const App = () => (
  <div>
    <GlobalStyle theme={globalStyles} />
    {routes}
  </div>
);

export default App;
