const ApiConstant = {
  STORAGE_KEYS: {
    userData: "userData",
    isRememberEnabled: "isRememberEnabled",
    applicationFlags: "applicationFlags",
    userEmail: "userEmail",
    userPhone: "userPhone",
    backButtonLabel: "backButtonLabel",
    showBackButton: "showBackButton",
    parentEventId: "parentEventId",
    initiatedChatId: "initiatedChatId",
    isSubsequentUser: "isSubsequentUser",
    yoeFilterApplied: "yoeFilterApplied",
    isFirstCompanyUser: "isFirstCompanyUser",
    filterOptions: "filterOptions", // selected filters in vendor listing page
    selectedLocationFilterOption: "selectedLocationFilterOption", //selected tab/button in vendorlisting page for location serevd filter
    vendorListingPageOffset: "vendorListingPageOffset",
    prevFilterOptions: "prevFilterOptions",
    comingFromVendorProfilePage: "comingFromVendorProfilePage",
    vendorProfliePage: "vendorProfliePage",
    prevUrl: "prevUrl",
    vendorViewUrl: "vendorViewUrl",
    routingTo: "routingTo",
    plantVisitPageEntry: "plantVisitPageEntry",
    requestAction: "requestAction",
    searchKeyData: "searchKeyData",
    guestRedirection: "guestRedirection",
    compareVendorLink: "compareVendorLink",
    clientVendorViewVisitedTab: "clientVendorViewVisitedTab",
    exploreVendorsCategory: "exploreVendorsCategory",
    advanceSearch: "advanceSearch",
    tempRegistrationDetails: "tempRegistrationDetails",
    tempPlantInfo: "tempPlantInfo",
    feedbackPopupMin: "feedbackPopupMin",
    feedbackPopupSec: "feedbackPopupSec",
    algoliaAnalyticsQueryId: "algoliaAnalyticsQueryId",
    clickedVendorPositionInVendorList: "clickedVendorPositionInVendorList",
    compareVendorSource: "compareVendorSource", // compare vendor source can either be click or link
    compareVendorParentEventId: "compareVendorParentEventId",
    inviteId: "inviteId",
    vendorCategoriesExploreText: "Explore",
    subCategoryIdFromVenwizLandingPage: "subCategoryIdFromVenwizLandingPage",
    subcategoryId: "subcategoryId",
    showVendorRequestModal: "showVendorRequestModal",
    jobId: "jobId",
    jobRequestId: "jobRequestId",
    resetPwdToken: "resetPwdToken",
    jobShortlistBanner: "jobShortlistBanner",
    jobShortlist: "jobShortlist",
    /** active page-number in vendor-listing pagination */
    pageNumToDisplay: "pageNumToDisplay",
    /** is vendor-list already viewed by user */
    isVendorListAlreadyRendered: "isVendorListAlreadyRendered",
    /** user's id saved when coming via password-reset-link */
    userIdForPassResetLink: "userIdForPassResetLink",
    // Show/Hide - Modal : To handle back when comes from modal popups
    isAboutCompanyModalOpened: "isAboutCompanyModalOpened",
    /** previous selected turn over slab name */
    previousSelectedTurnOverSlabName: "previousSelectedTurnOverSlabName",
    /** store URL params of submit-review page */
    submitReviewUrlParams: "submitReviewUrlParams",
    /** store page-response after submitting review */
    submittedReviewResponse: "submittedReviewResponse",
  },
};

export default ApiConstant;
