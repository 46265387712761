import styled from "styled-components";
import { COLORS } from "../package.styles";
import { Flex } from "../styled-system/primitives";
import { Button } from "../Button";
import { StylesProps } from "../styled-system/types";

export const ButtonWraper = styled(Button)`
  font-weight: 600;
`;

export const DEFAULT_STYLES: { [key: string]: StylesProps } = {
  accordionHeader: {
    width: "100%",
    border: "none",
    textAlign: "left",
    color: "#011759",
    fontSize: "0.875rem",
    padding: "0rem",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    height: "2.5rem", //40px
    position: "relative",
    background: "none",
    justifyContent: "flex-start",
  },

  accordionIcon: {
    position: "absolute",
    right: "1.25rem",
    top: "0.625rem",
  },
};
