import * as CSS from "csstype";
import { getAppliedCssValues, getCSSOnlyStringValueFromCSSProperties } from "./helpers";
import { SpaceProps, PartialCSSPropertiesHyphen } from "../types";

export const space = (props: SpaceProps) => {
  const getCSSValue = (cssPropertiesArray: string[]) =>
    getCSSOnlyStringValueFromCSSProperties(props, cssPropertiesArray);

  const cssObject: PartialCSSPropertiesHyphen = {
    // margin values
    margin: getCSSValue(["m", "margin"]) as CSS.MarginProperty<string | 0>,
    "margin-top": getCSSValue(["mt", "marginTop"]) as CSS.MarginTopProperty<string | 0>,
    "margin-right": getCSSValue(["mr", "marginRight"]) as CSS.MarginRightProperty<string | 0>,
    "margin-bottom": getCSSValue(["mb", "marginBottom"]) as CSS.MarginBottomProperty<string | 0>,
    "margin-left": getCSSValue(["ml", "marginLeft"]) as CSS.MarginLeftProperty<string | 0>,

    // padding values
    padding: getCSSValue(["p", "padding"]) as CSS.PaddingProperty<string | 0>,
    "padding-top": getCSSValue(["pt", "paddingTop"]) as CSS.PaddingTopProperty<string | 0>,
    "padding-right": getCSSValue(["pr", "paddingRight"]) as CSS.PaddingRightProperty<string | 0>,
    "padding-bottom": getCSSValue(["pb", "paddingBottom"]) as CSS.PaddingBottomProperty<string | 0>,
    "padding-left": getCSSValue(["pl", "paddingLeft"]) as CSS.PaddingLeftProperty<string | 0>,
  };

  return getAppliedCssValues(cssObject);
};
