import { Types as CompareVendorsTypes } from "_types/CompareVendorsTypes";

export const ActionCreators: any = {
  getCompareVendorsData: (data: any) => ({
    types: [
      CompareVendorsTypes.USER_DATA_REQUESTED,
      CompareVendorsTypes.USER_DATA_SUCCESS,
      CompareVendorsTypes.USER_DATA_FAILURE,
    ],
    promise: (client: any) => client.post("compare_vendor_data", { data }),
  }),
  shareableLink: (data: any) => ({
    types: [CompareVendorsTypes.SHAREABLE_LINK_SUCCESS,
      CompareVendorsTypes.SHAREABLE_LINK_SUCCESS,
      CompareVendorsTypes.SHAREABLE_LINK_SUCCESS,
    ],
    promise: (client: any) => client.post("share_compare_vendors_link", { data }),
  }),
  storeGstn: (data: any) => ({
    type: CompareVendorsTypes.STORE_GSTN,
    payload: data,
  }),
};

export default ActionCreators;
