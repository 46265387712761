import ApiConstant from "constants/ApiConstant";
import { getUniqueNames } from "vendorList/components/Filter/filter.helper";
import SessionStorage from "_services/SessionStorage";
import { Types as VendorListingTypes } from "_types/VendorListingTypes";

export const initialState = {
  displayFeedbackPopup: false,
  loading: true,
  vendorData: [],
  totalVendors: "",
  fileData: null,
  vendorRequestData: null,
  /** used to show toast for relevant results in vendor-list */
  hitLowerLimit: false,
  filters: [],
  facets: {
    registered_state: {} as { [key: string]: number },
    registered_city: {} as { [key: string]: number },
    equipFamily: {} as { [key: string]: number },
    serviceGroup: {} as { [key: string]: number },
    fabricationProcess: {} as { [key: string]: number },
    fabricationMaterials: {} as { [key: string]: number },
    fabricationProducts: {} as { [key: string]: number },
  },
  /** is populated/fetched only once - via useEffect in Filters.tsx */
  registeredVendorLocationsAcrossIndia: {
    /** state_Name_1: [cityName_1, ciyName_2] */
  } as { [key: string]: string[] },
  /** is used to display filter options for - Registered In tab*/
  availableRegisteredLocations: {
    /** contains available registered state_name & city_name mappings
     * state_Name_1: [cityName_1, ciyName_2] */
  } as { [key: string]: string[] },
  resetSearch: true,
  didYouMean: {},
  filterOptions: {
    search: "",
    subcategoryId: "",
    discipline: "",
    industries: [] as any[],
    natureOfWork: [] as any[],
    serviceGroup: [] as string[],
    equipFamily: [] as string[],
    fabricationProcess: [] as string[],
    fabricationMaterials: [] as string[],
    fabricationProducts: [] as string[],
    /** array of string(serving states) */
    location: [] as string[],
    /** selected states and cities from registered-locations sections */
    selectedRegisteredLocations: {
      /** contains all selected registered state_names & city_name
       * state_Name_1: [cityName_1, ciyName_2]
       */
    } as { [key: string]: string[] },
    turnOverSlab: [] as string[],
    min: 0,
    max: 200,
    aff: "open",
  },

  pageValues: {
    offset: 1,
    limit: 5,
    totalRecords: 0,
  },

  groupList: {
    loading: false,
    group: [],
    error: "",
  },

  groupSelected: {
    loading: false,
    error: "",
  },

  shortlist: {
    loading: false,
    data: [],
    total: 0,
    error: "",
  },

  shortlistRename: {
    message: "",
    error: "",
  },

  shortlistDelete: {
    message: "message",
    error: "error",
  },
  shortlistCard: {
    deleted: false,
  },

  vendorRequest: {
    loading: false,
    data: [],
    error: "",
    selectedRequest: "",
  },
  successMessage: "",

  jobsList: {
    loading: false,
    data: [],
    vendor: {},
    currJob: "",
    showHowToUse: false,
  },
  jobShortlistBanner: {
    show: false,
    type: "",
    jobs: [],
  },
  filterName: {
    query: ""
  }
};

export type IVendorListingInitialState = typeof initialState;

export default (state = initialState, action: any) => {
  switch (action.type) {
    case VendorListingTypes.GET_VENDORS_REQUESTED:
      return {
        ...state,
        hitLowerLimit: false,
        vendorData: [],
        pageValues: {
          ...state.pageValues,
          totalRecords: 0,
        },
        loading: true,
      };

    case VendorListingTypes.GET_VENDORS_SUCCESS:
      const availableServedBeforeStateNames = getUniqueNames(
        action?.result?.filterValues?.locations as string[]
      );

      const FACETS: IVendorListingInitialState["facets"] = {
        registered_state: action?.result?.facets?.registered_state || {},
        registered_city: action?.result?.facets?.registered_city || {},
        equipFamily: action?.result?.facets?.equipFamily || {},
        serviceGroup: action?.result?.facets?.serviceGroup || {},
        fabricationProcess: action?.result?.facets?.fabricationProcess || {},
        fabricationMaterials: action?.result?.facets?.fabricationMaterials || {},
        fabricationProducts: action?.result?.facets?.fabricationProducts || {},
      };

      return {
        ...state,
        loading: false,
        vendorData: action.result.vendorProfile,
        totalVendors: action.result.totalVendors,
        filters: {
          ...action?.result?.filterValues,
          /** Unique string(stateName) without empty-space as the starting character */
          locations: availableServedBeforeStateNames,
        },
        facets: FACETS,
        hitLowerLimit: action?.result?.hitLowerLimit || false,
        didYouMean: action.result.didYouMean,
        resetSearch: false,
        pageValues: {
          ...state.pageValues,
          totalRecords: action.result.totalVendors,
        },
        error: "",
      };

    case VendorListingTypes.GET_VENDORS_FAILURE:
      return {
        ...state,
        hitLowerLimit: false,
        loading: false,
        error: "",
      };

    case VendorListingTypes.SET_FILTER_OPTIONS:
      const { 
        offset: offsetFromPayload,
        limit: limitFromPayload,
        totalRecords: totalRecordsFromPayload 
      } = action?.payload || {};

      return {
        ...state,
        filterOptions: {
          ...action.payload,
          search: action.payload.search,
          subcategoryId: action.payload.subcategoryId,
          discipline: action.payload.discipline,
          industries: action.payload.industries,
          natureOfWork: action.payload.natureOfWork,
          min: action.payload.min,
          max: action.payload.max,
          location: action.payload.location,
          turnOverSlab: action.payload.turnOverSlab,
          aff: action.payload.aff,
          serviceGroup: action.payload.serviceGroup,
          equipFamily: action.payload.equipFamily,
        },
        pageValues: {
          ...state.pageValues,
          offset: offsetFromPayload // possibly NAN
            || state?.pageValues?.offset,
          limit: limitFromPayload // possibly NAN
            || state?.pageValues?.limit,
          totalRecords: totalRecordsFromPayload || state?.pageValues?.totalRecords,
        },
      };

    case VendorListingTypes.GET_REGISTERED_VENDOR_LOCATIONS_ACROSS_INDIA_REQUESTED:
      return { ...state, loading: true };

    case VendorListingTypes.GET_REGISTERED_VENDOR_LOCATIONS_ACROSS_INDIA_SUCCESS:
      return { ...state, loading: false, registeredVendorLocationsAcrossIndia: action.payload };

    case VendorListingTypes.GET_REGISTERED_VENDOR_LOCATIONS_ACROSS_INDIA_FAILURE:
      return { ...state, loading: false };

    case VendorListingTypes.SET_AVAILABLE_REGISTERED_LOCATIONS_OPTIONS:
      return { ...state, availableRegisteredLocations: action.payload };

    case VendorListingTypes.GET_VENDORS_SHORTLIST_REQUESTED:
      return {
        ...state,
        groupList: {
          loading: true,
          group: [],
          error: "",
        },
      };

    case VendorListingTypes.GET_VENDORS_SHORTLIST_SUCCESS:
      const arrangeGroups = () => {
        let groups = action.result.groups;
        action.result.groups.forEach((item: any, index: number) => {
          if (item.groupName === "Default") {
            groups.splice(index, 1);
            groups = [item, ...groups];
          }
        });
        return groups;
      };
      return {
        ...state,
        groupList: {
          loading: false,
          group: arrangeGroups(),
          error: "",
        },
      };

    case VendorListingTypes.GET_VENDORS_SHORTLIST_FAILURE:
      return {
        ...state,
        groupList: {
          ...state.groupList,
          loading: false,
          group: [],
          error: "",
        },
      };

    case VendorListingTypes.SET_VENDORS_SHORTLIST_REQUESTED:
      return {
        ...state,
        groupSelected: {
          loading: true,
          error: "some error",
        },
      };

    case VendorListingTypes.SET_VENDORS_SHORTLIST_SUCCESS:
      return {
        ...state,
        groupSelected: {
          loading: false,
          error: "",
        },
      };

    case VendorListingTypes.SET_VENDORS_SHORTLIST_FAILURE:
      return {
        ...state,
        groupSelected: {
          loading: false,
          error: "fail",
        },
      };
    //--------------------------------------------------------------------//
    case VendorListingTypes.GET_SHORTLIST_DETAILS_REQUESTED:
      return {
        ...state,
        shortlist: {
          loading: true,
          data: [],
          total: 0,
          error: "",
        },
      };
    case VendorListingTypes.GET_SHORTLIST_DETAILS_SUCCESS:
      const arrangeData = () => {
        let shortlist = action.result.data;
        action.result.data.forEach((item: any, index: number) => {
          if (item.groupName === "Default") {
            shortlist.splice(index, 1);
            shortlist.push(item);
          }
        });

        return shortlist;
      };
      return {
        ...state,
        shortlist: {
          loading: false,
          data: arrangeData(),
          total: action.result.total,
          error: "",
        },
      };
    case VendorListingTypes.GET_SHORTLIST_DETAILS_FAILURE:
      return {
        ...state,
        shortlist: {
          loading: false,
          data: [],
          total: 0,
          error: "",
        },
      };

    case VendorListingTypes.DELETE_SHORTLIST_GROUP_REQUESTED:
      return {
        ...state,
        shortlistDelete: {
          message: "",
          error: "error",
        },
      };
    case VendorListingTypes.DELETE_SHORTLIST_GROUP_SUCCESS:
      return {
        ...state,
        shortlistDelete: {
          message: action.result.message,
          error: "",
        },
      };
    case VendorListingTypes.DELETE_SHORTLIST_GROUP_FAILURE:
      return {
        ...state,
        shortlistDelete: {
          message: "",
          error: "error",
        },
      };

    //--------------------------------------------------------------------//
    case VendorListingTypes.SET_FILE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case VendorListingTypes.SET_SEARCH_BY_NAME:
      return {
        ...state,
        loading: true,
        filterName: action?.payload
      };

    case VendorListingTypes.SET_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        fileData: action.result,
        error: "",
      };

    case VendorListingTypes.SET_FILE__FAILURE:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case VendorListingTypes.POST_VENDOR_REQUEST_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case VendorListingTypes.POST_VENDOR_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorRequestData: action.result?.data,
        error: "",
      };

    case VendorListingTypes.POST_VENDOR_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case VendorListingTypes.GET_VENDOR_REQUEST_REQUESTED:
      return {
        ...state,
        vendorRequest: {
          loading: true,
          data: [],
          error: "",
        },
      };

    case VendorListingTypes.GET_VENDOR_REQUEST_SUCCESS:
      return {
        ...state,
        vendorRequest: {
          loading: false,
          data: action.result.data,
          error: "",
        },
      };

    case VendorListingTypes.GET_VENDOR_REQUEST_FAILURE:
      return {
        ...state,
        vendorRequest: {
          ...state.vendorRequest,
          loading: false,
          data: [],
          error: "failure",
        },
      };

    case VendorListingTypes.RESET_VENDOR_LIST:
      return {
        ...state,
        vendorData: [],
        resetSearch: true,
        autoCorrection: {},
        hitLowerLimit: false,
      };

    case VendorListingTypes.RESET_SEARCH_BY_NAME:
      return {
        ...state,
        filterName: {},
        hitLowerLimit: false,
      };

    case VendorListingTypes.GET_VENDOR_REQUEST_SUGGESTIONS_REQUESTED:
      return {
        ...state,
        vendorRequest: { ...state.vendorRequest, loading: true },
      };
    case VendorListingTypes.GET_VENDOR_REQUEST_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        vendorRequest: {
          ...state.vendorRequest,
          data: action.result,
          loading: false,
        },
        filters: action.result?.filterValues,
        pageValues: {
          ...state.pageValues,
          totalRecords: action.result.totalVendors,
        },
      };
    case VendorListingTypes.GET_VENDOR_REQUEST_SUGGESTIONS_FAILURE:
      return {
        ...state,
        vendorRequest: { ...state.vendorRequest, loading: false },
        error: action.error.error,
      };
    case VendorListingTypes.RESET_SEARCH_RESET:
      return {
        ...state,
        resetSearch: true,
      };
    case VendorListingTypes.SET_SELECTED_REQUEST:
      return {
        ...state,
        vendorRequest: {
          ...state.vendorRequest,
          selectedRequest: action.payload,
        },
      };
    case VendorListingTypes.SHOW_SEARCH_FEEDBACK_POP_UP:
      return {
        ...state,
        displayFeedbackPopup: action.payload,
      };
    case VendorListingTypes.REQUEST_VR_ADDITION_TO_A_PROJECT_SUCCESS:
      return {
        ...state,
        vendorRequestData: action.result,
        successMessage: action.result?.message,
      };
    case VendorListingTypes.RESET_ERROR_AND_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: "",
        error: "",
      };
    case VendorListingTypes.VENDOR_REQUEST_FAILURE:
      return {
        ...state,
        error: action.error.message,
      };
    case VendorListingTypes.VENDOR_REQUEST_REQUESTED:
      return {
        ...state,
      };
    case VendorListingTypes.RESET_VENDOR_REQUEST:
      return {
        ...state,
        vendorRequest: {
          loading: false,
          data: [],
          error: "",
          selectedRequest: "",
        },
        vendorRequestData: null,
      };

    case VendorListingTypes.GET_JOBS_TO_SHORTLIST_VENDOR_REQUESTED:
      return {
        ...state,
        jobsList: {
          ...state.jobsList,
          loading: true,
          data: [],
        },
      };
    case VendorListingTypes.GET_JOBS_TO_SHORTLIST_VENDOR_SUCCESS:
      return {
        ...state,
        jobsList: {
          ...state.jobsList,
          loading: false,
          data: action.result.data,
        },
      };
    case VendorListingTypes.GET_JOBS_TO_SHORTLIST_VENDOR_FAILURE:
      return {
        ...state,
        jobsList: {
          ...state.jobsList,
          loading: false,
          data: [],
        },
      };
    case VendorListingTypes.ADD_VENDOR_TO_SHORTLISTED_JOBS_REQUESTED:
      return {
        ...state,
        jobsList: { ...state.jobsList, loading: true },
      };
    case VendorListingTypes.ADD_VENDOR_TO_SHORTLISTED_JOBS_SUCCESS:
      return {
        ...state,
        jobsList: { ...state.jobsList, loading: false },
      };
    case VendorListingTypes.ADD_VENDOR_TO_SHORTLISTED_JOBS_FAILURE:
      return {
        ...state,
        jobsList: { ...state.jobsList, loading: false },
      };
    case VendorListingTypes.SET_VENDOR_TO_JOBS_SHORTLIST:
      return {
        ...state,
        jobsList: { ...state.jobsList, vendor: action.payload },
      };
    case VendorListingTypes.APPROVE_PQR_REQUESTED:
      return {
        ...state,
      };
    case VendorListingTypes.APPROVE_PQR_SUCCESS:
      return {
        ...state,
      };
    case VendorListingTypes.APPROVE_PQR_FAILURE:
      return {
        ...state,
      };
    case VendorListingTypes.SET_JOB_SHORTLIST_BANNER:
      return {
        ...state,
        jobShortlistBanner: action.payload || { show: false, type: "", jobs: [] },
      };
    case VendorListingTypes.SET_CURR_JOB_TO_JOBS_SHORTLIST:
      return {
        ...state,
        jobsList: { ...state.jobsList, currJob: action.payload },
      };
    case VendorListingTypes.SET_HOW_TO_USE_JOBS_SHORTLIST:
      return {
        ...state,
        jobsList: { ...state.jobsList, showHowToUse: action.payload },
      };
    default:
      return state;
  }
};
