type Args = { minutes: number; seconds: number };

export const setOTPTimerInSession = ({ minutes, seconds }: Args) => {
  sessionStorage.setItem("min", `${minutes}`);
  sessionStorage.setItem("sec", `${seconds}`);
};

/** show resend otp button after 2 minutes have passed */
export const whetherToShowResendOTPButton = ({ minutes, seconds }: Args) => {
  return minutes * 60 + seconds < 180;
};
