import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ApiConstant from "constants/ApiConstant";
import LocalStorage from "_services/LocalStorage";
import { initializeHeapAnalytics, initializeHeapAnalyticsOnlyForNonInternalVenwizUser } from "shared/helpers/heapAnalytics.helper";

const unAuthRoutes = [
  "/", 
  "/login", 
  "/comparison-report",
  "/submit-review",
];

const Main = ({ children }) => {
  const history = useHistory();
  const userData = LocalStorage.get(ApiConstant.STORAGE_KEYS.userData)?.userData;

  /** For Heap Analytics */
  useEffect(() => {
    /**
     * For a logged in user:
     * initialize heap analytics - after page is refreshed,
     * by calling heap.load function
     * reference: https://developers.heap.io/docs/web#base-installation
     */
    if (userData?.email) {
      initializeHeapAnalyticsOnlyForNonInternalVenwizUser(userData?.email , window);
    } else {
      /** for Guest Users */
      if (!unAuthRoutes?.includes(window.location.pathname)) {
        /** initialize heap at all public routes except list present within unAuthRoutes */
        initializeHeapAnalytics(window);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (
      history.location.pathname.includes(unAuthRoutes) &&
      LocalStorage.get(ApiConstant.STORAGE_KEYS.isRememberEnabled) &&
      LocalStorage.get(ApiConstant.STORAGE_KEYS.userData).access_token
    ) {
      history.push("/dashboard");
    }
  }, []);

  return <>{children}</>;
};

export default Main;
