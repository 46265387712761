import React from 'react';
import styled from 'styled-components';
import Button, { ButtonProps as AntButtonProps } from '../Button';

export interface ButtonProps extends AntButtonProps {
  iconClass: string,
  iconPlacement: 'left' | 'right'
}

const Icon = styled.i<Partial<ButtonProps>>`
  margin-right: ${(props) => `${props.iconPlacement === 'left' ? '1rem' : 0}`};
  margin-left: ${(props) => `${props.iconPlacement === 'right' ? '1rem' : 0}`};
  font-size: ${(props) => `${props.theme.button.sizes[props.size || 'regular'].iconSize}`};
  &:before {
    color: ${(props) => `${props.theme.button.color}`};
  }
`;

const IconButton = (props: ButtonProps) => {
  const {
    iconClass,
    iconPlacement,
    children,
    ...rest
  } = props;
  return (
    <Button {...props}>
      {iconPlacement === 'left' && <Icon className={iconClass} iconPlacement="left" {...rest} />}
      {children}
      {iconPlacement === 'right' && <Icon className={iconClass} iconPlacement="right" {...rest} />}
    </Button>
  );
};

export default IconButton;
