export const lowerCaseStringWithHyphens = (str: string) => {
   return str.split(" ").join("-").toLowerCase();
};

export const areAllStringsFromSourceArrayInTargetArray = ({ sourceArray, targetArray }: { 
   sourceArray: string[], targetArray: string[] 
}) => {
   if (
      !sourceArray 
      || !targetArray 
      || sourceArray?.length === 0 
      || targetArray?.length === 0
   ) return false;
   return sourceArray?.every((item) => targetArray?.includes(item));
};
