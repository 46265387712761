import React, { useEffect } from "react";
import styled from "styled-components";
import OtpInput from "react-otp-input";
import { DEVICE } from "constants/mediaQueries.constants";
import { useMediaQuery } from "hooks/useMediaQuery.hook";
import { Flex } from "components/package/styled-system/primitives";
import { Typography } from "components/package";
import { COLORS } from "components/package/package.styles";
import { INITIAL_OTP_STATE } from "./otpBox.constants";
import { setOTPTimerInSession } from "./otp.handler";
import { useDispatch, useSelector } from "react-redux";
import { AuthenticationActions } from "authentication/actions";
import { RootState } from "store/reducers";

interface OtpProps {
  renderResendOTPButton?: boolean;
  title?: string | React.ReactNode;
  customResendOTPButton?: string | React.ReactNode;
  initialOTPState: typeof INITIAL_OTP_STATE;
  handleResendOTP?: () => void;
  setInitialOTPState: React.Dispatch<React.SetStateAction<typeof INITIAL_OTP_STATE>>;
}

const OtpInputWrap = styled(OtpInput)`
  color: ${props => props.theme.colors.primary};
  font-size: 22px;
`;

const OtpBox = (props: OtpProps) => {
  const {
    title,
    setInitialOTPState,
    renderResendOTPButton,
    customResendOTPButton,
    initialOTPState: { seconds, minutes },
  } = props;

  const dispatch = useDispatch();
  const { otpError } = useSelector((state: RootState) => state.AuthenticationReducers);
  const isScreenWidthMoreThan640px = useMediaQuery(DEVICE.sm);
  const isScreenWidthMoreThan768px = useMediaQuery(DEVICE.md);
  const isScreenWidthMoreThan1024px = useMediaQuery(DEVICE.lg);
  const OTP_INPUT_BOX_SIZE = isScreenWidthMoreThan640px 
    ? "3.7rem" // 60px
    : isScreenWidthMoreThan768px 
      ? "2.75rem" // 44px
      : "2.75rem"
    ;
  
  const isOTPTimerExpired = seconds === 0 && minutes === 0;

  const handleOnChangeOTPValue = (e: any) => {
    props.setInitialOTPState(prev => ({ ...prev, otpValue: e }));
    dispatch(AuthenticationActions.setOTPBoxErrorState(false));
  };

  const handleResetOTPTimer = () => {
    setInitialOTPState(INITIAL_OTP_STATE);
    if (props?.handleResendOTP) props?.handleResendOTP();
  };

  setOTPTimerInSession({ minutes, seconds });

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) setInitialOTPState(prev => ({ ...prev, seconds: prev.seconds - 1 || 0 }));

      if (seconds === 0) {
        if (minutes === 0) {
          setInitialOTPState(prev => ({ ...prev, isInputDisabled: true, otpValue: "" }));
          clearInterval(myInterval);
        } else {
          setInitialOTPState(prev => ({ ...prev, minutes: prev.minutes - 1 || 0, seconds: 59 }));
        }
      }
    }, 1000);

    return () => clearInterval(myInterval);
  }, [minutes, seconds]);

  return (
    <div>
      <Typography
        color={COLORS.black_2}
        fontWeight={400}
        fontSize={"0.875rem"} // 14px
      >
        {title}
      </Typography>
      <Flex alignItems={"center"} mt={"1rem"}>
        <OtpInputWrap
          value={props?.initialOTPState?.otpValue || ""}
          onChange={handleOnChangeOTPValue}
          isDisabled={props?.initialOTPState?.isInputDisabled}
          numInputs={6}
          isInputNum
          containerStyle={{ width: "100%", justifyContent: "space-between" }}
          inputStyle={{
            width: OTP_INPUT_BOX_SIZE,
            height: OTP_INPUT_BOX_SIZE,
            "border-radius": "6px",
            "margin-right": "0.25rem",
            border: otpError ? "1px solid #BE000B" : "1px solid #D5D9EC",
          }}
        />
      </Flex>
      <Flex
        mt={"0.5rem"} // 8px
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {isOTPTimerExpired ? (
          <Typography color={"#be000b"} fontSize={{ default: "0.8rem", xxs: "0.8rem", xs: "1rem" }}>
            OTP Expired
          </Typography>
        ) : (
          <Typography display={"flex"} fontWeight={400} color={COLORS.black_2} w={"190px"}>
            OTP is valid for
            <Typography fontWeight={700} ml={"0.5rem"}>
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </Typography>
          </Typography>
        )}
        {otpError && !isOTPTimerExpired && (
          <Typography color={"#be000b"} fontSize={{ default: "0.8rem", xxs: "0.8rem", xs: "1rem" }}>
            {"Incorrect OTP"}
          </Typography>
        )}
        <>
          {(renderResendOTPButton || isOTPTimerExpired) && (
            <>
              {customResendOTPButton || (
                <Typography
                  ml={"0.5rem"}
                  fontWeight={600}
                  cursor={"pointer"}
                  color={COLORS.blue_1}
                  onClick={handleResetOTPTimer}
                  fontSize={{ default: "0.8rem", xxs: "0.8rem", xs: "1rem" }}
                >
                  Resend OTP
                </Typography>
              )}
            </>
          )}
        </>
      </Flex>
    </div>
  );
};
export default OtpBox;
