import React from "react";

export const PrivacyPolicyContent = () => {
  return (
    <>
      <p>
        <br />
        Venwiz Technologies Private Limited (hereinafter referred to as "we", "our", "us" and &ldquo;Venwiz&rdquo; and
        shall include any affiliates) understands the privileged position we are in while providing the services through
        our websites venwiz.com, client.venwiz.com, vendor.venwiz.com and tools.venwiz.com (collectively referred to as
        &ldquo;
        <strong>Platform</strong>&rdquo;) and are committed to protecting the information and data shared by all of our
        users (hereinafter referred to as &ldquo;you&rdquo;, &ldquo;yourself&rdquo; and &ldquo;User&rdquo;). In light of
        the same, we have created this privacy policy (&ldquo;
        <strong>Privacy Policy</strong>&rdquo;) which is in compliance with the Information Technology Act, 2000
        (&ldquo;<strong>IT Act</strong>
        &rdquo;), Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal
        Information) Rules, 2011 (the "<strong>SPI Rules</strong>") and Information Technology (Intermediary Guidelines
        and Digital Media Ethics Code) Rules, 2021. The privacy mechanism outlined in this Privacy Policy, as amended
        from time to time, identify personally identifiable or personal information that may be collected, how such
        information is used, and the choices you have regarding our use of this information.
        <br />
        <br />
        Please read this Privacy Policy carefully as by accessing or using the Platform, you agree to be bound by the
        terms described herein and all the terms incorporated by reference. Any information provided to us is subject to
        this Privacy Policy. If you do not agree to any or all of these terms, do not use the Platform. Should you
        continue to access and use the Platform and provide your personal information, such act would constitute your
        consent to the terms contained herein.
        <br />
        All capitalized terms shall have the meaning ascribed to them in the Terms of Use unless otherwise defined
        herein. <br />
        <br />
        <p>
          <br />
          <span style={{ textDecoration: "underline" }}>
            <strong>Your Consent</strong>
          </span>
          <br />
        </p>
      </p>
      <ol>
        <li>
          A prerequisite for obtaining access to the Platform and usage of the services provided on the Platform is the
          acceptance of this Privacy Policy. This Privacy Policy shall apply to all individuals and entities that use
          the Platform. You grant your consent to be bound by the terms of this Privacy Policy by:
        </li>
        <ol>
          <li>
            accessing the Platform or registering on the Platform in the manner as provided for in the Terms &amp;
            Conditions;
          </li>
          <li>submitting any information to Venwiz;</li>
          <li>using the features and services offered by Venwiz on the Platform;</li>
        </ol>
        <li>
          As part of providing our services to you, we may need to send routine communications to you, such as order
          transaction emails and other administrative messages through email and notifications posted on the Platform or
          through other means available, including text and other forms of messaging. Though we prefer to communicate
          with the users via email and SMS, as may be deemed necessary, you agree to receive our phone calls, or any
          other mode of communication as may be deemed appropriate.
        </li>
        <li>
          When you register with the Platform you also consent to allowing us to periodically send emails, SMS
          communications, WhatsApp messages or messages through any other means of communication available to Venwiz,
          now or in the future, for relaying information which you may find useful, interesting or in line with your
          interests. If you do not wish to receive promotional information from us, you can, at any time, choose to opt
          out of receiving such promotional communications by following the process mentioned in the promotional
          communication or by contacting us.
        </li>
        <li>
          You must ensure to provide us with the correct or up-to-date information at the time of creation of your
          Account and during the use of the Platform. This will allow us to contact you for further correspondences with
          respect to the services provided on the Platform. We are not responsible for any inaccurate information you
          provide to us, which may affect your experience when using the Platform and the services.
        </li>
        <li>
          If, at any time, you want to access, update, modify or eliminate any information provided by you or which
          forms a part of the account created with Venwiz, you may do so by accessing your account and then changing
          your information as required. You may also withdraw your consent for use of your personal information provided
          and stop using the services for which consent is required. Venwiz reserves the right to stop providing any
          services to which consent has been withdrawn.
        </li>
        <li>
          We may, in future, request other optional information from you in order to customise the Platform to deliver
          personalised service to our users.
        </li>
        <li>
          You may also write to us at care@venwiz.com with a request for updating, modifying, or deleting any
          information shared by you.
        </li>
      </ol>

      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Personal Information</strong>
        </span>
        <br />
      </p>
      <ol>
        <li>
          While availing our services on the Platform you may provide Venwiz with certain personal information which are
          required for performance of the services on the Platform. The term &ldquo;personal information&rdquo; means
          any information that relates to a natural person, which, either directly or indirectly, in combination with
          other information available with Venwiz, is capable of identifying the person concerned and includes amongst
          other things age, phone number, email id, location etc. You may also share certain &ldquo;sensitive personal
          information&rdquo; which included within its ambit personal information of any individual relating to
          financial information such as bank account or credit card or debit card or other payment instrument details.
          Any data that is freely available to the public or made available to Venwiz through public sources or by way
          of right to information will not be considered as sensitive personal information. We do not knowingly collect
          personal information from children, and all information upload by a user shall be deemed to be uploaded in
          compliance of all applicable laws.
        </li>
        <li>
          Venwiz has implemented reasonable security practices and procedures that are commensurate with the information
          assets being protected and with the nature of our business. While we try our best to provide security that is
          commensurate with the industry standards, because of the inherent vulnerabilities of the internet, we cannot
          ensure or warrant complete security of all information that is being transmitted to us by you. However, Venwiz
          will take all reasonable measures to ensure that your information is not modified or used for any unauthorised
          purposes.
        </li>
        <li>
          You have the right to access your Account with Venwiz and edit, modify, or delete any information including
          personal or sensitive personal information provided to Venwiz. When editing or deleting personal information
          shared by you at your request, we may ask you to verify your identity before we can act on your request.
          However, we do not assure that we will delete all residual copies and archives made by any third-party service
          providers for performance of their obligations.
        </li>
        <li>
          During the course of provision of services or registration and creation of your Account with Venwiz the
          following information may be collected from the Users:
        </li>
        <ol>
          <li>Name;</li>
          <li>GST Registration</li>
          <li>Role in the Company</li>
          <li>Email address;</li>
          <li>Mobile phone number</li>
          <li>Address (including country and ZIP/postal code);</li>
          <li>Financial Information (including details of payment instruments);</li>
          <li>Details required for provision of services; and</li>
          <li>Other details as the User may volunteer.</li>
        </ol>
        <li>
          Venwiz may keep records of telephone calls received and made to their personnel for provision of the services
          or for making inquiries, orders, or other reasons for the purpose of administration of services.
        </li>
        <li>
          Venwiz may further keep records of all chats, conversations and information shared on the Platform by the
          Users for the purpose of administration of the services.
        </li>
        <li>
          During the use of the Platform via the internet, we may automatically receive technical information about your
          computer/device, including your IP address, your computer operating system, time-zone, browser type and
          browser plug-in details, due to the communication protocol settings on the Internet. When you browse our
          Platform, we may also collect information regarding the pages you viewed, the web addresses from which you
          arrive or click through to, time spent on certain pages, download errors and page response times. All these
          information help us analyse user's trends and preferences, and thus assist us in improving our services.
        </li>
        <li>
          The Platform may enable users to communicate with other users or third-party service providers to post
          information to be accessed by others, whereupon other users may collect such data. Venwiz hereby expressly
          disclaims any liability for any misuse of such information that is made available by visitors in such a
          manner.
        </li>
        <li>
          Venwiz may include other optional requests for information from the Users with a view to customize the
          Platform to deliver personalized information to the User and for other purposes as mentioned in this Privacy
          Policy. Any such additional personal information will also be processed in accordance with this Privacy
          Policy.
        </li>
        <li>
          Venwiz shall not be responsible in any manner for the authenticity of the information including the personal
          information or sensitive personal information supplied by the User to Venwiz. If a User provides any
          information that is untrue, inaccurate, not current, or incomplete (or becomes untrue, inaccurate, not
          current, or incomplete), or Venwiz has reasonable grounds to suspect that such information is untrue,
          inaccurate, not current, or incomplete, Venwiz has the right to suspend or terminate such account at its sole
          discretion.
        </li>
        <li>
          Venwiz engages certain trusted third parties to perform functions and provide services to us, including,
          without limitation, hosting and maintenance, API integration, data verification, and database storage and
          management. You hereby consent to your information, including your personal information to be shared with
          these third parties. Venwiz shall ensure that these third party who have been given such data shall only use
          the data for the specific purposes for which the data is provided and such data is only shared pursuant to
          binding contractual obligations requiring such third parties to maintain the privacy and security of your
          data.
        </li>
      </ol>

      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Use of personal Information</strong>
        </span>
        <br />
      </p>
      <ol>
        <li>
          Venwiz will collect and retain your personally identifiable information only to the extent that it is
          necessary to fulfil our services to you and for regulatory purposes. Any personal information submitted by you
          on the Platform or collected by us will be used only for the purposes mentioned below:
        </li>
        <ol>
          <li>for registration of the User on the Platform</li>
          <li>to carry out our obligations arising from your requests for services on the Platform;</li>
          <li>To improve the Platform's functionality, and to customise your future experience with us;</li>
          <li>
            To make sure that our Platform&rsquo;s content is presented in the manner that is most effective for you;
          </li>
          <li>To ensure that the transactions made on the Platform are made in the most efficient manner;</li>
          <li>To deliver personalized information and target advertisements to the user;</li>
          <li>For conducting research on efficient administrative practices on the Platform;</li>
          <li>To communicate with you about any changes to our services;</li>
          <li>To verify your identity and perform checks to prevent fraud;</li>
          <li>
            Non-personally identifiable information, exclusively owned by Venwiz may be used in an aggregated or
            non-personally identifiable form for internal research, statistical analysis and business intelligence
            purposes including those for the purposes of determining the number of visitors and transactional details,
            and Venwiz may sell or otherwise transfer such research, statistical or intelligence data in an aggregated
            or non-personally identifiable form to third parties and affiliates;
          </li>
          <li>Dealing with grievances, complaints and queries raised by the users</li>
          <li>Ensuring that the provisions of the Terms of Use and Privacy Policy are complied with by all users. </li>
        </ol>
      </ol>

      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Disclosure of information of Vendors</strong>
        </span>
        <br />
      </p>
      <ol>
        <li>
          Venwiz in the course of performance of the services may need to disclose/ transfer certain information which
          may include personal information to third parties for the purposes mentioned in this Privacy Policy and the
          Terms & Conditions. The third parties to whom such information may be disclosed include:
        </li>
        <ol>
          <li>To the Clients on the Platform, to whom you have chosen to provide services.</li>
          <li>
            To third party service providers for the purpose of ensuring services are available on the Platform and as
            specified in para 2.11;
          </li>
          <li>to our payment service partners and websites;</li>
          <li>
            To our affiliates in India or in other countries who may use and disclose your information for the same
            purposes as us;
          </li>
          <li>
            To our employees for the purpose of ensuring provision of services on the Platform, subject to the fact that
            our employees shall be bound by obligations of confidentiality;
          </li>
          <li>
            To government institutions/ authorities to the extent required i) under the laws, rules, and regulations
            and/ or under orders of any relevant judicial or quasi-judicial authority; ii) for the purposes of
            prevention, investigation and prosecution of cyber incidents; iii) for reporting any offences committed by
            users; iv) to protect and defend the rights or property of Venwiz; v) to fight fraud and credit risk; vi) to
            enforce Venwiz's Terms of Use (to which this Privacy Policy is also a part); vii) for verification of any
            information provided by users; or viii) when Venwiz, in its sole discretion, deems it necessary in order to
            protect its rights or the rights of others;
          </li>
          <li>
            to third party ad servers, ad agencies, technology vendors and research firms to serve advertisements to the
            user;
          </li>
          <li>
            where substantially all our assets are acquired by a third party, such information may be one of the assets
            that is transferred or acquired by a third party. Under such circumstances, Venwiz would attempt to ensure
            that the third party acquiring Venwiz follows the provisions of this Privacy Policy and process your
            personal information as set forth in this Privacy Policy.
          </li>
        </ol>
      </ol>

      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Disclosure of personal information of Clients</strong>
        </span>
        <br />
      </p>
      <ol>
        <li>
          Venwiz in the course of performance of the services may need to disclose/ transfer certain information which
          may include sharing personal information with third parties for the purposes mentioned in this Privacy Policy
          and the Terms & Conditions. The third parties to whom such information may be disclosed include:
        </li>
        <ol>
          <li>To the Vendors on the Platform, whose services you have chosen to avail;</li>
          <li>
            To third party service providers for the purpose of ensuring services are available on the Platform and as
            specified in paragraph 2.11;
          </li>
          <li>to our payment service partners and websites;</li>
          <li>
            To our affiliates in India or in other countries who may use and disclose your information for the same
            purposes as Us;
          </li>
          <li>
            To our employees for the purpose of ensuring provision of services on the Platform, subject to the fact that
            our employees shall be bound by obligations of confidentiality;
          </li>
          <li>
            To government institutions/ authorities to the extent required i) under the laws, rules, and regulations
            and/ or under orders of any relevant judicial or quasi-judicial authority; ii) for the purposes of
            prevention, investigation and prosecution of cyber incidents; iii) for reporting any offences committed by
            users; iv) to protect and defend the rights or property of Venwiz; v) to fight fraud and credit risk; vi) to
            enforce Venwiz's Terms of Use (to which this Privacy Policy is also a part); vii) for verification of any
            information provided by users; or viii) when Venwiz, in its sole discretion, deems it necessary in order to
            protect its rights or the rights of others;
          </li>
          <li>
            to third party ad servers, ad agencies, technology vendors and research firms to serve advertisements to the
            user;
          </li>
          <li>
            where substantially all our assets are acquired by a third party, such information may be one of the assets
            that is transferred or acquired by a third party. Under such circumstances, Venwiz would attempt to ensure
            that the third party acquiring Venwiz follows the provisions of this Privacy Policy and process your
            personal information as set forth in this Privacy Policy.
          </li>
        </ol>
      </ol>

      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>How long will personal information be retained</strong>
        </span>
        <br />
      </p>
      <ol>
        <li>
          Under normal circumstances the personal information collected by Venwiz will only be retained for such periods
          of time as may be required for the provision of goods and services. We may retain the other information
          throughout your use of the Platform and for a period of 3 years thereafter or such other period as may be
          prescribed by applicable laws. Confidentiality of such information shall be maintained during the period of
          retention.
        </li>
        <li>
          Venwiz may be required to retain data in relation to the Users for records which may be required to be
          submitted to the appropriate authorities as and when required. Venwiz shall keep confidential all information
          retained for such record keeping purposes.
        </li>
      </ol>

      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Other Information for Vendor</strong>
        </span>
        <br />
      </p>
      <p>
        In addition to the personal information specified in the previous section, Venwiz may also collect the following
        information for the purpose of registration and creation of an Account on the Platform and for the utilization
        of the Platform by the Vendors:
      </p>
      <ol>
        <li>If the User wishes to register as a Vendor, additional information as provided below may be requested:</li>
        <ol>
          <li>Company/Partnership/LLP Email address;</li>
          <li>Company/Partnership/LLP&rsquo;s charter documents/partnership deed</li>
          <li>Audited financial records</li>
          <li>GST Registration</li>
          <li>Purchase Order Templates</li>
          <li>Licenses and Registrations obtained by Vendor</li>
          <li>List of products and services</li>
          <li>Vendor phone number (including grievance officer contact)</li>
          <li>Information of shareholders or key personnel of the Company/Partnership/LLP</li>
          <li>
            Registered Address and address of manufacturing units, if any (including country and ZIP/postal code);
          </li>
          <li>Details of Entity&rsquo;s business</li>
          <li>MSME registration</li>
          <li>Relevant licenses and compliance certifications</li>
          <li>Details required for provision of provision of services; and</li>
          <li>Other details as the Vendor may volunteer.</li>
        </ol>
      </ol>

      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Other Information for Client</strong>
        </span>
        <br />
      </p>
      <p>
        In addition to the personal information specified in the previous section, Venwiz may also collect the following
        information for the purpose of registration and creation of an Account on the Platform and for the utilization
        of the Platform by the Users:
      </p>
      <ol>
        <li>If the User wishes to register as a Client, additional information as provided below may be requested:</li>
        <ol>
          <li>Company/Partnership/LLP Email address;</li>
          <li>Company/Partnership/LLP’s charter documents/partnership deed</li>
          <li>Audited financial records</li>
          <li>GST Registration</li>
          <li>Factory Licenses</li>
          <li>Factory plans and layout, where applicable</li>
          <li>Licenses and Registrations obtained by Client</li>
          <li>Manufacturing capacity of Client</li>
          <li>List of key products of Client</li>
          <li>Client's contact information (including grievance officer contact)</li>
          <li>Information of shareholders or key personnel of the Company/Partnership/LLP</li>
          <li>
            Registered Address and address of manufacturing units, if any (including country and ZIP/postal code);
          </li>
          <li>Service requirements</li>
          <li>Details required for provision of provision of services; and</li>
          <li>Other details as the Client may volunteer.</li>
        </ol>
      </ol>
      <p>Venwiz shall keep confidential all information retained for such record keeping purposes.</p>

      <p>
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Security Policy</strong>
        </span>
        <br />
      </p>
      <ol>
        <li>
          Venwiz takes careful precaution to protect our Users information from unauthorised access, improper use or
          disclosure or unauthorised modification. To prevent unauthorised access, we have put in place the latest
          industry-standard security technology and procedures to safeguard the information we collect online including
          technical, operational, and physical security controls. Your information is encrypted and is protected with
          the most secure encryption software, which encrypts all information you input. We store your personally
          identifiable information on the computer servers placed in a secure environment. Venwiz also ensures that all
          third-party service providers to whom personal information is provided take similar precautions with the
          information. Even though we have taken significant steps to protect your information and specifically your
          personally identifiable information, no company, including the Platform can fully eliminate security risks
          associated with personal information. We further disclaim any responsibility for any intercepted information
          sent via the internet, and you hereby release us from any and all claims arising out of or related to the use
          of intercepted information in any unauthorized manner.
        </li>
      </ol>

      <p>
        <br />
        <strong>
          <span style={{ textDecoration: "underline" }}>Third Party Links</span>
        </strong>
        <br />
      </p>
      <ol>
        <li>
          The links to third-party advertisements, third party websites or any third-party electronic communication
          services (referred to as &ldquo;Third Party Links&rdquo;) may be provided on the Platform which are operated
          by third parties and are not controlled by, or affiliated to, or associated with Venwiz unless expressly
          specified on the Platform. If you access any such Third-Party Links, we request you review the website&rsquo;s
          privacy policy. We are not responsible for the policies or practices of Third-Party Links.
        </li>
      </ol>

      <p>
        &nbsp;
        <br />
        <span style={{ textDecoration: "underline" }}>
          <strong>Cookies</strong>
        </span>
        <br />
      </p>
      <ol>
        <li>
          The Platform uses temporary cookies to store certain data (that is not sensitive personal data or information)
          that is used by Us for maintenance of the Platform and its features as well as research and development, and
          for administration of Your account. In the course of serving advertisements or optimizing Services, We may
          allow authorized third parties to place or recognize a unique cookie on the Your browser. We do not store
          personally identifiable information in the cookies.
        </li>
      </ol>

      <p>
        <br />
        <strong>
          <span style={{ textDecoration: "underline" }}>Amendment of Privacy Policy</span>
        </strong>
        <br />
      </p>
      <ol>
        <li>
          Venwiz may update or amend this Privacy Policy at any time, with or without advance notice. In the event there
          are significant changes in the way Venwiz treats the User's personal information, Venwiz will display a notice
          on the Platform or send User&rsquo;s an email.
        </li>
        <li>
          Notwithstanding the above, Venwiz shall not be required to notify the users of any changes made to the Privacy
          Policy except sharing a privacy police once a year with all the amendments for the perusal of the users.
          Should you have any concern or reject the changes in the Privacy Policy you can refuse to accept the
          amendments and opt for withdrawing your personal information by writing to the Grievance Officer.
        </li>
      </ol>

      <p>
        <br />
        <strong>
          <span style={{ textDecoration: "underline" }}>Grievance Officer</span>
        </strong>
        <br />
      </p>
      <ol>
        <li>
          If you have any compliant or grievances or concerns with respect to Platform or this Privacy Policy, please
          inform such matters to the designated Grievance Officer as mentioned below in writing or through electronic
          mail:
        </li>
      </ol>
      <p>
        Name: Sandesh Paturi&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
        <br />
        Contact Details: sandesh@venwiz.com&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
        <br />
        Designation: Co-founder
        <br />
        <br />
        <br />
      </p>
    </>
  );
};
