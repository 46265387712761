import * as CSS from "csstype";
import { getAppliedCssValues, getCSSOnlyStringValueFromCSSProperties } from "./helpers";
import { LayoutProps, PartialCSSPropertiesHyphen } from "../types";

export const layout = (props: LayoutProps) => {
  const getCSSValue = (cssPropertiesArray: string[]) =>
    getCSSOnlyStringValueFromCSSProperties(props, cssPropertiesArray);

  const cssObject: PartialCSSPropertiesHyphen = {
    // width
    width: getCSSValue(["w", "width"]) as CSS.WidthProperty<string | 0>,
    "min-width": getCSSValue(["minW", "minWidth"]) as CSS.MinWidthProperty<string | 0>,
    "max-width": getCSSValue(["maxW", "maxWidth"]) as CSS.MaxWidthProperty<string | 0>,
    // height
    height: getCSSValue(["h", "height"]) as CSS.HeightProperty<string | 0>,
    "min-height": getCSSValue(["minH", "minHeight"]) as CSS.MinHeightProperty<string | 0>,
    "max-height": getCSSValue(["maxH", "maxHeight"]) as CSS.MaxHeightProperty<string | 0>,
    // display
    display: getCSSValue(["display"]),
    // vertical align
    "vertical-align": getCSSValue(["verticalAlign"]) as CSS.VerticalAlignProperty<string | 0>,
    // overflow
    overflow: getCSSValue(["overflow"]),
    "overflow-x": getCSSValue(["overflowX"]) as CSS.OverflowXProperty,
    "overflow-y": getCSSValue(["overflowY"]) as CSS.OverflowYProperty,
  };

  return getAppliedCssValues(cssObject);
};
