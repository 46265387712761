import { Types as DashboardTypes } from "_types/DashboardTypes"

const initialState = {
  loading: false,
  data: {},
  error: "",
  suggestedData:{},
  vendorCategories: {}
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case DashboardTypes.GET_SHORTLIST_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case DashboardTypes.GET_SHORTLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result,
        error: "",
      };

    case DashboardTypes.GET_SHORTLIST_FAILURE:
      return {
        ...state,
        loading: false,
      };


    case DashboardTypes.GET_SUGGESTED_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case DashboardTypes.GET_SUGGESTED_SUCCESS:
      return {
        ...state,
        loading: false,
        suggestedData: action.result,
        error: "",
      };

    case DashboardTypes.GET_SUGGESTED_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case DashboardTypes.GET_VENDOR_CATEGORIES_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case DashboardTypes.GET_VENDOR_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorCategories: action.result.categories,
      };

    case DashboardTypes.GET_VENDOR_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};