import React from "react";
import AntButton, { ButtonProps as AntButtonProps } from "antd/es/button";
import { Popover } from "antd";
import styled from "styled-components";
import { Sizes } from "../utils";

export interface ButtonProps extends AntButtonProps {
  onClick: (e: any) => void;
  variant?:
    | "primary"
    | "secondary"
    | "white"
    | "blue"
    | "tertiary"
    | "danger"
    | "red";
  size: Sizes;
  flexible?: boolean;
  color?: string;
  fontWeight?: "light" | "regular" | "medium" | "bold";
  border?: string;
  popover?: string;
}

const ButtonBase = styled(AntButton)<ButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    `${props.theme.colors[props.variant || "primary"]}`};
  border: ${props =>
    `1px solid ${
      props.theme.colors[props.border || props.variant || "primary"]
    }`};
  border-radius: ${props => `${props.theme.button.borderRadius}`};
  color: ${props => `${props.color || "#FFFFFF"}`};
  font-size: ${props =>
    `${props.theme.button.sizes[props.size || "middle"].fontSize}`};
  height: ${props =>
    `${props.theme.button.sizes[props.size || "middle"].height}`};
  line-height: ${props =>
    `${props.theme.button.sizes[props.size || "middle"].lineHeight}`};
  padding: ${props =>
    `${props.theme.button.sizes[props.size || "middle"].padding}`};
  width: ${props => `${props.flexible ? "100%" : "auto"}`};
  font-weight: ${props =>
    `${props.theme.fontWeights[`${props.fontWeight}` || "regular"]}`};

  &:active,
  &:focus {
    background-color: ${props =>
      `${props.theme.button.states[props.variant || "primary"].active}`};
    border-color: ${props =>
      `${props.theme.button.states[props.variant || "primary"].active}`};
    color: ${props => `${props.theme.button.color}`};
  }
  &:hover {
    background-color: ${props =>
      `${props.theme.button.states[props.variant || "primary"].active}`};
    color: ${props => `${props.color || "#FFFFFF"}`};
  }

  &:disabled {
    background-color: ${props =>
      `${props.theme.button.states[props.variant || "primary"].disabled}`};
    border-color: ${props =>
      `${props.theme.button.states[props.variant || "primary"].disabled}`};
    color: ${props => `${props.theme.button.color}`};
  }
`;

const Button = ({ children, popover, ...rest }: ButtonProps) => (
  <>
    {!!popover && (
      <Popover placement="right" content={popover} trigger="click">
        <ButtonBase {...rest}>{children}</ButtonBase>
      </Popover>
    )}
    {!!!popover && <ButtonBase {...rest}>{children}</ButtonBase>}
  </>
);

export default Button;
