export const Types = {
  GET_SHORTLIST_REQUESTED: 'dashboard/GET_SHORTLIST_REQUESTED',
  GET_SHORTLIST_SUCCESS: 'dashboard/GET_SHORTLIST_SUCCESS',
  GET_SHORTLIST_FAILURE: 'dashboard/GET_SHORTLIST_FAILURE',

  GET_SUGGESTED_REQUESTED: 'dashboard/GET_SUGGESTED_REQUESTED',
  GET_SUGGESTED_SUCCESS: 'dashboard/GET_SUGGESTED_SUCCESS',
  GET_SUGGESTED_FAILURE: 'dashboard/GET_SUGGESTED_FAILURE',

  GET_VENDOR_CATEGORIES_REQUESTED: 'dashboard/GET_VENDOR_CATEGORIES_REQUESTED',
  GET_VENDOR_CATEGORIES_FAILURE: 'dashboard/GET_VENDOR_CATEGORIES_FAILURE',

  // new dashboard
  GET_DASHBOARD_DATA_REQUEST: "dashboard/GET_DASHBOARD_DATA_REQUEST",
  GET_DASHBOARD_DATA_FAILURE: "dashboard/GET_DASHBOARD_DATA_FAILURE",

  GET_CLIENT_JOB_SUMMARY_SUCCESS: 'dashboard/GET_CLIENT_JOB_SUMMARY_SUCCESS',
  GET_VENDOR_CATEGORIES_SUCCESS: 'dashboard/GET_VENDOR_CATEGORIES_SUCCESS',

  POST_NEW_JOB_SUCCESS: "dashboard/POST_NEW_JOB_SUCCESS",

  SET_DASHBOARD_ACTIVE_ACCORDION: "dashboard/SET_DASHBOARD_ACTIVE_ACCORDION"
}