import { VENWIZ_INTERNAL_USER_EMAILS } from "constants/venwizInternalUserEmails.constants";

/**
 * Do not initialize Heap Script for retool instance
 */
const isWebAppOpenedViaRetool = (windowObj: typeof window) => { 
  return windowObj?.location?.ancestorOrigins?.[0]?.toLocaleLowerCase()?.includes("retool");
};

/**
 * before calling another heap function:
 * Always call this function first - only once - when-ever the react app loads 
 * reference: https://developers.heap.io/docs/web#base-installation
 */
export const initializeHeapAnalytics = (windowObj: typeof window) => {
  if (!isWebAppOpenedViaRetool(windowObj)) {
    if ((windowObj as any)?.heap) {
      if (!(windowObj as any)?.isHeapLoadInitialized) {
        (windowObj as any).isHeapLoadInitialized = true;
        /** call the function(heap.load) ONLY ONCE when:
         * the global variable `isHeapLoadInitialized` is absent/undefined  */
        (window as any)?.heap?.load(process.env.REACT_APP_HEAP_APP_ID);
      }
    }
  }
};

/**
 * Do not track heap analytics for internal venwiz users
 */
export const initializeHeapAnalyticsOnlyForNonInternalVenwizUser = (email: string, windowObj: typeof window) => {
  if (email) {
    let isInternalVenwizUser = false;
    const lowerCasedEmail = email?.toLowerCase();
    VENWIZ_INTERNAL_USER_EMAILS.forEach(websiteName => {
      if (lowerCasedEmail?.includes(websiteName)) {
        isInternalVenwizUser = true;
      }
    });

    if (!isInternalVenwizUser) {
      /** initialize heap script - for a user not part of venwiz-internal-team */
      initializeHeapAnalytics(windowObj);
    }

    /** initialize heap script - for testing on QA */
    if (lowerCasedEmail === "bazeela@ampe.com") {
      initializeHeapAnalytics(windowObj);
    }
  }
};

export const heapIdentifyTrigger = (heapObj: any, uniqueEmail: string) => {
  if (!isWebAppOpenedViaRetool(window)) {
    if (uniqueEmail && heapObj) {
      if (heapObj?.identify) heapObj?.identify(uniqueEmail);
      if (heapObj?.addUserProperties) heapObj?.addUserProperties({ customEmailId: uniqueEmail });
    }
  }
};

export const heapTrackTrigger = ({ windowObj, eventName, value }:
  {
    windowObj: typeof window;
    eventName: string;
    value: any;
  }) => {
  if ((windowObj as any)?.heap?.track) {
    return (windowObj as any)?.heap.track(eventName, value);
  }
};