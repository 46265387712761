import React from "react";
import { Button, Typography } from "components/package";
import { ButtonProps } from "components/package/Button/Button";
import { StylesProps } from "components/package/styled-system/types";

export const AuthenticationButton = (props: ButtonProps) => {
  const DEFAULT_STYLES: StylesProps = {
    borderRadius: "4px",
    borderColor: "#47C882",
    border: "1px solid #47C882",
    bgColor: props?.variant === "primary" ? "#47C882" : "",
    color: props?.variant === "primary" ? "#fff" : "#47C882",
    fontSize: {
      default: "0.8rem", // 12px
      xxs: "0.8rem", // 12px
      sm: "0.875rem", // 14px
    },
    fontWeight: { default: 600, xxs: 600, sm: 700 },
    letterSpacing: "0.01em",
    h: { default: "1.75rem", xxs: "1.75rem", sm: "2.5rem" },
  };

  return (
    <Button
      {...DEFAULT_STYLES}
      style={{ textTransform: "uppercase" }} // TODO: add support in package
      {...props}
    />
  );
};
