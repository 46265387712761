import { Select } from "antd";
import { AuthenticationActions } from "authentication/actions";
import OtpBox from "authentication/components/OtpBox/OtpBox";
import Terms from "authentication/components/Terms_Condition/Terms";
import { LoginActions } from "authentication/login/actions";
import AntdToast from "components/Toast/AntdToast";
import ApiConstant from "constants/ApiConstant";
import { FormikConfig, FormikHelpers, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import LocalStorage from "_services/LocalStorage";
import SessionStorage from "_services/SessionStorage";
import { Event } from "../../analytics/UniversalGoogleAnalytics/UniversalGoogleAnalytics";
import { RegistrationActions } from "./actions";
import { Radio } from "antd";
import AntdTooltip from "components/Tooltip";
import {
  BackNavigation,
  ErrorMessageDiv,
  ImageHolder,
  PlantInfoProps,
  RadioBttn,
  SelectDropdown,
  SelectionHeader,
  SelectionWrapper,
  StyledForm,
} from "./Registration.styles";
import { heapIdentifyTrigger, initializeHeapAnalyticsOnlyForNonInternalVenwizUser } from "shared/helpers/heapAnalytics.helper";
import { ResponsiveInput as Input, Typography } from "components/package";
import { Box, Flex } from "components/package/styled-system/primitives";
import { ErrorContainer } from "authentication/components/ErrorContainer/ErrorContainer";
import { COLORS } from "components/package/package.styles";
import { AUTH_0_EXHAUSTED_ATTEMPTS_ERROR_FOR_REGISTRATION, AUTH_0_OTP_VERIFICATION_ERROR_MESSAGE, INITIAL_OTP_STATE, INITIAL_OTP_TIMER } from "authentication/components/OtpBox/otpBox.constants";
import { AuthenticationButton } from "authentication/components/Button/Button";
import { HEAP_DATA_TRACKING_ID, HEAP_TRIGGERS } from "constants/heapAnalytics.constants";
import { AUTHENTICATION_PAYLOAD_TYPE } from "authentication/actions/actions";
import { whetherToShowResendOTPButton } from "authentication/components/OtpBox/otp.handler";
import { RootState } from "store/reducers";
import { getErrorMessage } from "authentication/login/login.handler";
import { AuthPageFooter } from "authentication/components/AuthPageFooter/AuthPageFooter";
import { LoaderActions } from "components/Spinner/actions";

const { Option } = Select;

const PlantInfo = (props: PlantInfoProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { otpError, error, resetTimer, email: authStateEmail } = useSelector(
    (state: RootState) => state.AuthenticationReducers,
  );
  const userData = useSelector((state: any) => state.RegistrationReducers);
  const { companyData, storeUserData, otpResponse, registerResponse } = userData;
  // const nameValidations = /^[^-\s][_A-z0-9]*((-|\s)*[_A-z0-9])*$/;
  const email = authStateEmail || LocalStorage.get(ApiConstant.STORAGE_KEYS.userEmail);
  const [renderOtp, setRenderOtp] = useState(false);
  const [plantNames, setPlantNames] = useState([] as any[]);
  const [dropdownValue, setDropdownValue] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [initialOTPState, setInitialOTPState] = useState({ ...INITIAL_OTP_STATE });
  const isReSendOTPButtonVisible = whetherToShowResendOTPButton({
    minutes: initialOTPState?.minutes,
    seconds: initialOTPState?.seconds,
  });
  const { STORAGE_KEYS } = ApiConstant;
  const [selectInputValue, setSelectInputValue] = useState(false);
  const [radioBttnState, setRadioBttnState] = useState(1);
  const [focusState, setFocusState] = useState(false);
  const initialValues = { companyName: "" };

  const validate = Yup.object().shape({
    companyName: Yup.string().required("Please enter your company name"),
  });

  const handleGenerateOTPForRegistration = (values: typeof initialValues, { setFieldError }: FormikHelpers<typeof initialValues>) => {
    setIsSubmit(true);
    const { companyName } = values;
    if (!companyName) {
      formik.setFieldError("companyName", "Please enter your company name");
      formik.setFieldTouched("companyName", true);
    } else if (dropdownValue !== "" && formik.isValid) {
      const domainName = storeUserData?.email?.split("@");
      /** generate OTP via email for registration */
      dispatch(LoaderActions.setIsLoading(true));
      dispatch(
        RegistrationActions.generateOtpForRegistration({
          email: storeUserData.email,
          fullName: storeUserData.fullName,
          companyEmail: `https://${domainName[1]}`,
          phoneNumber: storeUserData?.phoneNumber.length ? storeUserData?.phoneNumber : null,
        }),
      ).then(() => {
        setRenderOtp(true);
        dispatch(LoaderActions.setIsLoading(false));
        dispatch(AuthenticationActions.setOTPBoxErrorState(false));
        setInitialOTPState(prev => ({ ...prev, ...INITIAL_OTP_TIMER, otpValue: "", isInputDisabled: false }));
        props.onCall("step");
      }).catch((err: any) => {
        dispatch(LoaderActions.setIsLoading(false));
        setFieldError("companyName", err?.message);
      });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleGenerateOTPForRegistration,
    validationSchema: validate,
  });

  useEffect(() => {
    if (companyData?.isCompanyPresent && companyData.companyName) {
      formik.setFieldValue("companyName", companyData.companyName);
    } else {
      formik.setFieldError("companyName", "Please enter your company name");
      formik.setFieldTouched("companyName", true);
    }
  }, [companyData]);

  const loginSuccess = (res: any) => {
    dispatch(LoaderActions.setIsLoading(false));
    /** For Heap Analytics */
    initializeHeapAnalyticsOnlyForNonInternalVenwizUser(res?.userData?.email , window);
    if ((window as any)?.heap) heapIdentifyTrigger((window as any)?.heap, res?.userData?.email);
    SessionStorage.set(STORAGE_KEYS.userData, res);
    LocalStorage.set(STORAGE_KEYS.userData, res);
    LocalStorage.set(STORAGE_KEYS.isRememberEnabled, 1);

    const applicationFlags = LocalStorage.get(STORAGE_KEYS.applicationFlags);
    if (!applicationFlags) {
      LocalStorage.set(STORAGE_KEYS.applicationFlags, { showDemoVideo: true });
    }
    ReactGA.set({ userId: res.userData._id });
    Event("Login", "Login into Client Module", "Login Page");
    LocalStorage.set(ApiConstant.STORAGE_KEYS.isSubsequentUser, res.isSubsequentUser);

    history.push("/userinfo");
  };

  const loginMethod = () => {
    dispatch(LoaderActions.setIsLoading(true));
    if (otpResponse?.isSubsequentUser) {
      /** login subsequent  plant/corporate user */
      dispatch(
        LoginActions.setLogin({
          email: storeUserData.email,
          password: storeUserData.password,
        }),
      ).then((res: any) => {
        loginSuccess(res);
        dispatch(LoaderActions.setIsLoading(false));
        SessionStorage.remove(STORAGE_KEYS.tempRegistrationDetails);
        SessionStorage.remove(STORAGE_KEYS.tempPlantInfo);
      });
    } else {
      /** For Heap Analytics
       * For a new corporate/plant user */
      initializeHeapAnalyticsOnlyForNonInternalVenwizUser(storeUserData.email, window);

      dispatch(LoaderActions.setIsLoading(false));
      props.onCall("success");
      SessionStorage.remove(STORAGE_KEYS.tempPlantInfo);
    }
  };

  const handleVerifyOTP = () => {
    const domainName = storeUserData.email.split("@");
    const VERIFY_EMAIL_PAYLOAD: AUTHENTICATION_PAYLOAD_TYPE["VERIFY_EMAIL_OTP"] = {
      email: storeUserData.email,
      otp: String(initialOTPState?.otpValue),
    };

    dispatch(LoaderActions.setIsLoading(true));
    /** verify generated OTP in registration */
    dispatch(AuthenticationActions.verifyEmailOtp(VERIFY_EMAIL_PAYLOAD)).then(
      (res: any) => {
        dispatch(LoaderActions.setIsLoading(true));
        /** register user */
        dispatch(
          RegistrationActions.register({
            ...storeUserData,
            companyName: companyData?.companyName?.length ? companyData?.companyName : formik.values.companyName,
            companyEmail: `https://${domainName[1]}`,
            plant: dropdownValue,
            address: companyData?.address,
            foundedYear: companyData?.foundedYear,
            companyLogo: companyData?.companyLogo || "",
            totalEmployees: companyData?.totalEmployees,
            turnOver: companyData?.turnOver,
            userType: radioBttnState == 1 ? "Plant" : "Corporate",
            inviteId: LocalStorage.get(STORAGE_KEYS.inviteId),
          }),
        )
          .then(() => {
            loginMethod();
          })
          .catch((err: any) => {
            dispatch(LoaderActions.setIsLoading(false));
            const ERROR_MESSAGE = Array.isArray(err?.message) ? err?.message[0] : getErrorMessage(err) || "";
            AntdToast({ type: "error", message: "Error", description: ERROR_MESSAGE });
          });
      },
      (err: any) => {
        dispatch(LoaderActions.setIsLoading(false));
        const ERROR_MESSAGE = Array.isArray(err?.message) ? err.message[0] : getErrorMessage(err) || err?.message || "";
        if ((ERROR_MESSAGE as string)?.toLowerCase()?.includes(AUTH_0_OTP_VERIFICATION_ERROR_MESSAGE)) {
          dispatch(AuthenticationActions.setOTPBoxErrorState(true)); // OTP Error
        } else if ((ERROR_MESSAGE as string)?.toLowerCase()?.includes(AUTH_0_EXHAUSTED_ATTEMPTS_ERROR_FOR_REGISTRATION)) {
          AntdToast({ 
            type: "error", 
            message: "Error",
            description: "You've reached the maximum number of attempts. Please try to Register again.",
            duration: 0,
          });
        } else {
          AntdToast({ type: "error", message: "Error", description: ERROR_MESSAGE });
        }
      },
    );
  };

  const setDropdownValuesBind = (e: any, inputVal: boolean) => {
    if (inputVal === false) setDropdownValue(plantNames[e]);
  };

  useEffect(() => {
    if (otpResponse) {
      // storing regestration details in case of otp page refresh
      SessionStorage.set(STORAGE_KEYS.tempRegistrationDetails, userData);
      SessionStorage.set(STORAGE_KEYS.tempPlantInfo, { dropdownValue, radioBttnState });
    }
    if (registerResponse?.status === "SUCCESS") {
      SessionStorage.set(STORAGE_KEYS.tempRegistrationDetails, userData);
    }
  }, [otpResponse, registerResponse]);

  useEffect(() => {
    const tempRegistrationDetails = SessionStorage.get(STORAGE_KEYS.tempRegistrationDetails);
    const plantInfo = SessionStorage.get(STORAGE_KEYS.tempPlantInfo);
    if (tempRegistrationDetails) {
      setRenderOtp(true);
      const min = sessionStorage.getItem("min");
      const sec = sessionStorage.getItem("sec");
      if (min !== "0" && sec !== "0") {
        AntdToast({
          type: "warning",
          message: "Attention",
          description: "OTP has been sent. Please check your registered email address",
        });
      }
      dispatch(RegistrationActions.retrieveRegistrationData(tempRegistrationDetails));
      setDropdownValue(plantInfo?.dropdownValue);
      setRadioBttnState(plantInfo?.radioBttnState);
    } else {
      dispatch(AuthenticationActions.setResetTimerFlag(true));
    }
  }, []);

  const renderPageFooter = () => {
    return <AuthPageFooter type={"REGISTER_PAGE"} />;
  };

  if (renderOtp) {
    return (
      <Flex
        width={"100%"}
        flexDirection={"column"}
        mt={{
          default: "2.5rem", // 40px
          xxs: "2.5rem", // 40px
          sm: "1.25rem", // 20px
        }}
      >
        <OtpBox
          title={`Enter OTP sent to ${storeUserData?.email || email || "email"}`}
          renderResendOTPButton={isReSendOTPButtonVisible}
          handleResendOTP={() => handleGenerateOTPForRegistration(formik.values, formik)}
          setInitialOTPState={setInitialOTPState}
          initialOTPState={initialOTPState}
        />
        <Flex mt={"2rem"} flexDirection={"column"} alignItems={"center"}>
          <Typography
            mt={"0.3rem"}
            mr={"0.5rem"}
            fontWeight={700}
            fontSize={"0.75rem"} // 12px
            color={"rgba(17, 43, 122, 0.6)"}
          >
            Team Venwiz will verify your credentials and grant access to the platform
          </Typography>
          <AuthenticationButton
            mt={"0.625rem"}
            variant={"primary"}
            onClick={handleVerifyOTP}
            disabled={initialOTPState?.otpValue?.length < 6}
            id={otpResponse?.isSubsequentUser ? "vzc-registration-submit" : "vzc-registration-request-access"}
            {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.REGISTER.STEP_3.REQUEST_ACCESS_CTA }}
          >
            {otpResponse?.isSubsequentUser ? "SUBMIT" : "REQUEST ACCESS"}
          </AuthenticationButton>
        </Flex>
      </Flex>
    );
  }

  const handleSearch = (value: any) => {
    if (value?.length) {
      setDropdownValue(value.trim());
      setPlantNames(
        companyData?.plant?.filter(
          (name: any) => name !== "Corporate Office" && name.toLowerCase().includes(value.toLowerCase()),
        ) || [],
      );
    } else {
      setPlantNames([]);
    }
  };

  const handleRadioBttnValue = (e: any) => {
    if (e.target.value === 2) {
      setDropdownValue("Corporate Office");
    } else {
      setDropdownValue("");
    }
    setRadioBttnState(e.target.value);
  };

  const handleOnEnterPress = (e: any) => {
    if (e.key === "Enter") handleGenerateOTPForRegistration(formik.values, formik);
  };

  const FIELD_ERRORS = {
    companyName: Boolean(formik.touched.companyName && formik.errors.companyName),
  };

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      {/* Company Name */}
      <Input
        disabled={companyData?.companyName?.length}
        placeholder="Company Name"
        name="companyName"
        type="text"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.companyName}
        error={FIELD_ERRORS.companyName}
        fieldDescription={FIELD_ERRORS.companyName && <ErrorContainer errorText={formik.errors.companyName} />}
        {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.REGISTER.STEP_2.COMPANY_NAME_INPUT }}
      />
      <Box>
        <SelectionWrapper>
          <SelectionHeader>
            <Typography
              variant={"h3"}
              fontSize={"0.875rem"} // 14px
              color={COLORS.blue_1}
              fontWeight={700}
              letterSpacing={"0.01em"}
            >
              Select User Type
            </Typography>
          </SelectionHeader>
          <Radio.Group onChange={handleRadioBttnValue} value={radioBttnState}>
            <RadioBttn
              value={1}
              {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.REGISTER.STEP_2.RADIO_BUTTON.PLANT_USER }}
            >
              {/* TODO: (optional) convert into variant */}
              <Typography
                display={"inline-block"}
                variant={"p"}
                color={COLORS.black_80}
                letterSpacing={"0.01em"}
                cursor={"pointer"}
              >
                Plant User
              </Typography>
            </RadioBttn>
            <RadioBttn
              value={2}
              {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.REGISTER.STEP_2.RADIO_BUTTON.CORPORATE_USER }}
            >
              <Typography
                display={"inline-block"}
                variant={"p"}
                color={COLORS.black_80}
                letterSpacing={"0.01em"}
                cursor={"pointer"}
              >
                Corporate User
              </Typography>
            </RadioBttn>
          </Radio.Group>
        </SelectionWrapper>
        <AntdTooltip
          visibilityType={radioBttnState === 2 ? "onHover" : "visible"}
          position={"bottomRight"}
          body={"Select plant user to edit field"}
          alignConfig={{
            points: ["tr", "br"], // align top left point of sourceNode with top right point of targetNode
            offset: [0, 10], // the offset sourceNode by 10px in x and 20px in y,
            targetOffset: ["0%", "80%"], // the offset targetNode by 30% of targetNode width in x and 40% of targetNode height in y,
            overflow: { adjustX: true, adjustY: true }, // auto adjust position when sourceNode is overflowed
          }}
        >
          {/* TODO: style */}
          <SelectDropdown
            showArrow={false}
            dropdownStyle={{
              padding: "0px 30px",
              fontWeight: "500",
              fontSize: "16px",
              color: "#011759",
              opacity: "0.7",
              height: "40px !important",
              borderRadius: "0px 0px 8px 8px",
              borderTop: "none",
              boxShadow: " 0px 10px 12px rgba(159, 179, 248, 0.4)",
            }}
            dropdownAlign={{ offset: [0, -15] }}
            $focusState={focusState}
            onFocus={() => setFocusState(true)}
            onBlur={() => {
              dropdownValue.length && setFocusState(false);
            }}
            bordered={!focusState}
            onKeyDown={handleOnEnterPress}
            showSearch
            disabled={radioBttnState === 2}
            onSearch={handleSearch}
            value={dropdownValue?.length ? dropdownValue : undefined}
            listHeight={100}
            filterOption={false}
            onSelect={(e: any) => setDropdownValuesBind(e, selectInputValue)}
            style={{ width: "100%", borderRadius: "12px" }}
            placeholder="Enter Plant Name"
            notFoundContent={
              !!(!plantNames?.length && dropdownValue !== "") && <div style={{ display: "none" }}> </div>
            }
            listItemHeight={3}
          >
            {plantNames.map((item, index) => (
              <Option
                style={{
                  height: "40px",
                  marginBottom: "2px",
                }}
                value={index}
                key={item}
              >
                {item}
              </Option>
            ))}
          </SelectDropdown>
        </AntdTooltip>
        {isSubmit && dropdownValue === "" && (
          <ErrorMessageDiv>
            <ImageHolder />
            Please enter the Plant Name
          </ErrorMessageDiv>
        )}
      </Box>
      <Typography
        variant={"p"}
        fontWeight={600}
        mt={"2rem"} // 32px
        fontSize={"0.75rem"} // 12px
        letterSpacing={"0.01em"}
        lineHeight={"1.125rem"} // 18px
      >
        <Terms />
      </Typography>
      <AuthenticationButton
        mt={"2rem"}
        variant={"primary"}
        type="submit"
        id="vzc-registration-register"
        {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.REGISTER.STEP_2.NEXT_CTA }}
      >
        Next
      </AuthenticationButton>
      {renderPageFooter()}
      {/* <BackNavigation onClick={() => props.onCall("back")}>
        <LeftOutlined color={COLORS.blue_1} />
        <Typography color={COLORS.blue_1} ml={"0.25rem"} cursor={"pointer"}>
          Back To Step 1
        </Typography>
      </BackNavigation> */}
    </StyledForm>
  );
};

export default PlantInfo;
