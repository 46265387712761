import { AuthenticationActions } from "authentication/actions";
import ApiConstant from "constants/ApiConstant";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import LocalStorage from "_services/LocalStorage";
import SessionStorage from "_services/SessionStorage";

/** This page loads when:
 * any user(guest/customer) clicks password-reset-link from the mail box */
export const VerifyUser = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const navigateToLogin = (withError?: boolean) => {
    const errorState = withError ? { errMessage: "Email verification failed" } : {};
    history.push("/login", errorState);
  };

  useEffect(() => {
    if (id) {
      dispatch(AuthenticationActions.passwordResetLinkVerifyEmail(id)).then(
        (res: any) => {
          if (res?.status === 200 || res?.error === "Already verified.") {
            LocalStorage.set(ApiConstant.STORAGE_KEYS.userIdForPassResetLink, id);
            SessionStorage.set(ApiConstant.STORAGE_KEYS.userEmail, res?.email);
            SessionStorage.set(ApiConstant.STORAGE_KEYS.resetPwdToken, res?.resetPwdToken);
            history.push("/resetpassword");
          } else {
            navigateToLogin(true);
          }
        },
        (err: any) => navigateToLogin(true),
      );
    } else {
      navigateToLogin();
    }
  }, []);

  // TODO: confirm a better content for page
  return <>loading...</>;
};
