import React from "react";
import styled from "styled-components";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthenticationActions } from "authentication/actions";
import { Box, Flex } from "components/package/styled-system/primitives";
import { BoxProps } from "components/package/styled-system/primitives/Box";
import { IAuthenticationPage } from "authentication/interfaces/authentication.types";
import { PageSpinner } from "components/Spinner";
import { RootState } from "store/reducers";

interface authProps {
  title?: any;
  children?: React.ReactNode;
  showBackButton?: boolean;
  message?: string;
  height?: string;
  childContainerProps?: BoxProps;
}

const AuthenticationHeader = styled.div`
  font-weight: 600;
  font-size: 1.875rem;
  color: #215ec9;
  @media screen and (max-width: 1200px) {
    font-size: 1.5rem;
  }
`;

const BackButton = styled.div`
  background: #215ec9;
  border-radius: 15px;
  width: 64px;
  text-align: center;
  color: #ffffff;
  font-size: 11px;
  padding: 5px;
  margin-bottom: 60px;
  cursor: pointer;
`;

const MessageContainer = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #011759;
  margin-top: 15px;
`;

const AuthenticationContainer = (props: authProps) => {
  const { children, title, showBackButton, message } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading: isUserLoginInProgress } = useSelector((state: RootState) => state.LoginReducers);
  const { loading: isUserRegistrationInProgress } = useSelector((state: RootState) => state.RegistrationReducers);
  const { isLoading } = useSelector((state: RootState) => state.LoaderReducers);
  const isPageSpinnerVisible = isUserRegistrationInProgress || isUserLoginInProgress || isLoading;

  const handleBackClick = () => {
    dispatch(AuthenticationActions.resetState());
    dispatch(AuthenticationActions.setResetTimerFlag(true));
    history.goBack();
  };

  return (
    <>
      {/* Page Spinner appears when - user is being logged in */}
      <PageSpinner isLoading={isPageSpinnerVisible} />
      {/* AuthenticationBody */}
      <Box
        width={"100%"}
        height={"100vh"}
        padding={{ default: "1.5rem", xxs: "1.5rem", xs: "2rem", sm: "3.75rem" }}
        paddingBottom={"2.75rem"}
        zIndex={10}
        background={"#ffffff"}
      >
        {showBackButton && (
          <BackButton onClick={() => handleBackClick()}>
            <LeftOutlined />
            Back
          </BackButton>
        )}
        <AuthenticationHeader>{title}</AuthenticationHeader>
        <MessageContainer>{message}</MessageContainer>
        <Flex
          flexDirection={"column"}
          margin={"0 auto"}
          alignItems={"flex-start"}
          justifyContent={"center"}
          {...props?.childContainerProps}
        >
          {children}
        </Flex>
      </Box>
    </>
  );
};
export default AuthenticationContainer;
