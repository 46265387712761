import styled from "styled-components";
import { Carousel } from "antd";

export const CarouselBase = styled(Carousel)`
  & .slick-list {
    text-align: center;
  }

  & .slick-dots {
    margin-left: 0.08;
    margin-right: 0.18;

    & li button {
      width: 0.5625rem;
      height: 0.5625rem;
      border-radius: 50%;
    }

    & li.slick-active {
      width: 1rem !important;
    }
  }
  height: 10rem;
  width: 35.5rem;
  color: #ffffff;
  line-height: 4.166875rem;

  @media screen and (max-width: 1200px) {
    @media screen and (max-height: 700px) {
      height:9rem;
    }
  }
`;

export const CarouselText = styled.div`
  font-weight: 700;
  font-size: 2rem; // 32px
  line-height: 3rem; // 48px
  text-align: center;
  color: ${props => `${props.theme.colors.white}`};

  @media screen and (max-width: 1200px) {
    font-size: 2.125rem;
  }
`;