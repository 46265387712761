import { Box, Flex } from "components/package/styled-system/primitives";
import React from "react";

export const ErrorContainer = ({ errorText }: { errorText?: string }) => {
  return errorText ? (
    <Flex alignItems={"center"}>
      <Box
        backgroundImage={"url('/images/warning.svg')"}
        backgroundSize={"cover"}
        marginRight={"0.25rem"}
        h={"1rem"} // 16px
        w={"1rem"} // 16px
      />
      <>{errorText}</>
    </Flex>
  ) : (
    <></>
  );
};
