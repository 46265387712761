import { Types as LoginTypes } from "_types/LoginTypes";

export type LOGIN_PAYLOAD_TYPE = {
  VERIFY_MOBILE_AND_LOGIN: { mobileNo: string; otp: string; };
}

export const ActionCreators: any = {
  setLogin: (data: any) => ({
    types: [
      LoginTypes.SET_LOGIN_REQUESTED,
      LoginTypes.SET_LOGIN_SUCCESS,
      LoginTypes.SET_LOGIN_FAILURE,
    ],
    promise: (client: any) =>
      client.post("login", { data }),
  }),

  /** consumed at Login Via Mobile OTP */
  loginAfterVerifyingMobileOtp: (data: LOGIN_PAYLOAD_TYPE["VERIFY_MOBILE_AND_LOGIN"]) => ({
    types: [
      LoginTypes.LOGIN_AFTER_VERIFYING_MOBILE_OTP_REQUESTED,
      LoginTypes.LOGIN_AFTER_VERIFYING_MOBILE_OTP_SUCCESS,
      LoginTypes.LOGIN_AFTER_VERIFYING_MOBILE_OTP_FAILURE,
    ],
    promise: (client: any) => client.post("login_after_verifying_mobile_otp", { data }),
  }),

}

export default ActionCreators;