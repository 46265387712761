import React, { useState } from "react";
import { ResponsiveInput as Input, Typography } from "components/package";
import { StyledForm } from "authentication/registration/Registration.styles";
import { FormikHelpers, useFormik } from "formik";
import { FORMIK_INITIAL_VALUES, VALIDATION_SCHEMA } from "../login.constants";
import { Box, Flex } from "components/package/styled-system/primitives";
import { ErrorContainer } from "authentication/components/ErrorContainer/ErrorContainer";
import { getIntlString } from "_services/Locale";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ForgotPassword, Input as StyledInput } from "./loginViaEmail.styles";
import {
  analyticsHandler,
  getErrorMessage,
  redirectionHandler,
  updateLocalStorageAndSessionStorageHandler,
} from "../login.handler";
import { LoginActions } from "../actions";
import { COLORS } from "components/package/package.styles";
import { AuthenticationButton } from "authentication/components/Button/Button";
import { HEAP_DATA_TRACKING_ID, HEAP_TRIGGERS } from "constants/heapAnalytics.constants";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { LinkURL } from "clientVendorView/components/Tabs/Service/Service.styles";
import SSOModal from "authentication/components/SSOModal/SSOModal";
import { whetherToShowResendOTPButton } from "authentication/components/OtpBox/otp.handler";
import { INITIAL_OTP_STATE } from "authentication/components/OtpBox/otpBox.constants";

type InitialFormikValues = typeof FORMIK_INITIAL_VALUES.LOGIN_VIA_EMAIL;

interface IProps {
  setShowLoginViaPhone: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoginViaEmail = (props: IProps) => {
  const intl = getIntlString();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [isRemembered, setIsRemembered] = useState(true);
  const [renderOtp, setRenderOtp] = useState(false);
  const userData = useSelector((state: any) => state.RegistrationReducers);
  const { companyData, storeUserData, otpResponse, registerResponse } = userData;
  const [initialOTPState, setInitialOTPState] = useState({ ...INITIAL_OTP_STATE });
  const isReSendOTPButtonVisible = whetherToShowResendOTPButton({
    minutes: initialOTPState?.minutes,
    seconds: initialOTPState?.seconds,
  });
  const [userEmail, setUserEmail] = useState("");
  const [showSSOModal, setShowSSOModal] = useState(false);
  const ssoLink = process.env.REACT_APP_SSO_LINK;
  
  const loginUser = (values: InitialFormikValues, { setFieldError }: FormikHelpers<InitialFormikValues>) => {
    const { password } = values;
    const email = values["email"].toLowerCase();
    // Exclude SSO users
    if (email.includes('@' + process.env.REACT_APP_SSO_PRIMETAL_KEY + '.com')) {
      console.log('condition - showSSOModal', showSSOModal)
      setShowSSOModal(true);
      return;
    }
    setUserEmail(email);
    dispatch(LoginActions.setLogin({ email, password })).then(
      (res: any) => {
        analyticsHandler(res);
        updateLocalStorageAndSessionStorageHandler(res, isRemembered);
        redirectionHandler(res, history);
      },
      (err: any) => {
        const errString: string = getErrorMessage(err);
        const ERROR_MESSAGE = errString?.includes("User does't exist!") ? "User doesn't exist!" : errString;
        setFieldError("email", ERROR_MESSAGE);
      },
    );
  };

  const onRemember = (e: any) => setIsRemembered(e.target.checked);

  const navigateToForgotPassword = () => history.push("/forgotpassword");

  const formik = useFormik({
    initialValues: FORMIK_INITIAL_VALUES.LOGIN_VIA_EMAIL,
    onSubmit: loginUser,
    validationSchema: VALIDATION_SCHEMA.LOGIN_VIA_EMAIL,
  });

  const FIELD_ERRORS = {
    email: Boolean(formik.touched.email && formik.errors.email),
    password: Boolean(formik.touched.password && formik.errors.password),
  };

  return (
    <>
    {showSSOModal && 
      <SSOModal setShowSSOModal={setShowSSOModal} />
    }
    <StyledForm onSubmit={formik.handleSubmit}>
      {/* Email */}
      <Input
        placeholder="Email"
        name="email"
        type="email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={FIELD_ERRORS.email}
        fieldDescription={FIELD_ERRORS.email && <ErrorContainer errorText={formik.errors.email} />}
        {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.LOGIN.VIA_EMAIL.EMAIL_INPUT }}
      />
      {/* Password */}
      <Input
        placeholder="Password"
        name="password"
        type={showPassword ? "text" : "password"}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        error={FIELD_ERRORS.password}
        fieldDescription={FIELD_ERRORS.password && <ErrorContainer errorText={formik.errors.password} />}
        {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.LOGIN.VIA_EMAIL.PASSWORD_INPUT }}
        pr={"2rem"}
        rightIcon={
          <Box
            right={0}
            pr={"0.5rem"}
            cursor={"pointer"}
            position={"absolute"}
            onClick={() => setShowPassword(prev => !prev)}
            fontSize={{ default: "1rem", xxs: "1rem", sm: "1.2rem" }}
            top={{ default: "0.25rem", xxs: "0.25rem", sm: "0.625rem" }}
          >
            {showPassword ? <MdVisibility color={COLORS.blue_1} /> : <MdVisibilityOff color={COLORS.blue_1} />}
          </Box>
        }
      />
      <Flex
        w={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mt={{
          default: "1.875rem", // 30px,
          xxs: "1.875rem", // 30px,
          xl: "2.5rem", // 40px,
        }}
      >
        <Flex w={"150px"} h={"20px"} alignItems={"center"} gap={"0.5rem"}>
          <StyledInput type="checkbox" onChange={onRemember} checked={isRemembered} />
          <Typography
            variant={"p"}
            display={"inline-block"}
            fontSize={"0.75rem"} // 12px
            fontWeight={500}
            letterSpacing={"0.01em"}
            color={COLORS.blue_1}
          >
            {intl["login.remember"]}
          </Typography>
        </Flex>
        <Box
          onClick={navigateToForgotPassword}
          {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.LOGIN.LINKS.FORGOT_PASSWORD }}
        >
          <ForgotPassword>{intl["login.forgot"]}</ForgotPassword>
        </Box>
      </Flex>
      <Flex gap={"1rem"} flexDirection={"column"} mt={"1.5rem"}>
        <AuthenticationButton
          type="submit"
          variant="primary"
          {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.LOGIN.VIA_EMAIL.CTA.LOGIN_SUBMIT }}
        >
          {intl["login.submit"]}
        </AuthenticationButton>
        <AuthenticationButton
          variant="outlined"
          onClick={() => props?.setShowLoginViaPhone(true)}
          {...{ [HEAP_DATA_TRACKING_ID]: HEAP_TRIGGERS.LOGIN.VIA_EMAIL.CTA.LOGIN_VIA_MOBILE }}
        >
          Login Via Mobile OTP
        </AuthenticationButton>
      </Flex>
    </StyledForm>
    <AuthenticationButton
      variant="outlined"
      marginTop={'20px'}
      onClick={() => window.open(ssoLink, "_self")}
    >
      Login Via Microsoft
    </AuthenticationButton>
    </>
  );
};
