export const Types = {
  GET_SELECTED_CHAT_ID: "chat/GET_SELECTED_CHAT_ID",
  CHAT_LIST_REQUESTED: "chat/CHAT_LIST_REQUESTED",
  CHAT_LIST_SUCCESS: "chat/CHAT_LIST_SUCCESS",
  CHAT_LIST_FAILURE: "chat/CHAT_LIST_FAILURE",
  CHAT_MESSAGES_REQUESTED: "chat/CHAT_MESSAGES_REQUESTED",
  CHAT_MESSAGES_SUCCESS: "chat/CHAT_MESSAGES_SUCCESS",
  CHAT_MESSAGES_FAILURE: "chat/CHAT_MESSAGES_FAILURE",
  CHAT_OLD_MESSAGES_REQUESTED: "chat/CHAT_OLD_MESSAGE_REQUESTED",
  CHAT_OLD_MESSAGES_SUCCESS: "chat/CHAT_OLD_MESSAGE_SUCCESS",
  CHAT_OLD_MESSAGES_FAILURE: "chat/CHAT_OLD_MESSAGE_FAILURE",
  CHAT_SEND_MESSAGE_REQUESTED: "chat/CHAT_SEND_MESSAGE_REQUESTED",
  CHAT_SEND_MESSAGE_SUCCESS: "chat/CHAT_SEND_MESSAGE_SUCCESS",
  CHAT_SEND_MESSAGE_FAILURE: "chat/CHAT_SEND_MESSAGE_FAILURE",
  CHAT_INITIATE_FAILURE: "chat/CHAT_INITIATE_FAILURE",
  CHAT_INITIATE_SUCCESS: "chat/CHAT_INITIATE_SUCCESS",
  CHAT_INITIATE_REQUESTED: "chat/CHAT_INITIATE_REQUESTED",
  DELETE_ALL_CHAT_REQUESTED: "chat/DELETE_ALL_CHAT_REQUESTED",
  DELETE_ALL_CHAT_SUCCESS: "chat/DELETE_ALL_CHAT_SUCCESS",
  DELETE_ALL_CHAT_FAILURE: "chat/DELETE_ALL_CHAT_FAILURE",
  
  CLEAR_SELECTED_CHAT_ID:"chat/CLEAR_SELECTED_CHAT_ID",
  RESET_HAS_MORE_MESSAGES: "chat/RESET_HAS_MORE_MESSAGES",
  SET_IS_SCROLLING_ACTIVE: "chat/SET_IS_SCROLLING_ACTIVE",

  DELETE_CHAT_REQUESTED: "chat/DELETE_CHAT_REQUESTED",
  DELETE_CHAT_SUCCESS: "chat/DELETE_CHAT_SUCCESS",
  DELETE_CHAT_FAILURE: "chat/DELETE_CHAT_FAILURE",

  CHAT_FILES_UPLOAD_REQUEST: "chat/CHAT_FILES_UPLOAD_REQUEST",
  CHAT_FILES_UPLOAD_SUCCESS: "chat/CHAT_FILES_UPLOAD_SUCCESS",
  CHAT_FILES_UPLOAD_FAILURE: "chat/CHAT_FILES_UPLOAD_FAILURE",

  GET_CHAT_SHARED_FILES_REQUESTED: "chat/GET_CHAT_SHARED_FILES_REQUESTED",
  GET_CHAT_SHARED_FILES_SUCCESS: "chat/GET_CHAT_SHARED_FILES_SUCCESS",
  GET_CHAT_SHARED_FILES_FAILURE: "chat/GET_CHAT_SHARED_FILES_FAILURE",

  SET_BROADCAST_MESSAGES: "chat/SET_BROADCAST_MESSAGES"
};
