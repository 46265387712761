import { Types as InviteTypes } from "_types/InviteTypes";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,
  userInviteResponse: null,
  inviteeListData: null,
  inviteeListResponse: null,
  otpError: false,
  resendInviteResponse: null,
  userUpdateResponse: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case InviteTypes.USER_INVITE_REQUESTED:
      return {
        ...state,
        loading: true,
        inviteResponse: null,
      };

    case InviteTypes.USER_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        inviteResponse: action.result,
      };

    case InviteTypes.USER_INVITE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        inviteResponse: action.error.message,
      };

    case InviteTypes.RESEND_INVITE_REQUESTED:
      return {
        ...state,
        loading: true,
        // resendInviteResponse: null,
      };

    case InviteTypes.RESEND_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        // resendInviteResponse: action.result,
      };

    case InviteTypes.RESEND_INVITE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        // resendInviteResponse: action.error.message,
      };

    case InviteTypes.GET_INVITEE_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
        inviteeListResponse: false,
      };

    case InviteTypes.GET_INVITEE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        inviteeListData: action.result.data,
      };

    case InviteTypes.GET_INVITEE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        inviteeListResponse: action.error.message,
      };

    // case USERINFO_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     loaded: true,
    //     error: true,
    //     errorData: action.error.message,
    //   };
    default:
      return state;
  }
};
