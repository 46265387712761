export const Types = {
  USER_INVITE_REQUESTED: "inviteApi/USER_INVITE_REQUESTED",
  USER_INVITE_SUCCESS: "inviteApi/USER_INVITE_SUCCESS",
  USER_INVITE_FAILURE: "inviteApi/USER_INVITE_FAILURE",

  GET_INVITEE_LIST_REQUESTED: "inviteApi/GET_INVITEE_LIST_REQUESTED",
  GET_INVITEE_LIST_SUCCESS: "inviteApi/GET_INVITEE_LIST_SUCCESS",
  GET_INVITEE_LIST_FAILURE: "inviteApi/GET_INVITEE_LIST_FAILURE",

  RESEND_INVITE_REQUESTED: "inviteApi/RESEND_INVITE_REQUESTED",
  RESEND_INVITE_SUCCESS: "inviteApi/RESEND_INVITE_SUCCESS",
  RESEND_INVITE_FAILURE: "inviteApi/RESEND_INVITE_FAILURE",
};
