import { Types as CompareVendorsTypes } from "_types/CompareVendorsTypes";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,
  compareVendorsData: null,
  compareDataResponse: null,
  selectedData:null,
  shareData:null
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CompareVendorsTypes.USER_DATA_REQUESTED:
      return {
        ...state,
        loading: true,
        inviteResponse: null,
      };

    case CompareVendorsTypes.USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        compareVendorsData: action.result,
      };

    case CompareVendorsTypes.USER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        compareDataResponse: action.error.message,
      };

      case CompareVendorsTypes.STORE_GSTN:
        return {
          ...state,
          loading: false,
          loaded: true,
          error: false,
          selectedData: action.payload,
        };

        case CompareVendorsTypes.SHAREABLE_LINK_SUCCESS:
          return {
            ...state,
            loading: false,
            loaded: true,
            error: false,
            shareData: action.payload,
          };

    default:
      return state;
  }
};
