const ApiConfig = {
  hostname: process.env.REACT_APP_API_URL ?? "",
  hosttype: {
    vendor: "vendor",
    communication: "communication",
  },
  communication_hostname: process.env.REACT_APP_COMMUNICATION_API_URL ?? "",
  mock_server_hostname: process.env.REACT_APP_MOCK_SERVER ?? "",
  endpoints: {
    /** Authentication */
    // logout
    logout: "/api/v1/auth/logout",
    // login via email
    login: "/api/v1/authentication/login",
    // login - via phone - generate OTP
    generate_otp_for_login_via_phone: "/api/v1/authentication/send-mobile-otp",
    // login - via phone - verify OTP & login
    login_after_verifying_mobile_otp: "/api/v1/authentication/verify-mobile-otp-login",

    // registrations
    register: "api/v1/authentication/register",
    get_clear_bit_company_data: "api/v1/authentication/companyInfo",
    email_duplication: "/api/v1/authentication/validateEmail?",
    phone_duplication: "/api/v1/authentication/validatePhone?",
    generate_otp_for_registration: "api/v1/authentication/send-otp-to-register",

    // forgot/reset password
    forgot_password: "/api/v1/authentication/forgot-password",
    reset_password: "/api/v1/authentication/reset-password",
    update_password: "/api/v1/authentication/update-password",

    // APIs for User with password-reset-link in user's Email
    password_reset_link_verify_email: "/api/v1/authentication/verify-reset-email",
    password_reset_link_update_user_status: "/api/v1/authentication/update-verify-reset-email",

    // Email OTP Verification
    verify_email_otp: "/api/v1/authentication/verify-email-otp",

    // Mobile OTP Verification
    verify_mobile_otp: "/api/v1/authentication/verify-mobile-otp",

    // client viewing vendor
    vendor_profile_data: "/api/v1/vendor-profile",
    vendor_profile_data_without_token: "/api/v1/vendor-profile/share",

    // Dashboard
    get_shortlisted_vendors: "/api/v1/vendor-shortlist/dashboard-list",
    get_suggested_vendors: "/api/v1/request-vendor/suggested-vendors?offset=0",
    

    // Guest
    guest_vendor_listing: "/api/v1/vendor-list/searchWithoutToken?",

    //VendorListing Endpoints
    get_registered_vendor_locations_across_india: "api/v1/vendor-list/regCityStateMapping",
    get_vendor_shortlist_group: "/api/v1/vendor-shortlist/groups",
    set_vendor_shortlist_group: "/api/v1/vendor-shortlist/shortlist",

    //Shortlisted Listing Endpoints
    get_vendor_shortlist: "api/v1/vendor-shortlist/vendors-list",
    set_rename_vendor_shortlist: "api/v1/vendor-shortlist/rename-group",
    set_delete_vendor_shortlist: "api/v1/vendor-shortlist/delete-group",
    delete_shortlist_group_vendor: "api/v1/vendor-shortlist/remove-vendor",
    //file upload
    set_file_upload: "/api/v1/file/upload",

    //vendor request
    // post_vendor_request: "api/v1/job-rfq/create-job-rfq",
    get_vendor_requests: "api/v1/request-vendor/suggested",
    get_vendor_request: "/api/v1/request-vendor/job-req-details",
    update_vendor_request: "api/v1/job-rfq/edit-job-rfq",
    recommend_vendor: "api/v1/request-vendor/recommendVendor",
    add_vendor: "api/v1/request-vendor/addVendors",
    remove_vendor: "api/v1/job-rfq/remove-job-vendors",
    request_vr_addition_to_a_project: "api/v1/job-rfq/request-vr-to-project",
    post_vendor_request: "api/v1/job-rfq/create-job-rfq-vendor",
    get_vendor_email_num: "api/v1/job-rfq/get-vendors-with-email-and-num",
    update_last_activity_date: "api/v1/job-rfq/update-last-activity-date",

    add_vendors_to_final: "api/v1/final-vendors/add-final-vendors",
    get_final_vendors: "api/v1/final-vendors/get-final-vendors",

    // add_rfq_documents: "api/v1/request-vendor/add-rfq-document",
    add_rfq_documents: "api/v1/job-rfq/add-job-documents",
    remove_rfq_documents: "api/v1/request-vendor/remove-rfq-documents",
    save_resolution_approver_response: "api/v1/resolution/update-resolution",
    get_job_request_resolutions: "api/v1/resolution/get-resolution",
    update_job_status: "api/v1/request-vendor/update-job-status",
    restore_doc_version: "api/v1/job-rfq/restore-job-document",
    edit_doc_description: "api/v1/job-rfq/edit-job-description",
    get_rfq_doc_version: "api/v1/job-rfq/get-file-history",
    delete_job_document: "api/v1/job-rfq/delete-job-document",
    edit_rfq_file_name: "api/v1/job-rfq/rename-job-rfq-file",
    auto_publish_rfq_docs_update: "api/v1/job-rfq/update-rfq-file",
    get_comparison_reports: "api/v1/job-vendors-comparison-report",
    get_financial_report: "api/v1/job-vendors-financial-report",
    get_vendor_comparison_report: "api/v1/job-vendors-technical-report",
    add_comment_vendor_comparison_report: "api/v1/job-vendors-technical-report/add-comment",
    get_job_summary_report: "api/v1/client-job-report/get-job-summary-report",

    // chatList api
    chat_initiate: "v1/communication/initiate-chat",
    chatlist_get_data: "/v1/communication/message-list",
    chat_get_messages: "/v1/communication/fetch",
    chat_get_old_messages: "/v1/communication/fetch-old",
    send_message: "/v1/communication/send-message",
    delete_all_chats: "/v1/communication/clear-chat",
    delete_chat: "/v1/communication/delete-message",
    upload_file: "/v1/communication/upload-file",
    get_shared_files: "/v1/communication/get-shared-files",

    //profile creation
    get_userinfo_data: "api/v1/client-info/user",
    set_userinfo_data: "api/v1/client-info/user/update",
    get_plant_info: "api/v1/client-info/plant",
    set_plant_info: "api/v1/client-info/plant/update",
    user_plant_info_completion: "api/v1/client-info/user-plant-complete",
    update_logo: "/api/v1/client-info/companylogo",
    get_clients_list: "api/v1/client-info/getClients",
    set_vendor_share: "api/v1/vendor-profile/email-share-profile",
    // Analytics
    log_event: "api/v1/analytics/save",

    //invite apis
    send_invite_api: "api/v1/invite/send",
    get_invitee_list: "api/v1/invite/list-invites",
    resend_invite_api: "api/v1/invite/re-send",

    //compareVendor
    compare_vendor_data: "api/v1/compare-vendors",

    //Request-plantvisit
    get_request_type_list: "/api/v1/request-flow/request-type-list/get",
    post_request_create: "api/v1/request-flow/create",
    post_request_update: "api/v1/request-flow/update",
    post_request_delete: "/api/v1/request-flow/delete-by-request-id",
    get_request: "/api/v1/request-flow/request-by-request-id",
    get_request_list: "/api/v1/request-flow/get",
    get_new_update_request_count:
      "api/v2/request-vendor/notification-message-status",

    //share compare vendors link
    share_compare_vendors_link: "api/v1/compare-vendors/share",

    //bubbleApp
    get_bubble_link: "bubble-integration/get-module-link",

    //rfq
    send_rfq: "/v1/communication/send-rfq",
    get_rfq: "/api/v1/job-rfq/get-job-by-request-id",
    toggle_rfq_file_state: "api/v1/job-rfq/publish-job-document",
    publish_rfq: "api/v1/job-rfq/publish-job-rfq",
    get_job_requests: "api/v1/job-rfq/suggested-vendors-v3",

    //rfq-approval
    get_approvers_list: "api/v1/job-rfq/job-approvers-list",
    post_create_resolution: "api/v1/resolution/create-resolution",
    post_upload_approval_documents: "api/v1/resolution/add-documents",
    //job-request-report
    get_job_request_report: "api/v1/job-rfq/job-request-report",
    
    //pqr
    approve_pqr: "api/v4/job/approve-pqr",
    update_pqr:  "api/v4/job/update-pqr",
    //activity-log
    log_activity: "api/v1/activity-log/create-activity-log",
    get_activity_log: "api/v1/activity-log/get-activity-log",
    get_latest_activity_log: "api/v1/activity-log/get-latest-activity",

    //requestStatusUpdate
    update_vendors_new_message_count:
      "api/v1/request-vendor/update-vendors-new-message-count",
    update_has_new_quotation: "api/v1/request-vendor/update-has-quotation",
    update_has_new_vendor: "/api/v1/request-vendor/update-has-new-vendor",
    update_has_new_approval: "api/v1/resolution/update-has-approval-status",

    //review apis
    get_all_review: "api/v1/vendor-review/all",
    create_review: "api/v1/vendor-review/create",
    update_review: "api/v1/vendor-review/update",
    open_experience: "api/v1/vendor-review/open-experience",
    save_review_details: "api/v1/vendor-review/save-review-details",

    //compare-quotations
    submit_compare_quotation_request: "api/v1/client-request/create-request",
    resubmit_compare_quotation_request: "api/v1/client-request/update-request",
    get_compared_quotation: "api/v1/client-request/get-request-jobId",

    // multiple file upload
    upload_files_to_permanent_folder: "api/v1/file/uploads",

    //custom-reports
    get_all_generated_reports_by_company:
      "api/v1/client-request/get-request-by-company/custom_report",

    //broadcast-message
    create_broadcast_group: "api/v1/broadcast-group/create-broadcast-group",
    get_broadcast_groups_for_job: "api/v1/broadcast-group/job",
    send_broadcast_message: "api/v1/broadcast-group/broadcast-message",

    // send email for file download
    send_email_for_file_download: "api/v1/client-request/email-for-download/",

    //UAC
    get_projects_roles: "api/v1/job-rfq/get-project-roles-by-clientId",
    get_project_lists: "api/v1/client-project/list-of-client-projects",

    // contact non-onboarded vendor
    contact_notification: "api/v1/vendor-profile/contact-notification",

    // indian states and cities
    indianStatesOrCitiesAPI: "https://countriesnow.space/api/v0.1/countries",

    //Shortlist Vendor to jobs
    get_client_jobs: "api/v1/job-rfq/get-job-by-client-gstn",
    add_vendor_to_shortlisted_jobs: "api/v1/job-rfq/add-vendor-jobs",


    /* revamp
     add all api end points post revamp after this section. 
     For any existing api move endpoint to this section. 
     ToDo: Post revamp unused endpoints to ve deleted
    */
   
   //dashboard
   get_clients_job_summary: "api/v1/dashboard/get-jobs-summary",
   get_vendor_categories_list: "/api/v1/vendor-list/categories-list",
   post_new_job: "api/v4/job/create",
   vendor_listing: "/api/v1/vendor-list/search?",
    vendor_listing_by_name: "/api/v1/vendor-list/searchByName?",
    //auction
    get_auctions: "api/v1/job-rfq",

    //vendor-profile
    enquire_vendor: "/api/v1/vendor-profile/send-enquiry-details",
    
  },
} as const;

export default ApiConfig;
