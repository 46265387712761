export const Types = {
  GET_USER_INFO_REQUESTED: "login/GET_USER_INFO_REQUESTED",
  GET_USER_INFO_SUCCESS: "login/GET_USER_INFO_SUCCESS",
  GET_USER_INFO_FAILURE: "login/GET_USER_INFO_FAILURE",

  SET_USER_INFO_REQUESTED: "login/SET_USER_INFO_REQUESTED",
  SET_USER_INFO_SUCCESS: "login/SET_USER_INFO_SUCCESS",
  SET_USER_INFO_FAILURE: "login/SET_USER_INFO_FAILURE",

  GET_PLANT_INFO_REQUESTED: "login/GET_PLANT_INFO_REQUESTED",
  GET_PLANT_INFO_SUCCESS: "login/GET_PLANT_INFO_SUCCESS",
  GET_PLANT_INFO_FAILURE: "login/GET_PLANT_INFO_FAILURE",

  SET_PLANT_INFO_REQUESTED: "login/SET_PLANT_INFO_REQUESTED",
  SET_PLANT_INFO_SUCCESS: "login/SET_PLANT_INFO_SUCCESS",
  SET_PLANT_INFO_FAILURE: "login/SET_PLANT_INFO_FAILURE",

  GET_USER_PLANT_INFO_COMPLETION_REQUESTED:
    "login/GET_USER_PLANT_INFO_COMPLETION_REQUESTED",
  GET_USER_PLANT_INFO_COMPLETION_SUCCESS:
    "login/GET_USER_PLANT_INFO_COMPLETION_SUCCESS",
  GET_USER_PLANT_INFO_COMPLETION_FAILURE:
    "login/GET_USER_PLANT_INFO_COMPLETION_FAILURE",

  UPDATE_LOGO_REQUEST: 'login/UPDATE_LOGO_REQUEST',
  UPDATE_LOGO_SUCCESS: 'login/UPDATE_LOGO_SUCCESS',
  UPDATE_LOGO_FAILURE: 'login/UPDATE_LOGO_FAILURE',

  RESET: 'login/RESET',

  GET_CLIENT_LIST_REQUESTED: 'login/GET_CLIENT_LIST_REQUESTED',
  GET_CLIENT_LIST_SUCCESS: 'login/GET_CLIENT_LIST_SUCCESS',
  GET_CLIENT_LIST_FAILURE: 'login/GET_CLIENT_LIST_FAILURE',

  SET_VENDOR_SHARE_REQUESTED: 'login/SET_VENDOR_SHARE_REQUESTED',
  SET_VENDOR_SHARE_SUCCESS: 'login/SET_VENDOR_SHARE_SUCCESS',
  SET_VENDOR_SHARE_FAILURE: 'login/SET_VENDOR_SHARE_FAILURE',
}
