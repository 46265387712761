import { StylesProps } from "../types";
import {
  space,
  color,
  shadow,
  typography,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
} from "../utils";

/** cotains all css styles */
export const styles = (props: StylesProps) => {
  const allStyles = [
    space(props),
    color(props),
    shadow(props),
    typography(props),
    layout(props),
    flexbox(props),
    grid(props),
    background(props),
    border(props),
    position(props),
  ];

  return allStyles;
};
