import { Types as DashboardTypes } from "_types/DashboardTypes"

export const ActionCreators: any = {
  getShortlistedData: (params: any) => ({
    types: [DashboardTypes.GET_SHORTLIST_REQUESTED,
    DashboardTypes.GET_SHORTLIST_SUCCESS,
    DashboardTypes.GET_SHORTLIST_FAILURE],
    promise: (client: any) => client.get('get_shortlisted_vendors', {
      params,
    }
    )
  }),
  getSuggestedData: (params: any) => ({
    types: [DashboardTypes.GET_SUGGESTED_REQUESTED,
    DashboardTypes.GET_SUGGESTED_SUCCESS,
    DashboardTypes.GET_SUGGESTED_FAILURE],
    promise: (client: any) => client.get('get_suggested_vendors', {
      params,
    }
    )
  }),
  getVendorCategories: () => ({
    types: [
      DashboardTypes.GET_VENDOR_CATEGORIES_REQUESTED,
      DashboardTypes.GET_VENDOR_CATEGORIES_SUCCESS,
      DashboardTypes.GET_VENDOR_CATEGORIES_FAILURE
    ],
    promise: (client: any) => client.get('get_vendor_categories_list')
  })
}

export default ActionCreators;