import * as CSS from "csstype";
import { getAppliedCssValues, getCSSOnlyStringValueFromCSSProperties } from "./helpers";
import { PositionProps, PartialCSSPropertiesHyphen } from "../types";

export const position = (props: PositionProps) => {
  const getCSSValue = (cssPropertiesArray: string[]) =>
    getCSSOnlyStringValueFromCSSProperties(props, cssPropertiesArray);

  const cssObject: PartialCSSPropertiesHyphen = {
    "z-index": getCSSValue(["zIndex"]) as CSS.ZIndexProperty,
    top: getCSSValue(["top"]) as CSS.TopProperty<string | 0>,
    right: getCSSValue(["right"]) as CSS.RightProperty<string | 0>,
    bottom: getCSSValue(["bottom"]) as CSS.BottomProperty<string | 0>,
    left: getCSSValue(["left"]) as CSS.LeftProperty<string | 0>,
    position: getCSSValue(["pos", "position"]) as CSS.PositionProperty,
  };

  return getAppliedCssValues(cssObject);
};
