import styled from "styled-components";
import { Modal } from "antd";

export const Base = styled.div`
  color: ${props => props.theme.colors.black};
  font-size: 12px;
`;

export const ModalBox = styled(Modal)`
  width: 600px !important;
  & .ant-modal-title {
    color: ${props => props.theme.colors.blue};
    font-weight: 600;
    font-size: 20px;
  }
  & .ant-modal-content {
    border-radius: 60px;
  }
  & .ant-modal-header {
    border-radius: 60px 60px 0 0;
    border: 0px;
    padding: 60px 0px 20px 60px;
  }
  & .ant-modal-close {
    margin: 30px;
  }
  & .ant-modal-footer {
    text-align: center;
    border-top: 0px;
    display: flex;
    justify-content: center;
    padding: 0px;
  }
  & .ant-modal-body {
    padding: 0px 60px 0px 60px;
    color: ${props => props.theme.colors.darkBlue};
    height: 50vh;
    overflow-y: auto;
    line-height: 170%;
    ::-webkit-scrollbar {
      width: 8px;
      margin-right: 60px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      width: 8px;
      height: 104px;
      background: #d5d9ec;
      border-radius: 10px;
    }
  }
`;

export const PrivacyModal = styled(Modal)`
  width: 600px !important;
  & .ant-modal-title {
    color: ${props => props.theme.colors.blue};
    font-weight: 600;
    font-size: 20px;
  }
  & .ant-modal-content {
    border-radius: 60px;
  }
  & .ant-modal-header {
    border-radius: 60px 60px 0 0;
    border: 0px;
    padding: 60px 0px 20px 60px;
  }
  & .ant-modal-close {
    margin: 30px;
  }
  & .ant-modal-footer {
    text-align: center;
    border-top: 0px;
    display: flex;
    justify-content: center;
    padding: 0px;
  }
  & .ant-modal-body {
    padding: 0px 60px 0px 60px;
    color: ${props => props.theme.colors.darkBlue};
    height: 50vh;
    overflow-y: auto;
    line-height: 170%;
    ::-webkit-scrollbar {
      width: 8px;
      margin-right: 60px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      width: 8px;
      height: 104px;
      background: #d5d9ec;
      border-radius: 10px;
    }
  }
`;

export const ButtonWrap = styled.div`
  width: 184px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 20px 0px;
`;
