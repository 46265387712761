import { Modal } from "antd";
import styled from "styled-components";
import { ModalProp } from "./AntdModal";

export const Base = styled.div`
  background-attachment: fixed;
`;

export const ModalBox = styled(Modal)<ModalProp>`
  & .ant-modal-content {
    border-radius: ${props => (props.modalBorderRadius || "40px")};
    width: ${props => (props.usedFor === "deleteMessage" ? "445px" : "100%")};
    text-align: ${props => (props.usedFor === "deleteMessage" ? "center" : "")};
    font-weight: 800;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
  }
  & .ant-modal-header {
    border-radius: 60px 60px 0 0;
    border: 0px;
    padding: ${props => (props.modalHeaderPadding || "60px 0px 16px 60px" )};
  }
  & .ant-modal-footer {
    text-align: center;
    border-top: 0px;
    display: ${props => (props.footerHidden ? "none" : "flex")};
    justify-content: center;
  }
  & .ant-modal-body {
    padding: ${props => (props.modalPadding || "0px 60px 40px 60px")};
    color: ${props =>
      props.usedFor === "compareVendors" || props.usedFor === "loginPopup" ? "#011759" : props.theme.colors.secondary};
    overflow-y: auto;
    line-height: 170%;
    text-align: justify;
    font-size: ${props => (props.usedFor === "compareVendors" || props.usedFor === "loginPopup") && "16px"};
    text-align: ${props => props.usedFor === "compareVendors" && "center"};
  }
  & .ant-modal-close {
    margin: ${props => (props.closeMargin || "30px")}
  }
  .ant-modal-close-x img {
    height: 24px;
    width: 24px;
  }
`;

export const Header = styled.div`
  display: flex;
`;

export const Title = styled.div<ModalProp>`
  color: ${props => props.titleHeadingColor || props.theme.colors.secondary};
  font-weight: 600;
  font-size: ${props => props.titleHeadingColor || "20px"};
  margin-right: 10px;
`;

export const ErrorMessage = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #be000b;
  display: flex;
  align-items: center;
`;

export const ButtonWrap = styled.div<ModalProp>`
  display: flex;
  justify-content: space-around;
  width: ${props => (props?.buttonDirectionColumn ? "70%" : "100%")};
  height: ${props => (props?.buttonDirectionColumn ? "200px" : "none")};
  padding-bottom: 30px;
  flex-direction: ${props => (props?.buttonDirectionColumn ? "column" : "row")};
`;
