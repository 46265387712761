import { Types as ProfileCreationTypes } from "_types/ProfileCreationTypes";

export const ActionCreators: any = {
  getUserInfo: (data: any) => ({
    types: [
      ProfileCreationTypes.GET_USER_INFO_REQUESTED,
      ProfileCreationTypes.GET_USER_INFO_SUCCESS,
      ProfileCreationTypes.GET_USER_INFO_FAILURE,
    ],
    promise: (client: any) => client.get("get_userinfo_data", {}),
  }),
  setVendorShare: (data: any) => ({
    types: [
      ProfileCreationTypes.SET_VENDOR_SHARE_REQUESTED,
      ProfileCreationTypes.SET_VENDOR_SHARE_SUCCESS,
      ProfileCreationTypes.SET_VENDOR_SHARE_FAILURE,
    ],
    promise: (client: any) => client.post("set_vendor_share", { data })
  }),
  setUserInfo: (data: any) => ({
    types: [
      ProfileCreationTypes.SET_USER_INFO_REQUESTED,
      ProfileCreationTypes.SET_USER_INFO_SUCCESS,
      ProfileCreationTypes.SET_USER_INFO_FAILURE,
    ],
    promise: (client: any) => client.post("set_userinfo_data", { data }),
  }),
  getPlantInfo: () => ({
    types: [
      ProfileCreationTypes.GET_PLANT_INFO_REQUESTED,
      ProfileCreationTypes.GET_PLANT_INFO_SUCCESS,
      ProfileCreationTypes.GET_PLANT_INFO_FAILURE,
    ],
    promise: (client: any) => client.get("get_plant_info"),
  }),
  setPlantInfo: (data: any) => ({
    types: [
      ProfileCreationTypes.SET_PLANT_INFO_REQUESTED,
      ProfileCreationTypes.SET_PLANT_INFO_SUCCESS,
      ProfileCreationTypes.SET_PLANT_INFO_FAILURE,
    ],
    promise: (client: any) => client.post("set_plant_info", { data }),
  }),
  getUserPlantInfoCompletion: () => ({
    types: [
      ProfileCreationTypes.GET_USER_PLANT_INFO_COMPLETION_REQUESTED,
      ProfileCreationTypes.GET_USER_PLANT_INFO_COMPLETION_SUCCESS,
      ProfileCreationTypes.GET_USER_PLANT_INFO_COMPLETION_FAILURE,
    ],
    promise: (client: any) => client.get("user_plant_info_completion"),
  }),
  updateLogo: (data: any) => ({
    types: [
      ProfileCreationTypes.UPDATE_LOGO_REQUEST,
      ProfileCreationTypes.UPDATE_LOGO_SUCCESS,
      ProfileCreationTypes.UPDATE_LOGO_FAILURE,
    ],
    promise: (client: any) => client.post("update_logo", { data }),
  }),
  reset: (data: any) => ({
    type: ProfileCreationTypes.RESET,
  }),
  getClients: (data: any) => ({
    types: [
      ProfileCreationTypes.GET_CLIENT_LIST_REQUESTED,
      ProfileCreationTypes.GET_CLIENT_LIST_SUCCESS,
      ProfileCreationTypes.GET_CLIENT_LIST_FAILURE,
    ],
    promise: (client: any) => client.get("get_clients_list"),
  }),
};

export default ActionCreators;
