import { Types as DashboardTypes } from "_types/DashboardTypes";

interface IDashboardState {
  loading: boolean;
  clientsjobSummary: any;
  error: string;
  activeAccordion: number;
}
const initialState: IDashboardState = {
  loading: false,
  clientsjobSummary: null,
  error: "",
  activeAccordion: 0,
};

const DashboardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DashboardTypes.GET_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DashboardTypes.GET_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case DashboardTypes.GET_CLIENT_JOB_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        clientsjobSummary: action.result,
      };

    case DashboardTypes.POST_NEW_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DashboardTypes.SET_DASHBOARD_ACTIVE_ACCORDION:
      return {
        ...state,
        activeAccordion: action.payload,
      };
    default:
      return state;
  }
};

export default DashboardReducer;
