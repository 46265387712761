import { Types as InviteTypes } from "_types/InviteTypes";

export const ActionCreators: any = {
  sendInvite: (data: any) => ({
    types: [
      InviteTypes.USER_INVITE_REQUESTED,
      InviteTypes.USER_INVITE_SUCCESS,
      InviteTypes.USER_INVITE_FAILURE,
    ],
    promise: (client: any) => client.post("send_invite_api", { data }),
  }),
  
  getInviteeList: (data: any) => ({
    types: [
      InviteTypes.GET_INVITEE_LIST_REQUESTED,
      InviteTypes.GET_INVITEE_LIST_SUCCESS,
      InviteTypes.GET_INVITEE_LIST_FAILURE,
    ],
    promise: (client: any) => client.get("get_invitee_list", { }),
  }),
  
  resendInvite: (data: any) => ({
    types: [
      InviteTypes.RESEND_INVITE_REQUESTED,
      InviteTypes.RESEND_INVITE_SUCCESS,
      InviteTypes.RESEND_INVITE_FAILURE,
    ],
    promise: (client: any) => client.post("resend_invite_api", { data }),
  }),

};

export default ActionCreators;
