import superagent from "superagent";
import ApiConfig from "./ApiConfig";
import ApiConstant from "constants/ApiConstant";
import LocalStorage from "./LocalStorage";
import SessionStorage from "./SessionStorage";

const methods = ["get", "post", "put", "patch", "del"];

const HOST_NAME = ApiConfig.hostname;
const COMMUNICATION_HOST_NAME = ApiConfig.communication_hostname;
const MOCK_SERVER_HOST_NAME = ApiConfig.mock_server_hostname;
const ENDPOINTS = ApiConfig.endpoints;

function formatUrl(path, hostType) {
  const HOSTNAME =
    hostType === "communication" ? COMMUNICATION_HOST_NAME : hostType === "mock" ? MOCK_SERVER_HOST_NAME : HOST_NAME;
  let mappedEndpoint = ENDPOINTS[path];
  if (path.includes("/")) {
    mappedEndpoint = "";
    const splitPathArray = path.split("/");
    mappedEndpoint += `${ENDPOINTS[splitPathArray[0]]}/`;
    splitPathArray.shift();
    mappedEndpoint += splitPathArray.join("/");
  }
  const adjustedPath = mappedEndpoint[0] !== "/" ? `${HOSTNAME}/${mappedEndpoint}` : HOSTNAME + mappedEndpoint;
  return adjustedPath;
}

const formatParams = params => {
  const formatedParams = params;
  for (const key in params) {
    if (Array.isArray(formatedParams[key])) {
      formatedParams[key] = formatedParams[key].join("|");
    }
  }
  return formatedParams;
};

export default class ApiClient {
  constructor() {
    methods.forEach(method => {
      this[method] = (path, { params, data, headers, files, fields } = {}, hostType) =>
        new Promise((resolve, reject) => {
          let request = superagent[method](formatUrl(path, hostType));

          if (path.includes("fakeapi")) {
            const fakePath = path;
            const splitPathArray = fakePath.split("/");
            splitPathArray.shift();
            const constructedURL = splitPathArray.join("/");
            request = superagent[method](`http://localhost:3004/${constructedURL}`).withCredentials();
          }
          if (params) {
            request.query(formatParams(params));
          }

          if (headers) {
            request.set(headers);
          }
          const userData = LocalStorage.get(ApiConstant.STORAGE_KEYS.isRememberEnabled)
            ? LocalStorage.get("userData")
            : SessionStorage.get("userData");
          const token = userData && userData.access_token ? userData.access_token : "007";

          if (token) {
            request.set("Authorization", `Bearer ${token}`);
          }

          if (files) {
            request.unset("Content-Type");
            files.forEach(file => request.attach(file.key, file.value));
          }

          if (fields) {
            fields.forEach(item => request.field(item.key, item.value));
          }

          if (data) {
            request.send(data);
          }

          request.end((err, { body } = {}) => (err ? reject(body || err) : resolve(body)));
        });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  empty() { }
}
