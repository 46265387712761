export const Types = {
  FORGOT_PASSWORD_REQUESTED: "auth/FORGOT_PASSWORD_REQUESTED",
  FORGOT_PASSWORD_SUCCESS: "auth/FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "auth/FORGOT_PASSWORD_FAILURE",
  RESET_PASSWORD_REQUEST: "auth/RESET_PASSWORD_REQUESTED",
  RESET_PASSWORD_SUCCESS: "auth/RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "auth/RESET_PASSWORD_FAILURE",
  RESET_STATE: "auth/RESET_STATE",
  SET_RESET_TIMER_FLAG: "auth/SET_RESET_TIMER_FLAG",
  LOG_OUT_SUCCESS: "auth/LOG_OUT_SUCCESS",
  SET_OTP_BOX_ERROR_STATE: "auth/SET_OTP_BOX_ERROR_STATE",

  GENERATE_MOBILE_OTP_FOR_LOGIN_REQUESTED: "auth/GENERATE_MOBILE_OTP_FOR_LOGIN_REQUESTED",
  GENERATE_MOBILE_OTP_FOR_LOGIN_SUCCESS: "auth/GENERATE_MOBILE_OTP_FOR_LOGIN_SUCCESS",
  GENERATE_MOBILE_OTP_FOR_LOGIN_FAILURE: "auth/GENERATE_MOBILE_OTP_FOR_LOGIN_FAILURE",

  // used for every email OTP verification
  VERIFY_EMAIL_OTP_REQUESTED: "auth/VERIFY_EMAIL_OTP_REQUESTED",
  VERIFY_EMAIL_OTP_SUCCESS: "auth/VERIFY_EMAIL_OTP_SUCCESS",
  VERIFY_EMAIL_OTP_FAILURE: "auth/VERIFY_EMAIL_OTP_FAILURE",

  // used for every mobile/phone-number OTP verification except - Login via Phone
  VERIFY_MOBILE_OTP_REQUESTED: "auth/VERIFY_MOBILE_OTP_REQUESTED",
  VERIFY_MOBILE_OTP_SUCCESS: "auth/VERIFY_MOBILE_OTP_SUCCESS",
  VERIFY_MOBILE_OTP_FAILURE: "auth/VERIFY_MOBILE_OTP_FAILURE",

  // types related to password-reset-link
  PASSWORD_RESET_LINK_VERIFY_EMAIL_SUCCESS: "auth/PASSWORD_RESET_LINK_VERIFY_EMAIL_SUCCESS",
  PASSWORD_RESET_LINK_UPDATE_USER_STATUS_SUCCESS: "auth/PASSWORD_RESET_LINK_UPDATE_USER_STATUS_SUCCESS",

  // common types
  AUTHENTICATION_COMMON_REQUESTED: "auth/AUTHENTICATION_COMMON_REQUESTED",
  AUTHENTICATION_COMMON_FAILURE: "auth/AUTHENTICATION_COMMON_FAILURE",
};
