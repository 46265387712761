import { Types as NavigationTypes } from "_types/NavigationTypes";

const initialState = {
 procurementSchedulerLink: '',
 error:''
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case NavigationTypes.GET_BUBBLE_LINK_REQUEST:
      return {
        ...state,
      };
    case NavigationTypes.GET_BUBBLE_LINK_SUCCESS:
      return {
        ...state,
        procurementSchedulerLink: action.result.link
      };
    case NavigationTypes.GET_BUBBLE_LINK_FAILURE:
      return {
        ...state,
        error: action.result
      };
    default:
      return state;
    }
  }