import styled from "styled-components";
import { Button } from "components/Button";
import { Select } from "antd";
import { Radio } from "antd";

export const ForgotPassword = styled.a`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration-line: underline;
  color: #215ec9;
  width: max-content;
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #215ec9;
`;

export const Input = styled.input`
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 1.5px solid #215ec9;
  box-sizing: border-box;
`;

export const Wrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1200px) {
    margin-top: 30px;
  }
`;

export const ButtonComp = styled(Button)`
  padding: 1.25rem 4.1875rem;
  color: white;
  width: 440px;
  height: 60px;
  font-size: 14px;
  font-weight: 800;
  @media screen and (max-width: 1200px) {
    width: 375px;
  }
`;

export const InputWrapper = styled.div`
  margin: 15px 0px 0px 0px;
`;

export const PasswordInstruction = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  margin-top: 5px;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
`;

export const TextWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #011759;
  margin: 1.5rem 0 1rem;
`;

export const ErrorMessageDiv = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #be000b;
  padding-top: 5px;
`;

export const ImageHolder = styled.div`
  background-image: url("/images/warning.svg");
  background-size: cover;
  margin-right: 3px;
  height: auto;
  width: 16px;
  height: 16px;
`;

// --------- Plant Info & Otp Screen Styles ---------
export interface PlantInfoProps {
  onCall: (value: string) => void;
}

export const FormContainer = styled.div`
  width: 100%;
  height: 35vh;
`;

export const SelectDropdown = styled(Select)<any>`
  box-shadow: ${props => props?.$focusState && "0px 0px 12px rgba(159, 179, 248, 0.4)"};

  .ant-select-selector {
    height: 60px !important;
    border-radius: 12px !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #011759 !important;
    opacity: ${props => props?.$setOpacity};
    background: #ffffff;
  }

  .ant-select-selection-item {
    margin: 10px 0px !important;
  }
  .ant-select-selection-placeholder {
    margin: 10px 0px !important;
    color: #112b7a;
    opacity: 0.4;
  }
  .ant-select-selection-search {
    margin-top: 10px;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    padding: 30px 10px 0px 10px;
  }
`;

export const BackNavigation = styled.div`
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;

  color: #215ec9;
`;

export const RadioBttn = styled(Radio)`
  .ant-radio-inner {
    border-width: 2px;
    border-color: #215ec9;
  }
  .ant-radio-inner::after {
    top: 2px;
    left: 2px;
    background-color: #215ec9;
  }
`;

export const SelectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
`;

export const SelectionHeader = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const StyledForm = styled.form`
  width: 100%;
`;
